import { ClassData } from '@models/class-data';
import { RSVPLayout } from '@models/event-attendance/rsvp-layout.enum';
import { EventRSVPSettingsText } from '@models/events/event-rsvp-settings-text.model';
import { RSVPFormAppearance } from '@models/events/rsvp-form-appearance.enum';
export class EventRSVPSettings {
  id: number;
  eventTemplateId: number;
  layout: RSVPLayout;
  formAppearance: RSVPFormAppearance;
  rsvpWithoutEmail: boolean;
  rsvpWithoutPhone: boolean;
  rsvpWithoutAccount: boolean;
  requireGroupRsvp: boolean;
  requireGroupRsvpLabel: boolean;
  plusOnesAdditionalData: boolean;
  showPlusOnesFirstName: boolean;
  showPlusOnesLastName: boolean;
  showPlusOnesEmail: boolean;
  showPlusOnesPhone: boolean;
  texts: EventRSVPSettingsText[];
  backgroundColor: string;
  bodyColor: string;
  borderRadius?: number;
  borderColor: string;
  buttonBgColor: string;
  buttonTextColor: string;
  buttonBorderRadius: string;
  constructor(json: ClassData<EventRSVPSettings>) {
    this.id = +json.id;
    this.eventTemplateId = json.eventTemplateId;
    this.layout = json.layout;
    this.formAppearance = json.formAppearance;
    this.rsvpWithoutEmail = json.rsvpWithoutEmail;
    this.rsvpWithoutPhone = json.rsvpWithoutPhone;
    this.rsvpWithoutAccount = json.rsvpWithoutAccount;
    this.requireGroupRsvp = json.requireGroupRsvp;
    this.requireGroupRsvpLabel = json.requireGroupRsvpLabel;
    this.plusOnesAdditionalData = json.plusOnesAdditionalData;
    this.showPlusOnesFirstName = json.showPlusOnesFirstName;
    this.showPlusOnesLastName = json.showPlusOnesLastName;
    this.showPlusOnesEmail = json.showPlusOnesEmail;
    this.showPlusOnesPhone = json.showPlusOnesPhone;
    this.texts = json.texts;
    this.backgroundColor = json.backgroundColor;
    this.bodyColor = json.bodyColor;
    if (json.borderRadius !== undefined) {
      this.borderRadius = +json.borderRadius;
    }
    this.borderColor = json.borderColor;
    this.buttonBgColor = json.buttonBgColor;
    this.buttonTextColor = json.buttonTextColor;
    this.buttonBorderRadius = json.buttonBorderRadius;
  }
  isRsvpWithoutEmail(): boolean {
    return this.rsvpWithoutEmail;
  }

  hasPlusOnesAdditionalData(): boolean {
    if (!this.plusOnesAdditionalData) {
      return false;
    }

    return (
      this.showPlusOnesFirstName ||
      this.showPlusOnesLastName ||
      this.showPlusOnesEmail ||
      this.showPlusOnesPhone
    );
  }
}
