import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FormsModules } from '@utils/shared-modules';
import { RouterModule } from '@angular/router';
import { RegisterSuccess } from '@models/sign-up/register-success.model';

@Component({
  selector: 'app-sign-up-step1',
  standalone: true,
  imports: [CommonModule, TranslateModule, FormsModules, RouterModule],
  templateUrl: './sign-up-step1.component.html',
  styleUrl: './sign-up-step1.component.scss',
})
export class SignUpStep1Component implements OnChanges {
  form: FormGroup;

  showPassword = false;

  readonly termsAndConditionsLink = '/terms-and-conditions';

  @Input() selectedOption: string = 'phone';
  @Input() email?: string;
  @Input() phone?: string;
  @Input() hasNoAccount: boolean = false;
  @Input() signUpIsInProgress: boolean = false;
  @Input() isCommunity: boolean = false;
  @Output() registerSuccess: EventEmitter<RegisterSuccess> = new EventEmitter();
  @Output() next: EventEmitter<any> = new EventEmitter();

  constructor() {
    this.form = new FormGroup({
      name: new FormControl('', [Validators.required]),
    });

    if (this.selectedOption === 'email') {
      this.form.addControl(
        'password',
        new FormControl('', Validators.required),
      );
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.selectedOption === 'email') {
      if (!this.form.get('password')) {
        this.form.addControl(
          'password',
          new FormControl('', Validators.required),
        );
      }
    } else {
      this.form.removeControl('password');
    }
  }

  signUp(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    if (!this.email && !this.phone) {
      return;
    }

    this.next.emit(this.form.value);
  }
}
