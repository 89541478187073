<ng-container>
  <h1>{{ "APP.AUTH.CREATE_ACCOUNT_INFO_TITLE" | translate }}</h1>
  <div class="subheader subheader-sm">
    {{ "APP.AUTH.CREATE_ACCOUNT_INFO_SUBHEADER" | translate }}
  </div>

  <div class="form-380">
    <form class="form" [formGroup]="form" (keyup.enter)="signUp()">
      <mat-form-field
        class="w-100"
        [class.has-value]="form.controls['name'].value !== ''"
        appearance="outline"
      >
        <mat-label>{{ "APP.AUTH.WHATS_YOUR_NAME" | translate }}</mat-label>
        <input
          name="singUpName"
          type="text"
          matInput
          formControlName="name"
          placeholder=""
          autocomplete="off webauthn"
        />
        @if (form.controls["name"].hasError("required")) {
          <mat-error>
            {{ "APP.AUTH.ERRORS.NAME_REQUIRED" | translate }}
          </mat-error>
        }
      </mat-form-field>

      @if (selectedOption === "email") {
        <mat-form-field
          class="w-100"
          [class.has-value]="form.controls['password'].value !== ''"
          appearance="outline"
        >
          <mat-label>{{ "APP.AUTH.ENTER_PASSWORD" | translate }}</mat-label>
          <input
            name="singUpPassword"
            matInput
            formControlName="password"
            placeholder=""
            autocomplete="off webauthn"
            type="{{ showPassword ? 'text' : 'password' }}"
          />
          <i
            matSuffix
            (click)="showPassword = !showPassword"
            class="fal show-password-suffix"
            [class.fa-eye]="!showPassword"
            [class.fa-eye-slash]="showPassword"
          ></i>
          @if (
            form.controls["password"].hasError("strong") &&
            !form.controls["password"].hasError("required")
          ) {
            <mat-error>
              {{ "APP.AUTH.ERRORS.PASSWORD_INVALID" | translate }}
            </mat-error>
          } @else if (form.controls["password"].hasError("required")) {
            <mat-error>{{
              "APP.AUTH.ERRORS.PASSWORD_REQUIRED" | translate
            }}</mat-error>
          }
        </mat-form-field>
      }

      <div class="action-btn-container">
        <button
          type="button"
          class="btn btn-primary-party-page"
          [class.btn-community]='isCommunity'
          (click)="signUp()"
          [class.spinner]="signUpIsInProgress"
          [disabled]="signUpIsInProgress"
        >
          {{ "APP.NEXT" | translate }}
        </button>
      </div>
    </form>
  </div>
</ng-container>
