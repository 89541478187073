<div class="date-card" [class.brand-colors]='brandColors' [class.card-colors]='cardColors'>
  <div class="date-month">{{ dateMonth }}</div>
  <div class="date-info">
    <div class="date-date">{{ dateDate }}</div>
    @if(!monthOnly){
      <div class="date-day">{{ dateDay }}</div>
    }

  </div>
</div>
