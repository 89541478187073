export enum EventSectionType {
  MAIN = 'MAIN',
  TIMELINE = 'TIMELINE',
  LOCATION = 'LOCATION',
  GROOMSMEN = 'GROOMSMEN',
  RSVP = 'RSVP',
  WISHLIST = 'WISHLIST',
  FAQ = 'FAQ',
  NORMAL = 'NORMAL',
  GALLERY = 'GALLERY',
  FOOTER = 'FOOTER',
  SPONSORS = 'SPONSORS',
  TESTIMONIALS = 'TESTIMONIALS',
  EVENT_LIST = 'EVENT_LIST',
  DATE_LOCATION = 'DATE_LOCATION',
}
