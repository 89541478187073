import {
  AfterViewInit,
  Component,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { DesignTemplateOverlayPageComponent } from '../../create-template/dialogs/design-template-overlay-page/design-template-overlay-page.component';
import { FilterComponent } from '@modules/shared/components/filter/filter.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { PaginatorComponent } from '@modules/shared/components/paginator/paginator.component';
import { EventTemplateDataSource } from '@ds/event-template.ds';
import { EventTemplateService } from '@services/events/event-template.service';
import { NotificationService } from '@services/shared/notification.service';
import { SelectionModel } from '@angular/cdk/collections';
import { EventTemplateAccess } from '@models/design-templates/event-template-access.enum';
import { EventTemplateRelevance } from '@models/design-templates/event-template-relevance.enum';
import { CustomDialogData } from '@models/overlay-dialogs/custom-dialog-data.model';
import { CustomDialogComponent } from '@modules/shared/dialogs/custom-dialog/custom-dialog.component';
import { Clipboard } from '@angular/cdk/clipboard';
import { AppRoutes } from '../../../../../routes';
import { Router } from '@angular/router';
import { EventTemplate } from '@models/design-templates/event-template.model';
import { PageEvent } from '@angular/material/paginator';
import { EventCategory } from '@models/event-categories/event-category.model';
import { environment as env } from '@environments/environment';
import { EventTemplateAccessRequest } from '@models/design-templates/dto/event-template-access.request';
import { QRCodeModule } from 'angularx-qrcode';
import { AuthService } from '@services/auth/auth.service';
import { User } from '@models/users/user.model';
import { CopyTemplatePublicUrlOrCreateQrCodeChooseLanguageDialog } from '@modules/events/dialogs/copy-template-public-url-or-create-qr-code-choose-language/copy-template-public-url-or-create-qr-code-choose-language.dialog';
import { Helpers } from '@utils/helpers';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-design-template-list',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FilterComponent,
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatTableModule,
    QRCodeModule,
    PaginatorComponent,
  ],
  providers: [EventTemplateService, NotificationService],
  templateUrl: './design-template-list.page.html',
  styleUrl: './design-template-list.page.scss',
})
export class DesignTemplateListPage
  implements OnInit, OnChanges, AfterViewInit
{
  @ViewChild(FilterComponent) filter!: FilterComponent;
  @ViewChild(PaginatorComponent) paginator!: PaginatorComponent;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;

  dataSource: EventTemplateDataSource;

  displayedColumns: string[] = [
    'select',
    'name',
    'id',
    'access',
    'relevance',
    'creationDate',
    'category',
    'subcategory',
    'language',
    'views',
    'clicks',
    'ctr',
    'actions',
  ];

  selection = new SelectionModel<any>(true, []);

  pageSizeOptions: number[] = [5, 10, 15, 20, 50, 100]; // Options for the user to change the page size

  loggedUser?: User;
  currentLang = 'de';

  constructor(
    private dialog: MatDialog,
    private eventTemplateService: EventTemplateService,
    private notificationService: NotificationService,
    private clipboard: Clipboard,
    private translateService: TranslateService,
    private router: Router,
    private authService: AuthService,
  ) {
    this.trackUserChanges();

    this.dataSource = new EventTemplateDataSource(this.eventTemplateService);

    this.translateService.onLangChange
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        this.currentLang = this.translateService.currentLang;
      });

    this.currentLang = this.translateService.currentLang;
  }

  ngOnInit() {
    // Set the initial page
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Check if the 'currentPage' or 'products' have changed
    this.refresh();
  }

  ngAfterViewInit() {
    // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => {
      this.resetPageIndex();
      this.refresh();
    });

    this.refresh();
  }

  addDesignTemplate() {
    const dialogRef = this.dialog.open(DesignTemplateOverlayPageComponent, {
      width: '100%',
      height: '100%',
      maxWidth: '100%',
      panelClass: 'overlay-page-dialog',
      data: {},
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.filter?.onCreateNewProduct(true);
        this.resetPageIndex();
        this.refresh();
      }
    });
  }

  onFilterChange(event: any) {
    this.resetPageIndex();
    this.refresh();
  }

  onPageChange(event: PageEvent) {
    this.refresh();
  }

  private resetPageIndex() {
    this.paginator.pager.currentPage = 1;
  }

  refresh() {
    const filters = new Map<string, any>();

    if (this.filter?.searchText) {
      filters.set('searchTerm', this.filter.searchText);
    }

    if (this.filter?.selectedCategories.length > 0) {
      if (this.filter?.selectedSubcategories.length > 0) {
        filters.set(
          'eventSubCategoryIds',
          this.filter.selectedSubcategories.map((sc) => sc.id),
        );
      } else {
        filters.set(
          'eventCategoryIds',
          this.filter.selectedCategories.map((c) => c.id),
        );
      }
    } else {
      filters.set(
        'eventSubCategoryIds',
        this.filter.selectedSubcategories.map((sc) => sc.id),
      );
    }

    if (this.filter?.selectedAccess) {
      filters.set('access', this.filter.selectedAccess);
    }

    if (this.filter?.selectedRelevance) {
      filters.set('relevance', this.filter.selectedRelevance);
    }

    if (!!this.sort.direction) {
      filters.set('sortDirection', this.sort.direction);
      filters.set('sortProperty', this.sort.active);
    }

    if (this.loggedUser) {
      filters.set('languageCode', this.loggedUser.language);
    }

    this.dataSource.loadEventTemplates(
      filters,
      this.paginator.pager.currentPage !== 0
        ? this.paginator.pager.currentPage
        : 1,
      this.paginator.pageSize || 10,
    );
  }

  private trackUserChanges() {
    this.authService.userSubject
      .pipe(takeUntilDestroyed())
      .subscribe((user) => {
        this.loggedUser = user;
      });
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource?.data?.forEach((row) => this.selection.select(row));
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource?.data?.length;
    return numSelected === numRows;
  }

  toggleSelection(row: any) {
    this.selection.toggle(row);
  }

  getDisplayStatus(status: EventTemplateAccess) {
    return EventTemplateAccess.getDisplayName(status);
  }

  getDisplayRelevance(relevance: EventTemplateRelevance) {
    return EventTemplateRelevance.getDisplayName(relevance);
  }

  getEventCategoryNames(eventCategories: EventCategory[]) {
    return eventCategories.map((category) => category.name).join(', ');
  }

  getEventSubcategoryNames(eventSubcategories: EventCategory[]) {
    return eventSubcategories.map((category) => category.name).join(', ');
  }

  getTranslationLanguageNames(languageCode: string) {
    if (languageCode === 'de') {
      return this.translateService.instant('APP.LANGUAGES.DE');
    }
    if (languageCode === 'en') {
      return this.translateService.instant('APP.LANGUAGES.EN');
    }
  }

  editEventTemplateForLanguage(
    languageCode: string,
    eventTemplate: EventTemplate,
  ) {
    if (eventTemplate.languageCode === languageCode) {
      this.editEventTemplate(languageCode, eventTemplate);
    } else {
      const translation = eventTemplate.getTranslation();
      if (translation?.languageCode === languageCode) {
        this.eventTemplateService
          .get(translation.id, languageCode)
          .subscribe((newEventTemplate) => {
            eventTemplate = newEventTemplate;

            this.editEventTemplate(languageCode, eventTemplate);
          });
      }
    }
  }

  editEventTemplate(languageCode: string, eventTemplate: EventTemplate) {
    const dialogRef = this.dialog.open(DesignTemplateOverlayPageComponent, {
      width: '100%',
      height: '100%',
      maxWidth: '100%',
      panelClass: 'overlay-page-dialog',
      data: {
        isEdit: true,
        isFromPageTable: true,
        languageCode: languageCode,
        eventTemplate: eventTemplate,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.filter?.onCreateNewProduct(true);
        this.resetPageIndex();
        this.refresh();
      }
    });
  }

  copyTemplateId(eventTemplateId: number) {
    this.clipboard.copy(eventTemplateId.toString());

    this.notificationService.success(
      this.translateService.instant('APP.DESIGN_TEMPLATES.SUCCESS.ID_COPIED'),
    );
  }

  duplicateTemplate(eventTemplateId: number) {
    this.eventTemplateService.duplicate(eventTemplateId).subscribe({
      next: () => {
        this.refresh();
        this.notificationService.success(
          this.translateService.instant(
            'APP.DESIGN_TEMPLATES.SUCCESS.TEMPLATE_DUPLICATED',
          ),
        );
      },
      error: () => {
        this.notificationService.error(
          this.translateService.instant(
            'APP.DESIGN_TEMPLATES.ERRORS.TEMPLATE_DUPLICATE',
          ),
        );
      },
    });
  }

  copyTemplateLink(eventTemplateId: number) {
    this.clipboard.copy(
      env.website +
        '/' +
        AppRoutes.Admin.root +
        '/' +
        AppRoutes.Admin.DesignTemplates.designTemplates +
        '/' +
        eventTemplateId,
    );

    this.notificationService.success(
      this.translateService.instant('APP.DESIGN_TEMPLATES.SUCCESS.LINK_COPIED'),
    );
  }

  copyTemplatePublicUrl(eventTemplate: EventTemplate) {
    const dialogRef = this.dialog.open(
      CopyTemplatePublicUrlOrCreateQrCodeChooseLanguageDialog,
      {
        maxWidth: '602px',
        maxHeight: '100%',
        width: '100%',
        height: 'auto',
        panelClass: ['normal-dialog', 'rsvp-dialog', 'overlay-theme'],
        data: {
          isCopyTemplatePublicUrl: true,
          eventTemplate: eventTemplate,
        },
      },
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.clipboard.copy(result);
        this.notificationService.success(
          this.translateService.instant(
            'APP.DESIGN_TEMPLATES.SUCCESS.LINK_COPIED',
          ),
        );
      }
    });

    // this.clipboard.copy(eventTemplate.getLink());
  }

  pauseTemplate(eventTemplate: EventTemplate) {
    const dto: EventTemplateAccessRequest = {
      access: EventTemplateAccess.INACTIVE,
      restore: false,
    };

    this.eventTemplateService.setAccess(eventTemplate.id, dto).subscribe(() => {
      this.resetPageIndex();
      this.refresh();
    });
  }

  continueTemplate(eventTemplate: EventTemplate) {
    const dto: EventTemplateAccessRequest = {
      restore: true,
    };

    this.eventTemplateService.setAccess(eventTemplate.id, dto).subscribe(() => {
      this.resetPageIndex();
      this.refresh();
    });
  }

  archiveTemplate(eventTemplate: EventTemplate) {
    const dto: EventTemplateAccessRequest = {
      access: EventTemplateAccess.ARCHIVED,
      restore: false,
    };

    this.eventTemplateService.setAccess(eventTemplate.id, dto).subscribe(() => {
      this.resetPageIndex();
      this.refresh();
    });
  }

  restoreTemplate(eventTemplate: EventTemplate) {
    const dto: EventTemplateAccessRequest = {
      restore: true,
    };

    this.eventTemplateService.setAccess(eventTemplate.id, dto).subscribe(() => {
      this.resetPageIndex();
      this.refresh();
    });
  }

  deleteDesignTemplate(eventTemplate: EventTemplate) {
    const question = 'APP.DESIGN_TEMPLATES.DELETE_DIALOG.DELETE_MESSAGE';
    const warning = 'APP.DESIGN_TEMPLATES.DELETE_DIALOG.DELETE_WARNING';
    const cancelButtonMessage =
      'APP.DESIGN_TEMPLATES.DELETE_DIALOG.ACTION_BUTTONS.CANCEL';
    const deleteButtonMessage =
      'APP.DESIGN_TEMPLATES.DELETE_DIALOG.ACTION_BUTTONS.CONFIRM';

    const data: CustomDialogData = {
      isDeleteDialog: true,
      question: question,
      warning: warning,
      id: eventTemplate.id,
      title: eventTemplate.name.toUpperCase(),
      access: eventTemplate?.access,
      relevance: eventTemplate?.relevance,
      performance: {
        views: eventTemplate?.eventTemplateStats.totalViews,
        uses: eventTemplate?.eventTemplateStats.totalUses,
        utr: eventTemplate?.eventTemplateStats.totalUtr,
      },
      cancelButtonMessage: cancelButtonMessage,
      deleteButtonMessage: deleteButtonMessage,
    };

    const dialogRef = this.dialog.open(CustomDialogComponent, {
      maxWidth: '456px',
      width: '100%',
      height: 'auto',
      data: data,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.eventTemplateService.delete(eventTemplate.id).subscribe(() => {
          this.resetPageIndex();
          this.refresh();
        });
      }
    });
  }

  onRowClick(eventTemplate: EventTemplate, event: MouseEvent) {
    if (eventTemplate) {
      const selectedText = window.getSelection()?.toString();

      if (
        !selectedText ||
        !Helpers.isSelectionInsideRow(event.currentTarget as HTMLElement)
      ) {
        this.router.navigate([
          '/',
          AppRoutes.Admin.root,
          AppRoutes.Admin.DesignTemplates.designTemplates,
          eventTemplate.id,
        ]);
      }
    }
  }

  getEventTemplateQRCodeUrl(eventTemplate: EventTemplate) {
    const dialogRef = this.dialog.open(
      CopyTemplatePublicUrlOrCreateQrCodeChooseLanguageDialog,
      {
        maxWidth: '602px',
        maxHeight: '100%',
        width: '100%',
        height: 'auto',
        panelClass: ['normal-dialog', 'rsvp-dialog', 'overlay-theme'],
        data: {
          isCreateQrCode: true,
          eventTemplate: eventTemplate,
        },
      },
    );

    dialogRef.afterClosed().subscribe((result) => {});
  }
}
