@if (event && visual && font && sections) {
  <div class="page-container" [class.host-view]="isHostView">
    <div
      class="main-content"
      [ngStyle]="{
        'background-image': 'url(' + event.getEncodedMainImage() + ')',
        'background-color': visual.backgroundColor
      }"
    >
      <div class="overlay"></div>
      <div class="event-info-cont">
        <div class="event-name primary-font">{{ event.name }}</div>
        <div class="event-description body-font">{{ event.description }}</div>
      </div>
      <div class="rsvp-btn-cont">
        <div class="btn btn-weddingpage-transparent" (click)="goToRSVP()">
          {{ "APP.EVENT_RSVP.LET_US_KNOW" | translate }}
        </div>
        @if (event.getRsvpDisableDate()) {
          <div class="rsvp-disable-date">
            {{ "APP.EVENT_RSVP.UNTIL" | translate }}
            {{ event.getRsvpDisableDate() | date: "dd. MMMM yyyy" }}
          </div>
        }
      </div>
    </div>

    <div class="event-sections">
      @for (section of sections; track section.id) {
        <app-event-section
          [section]="section"
          [loggedUser]="loggedUser"
        ></app-event-section>
      }

      <app-footer
        class="after-footer"
        [showLogo]="true"
        [backgroundColor]="
          font.sectionAccentBackgroundColor !== ''
            ? font.sectionAccentBackgroundColor
            : 'rgba(255, 255, 255, 0.7)'
        "
      ></app-footer>
    </div>
  </div>
}
