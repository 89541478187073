import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnChanges,
  SimpleChanges,
  OnDestroy,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { CommunityAdditionalDataField } from '@models/communities/community-additional-data-field.model';
import { QuestionType } from '@models/event-attendance/event-attendee-question-type.enum';
import { EventAttendeeQuestion } from '@models/event-attendance/event-attendee-question.model';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModules } from '@utils/shared-modules';
import { debounceTime, distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import { TextEditableDirective } from '../../../../directives/text-editable.directive';

@Component({
  selector: 'app-event-rsvp-question',
  standalone: true,
  imports: [CommonModule, FormsModules, TranslateModule, TextEditableDirective],
  templateUrl: './event-rsvp-question.component.html',
  styleUrl: './event-rsvp-question.component.scss',
})
export class EventRsvpQuestionComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() form?: FormGroup;
  @Input() question?: EventAttendeeQuestion | CommunityAdditionalDataField;
  @Input() ngModelVal: any;
  @Input() disabled: boolean = false;
  @Input() editDisabled: boolean = false;

  @Input() matFormFieldClass = 'flex-input';
  @Input() matFormFieldAppearance: MatFormFieldAppearance = 'outline';

  @Output() ngModelValChange = new EventEmitter<any>();
  @Output() onChangeValue = new EventEmitter<any>();

  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();

  constructor(private fb: FormBuilder) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.updateQuestionControl();
  }

  ngOnInit(): void {
    if (this.form && this.question) {
      this.addQuestionControl();
    }
  }

  onChange(controlName: any) {
    if (this.form?.controls[controlName]) {
      const value = this.form.controls[controlName].value;
      this.onChangeValue.emit(value);
    }
  }

  updateQuestionControl() {
    if (this.form && this.question) {
      const controlName = `question#${this.question.id}`;
      const control = this.form.get(controlName);

      if (control) {
        control.setValue(this.ngModelVal || '');
      }
    }
  }

  addQuestionControl() {
    if (this.question && this.form) {
      const controlName = `question#${this.question.id}`;
      const control = this.fb.control(
        this.ngModelVal || '',
        this.question.isRequired ? Validators.required : [],
      );

      if (this.disabled) {
        control.disable();
      }

      this.form.addControl(controlName, control);
      this.form
        .get(controlName)
        ?.valueChanges.pipe(
          takeUntil(this._onDestroy),
          debounceTime(250),
          distinctUntilChanged(),
        )
        .subscribe((value) => {
          this.onChangeValue.emit(value);
        });
    }
  }

  onNgModelChange(val: any): void {
    this.ngModelValChange.emit(val);
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  protected readonly EventAttendeeQuestionType = QuestionType;
}
