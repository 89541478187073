import { Component, Input, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';
import { ScreenWidthService } from '@services/shared/screen-width.service';
import { FormsModules } from '@utils/shared-modules';
import {
  DateRange,
  DefaultMatCalendarRangeStrategy,
  MAT_DATE_RANGE_SELECTION_STRATEGY,
  MatCalendarCellCssClasses,
} from '@angular/material/datepicker';
import moment from 'moment-timezone';
import {
  SelectionSize,
  TimePickerComponent,
} from '../time-picker/time-picker.component';
import { FormGroup } from '@angular/forms';
import { TimezoneSelectComponent } from '@modules/shared/component/timezone-select/timezone-select.component';
import { TranslateModule } from '@ngx-translate/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-date-time-range-picker',
  standalone: true,
  imports: [
    CommonModule,
    FormsModules,
    TranslateModule,
    TimePickerComponent,
    TimezoneSelectComponent,
    MatTooltip,
  ],
  providers: [
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: DefaultMatCalendarRangeStrategy,
    },
    ScreenWidthService,
  ],
  templateUrl: './date-time-range-picker.component.html',
  styleUrl: './date-time-range-picker.component.scss',
})
export class DateTimeRangePickerComponent implements OnDestroy {
  selectedDateRange: DateRange<Date> | null = null;

  todayDate = new Date();

  @Input() dateClass: () => (date: Date) => MatCalendarCellCssClasses = () => {
    return (date: Date): MatCalendarCellCssClasses => {
      return '';
    };
  };

  private _form?: FormGroup;
  isDesktop: boolean = false;

  @Input() set form(form: FormGroup) {
    this._form = form;

    const startDate = form.controls['startDate'].value;
    const endDate = form.controls['endDate'].value;

    this.selectedDateRange = new DateRange(startDate, endDate);

    if (this.form) {
      this.form.controls['endTime'].valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe((val) => {
          const endDate = this.form?.controls['endDate'].value;
          if (
            val &&
            this.selectedDateRange &&
            this.selectedDateRange.start &&
            !this.selectedDateRange.end &&
            !endDate
          ) {
            this.selectedDateRange = new DateRange(
              this.selectedDateRange.start,
              this.selectedDateRange.start,
            );
            this.form?.controls['endDate'].patchValue(
              this.selectedDateRange.end,
            );
          }
        });
    }
  }

  get form(): FormGroup | undefined {
    return this._form;
  }

  @Input() startAt: Date | null = null;

  removeEndTime(event: any) {
    event.stopPropagation();
    if (this.form && this.selectedDateRange) {
      this.form.controls['endDate'].patchValue(null);
      this.selectedDateRange = new DateRange(
        this.selectedDateRange.start,
        null,
      );
    }
  }

  addEndDate(event: any) {
    event.stopPropagation();
    if (this.form && this.selectedDateRange && this.selectedDateRange.start) {
      const endDate = new Date(this.selectedDateRange.start.getTime());
      endDate.setHours(endDate.getHours() + 3);
      this.form.controls['endDate'].patchValue(endDate);
      this.form.controls['endTime'].patchValue(moment(endDate).format('HH:mm'));
      this.selectedDateRange = new DateRange(
        this.selectedDateRange.start,
        endDate,
      );
      this.form.controls['endDate'].patchValue(endDate);
      this.isStartDateSelection = false;
    }
  }

  isStartDateSelection = true;

  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();

  constructor(private screenWidthService: ScreenWidthService) {
    this.screenWidthService
      .isDesktop()
      .pipe(takeUntilDestroyed())
      .subscribe((isDesktop) => {
        this.isDesktop = isDesktop;
      });
  }

  _onSelectedChange(date: Date | null): void {
    if (date === null) {
      this.selectedDateRange = null;
    } else if (
      this.selectedDateRange &&
      this.selectedDateRange.start &&
      date > this.selectedDateRange.start &&
      !this.selectedDateRange.end
    ) {
      this.selectedDateRange = new DateRange(
        this.selectedDateRange.start,
        date,
      );
    } else {
      this.selectedDateRange = new DateRange(date, null);
    }

    if (this.form) {
      if (this.selectedDateRange) {
        this.form.controls['startDate'].patchValue(
          this.selectedDateRange.start,
        );
        this.form.controls['endDate'].patchValue(this.selectedDateRange.end);
      } else {
        this.form.controls['startDate'].patchValue(null);
        this.form.controls['endDate'].patchValue(null);
      }
    }
  }

  onStartDateSelection() {
    this.isStartDateSelection = true;
  }

  onEndDateSelection(event: any) {
    this.isStartDateSelection = false;

    if (this.selectedDateRange?.end && !this.isDesktop) {
      this.removeEndTime(event);
    }
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  protected readonly SelectionSize = SelectionSize;
}
