import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment as env } from '@environments/environment';
import { BehaviorSubject, Observable, catchError, map, throwError } from 'rxjs';
import { GoogleFont } from '@models/shared/google-font.model';
import { Helpers } from '@utils/helpers';

@Injectable({
  providedIn: 'root',
})
export class FontService {
  private _googleFonts: BehaviorSubject<GoogleFont[]> = new BehaviorSubject<
    GoogleFont[]
  >([]);

  public readonly googleFonts: Observable<GoogleFont[]> =
    this._googleFonts.asObservable();

  constructor(private http: HttpClient) {}

  fetchGoogleFonts() {
    if (this._googleFonts && this._googleFonts.getValue().length > 0) {
      return;
    }

    return this.http
      .get(
        `https://www.googleapis.com/webfonts/v1/webfonts?key=${env.googleFontsApiKey}&sort=popularity`,
      )
      .pipe(
        map((res: any) => res['items']),
        catchError((err: any) => {
          return throwError(() => err.error);
        }),
      )
      .subscribe({
        next: (googleFonts: GoogleFont[]) => {
          googleFonts.push(
            new GoogleFont({
              family: 'Euclid Circular B',
              category: '',
              variants: [
                '100',
                '200',
                '300',
                '400',
                '500',
                '600',
                '700',
                '800',
              ],
            }),
            new GoogleFont({
              family: 'Rift',
              category: '',
              variants: ['300', '400', '500', '600', '700'],
            }),
            new GoogleFont({
              family: 'Neoneon',
              category: '',
              variants: ['400'],
            }),
            new GoogleFont({
              family: 'Neue Plak',
              category: '',
              variants: [
                '100',
                '200',
                '300',
                '400',
                '500',
                '600',
                '700',
                '800',
              ],
            }),
            new GoogleFont({
              family: 'Begum',
              category: '',
              variants: ['300', '400', '500', '600', '700'],
            }),
            new GoogleFont({
              family: 'Mostra Nuova',
              category: '',
              variants: ['100', '300', '400', '500', '700', '800', '900'],
            }),
            new GoogleFont({
              family: 'Friz Quadrata',
              category: '',
              variants: ['400'],
            }),
            new GoogleFont({
              family: 'GT Walsheim Trial',
              category: '',
              variants: ['300', '400', '500', '700', '800', '900'],
            }),
            new GoogleFont({
              family: 'Futura PT',
              category: '',
              variants: ['300', '400', '500', '700', '800'],
            }),
          );
          this._googleFonts.next(googleFonts);

          // const googleFontsChunks = this.chunkArray(
          //   googleFonts.map((googleFont) => googleFont.family),
          //   500,
          // );

          // googleFontsChunks.forEach((googleFontsChunk, idx) => {
          //   this.updateFonts(googleFontsChunk, idx + 1);
          // });
        },
      });
  }

  updateFonts(fontFamilies: string[], fontChunk?: number) {
    let fontUri = 'https://fonts.googleapis.com/css?family=';
    fontFamilies = [...new Set(fontFamilies)];
    fontFamilies
      .filter(
        (fontFamily) =>
          fontFamily &&
          fontFamily !== '' &&
          !Helpers.hostedFonts.includes(fontFamily) &&
          !Helpers.localFonts.includes(fontFamily),
      )
      .forEach((fontFamily, i) => {
        if (i === 0) {
          fontUri += fontFamily.replaceAll(' ', '+');
        } else {
          fontUri += `|${fontFamily.replaceAll(' ', '+')}`;
        }

        fontUri += ':300,400,500,600,700';
      });

    let link = document.getElementById('event-fonts-link');

    if (fontChunk) {
      link = document.getElementById(`fonts-chunk${fontChunk}`);
    }

    if (!link) {
      link = document.createElement('link');
      link.id = fontChunk ? `fonts-chunk${fontChunk}` : 'event-fonts-link';
      link.setAttribute('rel', 'stylesheet');
      link.setAttribute('href', fontUri);
      document.head.appendChild(link);
    } else {
      link.setAttribute('rel', 'stylesheet');
      link.setAttribute('href', fontUri);
    }

    this.updateHostedFonts(fontFamilies);
    this.updateLocalFonts(fontFamilies);
  }

  chunkArray(arr: string[], size: number): string[][] {
    return arr.length > size
      ? [arr.slice(0, size), ...this.chunkArray(arr.slice(size), size)]
      : [arr];
  }

  setPresetFonts(fontFamilies: string[]) {
    let link = document.getElementById('preset-fonts-link');

    if (!link) {
      let fontUri = 'https://fonts.googleapis.com/css?family=';
      fontFamilies = [...new Set(fontFamilies)];
      fontFamilies
        .filter(
          (fontFamily) =>
            fontFamily &&
            fontFamily !== '' &&
            !Helpers.hostedFonts.includes(fontFamily) &&
            !Helpers.localFonts.includes(fontFamily),
        )
        .forEach((fontFamily, i) => {
          if (i === 0) {
            fontUri += fontFamily.replaceAll(' ', '+');
          } else {
            fontUri += `|${fontFamily.replaceAll(' ', '+')}`;
          }
        });

      link = document.createElement('link');
      link.id = 'preset-fonts-link';
      link.setAttribute('rel', 'stylesheet');
      link.setAttribute('href', fontUri);
      document.head.appendChild(link);

      this.updateHostedFonts(fontFamilies);
      this.updateLocalFonts(fontFamilies);
    }
  }

  updateHostedFonts(fontFamilies: string[]) {
    let fontUri = 'https://fonts.cdnfonts.com/css/';
    const hostedFontFamilies = fontFamilies.filter(
      (fontFamily) =>
        fontFamily &&
        fontFamily !== '' &&
        Helpers.hostedFonts.includes(fontFamily),
    );

    if (!hostedFontFamilies?.length) {
      return;
    }

    const currentLinks = document.getElementsByClassName(
      'event-hosted-fonts-link',
    );
    Array.from(currentLinks).forEach((link) => link.remove());

    hostedFontFamilies.forEach((fontFamily, i) => {
      const hostedFontUri =
        fontUri + fontFamily.replaceAll(' ', '-').toLowerCase();

      const link = document.createElement('link');
      link.className = 'event-hosted-fonts-link';
      link.setAttribute('rel', 'stylesheet');
      link.setAttribute('href', hostedFontUri);
      document.head.appendChild(link);
    });
  }

  updateLocalFonts(fontFamilies: string[]) {
    let fontUri = `${env.api}/font-css/`;
    const hostedFontFamilies = fontFamilies.filter(
      (fontFamily) =>
        fontFamily &&
        fontFamily !== '' &&
        Helpers.localFonts.includes(fontFamily),
    );

    if (!hostedFontFamilies?.length) {
      return;
    }

    const currentLinks = document.getElementsByClassName(
      'event-local-fonts-link',
    );
    Array.from(currentLinks).forEach((link) => link.remove());

    hostedFontFamilies.forEach((fontFamily, i) => {
      const localFontUri =
        fontUri + fontFamily.replaceAll(' ', '-').toLowerCase();

      const link = document.createElement('link');
      link.className = 'event-local-fonts-link';
      link.setAttribute('rel', 'stylesheet');
      link.setAttribute('href', localFontUri);
      document.head.appendChild(link);
    });
  }
}
