import { AsyncPipe, CommonModule, isPlatformServer } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { PageEvent } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommunityMemberFeDataSource } from '@ds/community-member.ds';
import { CommunityMemberStatus } from '@models/communities/community-member-status.enum';
import { CommunityMember } from '@models/communities/community-member.model';
import { Community } from '@models/communities/community.model';
import { TicketStatus } from '@modules/events/components/tickets-management/tickets-management.component';
import { PaginatorComponent } from '@modules/shared/components/paginator/paginator.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommunityService } from '@services/communities/community.service';
import { NotificationService } from '@services/shared/notification.service';
import { SidebarService } from '@services/sidebar.service';
import { CommunityStore } from '@services/stores/community.store';
import { Helpers } from '@utils/helpers';
import { FormsModules } from '@utils/shared-modules';

@Component({
  selector: 'app-community-members-management',
  standalone: true,
  imports: [
    TranslateModule,
    CommonModule,
    FormsModules,
    TranslateModule,
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
    AsyncPipe,
    PaginatorComponent,
  ],
  templateUrl: './community-members-management.component.html',
  styleUrl: './community-members-management.component.scss',
})
export class CommunityMembersManagementComponent
  implements OnInit, AfterViewInit
{
  status: TicketStatus = TicketStatus.ALL;
  displayedColumnsDefault: string[] = [
    'name',
    'date',
    'phone',
    'email',
    'status',
    'actions',
  ];
  community?: Community;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  @ViewChild(PaginatorComponent) paginator!: PaginatorComponent;

  displayedColumns = this.displayedColumnsDefault;
  allMembers: CommunityMember[] = [];

  isServer = false;
  dataSource: CommunityMemberFeDataSource;

  @Input() isPreview: boolean = false;
  @Input() isApplicationView: boolean = false;
  filteredMembers: CommunityMember[] = [];
  pageSizeOptions: number[] = [5, 10, 15, 20, 50, 100];

  constructor(
    private communityStore: CommunityStore,
    private sidebarService: SidebarService,
    private communityService: CommunityService,
    @Inject(PLATFORM_ID) platformId: Object,
    private cd: ChangeDetectorRef,
    private notificationService: NotificationService,
    private translateService: TranslateService,
  ) {
    this.isServer = isPlatformServer(platformId);
    this.dataSource = new CommunityMemberFeDataSource();
    this.communityStore.community
      .pipe(takeUntilDestroyed())
      .subscribe((community) => {
        this.community = community;
        if (community && community.members) {
          this.setMembers(community.members);
        }
      });
  }

  ngOnInit(): void {
    if (this.community) {
      this.communityService.getCommunityByUri(this.community.uri).subscribe({
        next: (result) => {
          if (result && result.members) {
            this.setMembers(result.members);
          }
        },
        error: () => {},
      });
    }

    if (this.isPreview) {
      this.displayedColumns.pop();
      this.filteredMembers = this.filteredMembers
        .sort((a, b) => {
          if (a.createdAt && b.createdAt) {
            return b.createdAt > a.createdAt ? 1 : -1;
          }
          return b.id - a.id;
        })
        .slice(0, 10);
    }
  }

  setMembers(communityMembers: CommunityMember[]) {
    this.allMembers = this.filterResult(communityMembers);
    this.filteredMembers = this.filterResult(communityMembers);
    this.dataSource.filterMembers(
      this.allMembers,
      new Map(),
      this.paginator && this.paginator.pager.currentPage !== 0
        ? this.paginator.pager.currentPage
        : 1,
      this.paginator?.pageSize,
    );
  }
  filterResult(communityMembers: CommunityMember[]) {
    if (this.isApplicationView) {
      return communityMembers.filter(
        (member) => member.status === CommunityMemberStatus.PENDING,
      );
    } else
      return communityMembers.filter(
        (member) => member.status !== CommunityMemberStatus.PENDING,
      );
  }
  ngAfterViewInit() {
    if (!this.isServer) {
      this.sort.sortChange.subscribe(() => {
        this.resetPageIndex();
        this.refresh();
      });

      this.refresh();
      this.cd.detectChanges();
    }
  }

  onPageChange(event: PageEvent) {
    this.refresh();
  }

  private resetPageIndex() {
    this.paginator.pager.currentPage = 1;
  }

  changeFilterStatus(filterStatus: TicketStatus) {
    this.status = filterStatus;
    this.applyFilters();
  }

  applyFilters() {
    switch (this.status) {
      case TicketStatus.ALL:
        this.filteredMembers = this.allMembers;
        break;
      case TicketStatus.INACTIVE:
        this.filteredMembers = this.allMembers.filter(
          (x) => this.getTicketStatus(x) === TicketStatus.INACTIVE,
        );
        break;
      case TicketStatus.ACTIVE:
        this.filteredMembers = this.allMembers.filter(
          (x) => this.getTicketStatus(x) === TicketStatus.ACTIVE,
        );
        break;
    }

    this.dataSource.filterMembers(
      this.filteredMembers,
      new Map(),
      this.paginator && this.paginator.pager.currentPage !== 0
        ? this.paginator.pager.currentPage
        : 1,
      this.paginator?.pageSize,
    );
  }
  getTicketStatus(member: CommunityMember): string {
    if (member.status === CommunityMemberStatus.REJECTED) {
      return TicketStatus.INACTIVE;
    }

    return TicketStatus.ACTIVE;
  }

  refresh() {
    let filteredMembers = this.filteredMembers || [];
    if (!!this.sort.direction) {
      if (this.sort.active === 'createdAt') {
        filteredMembers = filteredMembers.sort((a, b) => {
          if (this.sort.direction === 'asc') {
            if (a.createdAt && b.createdAt) {
              return b.createdAt > a.createdAt ? 1 : -1;
            }
            return a.id - b.id;
          } else {
            if (a.createdAt && b.createdAt) {
              return b.createdAt > a.createdAt ? 1 : -1;
            }
            return b.id - a.id;
          }
        });
      }
    }
    this.dataSource.filterMembers(
      filteredMembers,
      new Map(),
      this.paginator && this.paginator.pager.currentPage !== 0
        ? this.paginator.pager.currentPage
        : 1,
      this.paginator?.pageSize,
    );
  }

  approveMember(member: CommunityMember, status: CommunityMemberStatus) {
    if (this.community) {
      this.communityService
        .setMemberStatus(this.community.id, member.id, status)
        .subscribe({
          next: () => {
            if (this.community) {
              if (status === CommunityMemberStatus.REJECTED) {
                this.notificationService.success(
                  this.translateService.instant(
                    'APP.COMMUNITIES.MEMBERS.MEMBER_REJECTED',
                  ),
                );
              }

              if (status === CommunityMemberStatus.APPROVED) {
                this.notificationService.success(
                  this.translateService.instant(
                    'APP.COMMUNITIES.MEMBERS.MEMBER_APPROVED',
                  ),
                );
              }
              this.communityStore.refreshCommunity(this.community?.uri);
            }
          },
        });
    }
  }

  onRowClick(member: CommunityMember, event: MouseEvent) {
    const selectedText = window.getSelection()?.toString();
    this.communityStore.setCommunityMember(member);

    if (
      !selectedText ||
      !Helpers.isSelectionInsideRow(event.currentTarget as HTMLElement)
    ) {
      if (this.sidebarService.sidebarStateSubject.value) {
        this.sidebarService.toggleSidebar();
      }

      if (!this.sidebarService.sidebarDetailsStateSubject.value) {
        this.sidebarService.toggleDetailsSidebar();
      }
    }
  }

  protected readonly TicketStatus = TicketStatus;
  protected readonly CommunityMemberStatus = CommunityMemberStatus;
}
