import { Component, Input, OnInit } from '@angular/core';
import { Event } from '@models/events/event.model';
import { EventSectionEventListItemComponent } from '@modules/events/components/event-section-event-list-item/event-section-event-list-item.component';
import { TranslateModule } from '@ngx-translate/core';
import { ScreenWidthService } from '@services/shared/screen-width.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-event-section-event-list',
  standalone: true,
  imports: [EventSectionEventListItemComponent, TranslateModule],
  templateUrl: './event-section-event-list.component.html',
  styleUrl: './event-section-event-list.component.scss',
})
export class EventSectionEventListComponent implements OnInit {
  @Input() event?: Event;
  events?: Event[];
  filteredEvents?: Event[];
  isDesktop = true;
  isShowMore = false;

  constructor(private screenWidthService: ScreenWidthService) {
    this.screenWidthService
      .isOver1200()
      .pipe(takeUntilDestroyed())
      .subscribe({
        next: (isDesktop: boolean) => {
          this.isDesktop = isDesktop;
        },
      });
  }

  onShowMore() {
    this.isShowMore = true;
    this.filteredEvents = this.events;
  }

  onShowLess() {
    this.isShowMore = false;
    this.filteredEvents = this.events?.slice(0, 3);
  }

  isDateBeforeNow(date?: Date): boolean {
    if (date) {
      const now = new Date();
      return date < now;
    } else return false;
  }

  sortEventsByDate(events: Event[] | undefined): Event[] | undefined {
    return events?.sort((a, b) => {
      const aIsPast = this.isDateBeforeNow(a.startDate);
      const bIsPast = this.isDateBeforeNow(b.startDate);

      if (aIsPast === bIsPast && a.startDate && b.startDate) {
        return a.startDate?.getTime() - b.startDate?.getTime();
      }

      return aIsPast ? 1 : -1;
    });
  }

  ngOnInit(): void {
    if (this.event) {
      this.events = this.sortEventsByDate(this.event.getLandingPageEvents());
      this.filteredEvents = this.events?.slice(0, 3);
    }
  }

  get isUaEvent(): boolean {
    return !!this.event?.uri.includes('underarmour-infinite-elite-testival');
  }
}
