import { Component, Input } from '@angular/core';
import { EventWeddingPartyItemType } from '@models/events/event-wedding-party-item-type.enum';
import { EventWeddingPartyItem } from '@models/events/event-wedding-party-item.model';

@Component({
  selector: 'app-event-section-wedding-party-item',
  standalone: true,
  imports: [],
  templateUrl: './event-section-wedding-party-item.component.html',
  styleUrl: './event-section-wedding-party-item.component.scss',
})
export class EventSectionWeddingPartyItemComponent {
  @Input() item?: EventWeddingPartyItem;

  protected readonly EventWeddingPartyItemType = EventWeddingPartyItemType;
}
