import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Community } from '@models/communities/community.model';
import { ResendEmailCodeRequest } from '@models/users/dto/resend-email-code.request';
import { TranslateModule } from '@ngx-translate/core';
import { AuthService } from '@services/auth/auth.service';
import { UserVerificationCodeType } from '@models/sign-up/user-verification-code-type.enum';
import { CodeInputComponent } from '@modules/shared/components/code-input/code-input.component';
import { CommunityStore } from '@services/stores/community.store';
import { FormsModules } from '@utils/shared-modules';
import { finalize } from 'rxjs';

@Pipe({
  name: 'countdownFormat',
  standalone: true,
})
export class CountdownFormatPipe implements PipeTransform {
  transform(value: number): string {
    const minutes: number = Math.floor(value / 60);
    const seconds: number = value % 60;
    return `0${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  }
}

@Component({
  selector: 'app-verify-email-step',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModules,
    CodeInputComponent,
    CountdownFormatPipe,
  ],
  templateUrl: './verify-email-step.component.html',
  styleUrl: './verify-email-step.component.scss',
})
export class VerifyEmailStepComponent {
  resendInProgress = false;
  @Input() isWrongCode = false;
  resendSuccess = false;
  isResendInCooldown = false;

  timeLeft = 90;
  interval: any;

  community?: Community;
  @Input() isDialogVerify = false;

  @Input() isNoAccountRSVPVerify = false;

  @Input() userId?: number;
  @Input() email?: string;

  @Output() verifyEmailSuccess: EventEmitter<void> = new EventEmitter();
  @Output() onCodeChange: EventEmitter<boolean> = new EventEmitter();
  @Output() noAccountVerifyCodeCompleted: EventEmitter<string> =
    new EventEmitter<string>();

  @ViewChild('codeInput') codeInput!: CodeInputComponent;

  constructor(
    private authService: AuthService,
    private communityStore: CommunityStore,
  ) {
    this.communityStore.community
      .pipe(takeUntilDestroyed())
      .subscribe((community) => {
        this.community = community;
      });
    this.startTimer();
    this.isResendInCooldown = true;
    this.resendSuccess = true;
    setTimeout(() => (this.resendSuccess = false), 2000);
  }

  onCodeChanged(code: string) {
    this.isWrongCode = false;
    this.onCodeChange.emit(true);
  }

  onCodeCompleted(code: string) {
    if (this.isNoAccountRSVPVerify) {
      this.noAccountVerifyCodeCompleted.emit(code);
    } else {
      this.authService.verifyUserViaCode(code).subscribe({
        next: () => {
          this.verifyEmailSuccess.emit();
        },
        error: () => {
          this.isWrongCode = true;
        },
      });
    }
  }

  resendEmail() {
    // if (!this.userId) {
    //   return;
    // }

    if (this.isResendInCooldown || this.resendInProgress) {
      return;
    }

    this.resendInProgress = true;

    if (this.userId) {
      this.authService
        .resendVerificationCode(this.userId, UserVerificationCodeType.EMAIL)
        .pipe(finalize(() => (this.resendInProgress = false)))
        .subscribe({
          next: () => {
            this.startTimer();
            this.isResendInCooldown = true;
            this.resendSuccess = true;
            setTimeout(() => (this.resendSuccess = false), 2000);
            this.codeInput.reset();
          },
          error: (err) => {
            if (err?.message === 'cooldown time has not passed') {
              this.startTimer();
              this.isResendInCooldown = true;
            }
          },
        });
    } else {
      if (this.email) {
        let request: ResendEmailCodeRequest = {
          email: this.email,
        };

        if (this.community) {
          request = {
            ...request,
            communityUri: this.community.uri,
          };
        }
        this.authService
          .resendVerificationCodeForEmail(request)
          .pipe(finalize(() => (this.resendInProgress = false)))
          .subscribe({
            next: () => {
              this.startTimer();
              this.isResendInCooldown = true;
              this.resendSuccess = true;
              setTimeout(() => (this.resendSuccess = false), 2000);
              this.codeInput.reset();
            },
            error: (err) => {
              if (err?.message === 'cooldown time has not passed') {
                this.startTimer();
                this.isResendInCooldown = true;
              }
            },
          });
      } else {
        return;
      }
    }
  }

  startTimer() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        clearInterval(this.interval);
        this.timeLeft = 90;
        this.isResendInCooldown = false;
      }
    }, 1000);
  }
}
