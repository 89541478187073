<div class="tickets-wrapper">
  <div class="header">
    <div class="info-wrapper">
      <div class="title">{{ "APP.TICKETS_EDIT.TICKETS" | translate }}</div>
      <div class="description">
        {{ "APP.TICKETS_EDIT.TICKETS_MANAGEMENT_DESCRIPTION" | translate }}
      </div>
    </div>
    <button
      class="btn btn-primary"
      (click)="onCreateTicket()"
      [disabled]="
        event &&
        event.maxTicketCapacity &&
        event.maxTicketCapacity <= ticketsMaxCapacity
      "
    >
      {{ "APP.TICKETS_EDIT.TICKET_CREATION" | translate }}
    </button>
  </div>
  <div class="body">
    <div class="subfilters">
      <div
        class="table-filter"
        [class.active-filter]="ticketStatus === TicketStatus.ALL"
        (click)="changeFilterStatus(TicketStatus.ALL)"
      >
        {{ "APP.TICKETS_EDIT.ALL" | translate }}
      </div>
      <div
        class="table-filter"
        [class.active-filter]="ticketStatus === TicketStatus.ACTIVE"
        (click)="changeFilterStatus(TicketStatus.ACTIVE)"
      >
        {{ "APP.TICKETS_EDIT.ACTIVE" | translate }}
      </div>
      <div
        class="table-filter"
        [class.active-filter]="ticketStatus === TicketStatus.INACTIVE"
        (click)="changeFilterStatus(TicketStatus.INACTIVE)"
      >
        {{ "APP.TICKETS_EDIT.INACTIVE" | translate }}
      </div>
    </div>

    <div class="table-scrollable">
      <mat-table
        matSort
        [dataSource]="filteredTickets"
        class="mat-elevation-z8"
      >
        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <mat-header-cell mat-sort-header *matHeaderCellDef>{{
            "APP.DESIGN_TEMPLATES.TABLE_HEADERS.NAME" | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let ticket">
            <div class="name-and-picture-col">
              <div class="ellipsis-field fit-content">
                <b>{{ ticket.name }}</b>
              </div>
            </div>
          </mat-cell>
        </ng-container>

        <!-- Sold Column -->
        <ng-container matColumnDef="sold">
          <mat-header-cell mat-sort-header *matHeaderCellDef>
            {{ "APP.TICKETS_EDIT.SOLD" | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let ticket"
            >{{ ticket.numSoldTickets || 0 }} /
            {{ ticket.maxCapacity || ("APP.UNLIMITED" | translate) }}
          </mat-cell>
        </ng-container>

        <!-- Price Column -->
        <ng-container matColumnDef="price">
          <mat-header-cell mat-sort-header *matHeaderCellDef>
            {{ "APP.TICKETS_EDIT.PRICE" | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let ticket">{{
            ticket.getDisplayPrice()
          }}</mat-cell>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="status">
          <mat-header-cell mat-sort-header *matHeaderCellDef>
            {{ "APP.TICKETS_EDIT.SALE_STATUS" | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let ticket">
            {{ ticket.getTicketStatusDisplayName() | translate }}
          </mat-cell>
        </ng-container>

        <!-- Start Sale Column -->
        <ng-container matColumnDef="startSale">
          <mat-header-cell mat-sort-header *matHeaderCellDef>
            {{ "APP.TICKETS_EDIT.START_SALE" | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let ticket">{{
            ticket.saleStartDate
              ? (ticket.saleStartDate | date: "dd.MM.yyyy")
              : "-"
          }}</mat-cell>
        </ng-container>

        <!-- End Sale Column -->
        <ng-container matColumnDef="endSale">
          <mat-header-cell mat-sort-header *matHeaderCellDef>
            {{ "APP.TICKETS_EDIT.END_SALE" | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let ticket">{{
            ticket.saleEndDate ? (ticket.saleEndDate | date: "dd.MM.yyyy") : "-"
          }}</mat-cell>
        </ng-container>

        <!-- Day Column -->
        <ng-container matColumnDef="day">
          <mat-header-cell mat-sort-header *matHeaderCellDef
            >Day</mat-header-cell
          >
          <mat-cell *matCellDef="let ticket">
            <div class="name-and-picture-col">
              <div class="ellipsis-field fit-content">
                @if (ticket.day) {
                  <b>{{ ticket.day | date: "dd.MM.yyy" }}</b>
                } @else {
                  <b>{{ "APP.TICKETS_EDIT.ALL_DAYS" | translate }}</b>
                }
              </div>
            </div>
          </mat-cell>
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="actions" stickyEnd>
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let ticket">
            <button
              mat-icon-button
              [matMenuTriggerFor]="menu"
              class="more-vert-rotate-90"
              (click)="$event.stopPropagation()"
            >
              <mat-icon>more_vert</mat-icon>
            </button>

            <mat-menu #menu="matMenu" class="table-actions-container">
              <button mat-menu-item (click)="editTicket(ticket)">
                <span class="menu-item-content">
                  <i class="far fa-pen"></i>
                  <span class="text">{{ "APP.EDIT" | translate }}</span>
                </span>
              </button>
              <button mat-menu-item (click)="copyTicket(ticket)">
                <span class="menu-item-content">
                  <i class="far fa-copy"></i>
                  <span class="text">{{ "APP.COPY" | translate }}</span>
                </span>
              </button>
              <button mat-menu-item (click)="deleteTicket(ticket)">
                <span class="menu-item-content">
                  <i class="far fa-trash"></i>
                  <span class="text">{{ "APP.DELETE" | translate }}</span>
                </span>
              </button>
            </mat-menu>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell no-data-row" [colSpan]="displayedColumns.length">
            <div class="no-data-row-overlay"></div>
            <div class="no-data-row-cont">
              <div class="no-data-emoji">🙁</div>
              <div class="no-data-title">
                {{ "APP.TICKETS_EDIT.NO_TICKETS" | translate }}
              </div>
            </div>
          </td>
        </tr>
      </mat-table>
    </div>

    <div class="capacity-section">
      <div class="info">
        <span
          ><b>{{ "APP.TICKETS_EDIT.EVENT_CAPACITY" | translate }}</b
          >:
          {{
            event && event.maxTicketCapacity
              ? ticketsMaxCapacity + "/" + event.maxTicketCapacity
              : ("APP.TICKETS_EDIT.EVENT_CAPACITY_NOT_SET" | translate)
          }}</span
        >
        <i
          class="far fa-info-circle"
          matTooltip="{{ 'APP.TICKETS_EDIT.EVENT_CAPACITY_INFO' | translate }}"
          matTooltipPosition="below"
        ></i>
      </div>
      <div class="action" (click)="changeEventCapacity()">
        {{
          event && event.maxTicketCapacity
            ? ("APP.TICKETS_EDIT.CHANGE_CAPACITY" | translate)
            : ("APP.TICKETS_EDIT.ADD_CAPACITY" | translate)
        }}
      </div>
    </div>
  </div>
</div>
