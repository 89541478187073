<div class="tickets-wrapper" [class.preview]="isPreview">
  @if (!isPreview) {
    <div class="header">
      <div class="info-wrapper">
        <div class="title">
          @if(isApplicationView){
            {{ "APP.COMMUNITIES.MEMBERS.APPLICATIONS" | translate }}
          }@else{
            {{ "APP.COMMUNITIES.MEMBERS.TITLE" | translate }}
          }
        </div>
        <div class="description">
          @if(isApplicationView){
            {{ "APP.COMMUNITIES.MEMBERS.APPLICATIONS_DESCRIPTION" | translate }}
          }@else{
            {{ "APP.COMMUNITIES.MEMBERS.DESCRIPTION" | translate }}
          }
        </div>
      </div>
   <!--   <button class="btn btn-primary">
        {{ "APP.COMMUNITIES.MEMBERS.ADD_MEMBER" | translate }}
      </button>-->
    </div>
  }
  <div class="body">
    @if(!isPreview && !isApplicationView){
      <div class="subfilters">
        <div
          class="table-filter"
          [class.active-filter]="status === TicketStatus.ALL"
          (click)="changeFilterStatus(TicketStatus.ALL)"
        >
          {{ "APP.TICKETS_EDIT.ALL" | translate }}
        </div>
        <div
          class="table-filter"
          [class.active-filter]="status === TicketStatus.ACTIVE"
          (click)="changeFilterStatus(TicketStatus.ACTIVE)"
        >
          {{ "APP.TICKETS_EDIT.ACTIVE" | translate }}
        </div>
        <div
          class="table-filter"
          [class.active-filter]="status === TicketStatus.INACTIVE"
          (click)="changeFilterStatus(TicketStatus.INACTIVE)"
        >
          {{ "APP.TICKETS_EDIT.INACTIVE" | translate }}
        </div>
      </div>
    }

    <div class="table-scrollable" [class.loading]="dataSource.loading$ | async">
      <mat-table
        matSort
        [dataSource]="!isPreview ? dataSource : filteredMembers"
        class="mat-elevation-z8"
      >

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <mat-header-cell mat-sort-header *matHeaderCellDef>
            {{ "APP.COMMUNITIES.MEMBERS.NAME" | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let member">
            <div class="name-and-picture-col">
              <div class="ellipsis-field fit-content">
                {{ member.user.name }}
              </div>
            </div>
          </mat-cell>
        </ng-container>

        <!-- Date Column -->
        <ng-container matColumnDef="date">
          <mat-header-cell mat-sort-header *matHeaderCellDef>
            {{ "APP.COMMUNITIES.MEMBERS.DATE" | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let member">{{
              member.createdAt ? (member.createdAt | date: "dd.MM.yyyy") : "-"
            }}</mat-cell>
        </ng-container>

        <!-- Phone Column -->
        <ng-container matColumnDef="phone">
          <mat-header-cell mat-sort-header *matHeaderCellDef>
            {{ "APP.COMMUNITIES.MEMBERS.PHONE" | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let member">
            <div class="name-and-picture-col">
              <div class="ellipsis-field fit-content">
                {{ member.user.phone }}
              </div>
            </div>
          </mat-cell>
        </ng-container>


        <!-- Email Column -->
        <ng-container matColumnDef="email">
          <mat-header-cell mat-sort-header *matHeaderCellDef>
            {{ "APP.COMMUNITIES.MEMBERS.EMAIL" | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let member">
            <div class="name-and-picture-col">
              <div class="ellipsis-field fit-content">
                {{ member.user.email }}
              </div>
            </div>
          </mat-cell>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="status">
          <mat-header-cell mat-sort-header *matHeaderCellDef>
            {{ "APP.STATUS" | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let member">
            {{ member.status }}
          </mat-cell>
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="actions" stickyEnd>
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let member">
            <button
              mat-icon-button
              [matMenuTriggerFor]="menu"
              class="more-vert-rotate-90"
              (click)="$event.stopPropagation()"
            >
              <mat-icon>more_vert</mat-icon>
            </button>

            <mat-menu #menu="matMenu" class="table-actions-container">
              @if(isApplicationView){
                <button mat-menu-item (click)="approveMember(member, CommunityMemberStatus.APPROVED)">
                <span class="menu-item-content">
                  <span class="text">{{ "APP.COMMUNITIES.MEMBERS.APPROVE" | translate }}</span>
                </span>
                </button>
                <button mat-menu-item (click)="approveMember(member, CommunityMemberStatus.REJECTED)">
                <span class="menu-item-content">
                  <span class="text">{{ "APP.COMMUNITIES.MEMBERS.REJECT" | translate }}</span>
                </span>
                </button>
              }@else{
                <button mat-menu-item (click)="approveMember(member, CommunityMemberStatus.REJECTED)">
                <span class="menu-item-content">
                  <i class="far fa-delete"></i>
                  <span class="text">{{ "APP.DELETE" | translate }}</span>
                </span>
                </button>
              }

            </mat-menu>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: displayedColumns"
          (click)="!isPreview && onRowClick(row, $event)"
        ></mat-row>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell no-data-row" [colSpan]="displayedColumns.length">
            <div class="no-data-row-overlay"></div>
            <div class="no-data-row-cont">
              <div class="no-data-emoji">🙁</div>
              <div class="no-data-title">
                @if(isApplicationView){
                  {{ "APP.COMMUNITIES.MEMBERS.NO_APPLICATIONS_YET" | translate }}
                }@else{
                  {{ "APP.COMMUNITIES.MEMBERS.NO_MEMBERS_YET" | translate }}
                }
              </div>
            </div>
          </td>
        </tr>
      </mat-table>
    </div>
    @if (!isPreview) {
      <app-paginator
        [totalItems]="dataSource.numRecords$ | async"
        [pageSizeOptions]="pageSizeOptions"
        (changePage)="onPageChange($event)"
      >
      </app-paginator>
    }
  </div>
</div>
