@if (event && section) {
  <div
    class="event-section"
    [class.ua-section]="isUaSection"
    [class.host-view]="event.isUserHost(loggedUser?.id)"
    [class.main-section]="section.type === EventSectionType.MAIN"
    [class.timeline-section]="section.type === EventSectionType.TIMELINE"
    [class.footer-section]="section.type === EventSectionType.FOOTER"
    [class.all-map]="
      section.type === EventSectionType.LOCATION &&
      event.type === EventType.LANDINGPAGE
    "
    [class.sub-section]="isSubSection"
    [class.accent-section]="isAccentSection"
    [class.sport-page]="isDarkThemeDesign"
    [class.testimonials-section]="
      section.type === EventSectionType.TESTIMONIALS
    "
    [class.sponsors-section]="section.type === EventSectionType.SPONSORS"
    [class.location-section]="
      section.type === EventSectionType.LOCATION &&
      event.type === EventType.SPORTPAGE
    "
    [class.about-section]="!!section.backgroundImageUrl"
    [class.full-width]="isDarkThemeDesign"
    [class.sport-page-event]="event.type === EventType.SPORTPAGE"
    [class.centered]="section.type === EventSectionType.DATE_LOCATION"
    [class.max-width-unset]="
      section.type === EventSectionType.SPONSORS ||
      section.type === EventSectionType.LOCATION ||
      section.type === EventSectionType.TESTIMONIALS
    "
    [attr.id]="getAttrId(section.type)"
  >
    @if (section.mainIcon && section.mainIcon !== "") {
      @if (isUaSection) {
        <div class="icons-cont">
          <div
            class="event-section-main-icon"
            [class.big-icon]="isDarkThemeDesign"
          >
            <img [src]="section.mainIcon" alt="Main Section Icon" />
          </div>
          <div class="event-section-sponsor-icon">
            <img
              src="https://static.eventpage.ai/event-templates/event-templates-1594/sportscheck-logo.svg"
              alt="Main Section Sponsor"
            />
          </div>
        </div>
      } @else {
        <div
          class="event-section-main-icon"
          [class.big-icon]="isDarkThemeDesign"
        >
          <img [src]="section.mainIcon" alt="Main Section Icon" />
        </div>
      }
    }
    @if (section.mainTitle && section.mainTitle !== "") {
      <div class="event-section-main-title">
        @if (isDarkThemeDesign) {
          {{ section.mainTitle | uppercase }}
        } @else {
          {{ section.mainTitle }}
        }
      </div>
      @if (
        event.type === EventType.SPORTPAGE &&
        section.type === EventSectionType.MAIN
      ) {
        <div class="location-section location-section-title">
          <div class="location" [innerHTML]="locationsSafeHtml"></div>
        </div>
      }
    }
    @if (section.headline && section.headline !== "") {
      @if (isDarkThemeDesign) {
        <div
          class="event-section-headline"
          [innerHtml]="section.headline | safeHtml"
        ></div>
      } @else {
        <div class="event-section-headline">
          {{ section.headline }}
        </div>
      }
    }
    @if (section.title && section.title !== "") {
      @if (isDarkThemeDesign) {
        <div
          class="event-section-title"
          [innerHtml]="section.title | safeHtml"
        ></div>
      } @else {
        <div class="event-section-title">
          {{ section.title }}
        </div>
      }
    }

    @if (section.type === EventSectionType.MAIN && isUaSection) {
      <img
        src="https://static.eventpage.ai/event-templates/event-templates-1594/graphic-peace.png"
        class="ua-ran-too-far-graphic"
        [class.landing-graphic]="event.type === EventType.LANDINGPAGE"
      />
    }

    @if (
      section.type === EventSectionType.LOCATION &&
      (event.hasAddress() ||
        event.hasLatLng() ||
        event.type === EventType.LANDINGPAGE) &&
      !isServer
    ) {
      @if (isDarkThemeDesign) {
        <app-event-section-map style="height: 100%"></app-event-section-map>
      } @else {
        <app-event-section-map></app-event-section-map>
      }
    }

    @if (section.type === EventSectionType.DATE_LOCATION) {
      <app-event-section-location-date [event]="event" [section]="section" />
    }

    @if (section.description && section.description !== "") {
      @if (isDarkThemeDesign) {
        <div
          class="event-section-description"
          [innerHtml]="section.description | safeHtml"
        ></div>
      } @else {
        <div
          class="event-section-description"
          [innerText]="section.description"
        ></div>
      }
    }

    @if (section.type === EventSectionType.TIMELINE && eventTimetable) {
      @if (isDarkThemeDesign) {
        <app-event-section-timetable
          [class.program-section]="
            isDarkThemeDesign && event.type === EventType.LANDINGPAGE
          "
          [eventTimetable]="eventTimetable"
          [eventType]="event.type"
          [isUaEvent]="isUaSection"
        ></app-event-section-timetable>
      } @else {
        <app-event-section-timetable
          [eventTimetable]="eventTimetable"
        ></app-event-section-timetable>
      }
    }

    @if (
      section.type === EventSectionType.SPONSORS &&
      event.eventTemplate?.eventSponsors
    ) {
      <app-event-section-sponsors
        [sponsors]="event.eventTemplate?.eventSponsors"
      />
    }

    @if (
      section.type === EventSectionType.TESTIMONIALS &&
      event.eventTemplate?.eventTestimonials
    ) {
      <app-event-section-testimonials
        [testimonials]="event.eventTemplate?.eventTestimonials"
      />
    }

    @if (
      section.type === EventSectionType.EVENT_LIST &&
      event.type === EventType.LANDINGPAGE
    ) {
      <app-event-section-event-list [event]="event" />
    }

    @if (section.type === EventSectionType.GROOMSMEN && eventWeddingParty) {
      <app-event-section-wedding-party
        [eventWeddingParty]="eventWeddingParty"
      ></app-event-section-wedding-party>
    }

    @if (section.type === EventSectionType.FAQ && eventFaqs) {
      <app-event-section-faq
        [faqs]="eventFaqs"
        [event]="event"
      ></app-event-section-faq>
    }

    @if (
      section.type === EventSectionType.RSVP &&
      (event.canRsvp() || event.isUserHost(loggedUser?.id))
    ) {
      <app-event-section-rsvp
        [loggedUser]="loggedUser"
      ></app-event-section-rsvp>
    }

    @if (section.subSections && section.subSections.length > 0) {
      @for (
        subSection of section.getSubSectionsOrdered();
        track subSection.id
      ) {
        <app-event-section
          [section]="subSection"
          [isSubSection]="true"
        ></app-event-section>
      }
    }

    @if (
      section.type === EventSectionType.MAIN &&
      event.type === EventType.LANDINGPAGE
    ) {
      @if (isUaSection) {
        <div class="btn-section ua-section">
          <button class="btn btn-primary" (click)="onFindEvent()">
            <i class="far fa-calendar-alt"></i>
            {{ "APP.SPORT_PAGE.FIND_YOUR_DATE" | translate | uppercase }}
          </button>
          <button class="btn btn-secondary" (click)="onLearnMore()">
            <i class="far fa-running"></i>
            {{ "APP.SPORT_PAGE.LEARN_MORE" | translate | uppercase }}
          </button>
        </div>
      } @else {
        <div class="btn-section">
          <button class="btn btn-primary" (click)="onFindEvent()">
            {{ "APP.SPORT_PAGE.FIND_YOUR_EVENT" | translate | uppercase }}
          </button>
          <button class="btn btn-secondary" (click)="onLearnMore()">
            {{ "APP.SPORT_PAGE.LEARN_MORE" | translate | uppercase }}
          </button>
        </div>
      }
    }
    @if (
      (section.type === EventSectionType.TIMELINE &&
        event.type === EventType.SPORTPAGE) ||
      (section.type === EventSectionType.MAIN &&
        event.type === EventType.SPORTPAGE)
    ) {
      <div class="btn-section" [class.ua-section]="isUaSection">
        <app-event-rsvp
          [loggedUserId]="loggedUser?.id"
          [isLoggedUserHost]="
            loggedUser &&
            loggedUser.id &&
            (event.isUserHost(loggedUser.id) || loggedUser.isAdmin())
          "
          [showGuestCount]="event.showGuestCount"
          [isAttendeeView]="isAttendeeView"
        ></app-event-rsvp>
      </div>
    }

    @if (!!section.backgroundImageUrl && isUaSection) {
      <div class="about-button-section">
        <button class="btn btn-ua" (click)="goToUaWebsite()">
          <object
            data="https://static.eventpage.ai/event-templates/event-templates-1594/logo-icon.svg"
            type="image/svg+xml"
          ></object>
          {{ "APP.SPORT_PAGE.GO_TO_WEBSITE" | translate | uppercase }}
        </button>
        <div class="about-circles">
          <object
            data="https://static.eventpage.ai/event-templates/event-templates-1594/graphic_circle.svg"
            type="image/svg+xml"
          ></object>
          <object
            data="https://static.eventpage.ai/event-templates/event-templates-1594/graphic_circle.svg"
            type="image/svg+xml"
          ></object>
          <object
            data="https://static.eventpage.ai/event-templates/event-templates-1594/graphic_circle.svg"
            type="image/svg+xml"
          ></object>
        </div>
      </div>
    }
  </div>
}
