<div
  class="payment-wrapper"
  [class.advanced-event]="event?.type === EventType.ADVANCED_ONE_COLUMN"
>
  <div class="payment-body" [class.generic]="isGeneric">
    <div class="customer-info" [class.expanded]="setBillingAddressOpenState()">
      <app-personal-data
        [form]="form"
        [loggedUser]="loggedUser"
        [appearance]="getAppearance()"
        [showLoggedUserData]="isGeneric && this.loggedUser"
      />
      @if (isGeneric) {
        <mat-accordion class="wrapper-expansion partly-clickable" multi>
          <mat-expansion-panel
            [expanded]="setBillingAddressOpenState()"
            (opened)="setBillingAddressOpenState.set(true)"
            (closed)="setBillingAddressOpenState.set(false)"
            (click)="resetBillingAddress()"
          >
            <mat-expansion-panel-header>
              <mat-panel-title
                ><span class="panel-title bold">
                  @if (!setBillingAddressOpenState()) {
                    {{ "APP.VOUCHERS.ADD_BILLING_ADDRESS" | translate }}
                  } @else {
                    {{ "APP.VOUCHERS.HIDE_BILLING_ADDRESS" | translate }}
                  }
                </span></mat-panel-title
              >
            </mat-expansion-panel-header>
            <app-tax-invoice-details
              [form]="billingAddressForm"
              [appearance]="getAppearance()"
              [showTitle]="false"
              [isGeneric]="true"
              class="w-100"
            />
          </mat-expansion-panel>
        </mat-accordion>
      } @else {
        <app-tax-invoice-details
          [form]="billingAddressForm"
          [appearance]="getAppearance()"
          class="w-100"
        />
      }
      @if (
        stripe && elementsOptions && elementsOptions.clientSecret && canPay
      ) {
        @if (ticketOrder && ticketOrder.totalPrice > 0) {
          <div class="pay-with-cont">
            <div class="title-wrapper">
              <div class="title">
                {{ "APP.EVENT_PAYMENT.PAY_WITH" | translate }}
              </div>
            </div>
            <ngx-stripe-elements
              [stripe]="stripe"
              [elementsOptions]="elementsOptions"
              class="stripe-elements"
            >
              @if (isGeneric) {
                <div class="stripe-card-element">
                  <ngx-stripe-card
                    [options]="cardOptions"
                    (change)="stripeElementsChange($event)"
                  />
                </div>
              } @else {
                <ngx-stripe-payment
                  [options]="paymentElementOptions"
                  (change)="stripeElementsChange($event)"
                />
              }
            </ngx-stripe-elements>
          </div>
          @if (isGeneric) {
            <button
              class="btn btn-primary order-btn"
              (click)="pay()"
              [class.spinner]="paying()"
              [disabled]="!isStripeElementValid || !acceptTermsAndConditions"
            >
              {{ "APP.EVENT_PAYMENT.ORDER" | translate }}
            </button>
            <div class="accept-terms">
              <mat-checkbox
                (change)="toggleTermsAndConditions()"
              ></mat-checkbox>
              {{ "APP.I_AGREE_TO" | translate }}
              <a href="https://eventpage.ai/terms-of-use/" target="_blank">{{
                "APP.TERMS_AND_CONDITIONS" | translate
              }}</a>
            </div>
          }
        } @else {
          @if (isGeneric) {
            <button
              class="btn btn-primary order-btn"
              (click)="pay()"
              [class.spinner]="paying()"
              [disabled]="!isStripeElementValid"
            >
              {{ "APP.REGISTER" | translate }}
            </button>
          }
        }
      }
    </div>

    @if (!isGeneric) {
      <app-order-overview
        [voucher]="voucher"
        [ticketOrder]="ticketOrder"
        [mainImage]="mainImage"
        [squaredStyle]="event?.type === EventType.ADVANCED_ONE_COLUMN"
        [isValid]="
          isStripeElementValid && form.valid && !!billingAddressForm?.valid
        "
        [paying]="paying"
        [event]="event"
        (pay)="pay()"
      />
    }
  </div>
</div>
