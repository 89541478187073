import { CommonModule, isPlatformServer } from '@angular/common';
import { emitDistinctChangesOnlyDefaultValue } from '@angular/compiler';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  PLATFORM_ID,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  MatDialog,
  MatDialogRef,
  MatDialogState,
} from '@angular/material/dialog';
import { RouterLink } from '@angular/router';
import { environment as env } from '@environments/environment';
import { EventTemplateVisual } from '@models/design-templates/event-template-visual.model';
import { ConfigurableSectionItem } from '@models/events/dto/configurable-section-item.model';
import { EventUpdateRequest } from '@models/events/dto/event-update.request';
import { EventAttendeeTicketOrder } from '@models/events/event-attendee-ticket-order.model';
import { EventFaq } from '@models/events/event-faq.model';
import { EventRSVPConfirmationPageSectionType } from '@models/events/event-rsvp-confirmation-page-section-type.enum';
import { EventSectionBackgroundSize } from '@models/events/event-section-background-size.enum';
import { EventSectionType } from '@models/events/event-section-type.enum';
import { EventSection } from '@models/events/event-section.model';
import { EventTimetable } from '@models/events/event-timetable.model';
import { EventType } from '@models/events/event-type.enum';
import { Event } from '@models/events/event.model';
import { ImagePosition } from '@models/events/image-position.enum';
import { Address } from '@models/shared/address.model';
import { User } from '@models/users/user.model';
import { EventSectionFaqComponent } from '@modules/events/components/event-section-faq/event-section-faq.component';
import { EventSectionLocationDateComponent } from '@modules/events/components/event-section-location-date/event-section-location-date.component';
import { EventSectionMapComponent } from '@modules/events/components/event-section-map/event-section-map.component';
import { EventSectionSponsorsComponent } from '@modules/events/components/event-section-sponsors/event-section-sponsors.component';
import { EventSectionTestimonialsComponent } from '@modules/events/components/event-section-testimonials/event-section-testimonials.component';
import { EventSectionTimetableComponent } from '@modules/events/components/event-section-timetable/event-section-timetable.component';
import { GetBoughtTicketsDialog } from '@modules/events/dialogs/get-bought-tickets/get-bought-tickets.dialog';
import { GetTicketsDialog } from '@modules/events/dialogs/get-tickets/get-tickets.dialog';
import { RequestedRefundOverviewDialog } from '@modules/events/dialogs/requested-refund-overview/requested-refund-overview.dialog';
import { EventPageLogoComponent } from '@modules/shared/components/event-page-logo/event-page-logo.component';
import { RichTextEditorComponent } from '@modules/shared/components/rich-text-editor/rich-text-editor.component';
import { AddressPickerDialog } from '@modules/shared/dialogs/address-picker/address-picker.dialog';
import { DateTimeRangePickerDialog } from '@modules/shared/dialogs/date-time-range-picker/date-time-range-picker.dialog';
import { ImagePickerDialog } from '@modules/shared/dialogs/image-picker/image-picker.dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/auth/auth.service';
import { EventService } from '@services/events/event.service';
import { ScreenWidthService } from '@services/shared/screen-width.service';
import { EventStore } from '@services/stores/event.store';
import moment from 'moment/moment';
import { SafeHtmlPipe } from '../../../../pipes/safe-html.pipe';

@Component({
  selector: 'app-advanced-one-column-event-section',
  standalone: true,
  imports: [
    CommonModule,
    EventSectionLocationDateComponent,
    EventSectionTimetableComponent,
    EventSectionTestimonialsComponent,
    EventSectionSponsorsComponent,
    EventSectionMapComponent,
    EventSectionFaqComponent,
    RouterLink,
    TranslateModule,
    EventPageLogoComponent,
    RichTextEditorComponent,
    SafeHtmlPipe,
  ],
  templateUrl: './advanced-one-column-event-section.component.html',
  styleUrl: './advanced-one-column-event-section.component.scss',
})
export class AdvancedOneColumnEventSectionComponent
  implements OnInit, OnChanges
{
  @Input() section?: EventSection;
  @Input() parentSection?: EventSection;
  @Input() isSubSection?: boolean = false;
  @Input() isEdit?: boolean = false;
  @Output() editSection = new EventEmitter<ConfigurableSectionItem>();

  event?: Event;
  selectedDay?: Date;
  displayedEventTimetable?: EventTimetable;
  dates: Date[] = [];
  programDates: Date[] = [];
  loggedUser?: User;
  isDesktop?: boolean;
  imagePickerDialogRef?: MatDialogRef<ImagePickerDialog>;
  dialogRef?: MatDialogRef<AddressPickerDialog>;
  order?: EventAttendeeTicketOrder;
  get eventFaqs(): EventFaq[] | undefined {
    return this.event?.eventTemplate?.eventFaqs;
  }

  isServer = false;

  constructor(
    private eventService: EventService,
    private eventStore: EventStore,
    private translateService: TranslateService,
    private authService: AuthService,
    private screenWidthService: ScreenWidthService,
    private dialog: MatDialog,
    private viewContainerRef: ViewContainerRef,
    @Inject(PLATFORM_ID) platformId: Object,
  ) {
    this.isServer = isPlatformServer(platformId);

    this.eventStore.event.pipe(takeUntilDestroyed()).subscribe((event) => {
      this.event = event;
      this.updateDates();
    });

    this.authService.userSubject
      .pipe(takeUntilDestroyed())
      .subscribe((user) => {
        this.loggedUser = user;
      });

    this.screenWidthService
      .isDesktop()
      .pipe(takeUntilDestroyed())
      .subscribe((isDesktop) => {
        this.isDesktop = isDesktop;
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateDates();
  }

  ngOnInit(): void {
    if (this.section?.type === EventSectionType.DATE_LOCATION) {
      this.getBoughtTickets();
    }

    this.updateDates();
  }

  getBoughtTickets() {
    if (this.loggedUser && this.event) {
      this.eventService
        .getTicketOrders(this.event.id, true)
        .subscribe((ticketOrders) => {
          if (ticketOrders) {
            this.order = ticketOrders[0];
          }
        });
    }
  }

  addProgramDate() {
    const lastDay = new Date(this.programDates[this.programDates.length - 1]);
    const date = new Date(lastDay);
    date.setDate(lastDay.getDate() + 1);
    this.programDates.push(date);
  }

  checkIfDateInRange() {
    const lastDay = new Date(this.programDates[this.programDates.length - 1]);
    const date = new Date(lastDay);
    date.setDate(lastDay.getDate() + 1);
    if (this.event?.endDate) {
      const endDate = new Date(this.event?.endDate);
      lastDay.setHours(0, 0, 0, 0);
      endDate.setHours(0, 0, 0, 0);

      if (lastDay.getTime() < endDate.getTime()) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  updateDates() {
    this.dates = [];
    this.programDates = [];
    if (this.event?.startDate) {
      this.dates.push(this.event.startDate);
      let startDate = new Date(this.event.startDate);
      startDate.setHours(0, 0, 0, 0);

      if (!this.programDates.some((d) => d.getTime() === startDate.getTime())) {
        this.programDates.push(startDate);
      }

      if (this.eventTimetable?.items) {
        for (let i = 0; i < this.eventTimetable.items.length; i++) {
          let item = this.eventTimetable.items[i];
          let day = new Date(item.day);
          day.setHours(0, 0, 0, 0);

          if (day >= startDate) {
            if (this.event.endDate) {
              let endDate = new Date(this.event.endDate);
              endDate.setHours(0, 0, 0, 0);

              if (
                day <= endDate &&
                !this.programDates.some((d) => d.getTime() === day.getTime())
              ) {
                this.programDates.push(day);
              }
            } else if (
              !this.programDates.some((d) => d.getTime() === day.getTime())
            ) {
              this.programDates.push(day);
            }
          }
        }
      }
      if (this.event?.endDate) {
        let endDate = new Date(this.event.endDate);
        endDate.setHours(0, 0, 0, 0);
        this.dates.push(endDate);
      }
      this.selectedDay = startDate;
      this.displayedEventTimetable = this.filterEventTimetableByDate(
        this.selectedDay,
      );
    }
  }

  get eventTimetable(): EventTimetable | undefined {
    return this.event?.eventTemplate?.eventTimetable;
  }

  onConfigureSection(configurableObject?: ConfigurableSectionItem) {
    if (configurableObject) {
      this.editSection.emit({
        ...configurableObject,
        section: this.section,
      });
    } else {
      this.editSection.emit({
        section: this.section,
      });
    }
  }
  openPickerDialog() {
    if (this.dialogRef && this.dialogRef.getState() !== MatDialogState.CLOSED) {
      return;
    }

    this.dialogRef = this.dialog.open(AddressPickerDialog, {
      maxWidth: '654px',
      maxHeight: '100vh',
      width: '100%',
      height: 'auto',
      data: {
        address: this.event?.eventAddress,
        isEventEditPicker: true,
      },
      panelClass: ['normal-dialog'],
    });
    this.dialogRef.afterClosed().subscribe((address: Address) => {
      if (address) {
        if (this.event) {
          this.event.eventAddress = address;
          const deleted = address === undefined;

          this.update({
            address: this.event.eventAddress,
            deleteAddress: deleted,
            newUser: {
              eventUuid: this.event.uuid,
            },
          });
        }
      }
    });
  }

  openRequestedRefundOverviewDialog() {
    const dialogRef = this.dialog.open(RequestedRefundOverviewDialog, {
      maxWidth: '602px',
      maxHeight: '100vh',
      width: '100%',
      height: 'auto',
      data: {
        event: this.event,
        order: this.order,
        loggedUser: this.loggedUser,
      },
      panelClass: ['normal-dialog', 'rsvp-dialog', 'overlay-theme'],
    });

    dialogRef.afterClosed().subscribe(() => {
      if (this.event) this.eventStore.refreshEvent(this.event?.uri);
    });
  }

  openGetBoughtTicketsDialog() {
    const dialogRef = this.dialog.open(GetBoughtTicketsDialog, {
      maxWidth: '602px',
      maxHeight: '100vh',
      width: '100%',
      height: 'auto',
      data: {
        event: this.event,
        order: this.order,
        loggedUser: this.loggedUser,
      },
      panelClass: ['normal-dialog', 'rsvp-dialog', 'overlay-theme'],
    });
    dialogRef.afterClosed().subscribe(() => {
      if (this.event) this.eventStore.refreshEvent(this.event?.uri);
    });
  }

  openDateTimePickerDialog() {
    if (!this.event) {
      return;
    }

    const dialogRef = this.dialog.open(DateTimeRangePickerDialog, {
      maxWidth: '602px',
      maxHeight: '100vh',
      width: '100%',
      height: 'auto',
      data: {
        event: this.event,
      },
      panelClass: ['normal-dialog'],
    });
    dialogRef.afterClosed().subscribe((res?: any) => {
      if (res?.dateRange && this.event) {
        this.event.startDate = res.dateRange.startDate;
        this.event.endDate = res.dateRange.endDate;
        this.event.timeZone = res.dateRange.timeZone;

        this.update({
          startDate: this.event.startDate,
          endDate: this.event.endDate,
          timeZone: this.event.timeZone,
          newUser: {
            eventUuid: this.event.uuid,
          },
        });
      }
    });
  }

  update(req?: EventUpdateRequest) {
    if (this.event) {
      if (req) {
        req.localization = this.translateService.currentLang;
      }

      this.eventService.updateEvent(this.event.id, req).subscribe((event) => {
        this.event = event;
        this.eventStore.setEvent(event);
      });
    }
  }

  openGetTicketsDialog() {
    const data = {
      event: this.event,
      mainImage: this.event?.getEncodedMainImage(true),
      loggedUser: this.loggedUser,
    };
    const dialogRef = this.dialog.open(GetTicketsDialog, {
      viewContainerRef: this.viewContainerRef,
      maxWidth: '100dvw',
      width: '100%',
      height: '100dvh',
      data: data,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (this.event) this.eventStore.refreshEvent(this.event?.uri);
    });
  }

  onSelectDay(day: Date) {
    this.selectedDay = day;
    this.displayedEventTimetable = this.filterEventTimetableByDate(
      this.selectedDay,
    );
  }
  filterEventTimetableByDate(date?: Date): EventTimetable | undefined {
    const timetable = this.eventTimetable;

    if (!timetable || !timetable.items || !date) {
      return undefined;
    }
    const filteredItems = timetable.items.filter((item) =>
      moment(item.day).isSame(moment(date), 'day'),
    );

    return new EventTimetable({
      id: timetable.id,
      items: filteredItems,
    });
  }

  formatDateMonth(date?: Date) {
    if (!date) {
      return null;
    }
    const currentLang = this.translateService.currentLang;

    const locale = currentLang === 'de' ? 'de' : 'en';
    moment.locale(locale);

    const createdAtMoment = moment(date);
    return createdAtMoment.format('D. MMMM YYYY'); // This will use localized month names
  }

  get visual(): EventTemplateVisual | undefined {
    return this.event?.eventTemplate?.visual;
  }

  updateSection(section: any) {
    this.eventService
      .updateEvent(this.event?.id, { section: section })
      .subscribe((event) => {
        this.event = event;
        this.eventStore.refreshEvent(this.event.uri);
      });
  }

  saveText(event: any, textType: string) {
    const request = {
      ...this.section,
      [textType]: event[textType],
    };
    this.updateSection(request);
  }

  openImagePicker(isSection: boolean) {
    if (
      this.imagePickerDialogRef &&
      this.imagePickerDialogRef.getState() !== MatDialogState.CLOSED
    ) {
      return;
    }

    const dataObject = isSection
      ? {
          event: this.event,
          eventImagePicker: true,
          loggedUser: this.loggedUser,
          eventSection: this.section,
        }
      : {
          event: this.event,
          eventImagePicker: true,
          loggedUser: this.loggedUser,
        };
    this.imagePickerDialogRef = this.dialog.open(ImagePickerDialog, {
      maxWidth: '602px',
      maxHeight: '100vh',
      width: '100%',
      height: 'auto',
      data: dataObject,
      panelClass: ['normal-dialog'],
    });

    this.imagePickerDialogRef.afterClosed().subscribe((res) => {
      if (res) {
        if (res.event) {
          this.event = res.event;
        }
        if (this.event && res.imageUrl && res.imageUrl !== '') {
          if (!isSection) {
            this.event.mainPictureUrl = res.imageUrl;
          }
          this.eventStore.setEvent(this.event);
        }
      }
    });
  }

  protected readonly EventSectionType = EventSectionType;
  protected readonly EventType = EventType;

  protected env = env;
  protected readonly ImagePosition = ImagePosition;
  protected readonly EventSectionBackgroundSize = EventSectionBackgroundSize;
  protected readonly EventRSVPConfirmationPageSectionType =
    EventRSVPConfirmationPageSectionType;
  protected readonly emitDistinctChangesOnlyDefaultValue =
    emitDistinctChangesOnlyDefaultValue;
}
