import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  public sidebarStateSubject = new BehaviorSubject<boolean>(false);
  public sidebarState$ = this.sidebarStateSubject.asObservable();

  public sidebarDetailsStateSubject = new BehaviorSubject<boolean>(false);
  public sidebarDetailsState$ = this.sidebarDetailsStateSubject.asObservable();

  public sidebarEventSectionDetailsStateSubject = new BehaviorSubject<boolean>(
    false,
  );
  public sidebarEventSectionDetailsState$ =
    this.sidebarEventSectionDetailsStateSubject.asObservable();

  toggleSidebar(): void {
    this.sidebarStateSubject.next(!this.sidebarStateSubject.value);
  }

  toggleDetailsSidebar(): void {
    this.sidebarDetailsStateSubject.next(
      !this.sidebarDetailsStateSubject.value,
    );
  }

  toggleEventSectionDetailsSidebarOpen(): void {
    this.sidebarEventSectionDetailsStateSubject.next(true);
  }
  toggleEventSectionDetailsSidebar(): void {
    this.sidebarStateSubject.next(false);
    this.sidebarEventSectionDetailsStateSubject.next(
      !this.sidebarEventSectionDetailsStateSubject.value,
    );
  }

  closeAll() {
    this.sidebarStateSubject.next(false);
    this.sidebarDetailsStateSubject.next(false);
    this.sidebarEventSectionDetailsStateSubject.next(false);
  }
}
