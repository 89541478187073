import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EventTemplateVisual } from '@models/design-templates/event-template-visual.model';
import { QuestionType } from '@models/event-attendance/event-attendee-question-type.enum';
import { RSVPOptionType } from '@models/event-attendance/rsvp-option-type.enum';
import {
  EventRSVPRequest,
  RSVPQuestionAnswer,
} from '@models/events/dto/event-rsvp.request';
import { SaveAttendeeRequest } from '@models/events/dto/save-attendee.request';
import { Event } from '@models/events/event.model';
import { EventRsvpQuestionComponent } from '@modules/events/components/event-rsvp-question/event-rsvp-question.component';
import { TelInputComponent } from '@modules/shared/components/tel-input/tel-input.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { EventService } from '@services/events/event.service';
import { NotificationService } from '@services/shared/notification.service';
import { FormsModules } from '@utils/shared-modules';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FormsModules,
    MatCheckboxModule,
    TranslateModule,
    EventRsvpQuestionComponent,
    TelInputComponent,
  ],
  providers: [EventService, NotificationService],
  templateUrl: './add-guest.dialog.html',
  styleUrl: './add-guest.dialog.scss',
})
export class AddGuestDialog {
  form?: FormGroup;
  event?: Event;

  groupLeadAttendeeId?: number;

  numAttendeesOptions: number[] = [1];

  constructor(
    public dialogRef: MatDialogRef<AddGuestDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private eventService: EventService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
  ) {
    if (data) {
      this.event = data.event;
      this.groupLeadAttendeeId = data.groupLeadAttendeeId;

      if (
        this.event?.plusOnesLimit &&
        this.event.plusOnesLimit > 1 &&
        !this.groupLeadAttendeeId
      ) {
        this.numAttendeesOptions = [
          ...Array(this.event.plusOnesLimit).keys(),
        ].map((x) => ++x);
      }

      if (this.groupLeadAttendeeId) {
        this.form = this.fb.group({
          firstName: ['', Validators.required],
          lastName: ['', Validators.required],
          email: ['', [Validators.email]],
          phone: [''],
        });
      } else {
        this.form = this.fb.group({
          firstName: ['', Validators.required],
          lastName: ['', Validators.required],
          email: ['', [Validators.required, Validators.email]],
          numAttendees: [
            {
              value: 0,
              disabled: !this.event?.plusOnesLimit,
            },
          ],
          sendConfirmation: [true, Validators.required],
        });
      }

      if (
        this.event?.eventTemplate?.rsvpSettings?.requireGroupRsvp &&
        !this.groupLeadAttendeeId
      ) {
        this.form.addControl(
          'groupName',
          this.fb.control('', Validators.required),
        );
      }

      if (this.event?.eventTemplate?.attendeeQuestions) {
        for (const question of this.event.eventTemplate.attendeeQuestions) {
          this.form.addControl(
            `question#${question.id}`,
            this.fb.control('', question.isRequired ? Validators.required : []),
          );
        }
      }
    }
  }

  addGuest() {
    if (this.groupLeadAttendeeId) {
      return this.addPlusOne();
    }

    if (!this.event || !this.form) {
      return;
    }

    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    const value = this.form.value;

    const req: EventRSVPRequest = {
      addGuest: true,
      sendConfirmationToGuest: value.sendConfirmation,
      rsvpType: RSVPOptionType.YES,
      numAttendees: value.numAttendees,
    };

    if (value.firstName !== '') {
      req.firstName = value.firstName;
    }
    if (value.lastName !== '') {
      req.lastName = value.lastName;
    }
    if (value.email !== '') {
      req.email = value.email;
    }

    if (this.event.eventTemplate?.rsvpSettings?.requireGroupRsvp) {
      req.groupName = value.groupName;
    }

    if (this.event.eventTemplate?.attendeeQuestions) {
      const answers: RSVPQuestionAnswer[] = [];
      for (const question of this.event.eventTemplate?.attendeeQuestions) {
        if (value[`question#${question.id}`]) {
          const answer: RSVPQuestionAnswer = {
            eventAttendeeQuestionId: question.id,
          };

          if (question.type === QuestionType.DROPDOWN) {
            answer.eventAttendeeQuestionOptionId =
              +value[`question#${question.id}`];
          } else if (question.type === QuestionType.TEXT) {
            answer.openAnswer = value[`question#${question.id}`];
          } else if (question.type === QuestionType.CHECKBOX) {
            answer.boolAnswer = !!value[`question#${question.id}`];
          }

          answers.push(answer);
        }
      }

      req.rsvpQuestionAnswers = answers;
    }

    this.eventService.saveRSVP(this.event.id, req).subscribe({
      next: () => {
        this.dialogRef.close({
          refresh: true,
        });
        this.notificationService.success(
          this.translateService.instant('APP.EVENT_RSVP.ADD_GUEST_SUCCESS'),
        );
      },
      error: (err) => {
        this.checkAndShowRSVPError(err);
      },
    });
  }

  addPlusOne() {
    if (!this.event || !this.form) {
      return;
    }

    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    const value = this.form.value;

    const req: SaveAttendeeRequest = {
      groupLeadAttendeeId: this.groupLeadAttendeeId,
    };

    if (value.firstName !== '') {
      req.firstName = value.firstName;
    }
    if (value.lastName !== '') {
      req.lastName = value.lastName;
    }
    if (value.email !== '') {
      req.email = value.email;
    }
    if (value.phone !== '') {
      req.phone = value.phone;
    }

    if (this.event?.eventTemplate?.attendeeQuestions) {
      const answers: RSVPQuestionAnswer[] = [];
      for (const question of this.event.eventTemplate.attendeeQuestions) {
        if (value[`question#${question.id}`]) {
          const answer: RSVPQuestionAnswer = {
            eventAttendeeQuestionId: question.id,
          };

          if (question.type === QuestionType.DROPDOWN) {
            answer.eventAttendeeQuestionOptionId =
              +value[`question#${question.id}`];
          } else if (question.type === QuestionType.TEXT) {
            answer.openAnswer = value[`question#${question.id}`];
          } else if (question.type === QuestionType.CHECKBOX) {
            answer.boolAnswer = !!value[`question#${question.id}`];
          }

          answers.push(answer);
        }
      }

      req.rsvpQuestionAnswers = answers;
    }

    this.eventService.saveAttendee(this.event.id, req).subscribe({
      next: () => {
        this.dialogRef.close({
          refresh: true,
        });
        this.notificationService.success(
          this.translateService.instant('APP.EVENT_RSVP.ADD_GUEST_SUCCESS'),
        );
      },
      error: (err) => {
        if (
          err?.error ===
          'bad request - an attendee with the new email/phone already exists'
        ) {
          this.notificationService.error(
            this.translateService.instant(
              'APP.EVENT_RSVP.ERRORS.SAVE_ATTENDEE_EXISTS',
            ),
          );
        } else {
          this.notificationService.error(
            this.translateService.instant('APP.ERRORS.COULD_NOT_SAVE'),
          );
        }
      },
    });
  }

  close(event?: any) {
    this.dialogRef.close(event);
  }

  private checkAndShowRSVPError(err: any) {
    if (err?.error === 'bad request - max capacity exceeded') {
      this.notificationService.error(
        this.translateService.instant('APP.EVENT_RSVP.ERRORS.MAX_CAPACITY'),
        this.translateService.instant('APP.EVENT_RSVP.ERRORS.COULDNT_RSVP'),
      );
    } else if (err?.error === 'bad request - rsvp disabled') {
      this.notificationService.error(
        this.translateService.instant('APP.EVENT_RSVP.ERRORS.DISABLED'),
        this.translateService.instant('APP.EVENT_RSVP.ERRORS.COULDNT_RSVP'),
      );
    } else if (err?.error === 'bad request - plus ones limit exceeded') {
      this.notificationService.error(
        this.translateService.instant('APP.EVENT_RSVP.ERRORS.PLUS_ONES_LIMIT'),
        this.translateService.instant('APP.EVENT_RSVP.ERRORS.COULDNT_RSVP'),
      );
    } else if (err?.error === 'bad request - already part of guest list') {
      this.notificationService.error(
        this.translateService.instant('APP.EVENT_RSVP.ERRORS.GUEST_EXISTS'),
      );
    } else {
      this.notificationService.error(
        this.translateService.instant('APP.EVENT_RSVP.ERRORS.COULDNT_RSVP'),
      );
    }
  }

  get visual(): EventTemplateVisual | undefined {
    return this.event?.eventTemplate?.visual;
  }

  protected readonly RSVPOptionType = RSVPOptionType;
}
