import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import moment from 'moment';

@Component({
  selector: 'app-date-card',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './date-card.component.html',
  styleUrl: './date-card.component.scss',
})
export class DateCardComponent {
  @Input() date?: Date;
  @Input() monthOnly?: boolean = false;
  @Input() brandColors?: boolean = false;
  @Input() cardColors?: boolean = false;

  constructor(private translateService: TranslateService) {}

  get dateMonth(): string {
    if (!this.date) {
      return '';
    }
    const currentLang = this.translateService.currentLang;

    const locale = currentLang === 'de' ? 'de' : 'en';
    moment.locale(locale);

    return moment(this.date).format('MMM');
  }

  get dateDate(): string {
    if (!this.date) {
      return '';
    }
    const currentLang = this.translateService.currentLang;

    const locale = currentLang === 'de' ? 'de' : 'en';
    moment.locale(locale);

    return moment(this.date).format('DD');
  }

  get dateDay(): string {
    if (!this.date) {
      return '';
    }
    const currentLang = this.translateService.currentLang;

    const locale = currentLang === 'de' ? 'de' : 'en';
    moment.locale(locale);

    return moment(this.date).format('ddd');
  }
}
