import { MatFormFieldAppearance } from '@angular/material/form-field';

export enum RSVPFormAppearance {
  OUTLINE = 'outline',
  FILL = 'fill',
}

export namespace RSVPFormAppearance {
  export function getNameForFormAppearance(
    appearance: RSVPFormAppearance,
  ): MatFormFieldAppearance {
    switch (appearance) {
      case RSVPFormAppearance.OUTLINE:
        return 'outline';
      case RSVPFormAppearance.FILL:
        return 'fill';
    }
  }
}
