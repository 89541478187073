import {
  mergeApplicationConfig,
  ApplicationConfig,
  Injectable,
  importProvidersFrom,
} from '@angular/core';
import { appConfig } from './app.config';
import { provideNgcCookieConsent } from 'ngx-cookieconsent';
import { cookieConfig } from './config/cookie.config';
import {
  HAMMER_GESTURE_CONFIG,
  HammerGestureConfig,
  HammerModule,
} from '@angular/platform-browser';
import { DIRECTION_ALL } from 'hammerjs';

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  override overrides = <any>{
    swipe: { direction: DIRECTION_ALL, velocity: 0.4, threshold: 20 }, // override default settings
  };
}

const browserConfig: ApplicationConfig = {
  providers: [
    provideNgcCookieConsent(cookieConfig),
    importProvidersFrom(HammerModule),
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
  ],
};

export const config = mergeApplicationConfig(appConfig, browserConfig);
