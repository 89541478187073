import { CommonModule } from '@angular/common';
import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  Inject,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import {
  CreateEventTicketOrderSessionRequest,
  CreateEventTicketOrderSessionTicket,
} from '@models/events/dto/create-event-ticket-order-session.request';
import { EventAttendeeTicketOrderStatus } from '@models/events/event-attendee-ticket-order-status.enum';
import { EventAttendeeTicketOrder } from '@models/events/event-attendee-ticket-order.model';
import { EventTicket } from '@models/events/event-ticket.model';
import { EventType } from '@models/events/event-type.enum';
import { Event } from '@models/events/event.model';
import { TicketVoucherCodeResponse } from '@models/tickets/dto/ticket-voucher-code.response';
import { YesNoDialog } from '@modules/customer/dialogs/yes-no/yes-no.dialog';
import { PageViewRequest } from '@models/trackings/dto/page-view.request';
import { PageViewType } from '@models/trackings/page-view-type.enum';
import { EventGenericBuyingTicketsComponent } from '@modules/events/components/event-generic-buying-tickets/event-generic-buying-tickets.component';
import { EventPaymentComponent } from '@modules/events/components/event-payment/event-payment.component';
import { EventTicketsSelectionComponent } from '@modules/events/components/event-tickets-selection/event-tickets-selection.component';
import { TicketPaymentSuccessComponent } from '@modules/events/components/ticket-payment-success/ticket-payment-success.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { EventService } from '@services/events/event.service';
import { NotificationService } from '@services/shared/notification.service';
import { ScreenWidthService } from '@services/shared/screen-width.service';
import { TicketService } from '@services/tickets/ticket.service';
import { StripeError } from '@stripe/stripe-js';
import { TrackingService } from '@services/trackings/tracking.service';
import moment from 'moment/moment';
import {
  finalize,
  map,
  Observable,
  shareReplay,
  take,
  takeWhile,
  tap,
  timer,
} from 'rxjs';
import { User } from '@models/users/user.model';

enum ShowPopupInfoType {
  TIME_EXPIRED = 'TIME_EXPIRED',
  LEAVE_SESSION = 'LEAVE_SESSION',
  LEAVE_SESSION_BACK = 'LEAVE_SESSION_BACK',
  SESSION_ABOUT_TO_EXPIRE = 'SESSION_ABOUT_TO_EXPIRE',
  TICKETS_UNAVAILABLE = 'TICKETS_UNAVAILABLE',
}
@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    EventTicketsSelectionComponent,
    EventPaymentComponent,
    TicketPaymentSuccessComponent,
    MatExpansionModule,
    EventGenericBuyingTicketsComponent,
  ],
  providers: [
    TicketService,
    EventService,
    NotificationService,
    ScreenWidthService,
    TrackingService,
  ],
  templateUrl: './get-tickets.dialog.html',
  styleUrl: './get-tickets.dialog.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class GetTicketsDialog {
  event?: Event;
  mainImage?: string;
  step: number = 1;

  tickets?: EventTicket[];

  ticketOrder?: EventAttendeeTicketOrder;
  redirectStatus?: string;

  checkoutSessionTimeRemaining$?: Observable<number>;

  showPopupInfo = false;
  showPopupInfoType?: ShowPopupInfoType;
  checkoutCreationLoading = signal(false);
  isDesktop?: boolean;

  voucherApplied?: TicketVoucherCodeResponse = undefined;
  isGeneric: boolean = false;
  showOverview: boolean = false;

  loggedUser?: User;

  constructor(
    public dialogRef: MatDialogRef<GetTicketsDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ticketService: TicketService,
    private eventService: EventService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private screenWidthService: ScreenWidthService,
    private dialog: MatDialog,
    private trackingService: TrackingService,
  ) {
    this.screenWidthService
      .isDesktop()
      .pipe(takeUntilDestroyed())
      .subscribe({
        next: (isDesktop: boolean) => {
          this.isDesktop = isDesktop;
        },
      });

    if (data) {
      this.event = data.event;
      this.mainImage = data.mainImage;
      this.loggedUser = data.loggedUser;
      this.isGeneric = data.isGeneric;

      if (this.event) {
        this.ticketService.getTickets(this.event.id).subscribe({
          next: (tickets) => {
            this.tickets = tickets;
          },
        });
      }
      if (data.ticketOrder) {
        this.redirectStatus = data.redirectStatus;
        this.ticketOrder = data.ticketOrder;
        if (this.ticketOrder) {
          if (this.redirectStatus === 'failed') {
            this.ticketOrder.status =
              EventAttendeeTicketOrderStatus.REQUIRES_PAYMENT_METHOD;
            this.step = 2;
            this.onPaymentFailed();
          } else if (
            this.ticketOrder.isPaidOrProcessing() ||
            this.redirectStatus === 'succeeded'
          ) {
            if (this.redirectStatus === 'succeeded') {
              this.ticketOrder.status =
                EventAttendeeTicketOrderStatus.SUCCEEDED;
            }

            this.step = 4;
          }
        }
      }
    }
  }

  ngAfterViewInit(): void {
    this.trackPageView();
  }

  trackPageView(): void {
    if (!this.event || this.event.isUserHost(this.loggedUser?.id)) {
      return;
    }

    const pageViewRequest: PageViewRequest = {
      eventId: this.event.id,
      type: PageViewType.TICKETS,
    };
    this.trackingService
      .createPageView(pageViewRequest)
      .pipe(take(1))
      .subscribe();
  }

  nextStep() {
    this.step++;
    this.scrollToTop();
  }

  previousStep() {
    if (this.step === 3) {
      this.showPopupInfoType = ShowPopupInfoType.LEAVE_SESSION_BACK;
      this.showPopupInfo = true;
    } else {
      this.step--;
      this.scrollToTop();
    }
  }

  setVoucher(voucher: TicketVoucherCodeResponse) {
    this.voucherApplied = voucher;
  }

  onPaymentSuccess(ticketOrder: EventAttendeeTicketOrder) {
    this.ticketOrder = ticketOrder;
    this.nextStep();
  }

  onPaymentFailed(err?: StripeError) {
    this.openPaymentFailedDialog(err);
  }

  openPaymentFailedDialog(err?: StripeError) {
    let paymentFailedTitle = 'APP.EVENT_PAYMENT.PAYMENT_FAILED';
    let paymentFailedMessage = 'APP.EVENT_PAYMENT.PAYMENT_FAILED_MESSAGE';
    if (err) {
      if (err.decline_code && err.decline_code !== '') {
        paymentFailedTitle = `APP.STRIPE_DECLINE_CODES.${err.decline_code}.TITLE`;
        paymentFailedMessage = `APP.STRIPE_DECLINE_CODES.${err.decline_code}.MESSAGE`;
      } else if (err.message && err.message !== '') {
        paymentFailedMessage = err.message;
      }
    }

    this.dialog.open(YesNoDialog, {
      maxWidth: '602px',
      maxHeight: '100vh',
      width: '100%',
      height: 'auto',
      data: {
        isPaymentFailedDialog: true,
        title: paymentFailedTitle,
        message: paymentFailedMessage,
        yesLabel: 'APP.EVENT_PAYMENT.TRY_AGAIN',
        textLinkLabel: 'APP.EVENT_PAYMENT.CONTACT_HOST',
        textLink: this.getHostContact(),
      },
      panelClass: ['normal-dialog'],
    });
  }

  getHostContact(): string | undefined {
    let email = this.event?.hostUser?.email;
    // TODO: Per Anhalter email hardcoded, use support email
    if (!email || email === '') {
      email = 'peranhalter@eventpage.ai';
    }

    return `mailto:${email}`;
  }

  stay() {
    this.showPopupInfo = false;
    this.showPopupInfoType = undefined;
  }

  leaveSession() {
    if (this.ticketOrder) {
      this.eventService
        .leaveEventTicketOrderSession(this.ticketOrder.uuid)
        .pipe(
          finalize(() => {
            if (
              this.showPopupInfoType === ShowPopupInfoType.LEAVE_SESSION_BACK
            ) {
              this.ticketsUnavailableRefresh(true);
            }
          }),
        )
        .subscribe();
    }

    if (this.showPopupInfoType === ShowPopupInfoType.LEAVE_SESSION) {
      this.dialogRef.close();
    }
  }

  ticketsUnavailableRefresh(toPreviousStep = false) {
    if (!this.event) {
      return;
    }

    this.ticketService.getTickets(this.event.id).subscribe({
      next: (tickets) => {
        this.tickets = tickets;
        this.ticketOrder = undefined;

        if (toPreviousStep) {
          this.step--;
          this.scrollToTop();
        }

        this.showPopupInfo = false;
        this.showPopupInfoType = undefined;
      },
    });
  }

  createTicketOrderSession(tickets: CreateEventTicketOrderSessionTicket[]) {
    if (!this.event || this.checkoutCreationLoading()) {
      return;
    }

    this.checkoutCreationLoading.set(true);

    const request: CreateEventTicketOrderSessionRequest = {
      tickets: tickets,
    };

    if (this.voucherApplied) {
      request.ticketVoucherCode = this.voucherApplied.codeName;
    }
    this.eventService
      .createEventTicketOrderSession(this.event.id, request)
      .pipe(finalize(() => this.checkoutCreationLoading.set(false)))
      .subscribe({
        next: (ticketOrder) => {
          this.ticketOrder = ticketOrder;
          if (this.ticketOrder.checkoutSessionExpirationDate) {
            this.startTimeRemaining(
              this.ticketOrder.checkoutSessionExpirationDate,
            );
          }
          if (this.isGeneric) {
            this.showOverview = true;
          }
          this.step++;
          this.scrollToTop();
        },
        error: (err) => {
          if (err?.error === 'ticket is not on sale') {
            this.notificationService.error(
              this.translateService.instant(
                'APP.TICKETING.ERRORS.TICKET_NOT_ON_SALE',
              ),
            );
          } else if (err?.error === 'requested ticket quantity not available') {
            this.showPopupInfoType = ShowPopupInfoType.TICKETS_UNAVAILABLE;
            this.showPopupInfo = true;
          } else {
            this.notificationService.error(
              this.translateService.instant(
                'APP.TICKETING.ERRORS.COULD_NOT_CREATE_ORDER',
              ),
            );
          }
        },
      });
  }

  startTimeRemaining(checkoutSessionExpirationDate: Date) {
    const seconds = moment(checkoutSessionExpirationDate).diff(
      moment(),
      'seconds',
    );

    if (seconds > 0) {
      this.checkoutSessionTimeRemaining$ = timer(0, 1000).pipe(
        map((n) => (seconds - n) * 1000),
        takeWhile((n) => n >= 0),
        tap((remaining) => {
          if (remaining <= 30000 && remaining > 29000) {
            // console.log('About 30 seconds left');
          }
          if (remaining === 0 && this.step !== 4) {
            this.showPopupInfoType = ShowPopupInfoType.TIME_EXPIRED;
            this.showPopupInfo = true;
          }
        }),
        shareReplay(1),
      );
    }
  }

  onClose() {
    if (!this.isGeneric && this.step === 3) {
      this.showPopupInfoType = ShowPopupInfoType.LEAVE_SESSION;
      this.showPopupInfo = true;
    } else {
      this.dialogRef.close();
    }
  }

  formatEventDate(startDate?: Date, endDate?: Date): string {
    const currentLang = this.translateService.currentLang;
    const locale = currentLang === 'de' ? 'de' : 'en';
    moment.locale(locale);

    const startMoment = moment(startDate);
    const endMoment = moment(endDate);

    // Format the day abbreviation (e.g., "Fr." or "Sa.")
    const startDayFormat = startMoment.format('dd');
    const endDayFormat = endMoment.format('dd');

    // Format the date and time
    const startTime = startMoment.format('HH:mm');
    const endTime = endMoment.format('HH:mm');

    // Format the time zone
    const timeZoneAbbr = startMoment.format('z');

    // Case 1: Same day
    if (startMoment.isSame(endMoment, 'day')) {
      return `${startDayFormat} ${startMoment.format(
        'DD. MMMM YYYY',
      )}, ${startTime} - ${endTime} ${timeZoneAbbr}`;
    }

    // Case 2: Same month and year, different days
    if (
      startMoment.year() === endMoment.year() &&
      startMoment.month() === endMoment.month()
    ) {
      return `${startDayFormat} ${startMoment.format(
        'DD.',
      )}-${endDayFormat} ${endMoment.format(
        'DD. MMMM YYYY',
      )}, ${startTime} - ${endTime} ${timeZoneAbbr}`;
    }

    // Case 3: Same year, different months
    if (startMoment.year() === endMoment.year()) {
      return `${startDayFormat} ${startMoment.format(
        'DD. MMMM',
      )} - ${endDayFormat} ${endMoment.format(
        'DD. MMMM YYYY',
      )}, ${startTime} - ${endTime} ${timeZoneAbbr}`;
    }

    // Case 4: Different years
    return `${startDayFormat} ${startMoment.format(
      'DD. MMMM YYYY',
    )} - ${endDayFormat} ${endMoment.format(
      'DD. MMMM YYYY',
    )}, ${startTime} - ${endTime} ${timeZoneAbbr}`;
  }

  private scrollToTop() {
    setTimeout(() => {
      const contentContainer =
        document.querySelector('.mat-mdc-dialog-surface') || window;
      // reset the view to the top on each step change
      if (contentContainer) {
        contentContainer.scrollTo(0, 0);
      }
    });
  }

  protected readonly ShowPopupInfoType = ShowPopupInfoType;
  protected readonly EventType = EventType;
}
