import { Clipboard } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { Community } from '@models/communities/community.model';
import { Event } from '@models/events/event.model';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { EventService } from '@services/events/event.service';
import { NotificationService } from '@services/shared/notification.service';
import { ShareModule } from 'ngx-sharebuttons';
import { FormsModules } from '@utils/shared-modules';
import { QRCodeModule } from 'angularx-qrcode';
import { Helpers } from '@utils/helpers';

enum LinkType {
  'EVENT_LINK',
  'VOTING_LINK',
  'CAMPAIGN_INSURANCE_CHECK_LINK',
  'COMMUNITY_LINK',
}
@Component({
  selector: 'app-invite-share-options',
  standalone: true,
  providers: [NotificationService, EventService],
  imports: [
    TranslateModule,
    CommonModule,
    ShareModule,
    FormsModules,
    ReactiveFormsModule,
    MatIconModule,
    QRCodeModule,
  ],
  templateUrl: './invite-share-options.component.html',
  styleUrl: './invite-share-options.component.scss',
})
export class InviteShareOptionsComponent implements OnInit {
  @Input() community?: Community;
  @Input() event?: Event;
  @Input() showVoting?: boolean = true;
  @Input() showQRCodeDownload = true;
  form: FormGroup;
  votingLink?: string;
  eventLink?: string;
  campaignInsuranceCheckLink?: string;

  constructor(
    private fb: FormBuilder,
    private clipboard: Clipboard,
    private notificationService: NotificationService,
    private translateService: TranslateService,
  ) {
    this.form = this.fb.group({
      eventLink: [''],
      votingLink: [''],
      campaignInsuranceCheckLink: [''],
    });
  }

  copyLink(linkType: LinkType) {
    let link: string | undefined;
    switch (linkType) {
      case LinkType.EVENT_LINK:
        link = this.eventLink;
        break;
      case LinkType.VOTING_LINK:
        link = this.votingLink;
        break;
      case LinkType.CAMPAIGN_INSURANCE_CHECK_LINK:
        link = this.campaignInsuranceCheckLink;
        break;
    }
    if (link) {
      this.clipboard.copy(link);
      this.notificationService.success(
        this.translateService.instant(
          'APP.AFFILIATE_PRODUCTS_PAGE_LIST.SUCCESS.LINK_COPIED',
        ),
      );
    }
  }

  saveQRCodeAsImage(parent: any) {
    const parentElement =
      parent.qrcElement.nativeElement.querySelector('img').src;

    if (parentElement && this.event) {
      // converts base 64 encoded image to blobData
      let blobData = Helpers.convertBase64ToBlob(parentElement);
      // saves as image
      const blob = new Blob([blobData], { type: 'image/png' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      // name of the file
      link.download = this.event.uri;
      link.click();
    }
  }

  ngOnInit(): void {
    if (this.event) {
      this.eventLink = this.event?.getLink();
      this.votingLink = this.event?.getVotingLink();
      this.campaignInsuranceCheckLink =
        this.event?.getEventCampaignInsuranceCheckLink();
      this.form.patchValue({
        eventLink: this.eventLink,
        votingLink: this.votingLink,
        campaignInsuranceCheckLink: this.campaignInsuranceCheckLink,
      });
    }

    if (this.community) {
      this.eventLink = this.community?.getLink();
      this.form.patchValue({
        eventLink: this.eventLink,
      });
    }
  }

  protected readonly LinkType = LinkType;
}
