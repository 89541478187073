import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '@models/users/user.model';
import { TranslateService } from '@ngx-translate/core';
import { AuthGoogleService } from '@services/auth/auth-google.service';
import { AuthService } from '@services/auth/auth.service';
import { NotificationService } from '@services/shared/notification.service';
import { OAuthService } from 'angular-oauth2-oidc';
import moment from 'moment/moment';
import { AppRoutes } from '../../../../routes';

@Component({
  selector: 'app-sso-success',
  standalone: true,
  imports: [],
  providers: [NotificationService],
  templateUrl: './sso-success.page.html',
  styleUrl: './sso-success.page.scss',
})
export class SsoSuccessPage implements OnInit {

  constructor(
    private authGoogleService: AuthGoogleService,
    private authService: AuthService,
    private oAuthService: OAuthService,
    private router: Router,
    private notificationService: NotificationService,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.tryLogin();
  }

  tryLogin() {
    this.oAuthService.tryLogin().then(() => {
      const hasValidIdToken = this.oAuthService.hasValidIdToken();
      const state = this.oAuthService.state;
      if (hasValidIdToken && state?.length) {
        this.loginOrRegister(state);
      }
    });
  }

  loginOrRegister(redirectUri: string) {
    const idToken = this.authGoogleService.getIdToken();
    if (idToken) {
      this.authService.loginWithSSOIdToken(
        idToken,
        false,
        (user: User) => {
          if (
            user &&
            moment(user.createdAt).isBetween(
              moment().subtract(1, 'minutes'),
              moment(),
            )
          ) {
            this.router.navigate(['/', AppRoutes.Customer.welcome]);
          } else {
            this.router.navigate([redirectUri]);
          }
        },
        () => {
          this.notificationService.error(
            this.translateService.instant('APP.AUTH.ERRORS.SSO'),
          );
          this.authGoogleService.logout();
        },
      );
    } else {
      this.notificationService.error(
        this.translateService.instant('APP.AUTH.ERRORS.SSO'),
      );
      this.authGoogleService.logout();
    }
  }
}
