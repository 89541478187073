@if (event) {
  <div class="advanced-one-column-event-page">
    @for (section of getEventSectionsOrdered(); track section.id) {
      <div
        class="section"
        [class.timeline-section]="section.type === EventSectionType.TIMELINE"
      >
        <app-advanced-one-column-event-section
          [section]="section"
          [isEdit]='isEdit'
          (editSection)='onConfigureSection($event)'
        ></app-advanced-one-column-event-section>
      </div>
    }
    @if(isEdit){
    <app-advanced-one-column-configure-section [configurableObject]='configurableObject' (close)='onClose()'/>
    }
  </div>
  @if (showPopup && lowestTicketPrice) {
    <div
      class="ticket-section-popup"
      [class.host-view]="isHostView"
      [class.show]="showPopup"
    >
      <div class="ticket-popup-content">
        <div class="text">
          {{ "APP.FROM" | translate | uppercase }}
          <span class="price">{{ lowestTicketPrice }}</span>
        </div>
        <button class="btn btn-action" (click)="openGetTicketsDialog()">
          {{ "APP.EVENT.GET_YOUR_TICKET_NOW" | translate }}
        </button>
        @if (isDesktop) {
          <div class="text flex-placeholder">
            {{ "APP.FROM" | translate | uppercase }}
            <span class="price">{{ lowestTicketPrice }}</span>
          </div>
        }
      </div>
    </div>
  }
}
