import { Component, Input } from '@angular/core';
import { EventWeddingParty } from '@models/events/event-wedding-party.model';
import { EventSectionWeddingPartyItemComponent } from '../event-section-wedding-party-item/event-section-wedding-party-item.component';

@Component({
  selector: 'app-event-section-wedding-party',
  standalone: true,
  imports: [EventSectionWeddingPartyItemComponent],
  templateUrl: './event-section-wedding-party.component.html',
  styleUrl: './event-section-wedding-party.component.scss',
})
export class EventSectionWeddingPartyComponent {
  @Input() eventWeddingParty?: EventWeddingParty;
}
