@if (event && visual && font && sections) {
  <div class="page-container invitation-wrapper" [class.host-view]="isHostView">
    <div
      class="main-content"
      [style.backgroundColor]="
        visual.backgroundColor ? visual.backgroundColor : ''
      "
    >
      <div
        class="overlay"
        [ngStyle]="{
          'background-image': 'url(' + event.getEncodedMainImage() + ')',
          'background-color': visual.backgroundColor
        }"
      >
        @if (event.isMainPictureVideo()) {
          <video
            #keyVisualOverlayVideo
            class="overlay-video"
            [muted]="'muted'"
            autoplay
            playsinline
            loop
            disablePictureInPicture
          >
            <source [src]="event.mainPictureUrl" type="video/mp4" />
          </video>
        }
      </div>
      @if (event.isMainPictureVideo()) {
        <div
          class="key-visual-video-cont"
          [class.attendee-view]="isAttendeeView || isPendingAttendeeView"
        >
          <video
            #keyVisualVideo
            class="key-visual-video"
            [muted]="'muted'"
            autoplay
            playsinline
            loop
            disablePictureInPicture
          >
            <source [src]="event.mainPictureUrl" type="video/mp4" />
          </video>

          @if (isVideoMuted) {
            <button
              class="unmute-button"
              #unMuteTooltip="matTooltip"
              matTooltip="{{ 'APP.UNMUTE_VIDEO_TOOLTIP' | translate }}"
              matTooltipPosition="above"
              matTooltipTouchGestures="off"
              [matTooltipClass]="['unmute-tooltip']"
              (click)="onUnmuteVideo()"
            >
              <i class="fa fa-volume-slash"></i>
            </button>
          } @else {
            <button class="unmute-button" (click)="onMuteVideo()">
              <i class="fa fa-volume"></i>
            </button>
          }
        </div>
      } @else {
        <img
          [src]="event.getEncodedMainImage()"
          class="key-visual-img"
          alt="Key Visual"
        />
      }
    </div>

    <div
      class="event-sections"
      [style.backgroundColor]="
        visual.backgroundColor ? visual.backgroundColor : ''
      "
    >
      <div
        class="event-sections-content"
        [style.backgroundColor]="
          visual.backgroundColor ? visual.backgroundColor : ''
        "
      >
        <div
          class="invitation-title-section primary-font"
          [style.font-weight]="
            font.primaryFontWeight ? font.primaryFontWeight : null
          "
        >
          {{ event.subHeader }}
        </div>

        @if (!checkIsEmptyHtml(eventDescriptionHtml)) {
          <div class="invitation-message-section body-font">
            <div
              [innerHtml]="eventDescriptionHtml | safeHtml"
              class="text-select"
            ></div>
          </div>
        }

        <div class="invitation-date-section body-font">
          <div class="invitation-date">
            <div class="invitation-date-header">
              {{ "APP.DATE" | translate }}:
            </div>
            @if (event.startDate) {
              <div class="event-date-cont">
                <span>{{ getFormattedDate(event.startDate) }}</span>
                <span> | </span>
                @if (event.is12HourDateFormat()) {
                  <span>{{ event.startDate | dateAmPm }}</span>
                } @else {
                  <span>{{ event.startDate | date: "HH:mm" }}</span>
                }
                @if (event.endsOnSameDate()) {
                  @if (event.is12HourDateFormat()) {
                    <span> - {{ event.endDate | dateAmPm }}</span>
                  } @else {
                    <span> - {{ event.endDate | date: "HH:mm" }}</span>
                  }
                }
                @if (event.endDate && !event.endsOnSameDate()) {
                  <span> - </span>
                }
                @if (event.endDate && !event.endsOnSameDate()) {
                  <span>{{ getFormattedDate(event.endDate) }}</span>
                  <span> | </span>
                  @if (event.is12HourDateFormat()) {
                    <span>{{ event.endDate | dateAmPm }}</span>
                  } @else {
                    <span>{{ event.endDate | date: "HH:mm" }}</span>
                  }
                }
              </div>
            } @else {
              {{ "APP.EVENT.DATE_TBD" | translate }}
            }
          </div>
        </div>

        <div class="invitation-location-section body-font">
          <div class="invitation-location-header">
            {{ "APP.EVENT_LINK_BIO.LOCATION" | translate }}:
          </div>
          <div class="invitation-location-cont">
            <div
              class="invitation-location"
              [innerHtml]="
                event.eventAddress
                  ? event.eventAddress.toStringWithNameWHtmlLineBreak()
                  : '-'
              "
            ></div>
            @if (event.eventAddress) {
              <div class="invitation-location-navigate">
                <i class="far fa-location-arrow"></i>
                <a
                  [href]="event.getGoogleMapsLink()"
                  target="_blank"
                  class="invitation-text-link"
                  >{{ "APP.NAVIGATE" | translate }}</a
                >
              </div>
            }
          </div>
        </div>

        @for (section of sections; track section.id) {
          @if (section.type === EventSectionType.TIMELINE) {
            <div class="invitation-timeline-section body-font">
              @if (section.headline && section.headline !== "") {
                <div class="event-section-headline">
                  {{ section.headline }}
                </div>
              }

              @if (section.title && section.title !== "") {
                <div class="event-section-title">
                  {{ section.title }}
                </div>
              }

              <div
                class="event-section-description text-select"
                [innerHtml]="section.description | safeHtml"
              ></div>
            </div>
          } @else if (section.type === EventSectionType.GALLERY) {
            <div class="invitation-gallery-section body-font">
              @if (section.headline && section.headline !== "") {
                <div class="event-section-headline">
                  {{ section.headline }}
                </div>
              }

              @if (section.title && section.title !== "") {
                <div class="event-section-title">
                  {{ section.title }}
                </div>
              }

              <div
                class="event-section-description text-select"
                [innerHtml]="section.description | safeHtml"
              ></div>
            </div>
          }
        }

        <div class="invitation-rsvp-section body-font">
          <!-- <div class="rsvp-buttons-cont" appStickyFixed>
            @if (isAttendeeView) {
              @if (attendeeRsvpType === RSVPOptionType.NO) {
                <div
                  class="btn btn-invitation-transparent attendee-view"
                  [style.background]="
                    visual.backgroundColor ? visual.backgroundColor : ''
                  "
                >
                  YOU ARE NOT JOINING
                </div>
              } @else {
                <div
                  class="btn btn-invitation-transparent attendee-view"
                  [style.background]="
                    visual.backgroundColor ? visual.backgroundColor : ''
                  "
                >
                  YOU ARE JOINING <i class="fal fa-check"></i>
                </div>
              }
            } @else if (isPendingAttendeeView) {
              <div
                class="btn btn-invitation-transparent attendee-view"
                [style.background]="
                  visual.backgroundColor ? visual.backgroundColor : ''
                "
              >
                {{ "APP.WAIT_LIST.PENDING_APPROVAL" | translate }}
              </div>
            } @else {
              <div
                class="btn btn-invitation-transparent"
                [style.background]="
                  visual.backgroundColor ? visual.backgroundColor : ''
                "
                (click)="rsvp(RSVPOptionType.YES)"
              >
                I’M JOINING
              </div>
              <div
                class="btn btn-invitation-transparent"
                [style.background]="
                  visual.backgroundColor ? visual.backgroundColor : ''
                "
                (click)="rsvp(RSVPOptionType.NO)"
              >
                CAN’T JOIN
              </div>
            }
          </div> -->

          <div class="rsvp-buttons-cont" appStickyFixed>
            @if (isAttendeeView) {
              <div
                class="btn btn-invitation-transparent attendee-view"
                [style.background]="
                  visual.backgroundColor ? visual.backgroundColor : ''
                "
                (click)="notGoingRsvp()"
              >
                {{
                  attendeeRsvpType &&
                    (event.getEventRSVPTitle(attendeeRsvpType) | translate)
                }}
                @if (attendeeRsvpType === RSVPOptionType.YES) {
                  <i class="fal fa-check"></i>
                }
              </div>
            } @else if (isPendingAttendeeView) {
              <div
                class="btn btn-invitation-transparent attendee-view"
                [style.background]="
                  visual.backgroundColor ? visual.backgroundColor : ''
                "
                (click)="notGoingRsvp()"
              >
                {{ "APP.WAIT_LIST.PENDING_APPROVAL" | translate }}
              </div>
            } @else {
              @for (rsvpOption of event.rsvpOptions; track rsvpOption.type) {
                @if (
                  rsvpOption.type === RSVPOptionType.YES ||
                  rsvpOption.type === RSVPOptionType.NO
                ) {
                  <div
                    class="btn btn-invitation-transparent"
                    [style.background]="
                      visual.backgroundColor ? visual.backgroundColor : ''
                    "
                    (click)="rsvp(rsvpOption.type)"
                  >
                    {{ rsvpOption.name | translate }}
                  </div>
                }
              }
            }
          </div>

          @for (section of sections; track section.id) {
            @if (
              ![EventSectionType.TIMELINE, EventSectionType.GALLERY].includes(
                section.type
              )
            ) {
              @if (section.headline && section.headline !== "") {
                <div class="event-section-headline">
                  {{ section.headline }}
                </div>
              }

              @if (section.title && section.title !== "") {
                <div class="event-section-title">
                  {{ section.title }}
                </div>
              }

              <div
                class="event-section-description"
                [innerHtml]="section.description | safeHtml"
              ></div>
            }
          }
        </div>
      </div>

      <div class="invitation-footer-section body-font">
        <div class="footer-powered-by-cont">
          <span>{{ "APP.POWERED_BY" | translate }}</span>
          <a class="footer-icon-a" routerLink="/">
            <img
              class="media-object footer-icon"
              [src]="env.iconAsset"
              alt="eventpage.ai - Logo"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
  @if (visualEffectUrl) {
    <div
      class="effect-overlay"
      [style.background-image]="
        visualEffectUrl && visual.isEffectImage()
          ? 'url(' + visualEffectUrl + ')'
          : ''
      "
      [class.host-view]="isHostView"
    >
      @if (!visual.isEffectImage()) {
        <video
          #effectVideo
          class="effect-video"
          [muted]="'muted'"
          playsinline
          [loop]="visual.effect?.repeat"
          preload="metadata"
        >
          @if (visualEffectHevcUrl !== "") {
            <source
              [src]="visualEffectHevcUrl + '#t=0.001'"
              type="video/mp4;codecs=hvc1"
            />
          }
          <source
            [src]="visualEffectUrl"
            [type]="visual.effect?.mimeType || 'video/mp4'"
          />
        </video>
      }
    </div>
  }
}
