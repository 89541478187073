import { ClassData } from '@models/class-data';
import { EventMessageChannel } from '@models/events/event-message-channel.model';
import { EventMessageRecipient } from '@models/events/event-message-recipient.model';
import { User } from '@models/users/user.model';

export class EventMessage {
  id: number;
  createdAt: Date;
  eventId: number;
  userId: number;
  user: User;
  message: string;
  channels: EventMessageChannel[];
  recipients: EventMessageRecipient[];

  constructor(json: ClassData<EventMessage>) {
    this.id = +json.id;
    this.createdAt = new Date(json.createdAt);
    this.eventId = json.eventId;
    this.userId = json.userId;
    this.user = new User(json.user);
    this.message = json.message;
    this.channels = json.channels.map(
      (channel) => new EventMessageChannel(channel),
    );
    this.recipients = json.recipients.map(
      (recipient) => new EventMessageRecipient(recipient),
    );
  }
}
