<div class="share-wrapper">
  <form [formGroup]="form" class="w-100 form-wrapper">
    <div class="invite-link">
      <label class="invite-title">{{
        "APP.EVENT_INVITE.INVITE_VIA_LINK" | translate
      }}</label>
      <mat-form-field appearance="outline" class="w-100 copy-button">
        <input
          class="input-link"
          type="text"
          matInput
          formControlName="eventLink"
          [readonly]="true"
        />
        <div matSuffix class="icon" (click)="copyLink(LinkType.EVENT_LINK)">
          <i class="far fa-copy icon-copy"></i>
        </div>
      </mat-form-field>
    </div>
    <div class="invite-link">
      <label class="invite-title">
        {{ "APP.EVENT_INVITE.INVITE_VIA_SOCIALS" | translate }}
      </label>
      @if (event) {
        <div class="share-buttons">
          <i
            class="fab fa-facebook-messenger share-icon"
            shareButton="messenger"
            [url]="event.getLink()"
            [title]="event.name"
          ></i>
          <i
            class="fab fa-facebook-square share-icon"
            shareButton="facebook"
            [url]="event.getLink()"
            [title]="event.name"
          ></i>
          <i
            class="fab fa-whatsapp-square share-icon"
            shareButton="whatsapp"
            [url]="event.getLink()"
            [title]="event.name"
          ></i>
        </div>
      }
      @if(community){
        <div class="share-buttons">
          <i
            class="fab fa-facebook-messenger share-icon"
            shareButton="messenger"
            [url]="community.getLink()"
            [title]="community.name"
          ></i>
          <i
            class="fab fa-facebook-square share-icon"
            shareButton="facebook"
            [url]="community.getLink()"
            [title]="community.name"
          ></i>
          <i
            class="fab fa-whatsapp-square share-icon"
            shareButton="whatsapp"
            [url]="community.getLink()"
            [title]="community.name"
          ></i>
        </div>
      }
    </div>

    @if (event) {
      <div class="invite-link">
        <label class="invite-title">
          {{ "APP.QR_CODE" | translate }}
        </label>
        <qrcode
          #parent
          [qrdata]="event.getLink()"
          [allowEmptyString]="true"
          [elementType]="'img'"
          [errorCorrectionLevel]="'M'"
          [margin]="0"
          [scale]="1"
          [width]="186"
        ></qrcode>
        @if (showQRCodeDownload) {
          <button
            type="button"
            class="btn btn-primary-party-page download-qr-btn"
            (click)="saveQRCodeAsImage(parent)"
          >
            {{ "APP.DOWNLOAD" | translate }}
          </button>
        }
      </div>
    }

    @if (community) {
      <div class="invite-link">
        <label class="invite-title">
          {{ "APP.QR_CODE" | translate }}
        </label>
        <qrcode
          #parent
          [qrdata]="community.getLink()"
          [allowEmptyString]="true"
          [elementType]="'img'"
          [errorCorrectionLevel]="'M'"
          [margin]="0"
          [scale]="1"
          [width]="186"
        ></qrcode>
        @if (showQRCodeDownload) {
          <button
            type="button"
            class="btn btn-primary-party-page download-qr-btn"
            (click)="saveQRCodeAsImage(parent)"
          >
            {{ "APP.DOWNLOAD" | translate }}
          </button>
        }
      </div>
    }
    @if (event?.getEventCampaignInsuranceCheckLink()) {
      <div class="invite-link">
      <label class="invite-title">{{
          "APP.EVENT_INVITE.CAMPAIGN_INSURANCE_CHECK_LINK" | translate
        }}</label>
      <mat-form-field appearance="outline" class="w-100 copy-button">
        <input
          class="input-link"
          type="text"
          matInput
          formControlName="campaignInsuranceCheckLink"
          [readonly]="true"
        />
        <div matSuffix class="icon" (click)="copyLink(LinkType.CAMPAIGN_INSURANCE_CHECK_LINK)">
          <i class="far fa-copy icon-copy"></i>
        </div>
      </mat-form-field>
    </div>
    }

    @if (event?.eventCampaign?.campaign && showVoting) {
      <div class="invite-link">
        <label class="invite-title"
          >{{ "APP.EVENT_INVITE.YOUR_VOTING_LINK" | translate }}
        </label>
        <span class="description">{{
          "APP.EVENT_INVITE.VOTING_LINK_DESCRIPTION" | translate
        }}</span>
        <div class="voting-link">
          <mat-form-field appearance="outline" class="w-100 copy-button">
            <input
              class="input-link"
              type="text"
              matInput
              formControlName="votingLink"
              [readonly]="true"
            />
            <div
              matSuffix
              class="icon"
              (click)="copyLink(LinkType.VOTING_LINK)"
            >
              <i class="far fa-copy icon-copy"></i>
            </div>
          </mat-form-field>
        </div>
      </div>
    }
  </form>
</div>
