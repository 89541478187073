@if (isUaSection && event && visual) {
  <div class="ua-event-wrapper">
    <div
      class="event-image"
      [ngStyle]="{
        'background-image':
          'url(' +
          (event.mainPictureUrl && event.mainPictureUrl !== ''
            ? event.mainPictureUrl
            : visual.keyVisual2Url) +
          ')',
        'background-color': visual.backgroundColor
      }"
    >
      <div class="img-overlay"></div>
      <img
        class="sponsor-overlay"
        src="https://static.eventpage.ai/event-templates/event-templates-1594/sportscheck-logo.svg"
        alt="sportScheck Logo"
      />
    </div>
    <div class="event-data">
      <div class="event-data-info">
        <div class="date">
          <object
            data="https://static.eventpage.ai/event-templates/event-templates-1594/plus.svg"
            type="image/svg+xml"
          ></object>
          {{ this.formatDate(event.startDate) }}
        </div>
        <div class="date">
          <object
            data="https://static.eventpage.ai/event-templates/event-templates-1594/plus.svg"
            type="image/svg+xml"
          ></object>
          {{ this.formatTime(event.startDate) }}
        </div>
      </div>
      <div class="location-section">
        <div class="location" [innerHTML]="locationSafeHtml"></div>
      </div>
    </div>
  </div>
} @else if (event?.type === EventType.ADVANCED_ONE_COLUMN && dates) {
  <div class="section-wrapper">
    <div class="date">
      <span (click)="isDateEdit && editDate()" [class.is-edit]="isDateEdit">{{
        formatRange | uppercase
      }}</span>
      {{ "APP.IN" | translate | uppercase }}
      <span
        (click)="isAddressEdit && editAddress()"
        [class.is-edit]="isAddressEdit"
        >{{
          event?.eventAddress || !isAddressEdit
            ? (event?.eventAddress?.locality | uppercase)
            : ("APP.LOCATION" | translate)
        }}</span
      >
    </div>
  </div>
} @else {
  <div class="section-wrapper">
    @if (!section?.parentEventSectionId) {
      <div class="date-section">
        <div class="date">{{ formatDate(event?.startDate) }}</div>
        <div class="time">
          {{ formatTime(event?.startDate) }}
          @if (event?.endDate) {
            - {{ formatTime(event?.endDate) }}
          }
          {{ "APP.TIME_AGO.SINGLE_HOUR" | translate | uppercase }}
        </div>
      </div>
    }

    <div class="location-section">
      <div class="location" [innerHTML]="locationSafeHtml"></div>
    </div>
  </div>
}
