@if (eventTemplate && event) {
  <div
    class="overlay-page overlay-theme template-preview-overlay"
    [class.mood-board-preview]="isAIMoodBoardPreview"
    [class.weddingpage-overlay]="event.type === EventType.WEDDINGPAGE"
  >
    @if (event.type === EventType.WEDDINGPAGE) {
      <app-weddingpage-event />
    } @else if (event.type === EventType.INVITATION) {
      <app-invitation-event />
    } @else if (
      event.type === EventType.SPORTPAGE || event.type === EventType.LANDINGPAGE
    ) {
      <app-sportpage-event />
    } @else if (event.type === EventType.ADVANCED_ONE_COLUMN) {
      <app-advanced-one-column-event />
    } @else {
      <app-event (useTemplate)="useTemplate()" />
    }
    @if (isAIMoodBoardPreview) {
      <div class="preview-label">{{ "APP.PREVIEW" | translate }}</div>
    }
    <div class="close-button">
      <i class="fal fa-times-circle" (click)="close()"></i>
    </div>

    @if (isAdminPreview) {
      <div class="floating-button-container">
        <button (click)="close()" type="button" class="btn floating-btn">
          <i class="far fa-pen"></i>
          {{ "APP.DESIGN_TEMPLATES.CREATE.EDIT" | translate }}
        </button>
      </div>
    } @else if (isAIMoodBoardPreview) {
      <div class="floating-button-container floating-button-container-ai">
        <button
          (click)="useMoodBoard()"
          type="button"
          class="btn btn-primary-party-page floating-btn-primary"
          [class.spinner]="inProgress"
          [disabled]="inProgress"
        >
          {{
            "APP.CREATE_WITH_AI.CREATE_EVENT"
              | translate: { platformShort: env.platformShort }
          }}
        </button>
      </div>
    } @else {
      <div class="template-info-cont">
        <div class="info-cont">
          <div class="template-name">{{ eventTemplate.eventName }}</div>
          <div class="template-creator desktop-only">
            <span class="template-by">{{ "APP.BY" | translate }}</span>
            {{ env.platform }}
          </div>
        </div>
        <div class="actions-cont">
          <button
            type="button"
            class="btn btn-primary use-button"
            (click)="useTemplate()"
          >
            {{ "APP.CREATE_EVENT.USE_TEMPLATE" | translate }}
          </button>
          <ng-container *ngIf="showOtherActions">
            <button
              type="button"
              class="btn btn-primary-outline ai-button desktop-only"
              (click)="startWithAi()"
            >
              {{ "APP.CREATE_EVENT.START_NEW_WITH_AI" | translate }}
            </button>
            <div
              [matMenuTriggerFor]="menu"
              class="icon-btn"
              (click)="$event.stopPropagation()"
            >
              <i class="far fa-ellipsis-h"></i>
            </div>
          </ng-container>
          <mat-menu
            #menu="matMenu"
            class="mat-menu-actions-container mat-menu-template-preview"
          >
            <button mat-menu-item (click)="startWithAi()" class="mobile-only">
              <span class="menu-item-content">
                <i class="far fa-sparkles"></i>
                <span class="text">{{
                  "APP.CREATE_EVENT.START_NEW_WITH_AI" | translate
                }}</span>
              </span>
            </button>
            <button mat-menu-item (click)="share()">
              <span class="menu-item-content">
                <i class="far fa-share"></i>
                <span class="text">{{ "APP.SHARE" | translate }}</span>
              </span>
            </button>
            <button mat-menu-item (click)="report()">
              <span class="menu-item-content">
                <i class="far fa-flag"></i>
                <span class="text">{{ "APP.REPORT" | translate }}</span>
              </span>
            </button>
          </mat-menu>
        </div>
      </div>
    }
  </div>
}
