<div
  class="date-time-range-picker-dialog event-dialog-content"
  mat-dialog-content
>
  <div (click)="clear()" class="clear-button">
    <span>{{ "APP.EVENT_LINK_BIO.CLEAR" | translate }}</span>
  </div>
  <div (click)="close()" class="close-button">
    <i class="fal fa-times-circle"></i>
  </div>
  <div class="dialog-body">
    <div class="dialog-title">{{ "APP.CHOSE_DATE" | translate }}</div>
    <div>
      <app-date-time-range-picker
        [form]="form"
        [dateClass]="getDateValidationClass()"
        [startAt]="startAt"
      ></app-date-time-range-picker>
    </div>
  </div>
  <div mat-dialog-actions class="dialog-actions">
    <div class="btn btn-primary-party-page" (click)="saveDate()">
      {{ "APP.SAVE_DATE" | translate }}
    </div>
  </div>
</div>
