import { ClassData } from '@models/class-data';
import { CommunityAdditionalDataField } from '@models/communities/community-additional-data-field.model';

export class CommunityMemberAdditionalData {
  id: number;
  communityAdditionalDataFieldId: number;
  communityAdditionalDataField?: CommunityAdditionalDataField;
  communityMemberId: number;
  communityAdditionalDataFieldOptionId?: number;
  openAnswer?: string;
  boolAnswer?: boolean;

  constructor(json: ClassData<CommunityMemberAdditionalData>) {
    this.id = json.id;
    this.communityAdditionalDataFieldId = json.communityAdditionalDataFieldId;
    if (json.communityAdditionalDataField) {
      this.communityAdditionalDataField = new CommunityAdditionalDataField(
        json.communityAdditionalDataField,
      );
    }
    this.communityMemberId = json.communityMemberId;
    this.communityAdditionalDataFieldOptionId =
      json.communityAdditionalDataFieldOptionId;
    this.openAnswer = json.openAnswer;
    this.boolAnswer = json.boolAnswer;
  }
}
