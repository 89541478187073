<h1 mat-dialog-title class="title">{{ "APP.ADDRESS.SELECT" | translate }}</h1>
<div
  mat-dialog-content
  [formGroup]="form"
  class="address-picker-dialog-content"
>
  <div class="address-picker-dialog-fields">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>{{ "APP.LOCATION_NAME" | translate }}</mat-label>
      <input
        type="text"
        matInput
        formControlName="name"
        placeholder=""
        #search
      />
    </mat-form-field>
    <div class="flex-inputs-cont">
      <mat-form-field appearance="outline" class="street-field flex-input">
        <mat-label>{{ "APP.ADDRESS.STREET" | translate }}*</mat-label>
        <input
          type="text"
          matInput
          formControlName="street"
          placeholder=""
          #streetSearch
        />
      </mat-form-field>
      <mat-form-field
        appearance="outline"
        class="street-number-field flex-input"
      >
        <mat-label>{{ "APP.ADDRESS.STREET_NUMBER" | translate }}*</mat-label>
        <input
          type="text"
          matInput
          formControlName="streetNumber"
          placeholder=""
        />
      </mat-form-field>
    </div>
    <div class="flex-inputs-cont">
      <mat-form-field appearance="outline" class="postal-code-field flex-input">
        <mat-label>{{ "APP.ADDRESS.POSTAL_CODE" | translate }}*</mat-label>
        <input
          type="text"
          matInput
          formControlName="postalCode"
          placeholder=""
        />
      </mat-form-field>
      <mat-form-field appearance="outline" class="locality-field flex-input">
        <mat-label>{{ "APP.ADDRESS.LOCALITY" | translate }}*</mat-label>
        <input type="text" matInput formControlName="locality" placeholder="" />
      </mat-form-field>
    </div>
    <div class="flex-inputs-cont">
      <mat-form-field appearance="outline" class="flex-half flex-input">
        <mat-label>{{ "APP.ADDRESS.FEDERAL_STATE" | translate }}</mat-label>
        <input
          type="text"
          matInput
          formControlName="federalState"
          placeholder=""
        />
      </mat-form-field>
      <mat-form-field appearance="outline" class="flex-half flex-input">
        <mat-label>{{ "APP.ADDRESS.COUNTRY" | translate }}</mat-label>
        <input type="text" matInput formControlName="country" placeholder="" />
      </mat-form-field>
    </div>
  </div>

  <div class="google-map-container">
    <google-map
      mapId="address-picker-map"
      [zoom]="mapZoom"
      [center]="mapCenter"
      [options]="mapOptions"
      (mapClick)="mapClick($event)"
    >
      <map-advanced-marker
        [position]="markerPosition"
        [options]="markerOptions"
      ></map-advanced-marker>
    </google-map>
  </div>
  <div class="actions-container">
    <button (click)="cancel()" class="actionButton btn btn-white">
      <span class="actions"> {{ "APP.CANCEL" | translate }} </span>
    </button>
    <button (click)="ok()" type="button" class="actionButton btn btn-primary">
      <span class="actions"> {{ "APP.OK" | translate }} </span>
    </button>
  </div>
</div>
