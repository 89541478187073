import moment, { Moment } from 'moment-timezone';
import { NumberUtils } from './number-utils';
import { TimezoneOption } from '@models/timezone/timezone-option.model';

export class DateUtils {
  static getDateRangeAtTimezone(
    startDate: Date | undefined,
    endDate: Date | undefined,
    startTime: string,
    endTime: string,
    timeZone: string,
  ): { startDate?: Date; endDate?: Date } {
    if (!timeZone || timeZone === '') {
      timeZone = moment.tz.guess();
    }

    let startDateFull = null;
    let endDateFull = null;

    if (startDate) {
      const dateOnlyString = this.dateOnlyToString(startDate);

      startDateFull = moment.tz(`${dateOnlyString} ${startTime}`, timeZone);
    }
    if (!endDate && endTime && endTime !== '') {
      endDate = startDate;
    }
    if (endDate) {
      const dateOnlyString = this.dateOnlyToString(endDate);

      endDateFull = moment.tz(`${dateOnlyString} ${endTime}`, timeZone);
    }

    return {
      startDate: startDateFull?.toDate(),
      endDate: endDateFull?.toDate(),
    };
  }

  static isSameDate(date1: string | Date, date2: Date): boolean {
    const d1 = this.formatToDateOnly(date1);
    const d2 = this.formatToDateOnly(date2);
    return d1 === d2;
  }

  static formatToDateOnly(date: string | Date): string {
    const d = new Date(date);
    return d.toISOString().split('T')[0]; // Extract YYYY-MM-DD
  }

  static combineDateAndTime(
    date: Date | null,
    time: string | null,
  ): Date | null {
    if (!date) return null;

    const dateObj = new Date(date);

    if (time) {
      const [hours, minutes] = time.split(':').map(Number);
      dateObj.setHours(hours);
      dateObj.setMinutes(minutes);
    }
    return dateObj;
  }

  static dateOnlyToString(date: Date): string | null {
    if (!date) {
      return null;
    }

    const monthNumber = date.getMonth() + 1;
    const month = NumberUtils.padStart(monthNumber, 2, '0');

    const dayNumber = date.getDate();
    const day = NumberUtils.padStart(dayNumber, 2, '0');

    return `${date.getFullYear()}-${month}-${day}`;
  }

  static getUserTimezoneOption(): TimezoneOption {
    const timezone = moment.tz.guess();
    const offset = moment.tz(timezone).utcOffset();

    return new TimezoneOption({
      name: timezone,
      offset: offset,
    });
  }

  static getDateAfterBusinessDays(businessDays: number): Moment {
    const currentDate = moment();

    let businessDaysCount = 0;
    while (businessDaysCount < businessDays) {
      currentDate.add(1, 'day');
      if (currentDate.day() !== 0 && currentDate.day() !== 6) {
        businessDaysCount++;
      }
    }

    return currentDate;
  }

  static isAtLeastXBusinessDaysFuture(date: Date, businessDays: number) {
    const currentDate = moment();
    const futureDate = moment(date);

    if (futureDate.isSameOrBefore(currentDate)) {
      return false;
    }

    let businessDaysCount = 0;
    while (businessDaysCount < businessDays) {
      currentDate.add(1, 'day');
      if (currentDate.day() !== 0 && currentDate.day() !== 6) {
        businessDaysCount++;
      }
    }

    return currentDate.isSameOrBefore(futureDate, 'day');
  }

  static formatDateDay(date: Date, currentLang?: string) {
    const day = date.getDay();
    switch (day) {
      case 1:
        return currentLang === 'en' ? 'Monday' : 'Montag';
      case 2:
        return currentLang === 'en' ? 'Tuesday' : 'Dienstag';
      case 3:
        return currentLang === 'en' ? 'Wednesday' : 'Mittwoch';
      case 4:
        return currentLang === 'en' ? 'Thursday' : 'Donnerstag';
      case 5:
        return currentLang === 'en' ? 'Friday' : 'Freitag';
      case 6:
        return currentLang === 'en' ? 'Saturday' : 'Samstag';
      default:
        return currentLang === 'en' ? 'Sunday' : 'Sonntag';
    }
  }

  static formatDateMonth(date: Date, currentLang?: string) {
    if (currentLang) {
      return moment(date).locale(currentLang).format('MMMM');
    }

    return moment(date).format('MMMM');
  }

  static getTimezoneDisplay(timezone: string): string | undefined {
    return `GMT${this.getOffsetDisplay(moment.tz(timezone).utcOffset())}`;
  }

  static getOffsetDisplay(utcOffset: number): string {
    let utcOffsetHours = (utcOffset / 60).toString();

    if (utcOffsetHours[0] !== '-') {
      utcOffsetHours = '+' + utcOffsetHours;
    }

    return utcOffsetHours;
  }

  static addDays(date: Date, days: number): Date {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  static isDateInThePast(dateTime: Date | string): boolean {
    const inputDateTime = new Date(dateTime);
    const now = new Date();

    return inputDateTime < now;
  }

  static isDateInTheFuture(dateTime: Date | string): boolean {
    const inputDateTime = new Date(dateTime);
    const now = new Date();

    return inputDateTime > now;
  }
}
