import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  OnInit,
  PLATFORM_ID,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CommonModule, isPlatformServer } from '@angular/common';
import { RouterLink } from '@angular/router';
import { EventAttendeeStatus } from '@models/event-attendance/event-attendee-status.enum';
import { EventAttendee } from '@models/event-attendance/event-attendee.model';
import { EventRSVPOption } from '@models/event-attendance/event-rsvp-option.model';
import { EventType } from '@models/events/event-type.enum';
import { TranslateModule } from '@ngx-translate/core';
import { AuthService } from '@services/auth/auth.service';
import { EventTemplateVisual } from '@models/design-templates/event-template-visual.model';
import { EventTemplateFont } from '@models/design-templates/event-template-font.model';
import { Event } from '@models/events/event.model';
import { FontService } from '@services/shared/font.service';
import { ScreenWidthService } from '@services/shared/screen-width.service';
import { User } from '@models/users/user.model';
import { EventService } from '@services/events/event.service';
import { EventStore } from '@services/stores/event.store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Helpers } from '@utils/helpers';
import { EventWidget } from '@models/events/event-widget.enum';
import { EventSection } from '@models/events/event-section.model';
import { EventSectionComponent } from '../event-section/event-section.component';
import 'moment/locale/de';
import { MatTooltip, MatTooltipModule } from '@angular/material/tooltip';
import moment from 'moment/moment';
import { EventSectionType } from '@models/events/event-section-type.enum';
import { ScrollToModule, ScrollToService } from '@hungthuanmk/ngx-scroll-to';
import { environment as env } from '@environments/environment';

@Component({
  selector: 'app-sportpage-event',
  standalone: true,
  imports: [
    CommonModule,
    MatTooltipModule,
    TranslateModule,
    EventSectionComponent,
    ScrollToModule,
    RouterLink,
  ],
  providers: [EventService, FontService, ScreenWidthService, ScrollToService],
  templateUrl: './sportpage-event.component.html',
  styleUrl: './sportpage-event.component.scss',
})
export class SportPageEventComponent implements OnInit, OnChanges {
  isServer = false;

  private _event?: Event;
  @Input() isVotingView?: boolean = false;
  invitedAttendeeUuid?: string;
  invitedAttendee?: EventAttendee;

  @Input() loggedUser?: User;
  inviteeEmail?: string;
  inviteeFirstName?: string;
  inviteeLastName?: string;

  @ViewChild('keyVisualVideo', { read: ElementRef, static: false })
  keyVisualVideo?: ElementRef;

  @ViewChild('unMuteTooltip', { static: false })
  unMuteTooltip?: MatTooltip;

  isVideoMuted = true;

  hasVideoAutoplayOccurred = false;

  @ViewChild('effectVideo', { read: ElementRef, static: false })
  effectVideo?: ElementRef;

  aboutHostsSection?: EventSection;

  @ViewChild('aboutHostsSectionVideo', { read: ElementRef, static: false })
  aboutHostsSectionVideo?: ElementRef;

  @Input() set event(event: Event) {
    this._event = event;

    if (this.font) {
      const primaryLogoFont = this.font.primaryLogoFont;
      const bodyFont = this.font.bodyFont;
      const fontFamilies: string[] = [];
      if (primaryLogoFont) {
        fontFamilies.push(primaryLogoFont);
      }
      if (bodyFont) {
        fontFamilies.push(bodyFont);
      }
      fontFamilies.push('Bodoni Moda');

      this.fontService.updateFonts(fontFamilies);

      Helpers.setEventFonts(
        this.font.primaryLogoFont,
        this.font.bodyFont,
        this.font.bodyFontWeight,
      );
    }
  }

  get event(): Event | undefined {
    return this._event;
  }

  @Input() isHostView = false;

  eventActionsSticky = false;
  isDesktop = true;
  isNotAttendingUser?: boolean;
  availableCapacity?: number;
  showMaxCapacity?: boolean;
  isCapacity?: boolean;
  userOnWaitList: boolean = false;

  constructor(
    private eventService: EventService,
    private eventStore: EventStore,
    private fontService: FontService,
    private screenWidthService: ScreenWidthService,
    private authService: AuthService,
    private scrollToService: ScrollToService,
    private cd: ChangeDetectorRef,

    @Inject(PLATFORM_ID) platformId: Object,
  ) {
    this.isServer = isPlatformServer(platformId);

    this.eventStore.event.pipe(takeUntilDestroyed()).subscribe((event) => {
      if (event) {
        this.event = event;
      }
    });
    this.screenWidthService
      .isOver1200()
      .pipe(takeUntilDestroyed())
      .subscribe({
        next: (isDesktop: boolean) => {
          this.isDesktop = isDesktop;
        },
      });
    this.screenWidthService
      .isOver992()
      .pipe(takeUntilDestroyed())
      .subscribe({
        next: (isOver992: boolean) => {
          this.eventActionsSticky = !isOver992;
        },
      });

    this.authService.userSubject
      .pipe(takeUntilDestroyed())
      .subscribe((user) => {
        if (user.id > 0) {
          this.loggedUser = user;
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.font && this.isVotingView) {
      Helpers.setEventTheme(
        this.font.primaryLogoFontColor,
        this.font.bodyFontColor,
        this.visual?.backgroundColor,
        this.font.signalFontColor,
      );
    }
  }

  ngOnInit(): void {
    const foundAttendee = this.event?.attendees?.find(
      (x) => x.userId === this.loggedUser?.id,
    );
    if (
      foundAttendee &&
      foundAttendee.status === EventAttendeeStatus.ON_WAITLIST
    ) {
      this.userOnWaitList = true;
    }

    this.loggedUser && this.checkIfUserAttending();
    this.calculateMaxCapacity();
    if (this.font && this.isVotingView) {
      Helpers.setEventTheme(
        this.font.primaryLogoFontColor,
        this.font.bodyFontColor,
        this.visual?.backgroundColor,
      );
    }

    this.aboutHostsSection = this.event?.eventTemplate
      ?.getEventSectionsOrdered()
      ?.find((x) => x.backgroundImageUrl);
  }

  scrollToTourDates() {
    this.scrollToService.scrollTo({
      target: 'event-list-section',
    });
  }

  scrollToProgram() {
    this.scrollToService.scrollTo({
      target: 'program-tour',
    });
  }
  get userName(): string {
    if (this.invitedAttendee) {
      const name = this.invitedAttendee.getName();
      if (name === '') {
        return this.invitedAttendee.getEmail();
      }
      return this.invitedAttendee.getName();
    } else if (this.inviteeFirstName && this.inviteeFirstName !== '') {
      return this.inviteeFirstName;
    } else if (this.inviteeLastName && this.inviteeLastName !== '') {
      return this.inviteeLastName;
    } else if (this.loggedUser && this.loggedUser.id) {
      return this.loggedUser.getDisplayName();
    } else {
      return 'Guest';
    }
  }

  onUnmuteVideo() {
    if (!this.keyVisualVideo || !this.keyVisualVideo.nativeElement) {
      return;
    }

    this.keyVisualVideo.nativeElement.muted = false;
    this.isVideoMuted = false;
    this.cd.detectChanges();
  }

  onMuteVideo() {
    if (!this.keyVisualVideo || !this.keyVisualVideo.nativeElement) {
      return;
    }

    this.keyVisualVideo.nativeElement.muted = true;
    this.isVideoMuted = true;
    this.cd.detectChanges();
  }

  onUnmuteAboutHostsSectionVideo() {
    if (
      !this.aboutHostsSectionVideo ||
      !this.aboutHostsSectionVideo.nativeElement
    ) {
      return;
    }

    this.aboutHostsSectionVideo.nativeElement.muted = false;
    this.isVideoMuted = false;
    this.cd.detectChanges();
  }

  onMuteAboutHostsSectionVideo() {
    if (
      !this.aboutHostsSectionVideo ||
      !this.aboutHostsSectionVideo.nativeElement
    ) {
      return;
    }

    this.aboutHostsSectionVideo.nativeElement.muted = true;
    this.isVideoMuted = true;
    this.cd.detectChanges();
  }

  get isAttendeeView(): boolean {
    return (
      (!!this.loggedUser &&
        !!this.event?.getUserAttendeeRSVPOption(this.loggedUser.id) &&
        this.event.isNonPendingAttendee(this.loggedUser.id)) ||
      (!!this.invitedAttendee &&
        !!this.event?.getAttendeeRSVPOptionById(this.invitedAttendee.id) &&
        this.event.isNonPendingAttendeeById(this.invitedAttendee.id)) ||
      (!!this.inviteeEmail &&
        this.inviteeEmail !== '' &&
        !!this.event?.getAttendeeRSVPOptionByEmail(this.inviteeEmail) &&
        this.event.isNonPendingAttendeeByEmail(this.inviteeEmail))
    );
  }

  get isPendingAttendeeView(): boolean {
    return (
      (!!this.loggedUser &&
        !!this.event?.getUserAttendeeRSVPOption(this.loggedUser.id) &&
        this.event.isPendingAttendee(this.loggedUser.id)) ||
      (!!this.invitedAttendee &&
        !!this.event?.getAttendeeRSVPOptionById(this.invitedAttendee.id) &&
        this.event.isPendingAttendeeById(this.invitedAttendee.id)) ||
      (!!this.inviteeEmail &&
        this.inviteeEmail !== '' &&
        !!this.event?.getAttendeeRSVPOptionByEmail(this.inviteeEmail) &&
        this.event.isPendingAttendeeByEmail(this.inviteeEmail))
    );
  }

  get attendee(): EventAttendee | undefined {
    if (this.loggedUser && this.loggedUser.id) {
      return this.event?.getAttendee(this.loggedUser.id);
    } else if (this.invitedAttendee) {
      return this.event?.getAttendeeById(this.invitedAttendee.id);
    } else if (this.inviteeEmail && this.inviteeEmail !== '') {
      return this.event?.getAttendeeByEmail(this.inviteeEmail);
    }

    return undefined;
  }

  calculateMaxCapacity() {
    if (!this.event?.maxCapacity || this.event.maxCapacity === 0) {
      this.showMaxCapacity = false;
      this.isCapacity = true; // There is no limit
    } else {
      const guestsGoing = this.event.getNumYesAttendees() || 0;
      const availablePlaces = this.event.maxCapacity - guestsGoing;

      if (availablePlaces > 0) {
        this.availableCapacity = availablePlaces;
        this.showMaxCapacity = true;
      } else {
        this.showMaxCapacity = false;
      }

      this.isCapacity = this.showMaxCapacity || guestsGoing === 0;
    }
  }
  checkIfUserAttending() {
    const loggedInUserId = this.loggedUser?.id;
    const eventAttendees = this.event?.attendees?.map((x) => x.user?.id);
    const eventHosts = this.event?.hosts?.map((x) => x.user?.id);

    const isNotHost = this.event?.hostUserId !== loggedInUserId;
    const isNotCoHost = !eventAttendees?.includes(loggedInUserId);
    const isNotAttendee = !eventHosts?.includes(loggedInUserId);

    this.isNotAttendingUser = isNotHost && isNotCoHost && isNotAttendee;
  }

  onRefreshEvent() {
    if (this.event) {
      const params = new Map<string, string>();
      const userLang = localStorage.getItem('userLang');
      if (userLang) {
        params.set('userLang', userLang);
      }
      this.eventService.getByUri(this.event.uri, params).subscribe({
        next: (event) => {
          this.event = event;
        },
      });
    }
  }

  shareEvent() {
    if (this.event) {
      window.navigator.share({
        text: this.event.description,
        title: this.event.name,
        url: this.event.getLink(),
      });
    }
  }

  getFormattedDate(date: Date): string {
    const toFormat = moment(date);
    return toFormat.format('DD.MM.YYYY');
  }

  get sections(): EventSection[] | undefined {
    return this.event?.eventTemplate?.getEventSectionsOrdered();
  }

  get visual(): EventTemplateVisual | undefined {
    return this.event?.eventTemplate?.visual;
  }

  get font(): EventTemplateFont | undefined {
    return this.event?.eventTemplate?.font;
  }

  get widgets(): EventWidget[] | undefined {
    return this.event?.eventTemplate?.widgets;
  }

  getSectionUrl(section: EventSection) {
    return section.backgroundImageUrl;
  }

  get isUserHost(): boolean {
    return (
      !!this.loggedUser &&
      !!this.loggedUser.id &&
      !!this.event &&
      (this.event.isUserHost(this.loggedUser.id) ||
        this.event.isNotLoggedUserHost())
    );
  }

  get userAttendeeRSVPOption(): EventRSVPOption | undefined {
    return this.loggedUser?.id
      ? this.event?.getUserAttendeeRSVPOption(this.loggedUser.id)
      : undefined;
  }
  getEventSectionByType(type: EventSectionType) {
    return this.event?.eventTemplate?.getEventSectionByType(type);
  }

  get isUaEvent(): boolean {
    return !!this.event?.uri.includes('underarmour-infinite-elite-testival');
  }

  protected readonly EventWidget = EventWidget;
  protected readonly EventSectionType = EventSectionType;
  protected readonly EventSection = EventSection;
  protected readonly EventType = EventType;
  protected readonly env = env;
}
