import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup } from '@angular/forms';
import { FormsModules } from '@utils/shared-modules';
import { TranslateModule } from '@ngx-translate/core';
import { NgxColorsModule } from 'ngx-colors';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-color-picker',
  standalone: true,
  imports: [CommonModule, FormsModules, NgxColorsModule, TranslateModule],
  templateUrl: './color-picker.component.html',
  styleUrl: './color-picker.component.scss',
})
export class ColorPickerComponent implements OnInit, OnDestroy {
  @Input() form!: FormGroup;
  @Input() colorFormControlName!: string;
  @Input() fieldClass?: string;
  @Input() label = 'APP.COLOR';

  chosenColor: string = '';

  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();

  ngOnInit(): void {
    const formControl = this.form.controls[this.colorFormControlName];
    this.chosenColor = formControl.value;

    formControl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe((color) => (this.chosenColor = color));
  }

  get colorFormControl() {
    return this.form.controls[this.colorFormControlName];
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
