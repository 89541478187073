import { ClassData } from '@models/class-data';
import { EventTicket } from '@models/events/event-ticket.model';

export class TicketLayout {
  id: number;
  createdAt?: Date;
  updatedAt?: Date;

  creatorId: number;

  name: string;

  ticketPdfIntro: string;
  ticketPdfIntroImageUrl: string;

  ticketPdfBackgroundColor: string;
  ticketPdfBodyColor: string;
  ticketPdfBorderColor: string;

  walletPassThumbnailUrl: string;
  walletPassLogoUrl: string;

  walletPassBackgroundColor: string;
  walletPassLabelColor: string;
  walletPassInfosColor: string;

  tickets?: EventTicket[];

  isDefault: boolean;
  hasDefaultColors: boolean;

  constructor(json: ClassData<TicketLayout>) {
    this.id = +json.id;
    if (json.createdAt) {
      this.createdAt = new Date(json.createdAt);
    }
    if (json.updatedAt) {
      this.updatedAt = new Date(json.updatedAt);
    }

    this.creatorId = +json.creatorId;

    this.name = json.name;

    this.ticketPdfIntro = json.ticketPdfIntro;
    this.ticketPdfIntroImageUrl = json.ticketPdfIntroImageUrl;

    this.ticketPdfBackgroundColor = json.ticketPdfBackgroundColor;
    this.ticketPdfBodyColor = json.ticketPdfBodyColor;
    this.ticketPdfBorderColor = json.ticketPdfBorderColor;

    this.walletPassThumbnailUrl = json.walletPassThumbnailUrl;
    this.walletPassLogoUrl = json.walletPassLogoUrl;

    this.walletPassBackgroundColor = json.walletPassBackgroundColor;
    this.walletPassLabelColor = json.walletPassLabelColor;
    this.walletPassInfosColor = json.walletPassInfosColor;

    if (json.tickets) {
      this.tickets = json.tickets.map((it) => new EventTicket(it));
    }

    this.isDefault = json.isDefault;
    this.hasDefaultColors = json.hasDefaultColors;
  }

  isActive() {
    return this.tickets && this.tickets.length > 0;
  }

  numTicketsInUse() {
    if (!this.tickets) {
      return 0;
    }

    return this.tickets.length;
  }

  hasUpdatedAt(): boolean {
    return !!this.updatedAt && this.updatedAt > new Date(1, 0, 1);
  }
}
