import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCalendarCellCssClasses } from '@angular/material/datepicker';
import { Campaign } from '@models/campaigns/campaign.model';
import { CampaignDateInvalidDialog } from '@modules/events/dialogs/campaign-date-invalid/campaign-date-invalid.dialog';
import { DateTimeRangePickerComponent } from '@modules/shared/components/date-time-range-picker/date-time-range-picker.component';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
} from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DateUtils } from '@utils/date-utils';
import { Event } from '@models/events/event.model';
import { DateRange } from '@models/shared/date-range.model';
import moment from 'moment';
import { EventCampaignCategory } from '@models/campaigns/event-campaign-category.enum';
import { Subject, takeUntil } from 'rxjs';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    DateTimeRangePickerComponent,
    MatDialogContent,
    MatDialogActions,
  ],
  templateUrl: './date-time-range-picker.dialog.html',
  styleUrl: './date-time-range-picker.dialog.scss',
})
export class DateTimeRangePickerDialog implements OnInit {
  form: FormGroup;
  event?: Event;
  campaign?: Campaign;
  campaignCategory?: EventCampaignCategory;
  eventDate = '';
  confirmNoPackage?: boolean = false;

  startAt: Date | null = null;

  shippingOffStartDate = moment('2024-12-20', 'YYYY-MM-DD');
  shippingOffEndDate = moment('2025-01-04', 'YYYY-MM-DD');

  wgFimoChallengeStartDate = moment('2025-01-13', 'YYYY-MM-DD');

  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<DateTimeRangePickerDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private dialog: MatDialog,
  ) {
    if (data) {
      if (data.event) {
        this.event = data.event;
        this.campaign = data.event.eventCampaign?.campaign;
        this.campaignCategory = data.event.eventCampaign?.category;
        this.confirmNoPackage = data.event.eventCampaign?.confirmNoPackages
          ? data.event.eventCampaign?.confirmNoPackages
          : false;
      }

      if (data.campaign) {
        this.campaign = data.campaign;
      }
      this.campaignCategory = data.campaignCategory;
    }

    if (this.isWgFimoChallenge) {
      this.startAt = moment().isAfter(this.wgFimoChallengeStartDate)
        ? null
        : this.wgFimoChallengeStartDate.toDate();
    }

    this.form = this.fb.group({
      startDate: [this.event?.getStartDate() || null],
      endDate: [this.event?.getEndDate() || null],
      startTime: [this.event?.getStartTime() || ''],
      endTime: [this?.event?.getEndTime() || ''],
      timeZone: [
        this.event?.getTimezoneOption() || DateUtils.getUserTimezoneOption(),
      ],
    });
  }

  ngOnInit(): void {
    this.subscribeToChange();
  }

  emptyForm() {
    if (this.event) {
      this.form = this.fb.group({
        startDate: [null],
        endDate: [null],
        startTime: [''],
        endTime: [''],
        timeZone: [
          this.event?.getTimezoneOption() || DateUtils.getUserTimezoneOption(),
        ],
      });
    }
  }

  close() {
    this.dialogRef.close();
  }

  clear() {
    this.emptyForm();
  }

  subscribeToChange() {
    if (this.isWgCampaignSose23) {
      this.form!.controls['startDate'].valueChanges.pipe(
        takeUntil(this._onDestroy),
      ).subscribe((value) => {
        if (!value) {
          return;
        }

        this.eventDate = moment(value).format('DD.MM.YYYY');

        if (moment(value).isAfter(moment('2024-07-30', 'YYYY-MM-DD'))) {
          const dialogRef = this.dialog.open(CampaignDateInvalidDialog, {
            maxWidth: '90vw',
            width: '456px',
            height: 'auto',
            maxHeight: '95vh',
            data: {
              isTooEarly: false,
              date: moment('2024-07-30', 'YYYY-MM-DD').toDate(),
            },
          });

          dialogRef
            .afterClosed()
            .subscribe(
              (confirmed) =>
                (this.confirmNoPackage = confirmed !== undefined && confirmed),
            );
        } else if (!DateUtils.isAtLeastXBusinessDaysFuture(value, 10)) {
          const dialogRef = this.dialog.open(CampaignDateInvalidDialog, {
            maxWidth: '90vw',
            width: '456px',
            height: 'auto',
            maxHeight: '95vh',
            data: {
              isTooEarly: true,
              date: DateUtils.getDateAfterBusinessDays(10).toDate(),
            },
          });

          dialogRef
            .afterClosed()
            .subscribe(
              (confirmed) =>
                (this.confirmNoPackage = confirmed !== undefined && confirmed),
            );
        }
      });
    } else if (this.isWgCampaign) {
      if (this.isWgFimoChallenge) {
        this.form!.controls['startDate'].valueChanges.pipe(
          takeUntil(this._onDestroy),
        ).subscribe((value) => {
          if (!value) {
            return;
          }

          this.eventDate = moment(value).format('DD.MM.YYYY');

          let invalidDate = undefined;
          let isTooEarly = undefined;

          if (moment(value).isAfter(moment('2025-02-28', 'YYYY-MM-DD'))) {
            isTooEarly = false;
            invalidDate = moment('2025-02-28', 'YYYY-MM-DD').toDate();
          } else if (moment(value).isBefore(this.wgFimoChallengeStartDate)) {
            isTooEarly = true;
            invalidDate = this.wgFimoChallengeStartDate.toDate();
          }

          if (invalidDate !== undefined && isTooEarly !== undefined) {
            const dialogRef = this.dialog.open(CampaignDateInvalidDialog, {
              maxWidth: '90vw',
              width: '456px',
              height: 'auto',
              maxHeight: '95vh',
              data: {
                isTooEarly: isTooEarly,
                date: invalidDate,
                isWgPartyChallenge: true,
                isWgFimoChallenge: true,
              },
            });

            dialogRef
              .afterClosed()
              .subscribe(
                (confirmed) =>
                  (this.confirmNoPackage =
                    confirmed !== undefined && confirmed),
              );
          }
        });
      } else {
        this.form!.controls['startDate'].valueChanges.pipe(
          takeUntil(this._onDestroy),
        ).subscribe((value) => {
          if (!value) {
            return;
          }

          this.eventDate = moment(value).format('DD.MM.YYYY');

          let invalidDate = undefined;
          let isTooEarly = undefined;

          if (moment(value).isAfter(moment('2025-02-28', 'YYYY-MM-DD'))) {
            isTooEarly = false;
            invalidDate = moment('2025-02-28', 'YYYY-MM-DD').toDate();
          } else if (!DateUtils.isAtLeastXBusinessDaysFuture(value, 10)) {
            isTooEarly = true;
            invalidDate = DateUtils.getDateAfterBusinessDays(10).toDate();
          } else if (
            moment(value).isBefore(moment('2024-10-15', 'YYYY-MM-DD'))
          ) {
            isTooEarly = true;
            invalidDate = moment('2024-10-15', 'YYYY-MM-DD').toDate();
          }

          let isShippingOff = undefined;

          if (
            isTooEarly === undefined &&
            moment(value).isBetween(
              this.shippingOffStartDate,
              this.shippingOffEndDate,
            )
          ) {
            isShippingOff = true;
            invalidDate = this.shippingOffStartDate.toDate();
          }

          if (
            invalidDate !== undefined &&
            (isTooEarly !== undefined || isShippingOff !== undefined)
          ) {
            const dialogRef = this.dialog.open(CampaignDateInvalidDialog, {
              maxWidth: '90vw',
              width: '456px',
              height: 'auto',
              maxHeight: '95vh',
              data: {
                isTooEarly: isTooEarly,
                date: invalidDate,
                isWgPartyChallenge: true,
                isShippingOff: isShippingOff,
              },
            });

            dialogRef
              .afterClosed()
              .subscribe(
                (confirmed) =>
                  (this.confirmNoPackage =
                    confirmed !== undefined && confirmed),
              );
          }
        });
      }
    } else if (this.isBitsCampaign) {
      this.form!.controls['startDate'].valueChanges.pipe(
        takeUntil(this._onDestroy),
      ).subscribe((value) => {
        if (!value) {
          return;
        }

        this.eventDate = moment(value).format('DD.MM.YYYY');

        let invalidDate = undefined;
        let isTooEarly = undefined;

        if (moment(value).isAfter(moment('2024-12-23', 'YYYY-MM-DD'))) {
          isTooEarly = false;
          invalidDate = moment('2024-12-23', 'YYYY-MM-DD').toDate();
        } else if (moment(value).isBefore(moment('2024-10-31', 'YYYY-MM-DD'))) {
          isTooEarly = true;
          invalidDate = moment('2024-10-31', 'YYYY-MM-DD').toDate();
        }

        if (invalidDate !== undefined && isTooEarly !== undefined) {
          const dialogRef = this.dialog.open(CampaignDateInvalidDialog, {
            maxWidth: '90vw',
            width: '456px',
            height: 'auto',
            maxHeight: '95vh',
            data: {
              isTooEarly: isTooEarly,
              date: invalidDate,
              isBitsCampaign: true,
            },
          });

          dialogRef
            .afterClosed()
            .subscribe(
              (confirmed) =>
                (this.confirmNoPackage = confirmed !== undefined && confirmed),
            );
        }
      });
    } else if (this.campaign?.hasDatesSet()) {
      this.form!.controls['startDate'].valueChanges.pipe(
        takeUntil(this._onDestroy),
      ).subscribe((value) => {
        if (!value) {
          return;
        }

        this.eventDate = moment(value).format('DD.MM.YYYY');

        let invalidDate = undefined;
        let isTooEarly = undefined;

        if (
          this.campaign?.endDate &&
          moment(value).isAfter(moment(this.campaign.endDate))
        ) {
          isTooEarly = false;
          invalidDate = this.campaign.endDate;
        } else if (
          this.campaign?.daysInFuture &&
          !DateUtils.isAtLeastXBusinessDaysFuture(
            value,
            this.campaign.daysInFuture,
          )
        ) {
          isTooEarly = true;
          invalidDate = DateUtils.getDateAfterBusinessDays(
            this.campaign.daysInFuture,
          ).toDate();

          if (
            this.campaign?.startDate &&
            invalidDate < this.campaign.startDate
          ) {
            invalidDate = this.campaign.startDate;
          }
        } else if (
          this.campaign?.startDate &&
          moment(value).isBefore(moment(this.campaign.startDate))
        ) {
          isTooEarly = true;
          invalidDate = this.campaign.startDate;
        }

        let isShippingOff = undefined;

        if (
          this.campaign?.uri === 'wg-party-challenge-halloween-edition' &&
          isTooEarly === undefined &&
          moment(value).isBetween(
            this.shippingOffStartDate,
            this.shippingOffEndDate,
          )
        ) {
          isShippingOff = true;
          invalidDate = this.shippingOffStartDate.toDate();
        }

        if (
          invalidDate !== undefined &&
          (isTooEarly !== undefined || isShippingOff !== undefined)
        ) {
          const dialogRef = this.dialog.open(CampaignDateInvalidDialog, {
            maxWidth: '90vw',
            width: '456px',
            height: 'auto',
            maxHeight: '95vh',
            data: {
              isTooEarly: isTooEarly,
              date: invalidDate,
              campaign: this.campaign,
              isShippingOff: isShippingOff,
            },
          });

          dialogRef
            .afterClosed()
            .subscribe(
              (confirmed) =>
                (this.confirmNoPackage = confirmed !== undefined && confirmed),
            );
        }
      });
    }
  }

  get isWgCampaignSose23() {
    return this.campaign?.uri === 'wg-party-challenge-sose23';
  }

  get isWgCampaign() {
    return this.campaign?.uri === 'wg-party-challenge';
  }

  get isBitsCampaign() {
    return this.campaign?.uri === 'bits24';
  }

  get isWgFimoChallenge() {
    return this.campaignCategory === EventCampaignCategory.FIMO_CREATIVE_PARTY;
  }

  saveDate() {
    if (this.form) {
      const value = this.form.value;

      const timeZoneObj = value.timeZone;
      let timeZone = '';
      if (timeZoneObj) {
        timeZone = timeZoneObj.name;
      }

      const dateRange = DateUtils.getDateRangeAtTimezone(
        value.startDate,
        value.endDate,
        value.startTime,
        value.endTime,
        timeZone,
      );

      const res: DateRange = {
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
        timeZone: timeZone,
      };

      this.dialogRef.close({
        dateRange: res,
        confirmNoPackages: this.confirmNoPackage,
      });
    }
  }

  dateClassDefault() {
    return (date: Date): MatCalendarCellCssClasses => {
      return '';
    };
  }

  dateClass() {
    return (date: Date): MatCalendarCellCssClasses => {
      if (
        moment(date).isSameOrAfter(moment(), 'day') &&
        (moment(date).isAfter(moment('2024-07-30', 'YYYY-MM-DD')) ||
          !DateUtils.isAtLeastXBusinessDaysFuture(date, 10))
      ) {
        return 'invalid-campaign-date';
      } else {
        return '';
      }
    };
  }

  dateClassWgCampaign(
    shippingOffStartDate: moment.Moment,
    shippingOffEndDate: moment.Moment,
  ) {
    return (date: Date): MatCalendarCellCssClasses => {
      if (
        moment(date).isAfter(moment('2025-02-28', 'YYYY-MM-DD')) ||
        moment(date).isBefore(moment('2024-10-15', 'YYYY-MM-DD')) ||
        !DateUtils.isAtLeastXBusinessDaysFuture(date, 10) ||
        moment(date).isBetween(shippingOffStartDate, shippingOffEndDate)
      ) {
        return 'invalid-campaign-date';
      } else {
        return '';
      }
    };
  }

  dateClassWgFimoCampaign() {
    return (date: Date): MatCalendarCellCssClasses => {
      if (
        moment(date).isAfter(moment('2025-02-28', 'YYYY-MM-DD')) ||
        moment(date).isBefore(this.wgFimoChallengeStartDate)
      ) {
        return 'invalid-campaign-date';
      } else {
        return '';
      }
    };
  }

  dateClassBitsCampaign() {
    return (date: Date): MatCalendarCellCssClasses => {
      if (
        moment(date).isAfter(moment('2024-12-23', 'YYYY-MM-DD')) ||
        moment(date).isBefore(moment('2024-10-31', 'YYYY-MM-DD'))
      ) {
        return 'invalid-campaign-date';
      } else {
        return '';
      }
    };
  }

  dateClassCampaign(
    campaign: Campaign,
    shippingOffStartDate: moment.Moment,
    shippingOffEndDate: moment.Moment,
  ) {
    return (date: Date): MatCalendarCellCssClasses => {
      if (
        (campaign.endDate && moment(date).isAfter(moment(campaign.endDate))) ||
        (campaign.startDate &&
          moment(date).isBefore(moment(campaign.startDate))) ||
        (campaign.daysInFuture &&
          !DateUtils.isAtLeastXBusinessDaysFuture(
            date,
            campaign.daysInFuture,
          )) ||
        (campaign.uri === 'wg-party-challenge-halloween-edition' &&
          moment(date).isBetween(shippingOffStartDate, shippingOffEndDate))
      ) {
        return 'invalid-campaign-date';
      } else {
        return '';
      }
    };
  }

  getDateValidationClass(): () => (date: Date) => MatCalendarCellCssClasses {
    if (this.campaign) {
      if (this.isWgCampaignSose23) {
        return this.dateClass;
      } else if (this.isWgCampaign) {
        if (this.isWgFimoChallenge) {
          return () => this.dateClassWgFimoCampaign();
        } else {
          return () =>
            this.dateClassWgCampaign(
              this.shippingOffStartDate,
              this.shippingOffEndDate,
            );
        }
      } else if (this.isBitsCampaign) {
        return this.dateClassBitsCampaign;
      } else if (this.campaign.hasDatesSet()) {
        return () =>
          this.dateClassCampaign(
            this.campaign!,
            this.shippingOffStartDate,
            this.shippingOffEndDate,
          );
      }
    }

    return this.dateClassDefault;
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
