@if (event) {
  <div class="comments-cont">
    @if (isUserHostOrNonPendingAttendee && event.isPublished()) {
      <div class="add-comment-cont">
        <app-user-avatar
          [user]="loggedUser"
          [size]="50"
          [placeholderFontSize]="18"
        ></app-user-avatar>
        <div class="comment-message-cont add-comment-message-cont body-font">
          <div class="message-content">
            <div
              text-editable
              class="add-message-input"
              [(ngModel)]="newComment"
              [attr.placeholder]="'APP.COMMENTS.PLACEHOLDER' | translate"
            ></div>
            @if (commentImageAsset) {
              <img
                [src]="createDataUrl(commentImageAsset)"
                class="comment-image"
              />
            } @else if (commentImageUrl) {
              <img [src]="commentImageUrl" class="comment-image" />
            }
          </div>

          <div
            class="comment-actions"
            [class.has-image]="!!commentImageAsset || commentImageUrl"
          >
            <i class="fa fa-camera upload-icon" (click)="openImagePicker()"></i>
            <i class="fa fa-paper-plane send-icon" (click)="addComment()"></i>
          </div>
        </div>
      </div>
    }

    @if (event.isPreviewEvent) {
      <div class="no-comments-wrapper">
        <div class="icon">💬</div>
        <div class="title">
          {{ "APP.COMMENTS.NO_COMMENTS_YET" | translate }}
        </div>
        <div class="description">
          {{ "APP.COMMENTS.ACTIVATE_TEMPLATE" | translate }}
        </div>
        <button class="btn btn-primary" (click)="onUseTemplate()">
          {{ "APP.COMMENTS.USE_TEMPLATE" | translate }}
        </button>
      </div>
    } @else if (event.isPublished() && !isUserHostOrAttendee) {
      @if (event.canRsvp()) {
        <div class="no-comments-wrapper">
          <div class="icon">🔒</div>
          <div class="title">
            {{ "APP.COMMENTS.RESTRICTED_ACCESS" | translate }}
          </div>
          <div class="description">
            {{ "APP.COMMENTS.RSVP_TO_COMMENT" | translate }}
          </div>
          <button class="btn btn-primary" (click)="onRsvp()">
            {{ "APP.COMMENTS.RSVP" | translate }}
          </button>
        </div>
      } @else if (loggedUser) {
        <div class="no-comments-wrapper">
          <div class="icon">🔒</div>
          <div class="title">
            {{ "APP.COMMENTS.RESTRICTED_ACCESS" | translate }}
          </div>
          <div class="description">
            {{ "APP.COMMENTS.RSVP_DISABLED" | translate }}
          </div>
        </div>
      } @else {
        <div class="no-comments-wrapper">
          <div class="icon">🔒</div>
          <div class="title">
            {{ "APP.COMMENTS.RESTRICTED_ACCESS" | translate }}
          </div>
          <div class="description">
            {{ "APP.COMMENTS.SIGN_IN_TO_VIEW" | translate }}
          </div>
          <button class="btn btn-primary" (click)="signIn()">
            {{ "APP.COMMENTS.SIGN_IN" | translate }}
          </button>
        </div>
      }
    } @else if (init && comments.length === 0) {
      @if (isUserHost && !event.isPublished()) {
        <div class="no-comments-wrapper">
          <div class="icon">💬</div>
          <div class="title">
            {{ "APP.COMMENTS.NO_COMMENTS_YET" | translate }}
          </div>
          <div class="description">
            {{ "APP.COMMENTS.PUBLISH_EVENT_TO_COLLECT_COMMENTS" | translate }}
          </div>
          <button class="btn btn-primary" (click)="onPublishEvent()">
            {{ "APP.COMMENTS.PUBLISH_EVENT" | translate }}
          </button>
        </div>
      } @else if (event.isPublished() && isUserHostOrNonPendingAttendee) {
        <div class="no-comments-wrapper">
          <div class="icon">💬</div>
          <div class="title">
            {{ "APP.COMMENTS.NO_COMMENTS_YET" | translate }}
          </div>
          <div class="description">
            {{ "APP.COMMENTS.BE_THE_FIRST_COMMENT" | translate }}
          </div>
        </div>
      }
    } @else {
      @for (comment of comments; track comment.id) {
        <app-event-comment
          [loggedUser]="loggedUser"
          [event]="event"
          [comment]="comment"
          (addCommentReaction)="
            addCommentReaction($event.emoji, $event.commentId)
          "
          (removeComment)="removeComment($event)"
        ></app-event-comment>
      }
    }
  </div>
}
