import { CommonModule } from '@angular/common';
import {
  Component,
  computed,
  CUSTOM_ELEMENTS_SCHEMA,
  EventEmitter,
  Input,
  Output,
  Signal,
} from '@angular/core';
import { EventTemplateVisual } from '@models/design-templates/event-template-visual.model';
import { EventAttendeeTicketOrder } from '@models/events/event-attendee-ticket-order.model';
import { Event } from '@models/events/event.model';
import { OrderOverviewComponent } from '@modules/events/components/event-payment/components/order-overview/order-overview.component';
import { DateCardComponent } from '@modules/shared/components/date-card/date-card.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { EventService } from '@services/events/event.service';
import { NotificationService } from '@services/shared/notification.service';
import { ScreenWidthService } from '@services/shared/screen-width.service';
import { FooterComponent } from '../../../../components/footer/footer.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-ticket-payment-success',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    OrderOverviewComponent,
    FooterComponent,
    DateCardComponent,
  ],
  providers: [EventService, NotificationService],
  templateUrl: './ticket-payment-success.component.html',
  styleUrl: './ticket-payment-success.component.scss',
  schemas: [],
})
export class TicketPaymentSuccessComponent {
  private _ticketOrder?: EventAttendeeTicketOrder;
  isDesktop?: boolean;
  @Input() disableClose: boolean = false;
  @Input() isGeneric?: boolean = false;

  @Input() set ticketOrder(ticketOrder: EventAttendeeTicketOrder | undefined) {
    if (ticketOrder) {
      this._ticketOrder = ticketOrder;
    }
  }

  get ticketOrder(): EventAttendeeTicketOrder | undefined {
    return this._ticketOrder;
  }

  @Input() mainImage?: string;
  @Input() event?: Event;
  @Output() onClose = new EventEmitter();
  currentLang?: string;

  encodedMainImage: Signal<string> = computed(() => {
    return this.event ? this.event.getEncodedMainImage() : '';
  });

  constructor(
    private eventService: EventService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private screenWidthService: ScreenWidthService,
  ) {
    this.screenWidthService
      .isDesktop()
      .pipe(takeUntilDestroyed())
      .subscribe({
        next: (isDesktop: boolean) => {
          this.isDesktop = isDesktop;
        },
      });

    this.translateService.onLangChange
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        this.currentLang = this.translateService.currentLang;
      });
    this.currentLang = this.translateService.currentLang;
  }

  downloadTicketPdf() {
    if (!this.ticketOrder) {
      return;
    }

    let fileName = `${this.ticketOrder.getDateDisplayFileName()}_${
      this.ticketOrder.orderNumber
    }.pdf`;
    if (this.event) {
      fileName = `${this.ticketOrder.getDateDisplayFileName()}_${this.event.getSanitizedName()}_${
        this.ticketOrder.orderNumber
      }.pdf`;
    }

    this.eventService
      .downloadTicketOrderPdf(this.ticketOrder.uuid, fileName)
      .subscribe({
        error: () => {
          this.notificationService.error(
            this.translateService.instant(
              'APP.EVENT_PAYMENT.COULD_NOT_DOWNLOAD',
            ),
          );
        },
      });
  }

  addToWallet() {
    if (!this.ticketOrder || this.ticketOrder.isProcessing()) {
      return;
    }

    this.eventService
      .getEventTicketOrderWalletPassBundle(this.ticketOrder.uuid)
      .subscribe({
        next: (passPageLink: string) => {
          window.open(passPageLink, '_blank');
        },
        error: () => {
          this.notificationService.error(
            this.translateService.instant(
              'APP.EVENT_PAYMENT.COULD_NOT_DOWNLOAD',
            ),
          );
        },
      });
  }

  get visual(): EventTemplateVisual | undefined {
    return this.event?.eventTemplate?.visual;
  }

  close() {
    this.onClose.emit();
  }
}
