import { EventCampaignCategory } from '@models/campaigns/event-campaign-category.enum';

export const categoryData = [
  {
    type: EventCampaignCategory.MOST_GUESTS,
    title: 'APP.CAMPAIGN.CATEGORY.MOST_GUESTS.TITLE',
    description: 'APP.CAMPAIGN.CATEGORY.MOST_GUESTS.DESCRIPTION',
  },
  {
    type: EventCampaignCategory.MOST_CREATIVE_CONCEPT,
    title: 'APP.CAMPAIGN.CATEGORY.MOST_CREATIVE_CONCEPT.TITLE',
    description: 'APP.CAMPAIGN.CATEGORY.MOST_CREATIVE_CONCEPT.DESCRIPTION',
  },
  {
    type: EventCampaignCategory.MOST_CONVINCING_APPLICATION,
    title: 'APP.CAMPAIGN.CATEGORY.MOST_CONVINCING_APPLICATION.TITLE',
    description:
      'APP.CAMPAIGN.CATEGORY.MOST_CONVINCING_APPLICATION.DESCRIPTION',
  },
  {
    type: EventCampaignCategory.FIMO_CREATIVE_PARTY,
    image: '',
    title: 'APP.CAMPAIGN.CATEGORY.WG.FIMO_CREATIVE_PARTY.TITLE',
    description: 'APP.CAMPAIGN.CATEGORY.WG.FIMO_CREATIVE_PARTY.DESCRIPTION',
    isChallenge: true,
  },
  {
    type: EventCampaignCategory.NORMAL_WG_PARTY,
    image: '',
    title: 'APP.CAMPAIGN.CATEGORY.WG.NORMAL_WG_PARTY.TITLE',
    description: 'APP.CAMPAIGN.CATEGORY.WG.NORMAL_WG_PARTY.DESCRIPTION',
    isChallenge: true,
  },
  // {
  //   type: EventCampaignCategory.GAMING_STYLE_PARTY,
  //   image: '',
  //   title: 'APP.CAMPAIGN.CATEGORY.WG.GAMING_STYLE_PARTY.TITLE',
  //   description: 'APP.CAMPAIGN.CATEGORY.WG.GAMING_STYLE_PARTY.DESCRIPTION',
  // },
  {
    type: EventCampaignCategory.ONLY_PARTY_PACKAGE_PLS,
    title: 'APP.CAMPAIGN.CATEGORY.WG.ONLY_PARTY_PACKAGE_PLS.TITLE',
    description: 'APP.CAMPAIGN.CATEGORY.WG.ONLY_PARTY_PACKAGE_PLS.DESCRIPTION',
    isChallenge: false,
  },
  {
    type: EventCampaignCategory.HALLOWEEN_WG_PARTY,
    image: '',
    title: 'APP.CAMPAIGN.CATEGORY.WG.HALLOWEEN_WG_PARTY.TITLE',
    description: 'APP.CAMPAIGN.CATEGORY.WG.HALLOWEEN_WG_PARTY.DESCRIPTION',
    isChallenge: true,
  },
];
