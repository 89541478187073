import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  Inject,
  ViewChild,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogRef,
} from '@angular/material/dialog';
import { EventAttendeeTicketOrderStatus } from '@models/events/event-attendee-ticket-order-status.enum';
import { EventAttendeeTicketOrder } from '@models/events/event-attendee-ticket-order.model';
import { EventAttendeeTicket } from '@models/events/event-attendee-ticket.model';
import { EventAttendeeTicketOrderRefundStatus } from '@models/events/event-attendee.ticket-order-refund-status.enum';
import { Event } from '@models/events/event.model';
import { User } from '@models/users/user.model';
import { TicketActionComponent } from '@modules/events/dialogs/get-bought-tickets/components/ticket-action/ticket-action.component';
import { QrDataCardComponent } from '@modules/shared/components/qr-data-card/qr-data-card.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { EventService } from '@services/events/event.service';
import { NotificationService } from '@services/shared/notification.service';
import { TicketAction } from '@models/tickets/ticket-action.enum';
import { environment as env } from '@environments/environment';

@Component({
  selector: 'app-requested-refund-overview.dialog',
  standalone: true,
  imports: [
    TranslateModule,
    MatDialogActions,
    TicketActionComponent,
    QrDataCardComponent,
  ],
  templateUrl: './requested-refund-overview.dialog.html',
  styleUrl: './requested-refund-overview.dialog.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [EventService, NotificationService, TranslateService],
})
export class RequestedRefundOverviewDialog {
  tickets: EventAttendeeTicket[] = [];
  loggedUser?: User;
  event?: Event;
  order?: EventAttendeeTicketOrder;
  @ViewChild('swiperContainer') swiperContainer!: any;
  @ViewChild('swiperRef', { static: false }) swiperRef: any;
  @ViewChild('swiperElement') swiperElement: any;
  constructor(
    public dialogRef: MatDialogRef<RequestedRefundOverviewDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private eventService: EventService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
  ) {
    if (data) {
      this.event = data.event;
      this.order = data.order;
      if (data.order) {
        data.order.eventAttendeeTickets.forEach(
          (ticket: EventAttendeeTicket) => {
            this.tickets.push(ticket);
          },
        );
      }
      this.loggedUser = data.loggedUser;
    }
  }

  getHeaderText() {
    return `${this.translateService.instant(
      'APP.REQUEST_REFUND.ORDER_NUMBER',
    )}: ${this.order?.orderNumber} (${this.order?.eventAttendeeTickets
      .length}) ${this.translateService.instant('APP.REQUEST_REFUND.TICKETS')}`;
  }

  contactHost() {
    let email = this.event?.hostUser?.email;
    // TODO: Per Anhalter email hardcoded, use support email
    if (!email || email === '') {
      email = 'peranhalter@eventpage.ai';
    }
    if (email) {
      const mailtoLink = `mailto:${email}`;
      window.location.href = mailtoLink;
    }
  }

  executeAction(action: TicketAction) {
    switch (action) {
      case TicketAction.CANCEL_REFUND_REQUEST:
        this.cancelRefund();
        break;
      case TicketAction.DOWNLOAD:
        this.downloadRefundInvoice();
        break;
    }
  }

  downloadRefundInvoice() {
    if (this.order?.refund) {
      this.eventService
        .downloadTicketOrderRefundPdf(this.order.refund.id)
        .subscribe({
          error: () => {
            this.notificationService.error(
              this.translateService.instant(
                'APP.EVENT_PAYMENT.COULD_NOT_DOWNLOAD',
              ),
            );
          },
        });
    }
  }

  cancelRefund() {
    if (this.order?.refund) {
      this.eventService
        .cancelTicketOrderRefund(this.order?.refund?.id)
        .subscribe({
          next: (res) => {
            this.notificationService.success(
              this.translateService.instant(
                'APP.REFUND_REASONS.SUCCESSFULLY_CANCELLED_REFUND',
              ),
            );
            this.close();
          },
        });
    }
  }

  close() {
    this.dialogRef.close();
  }

  protected env = env;
  protected readonly TicketAction = TicketAction;
  protected readonly EventAttendeeTicketOrderStatus =
    EventAttendeeTicketOrderStatus;
  protected readonly EventAttendeeTicketOrderRefundStatus =
    EventAttendeeTicketOrderRefundStatus;
}
