import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { CommunityMember } from '@models/communities/community-member.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { Helpers } from '@utils/helpers';

export class CommunityMemberFeDataSource
  implements DataSource<CommunityMember>
{
  private membersSubject = new BehaviorSubject<CommunityMember[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private numRecordsSubject = new BehaviorSubject<number>(0);

  numRecords$ = this.numRecordsSubject.asObservable();
  loading$ = this.loadingSubject.asObservable();

  data!: CommunityMember[];

  constructor() {}
  connect(_: CollectionViewer): Observable<CommunityMember[]> {
    return this.membersSubject.asObservable();
  }

  disconnect(_: CollectionViewer): void {
    this.membersSubject.complete();
    this.numRecordsSubject.complete();
    this.loadingSubject.complete();
  }

  filterMembers(
    communityMembers: CommunityMember[],
    filters: Map<string, any>,
    pageNumber = 1,
    pageSize = 10,
  ) {
    this.loadingSubject.next(true);

    this.membersSubject.next(
      Helpers.paginateArray(communityMembers, pageNumber, pageSize),
    );
    this.numRecordsSubject.next(communityMembers.length);

    this.data = communityMembers;

    this.loadingSubject.next(false);
  }
}
