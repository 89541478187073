@if (event) {
  <div>
    <div class="details-header body-font">{{ "APP.LOCATION" | translate }}</div>
    <div class="body-font">
      @if (event.hasAddress()) {
        <div class="event-info-primary">
          @if (event.getAddressDisplayNamePrimary().length > 20 && !isDesktop) {
            {{ event.getAddressDisplayNamePrimary().slice(0, 20) }}...
          } @else if (event.getAddressDisplayNamePrimary().length > 35) {
            {{ event.getAddressDisplayNamePrimary().slice(0, 35) }}...
          } @else {
            {{ event.getAddressDisplayNamePrimary() }}
          }
        </div>
        <div class="event-info-sub">
          {{ event.getAddressDisplayNameSecondary() }}
        </div>
      } @else {
        <div class="event-info-primary">
          {{ "APP.EVENT.LOCATION_TBD" | translate }}
        </div>
      }
    </div>
    @if (!isServer && event.hasAddress()) {
      <app-event-section-map
        class="section-map"
        [disableActions]="true"
      ></app-event-section-map>
    }
  </div>
}
