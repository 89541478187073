import { NgOptimizedImage } from '@angular/common';
import { Component, Input } from '@angular/core';
import { EventSponsor } from '@models/events/event-sponsor.model';

@Component({
  selector: 'app-event-section-sponsor-item',
  standalone: true,
  imports: [NgOptimizedImage],
  templateUrl: './event-section-sponsor-item.component.html',
  styleUrl: './event-section-sponsor-item.component.scss',
})
export class EventSectionSponsorItemComponent {
  @Input() sponsor?: EventSponsor;

  navigateTo(url?: string) {
    if (url) {
      window.open(url, '_blank');
    }
  }
}
