import { ClassData } from '@models/class-data';
import { CommunityAdditionalDataFieldOption } from '@models/communities/community-additional-data-field-option.model';
import { QuestionType } from '@models/event-attendance/event-attendee-question-type.enum';

export class CommunityAdditionalDataField {
  id: number;
  communityId: number;
  type: QuestionType;
  label: string;
  order: number;
  isRequired: boolean;
  editDisabled: boolean;
  options?: CommunityAdditionalDataFieldOption[];

  constructor(json: ClassData<CommunityAdditionalDataField>) {
    this.id = json.id;
    this.communityId = json.communityId;
    this.type = json.type;
    this.label = json.label;
    this.order = json.order;
    this.isRequired = json.isRequired;
    this.editDisabled = json.editDisabled;
    if (json.options) {
      this.options = json.options.map(
        (option) => new CommunityAdditionalDataFieldOption(option),
      );
    }
  }
}
