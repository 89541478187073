import { Component, ViewChild, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { FilterComponent } from '@modules/shared/components/filter/filter.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { PaginatorComponent } from '@modules/shared/components/paginator/paginator.component';
import { SelectionModel } from '@angular/cdk/collections';
import { PageEvent } from '@angular/material/paginator';
import { AppRoutes } from 'app/routes';
import { Router } from '@angular/router';
import { EventDataSource } from '@ds/event.ds';
import { EventService } from '@services/events/event.service';
import { EventCampaignStatus } from '@models/campaigns/event-campaign-status.enum';
import { Event } from '@models/events/event.model';
import { EventStatus } from '@models/events/event-status.enum';
import { EventCampaignCategory } from '@models/campaigns/event-campaign-category.enum';
import { Helpers } from '@utils/helpers';
import { EventCampaignPartyStatus } from '@models/campaigns/event-campaign-party-status.enum';
import { EventCampaignWorkingStatus } from '@models/campaigns/event-campaign-working-status.enum';
import { FormsModules } from '@utils/shared-modules';
import { YesNoDialog } from '@modules/customer/dialogs/yes-no/yes-no.dialog';
import { SetEventCampaignDialog } from '../../dialogs/set-event-campaign/set-event-campaign.dialog';
import { SetEventCampaignRequest } from '@models/campaigns/dto/set-event-campaign.request';
import { NotificationService } from '@services/shared/notification.service';

@Component({
  selector: 'app-event-list',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FilterComponent,
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatTableModule,
    FormsModules,
    PaginatorComponent,
  ],
  providers: [EventService, NotificationService],
  templateUrl: './event-list.component.html',
  styleUrl: './event-list.component.scss',
})
export class EventListComponent implements OnInit {
  @Input() campaignId?: number;

  @ViewChild(FilterComponent) filter!: FilterComponent;
  @ViewChild(PaginatorComponent) paginator!: PaginatorComponent;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;

  dataSource: EventDataSource;

  displayedColumns: string[] = [
    'select',
    'name',
    'date',
    'createdAt',
    'location',
    'host',
    'hostContact',
    'hostLastLogin',
    'referredByUser',
    'status',
    'guests',
    'campaignCategory',
    'campaignStatus',
    'campaignPartyStatus',
    'campaignWorkingStatus',
    'shippingContact',
    'shippingAddress',
    'actions',
  ];

  selection = new SelectionModel<any>(true, []);

  pageSizeOptions: number[] = [5, 10, 15, 20, 50, 100]; // Options for the user to change the page size

  constructor(
    private dialog: MatDialog,
    private eventService: EventService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private router: Router,
  ) {
    this.dataSource = new EventDataSource(this.eventService);
  }

  ngOnInit() {
    // Set the initial page
    if (this.campaignId) {
      const index = this.displayedColumns.indexOf('actions');
      if (index !== -1) {
        this.displayedColumns.splice(index, 0, 'eligibleForPackages');
        this.displayedColumns.splice(
          index + 1,
          0,
          'campaignInsuranceCheckLink',
        );
      }
    }
  }

  ngAfterViewInit() {
    // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => {
      this.resetPageIndex();
      this.refresh();
    });

    this.refresh();
  }

  onFilterChange(event: any) {
    this.resetPageIndex();
    this.refresh();
  }

  onPageChange(event: PageEvent) {
    this.refresh();
  }

  private resetPageIndex() {
    this.paginator.pager.currentPage = 1;
  }

  refresh() {
    const filters = new Map<string, any>();

    if (this.campaignId) {
      filters.set('campaignId', this.campaignId);
    }

    if (this.filter?.selectedEventCampaignCategory) {
      filters.set('eventCampaignCategory', this.filter.selectedEventCampaignCategory);
    }

    if (this.filter?.selectedEventCampaignStatus) {
      filters.set(
        'eventCampaignStatus',
        this.filter.selectedEventCampaignStatus,
      );
    }
    if (this.filter?.selectedEventCampaignPartyStatus) {
      filters.set(
        'eventCampaignPartyStatus',
        this.filter.selectedEventCampaignPartyStatus,
      );
    }
    if (this.filter?.selectedEventCampaignWorkingStatus) {
      filters.set(
        'eventCampaignWorkingStatus',
        this.filter.selectedEventCampaignWorkingStatus,
      );
    }

    if (this.filter?.searchText) {
      filters.set('searchTerm', this.filter.searchText);
    }

    if (!!this.sort.direction) {
      filters.set('sortDirection', this.sort.direction);
      filters.set('sortProperty', this.sort.active);
    }

    this.dataSource.loadEvents(
      filters,
      this.paginator.pager.currentPage !== 0
        ? this.paginator.pager.currentPage
        : 1,
      this.paginator.pageSize || 10,
    );
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource?.data?.forEach((row) => this.selection.select(row));
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource?.data?.length;
    return numSelected === numRows;
  }

  toggleSelection(row: any) {
    this.selection.toggle(row);
  }

  onRowClick(event: Event, mouseEvent: MouseEvent) {
    if (event) {
      const selectedText = window.getSelection()?.toString();

      if (
        !selectedText ||
        !Helpers.isSelectionInsideRow(mouseEvent.currentTarget as HTMLElement)
      ) {
        this.router.navigate(['/', AppRoutes.Events.events, event.uri]);
      }
    }
  }

  downloadKeyVisual(event: Event) {
    const keyVisualUrl =
      event.mainPictureUrl || event.eventTemplate?.visual?.keyVisual1?.url;

    if (keyVisualUrl && keyVisualUrl !== '') {
      this.eventService.downloadEventKeyVisual(keyVisualUrl);
    }
  }

  setEventCampaignStatus(eventId: number, status: EventCampaignStatus) {
    this.eventService
      .setEventCampaignStatus(eventId, { status: status })
      .subscribe(() => {
        this.resetPageIndex();
        this.refresh();
      });
  }

  setEventCampaign(event: Event) {
    const dialogRef = this.dialog.open(SetEventCampaignDialog, {
      maxWidth: '602px',
      maxHeight: '100vh',
      width: '100%',
      height: 'auto',
      data: {
        event: event,
      },
      panelClass: ['normal-dialog'],
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result && result.refresh === true) {
        this.resetPageIndex();
        this.refresh();
      }
    });
  }

  removeEventCampaign(eventId: number) {
    const dialogRef = this.dialog.open(YesNoDialog, {
      maxWidth: '602px',
      maxHeight: '100vh',
      width: '100%',
      height: 'auto',
      data: {
        title: 'APP.REMOVE_EVENT_CAMPAIGN.TITLE',
        message: 'APP.REMOVE_EVENT_CAMPAIGN.MESSAGE',
      },
      panelClass: ['normal-dialog'],
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result === true) {
        const request: SetEventCampaignRequest = {
          remove: true,
        };

        this.eventService.setEventCampaign(eventId, request).subscribe({
          next: () => {
            this.resetPageIndex();
            this.refresh();
          },
          error: (err) => {
            this.notificationService.error(
              this.translateService.instant('APP.ERRORS.COULD_NOT_DELETE'),
            );
          },
        });
      }
    });
  }

  onCampaignPartyStatusChange(eventId: number, status: EventCampaignPartyStatus) {
    this.eventService
      .setEventCampaignStatus(eventId, { partyStatus: status })
      .subscribe(() => {
        this.resetPageIndex();
        this.refresh();
      });
  }

  onCampaignWorkingStatusChange(
    eventId: number,
    status: EventCampaignWorkingStatus,
  ) {
    this.eventService
      .setEventCampaignStatus(eventId, { workingStatus: status })
      .subscribe(() => {
        this.resetPageIndex();
        this.refresh();
      });
  }

  protected readonly EventStatus = EventStatus;
  protected readonly EventCampaignStatus = EventCampaignStatus;
  protected readonly EventCampaignPartyStatus = EventCampaignPartyStatus;
  protected readonly EventCampaignWorkingStatus = EventCampaignWorkingStatus;
  protected readonly EventCampaignCategory = EventCampaignCategory;
}
