@if (event) {
  <div class="event-rsvp-section">
    @if (isLoggedUserAttendee) {
      <div class="description">
        {{ "APP.EVENT_RSVP.THANK_YOU" | translate }}
      </div>
    } @else if (event.isInThePast()) {
      <div class="description">
        {{ "APP.EVENT_RSVP.PAST_EVENT_RSVP_TITLE" | translate }}
      </div>

      <div class="description">
        {{ "APP.EVENT_RSVP.PAST_EVENT_RSVP_DESCRIPTION" | translate }}
      </div>
    } @else {
      @if (event.getRsvpDisableDate()) {
        <div class="description">
          {{ "APP.EVENT_RSVP.RSVP_DISABLE_DESC_1" | translate }}
          <span class="font-weight-bold">{{
            event.getRsvpDisableDate() | date: "dd. MMMM yyyy"
          }}</span>
          {{ "APP.EVENT_RSVP.RSVP_DISABLE_DESC_2" | translate }}
        </div>
      }

      @if (event.plusOnesLimit && form) {
        <form [formGroup]="form">
          @if (!loggedUser || !loggedUser.id) {
            <div class="flex-inputs-cont">
              @if (
                event.eventTemplate?.rsvpSettings?.hasPlusOnesAdditionalData()
              ) {
                @if (event.eventTemplate?.rsvpSettings?.showPlusOnesFirstName) {
                  <mat-form-field appearance="outline" class="flex-input">
                    <mat-label>{{ "APP.FIRST_NAME" | translate }}</mat-label>
                    <input
                      type="text"
                      matInput
                      formControlName="firstName"
                      placeholder=""
                    />
                  </mat-form-field>
                }
                @if (event.eventTemplate?.rsvpSettings?.showPlusOnesLastName) {
                  <mat-form-field appearance="outline" class="flex-input">
                    <mat-label>{{ "APP.LAST_NAME" | translate }}</mat-label>
                    <input
                      type="text"
                      matInput
                      formControlName="lastName"
                      placeholder=""
                    />
                  </mat-form-field>
                }
                @if (event.eventTemplate?.rsvpSettings?.showPlusOnesEmail) {
                  <mat-form-field
                    appearance="outline"
                    class="flex-input email-input"
                  >
                    <mat-label>{{ "APP.EMAIL" | translate }}</mat-label>
                    <input type="text" matInput formControlName="email" />
                  </mat-form-field>
                }
                @if (event.eventTemplate?.rsvpSettings?.showPlusOnesPhone) {
                  <mat-form-field
                    class="flex-input phone-form"
                    appearance="outline"
                  >
                    <app-tel-input formControlName="phone"></app-tel-input>
                    @if (form.controls["phone"].hasError("custom")) {
                      <mat-error>
                        {{ form.controls["phone"].getError("custom") }}
                      </mat-error>
                    } @else if (form.controls["phone"].hasError("required")) {
                      <mat-error>
                        {{ "APP.AUTH.ERRORS.PHONE_REQUIRED" | translate }}
                      </mat-error>
                    } @else if (
                      form.controls["phone"].hasError("invalidNumber")
                    ) {
                      <mat-error>
                        {{ "APP.AUTH.ERRORS.PHONE_INVALID" | translate }}
                      </mat-error>
                    }
                  </mat-form-field>
                }

                @if (event.eventTemplate?.attendeeQuestions) {
                  @for (
                    question of event.eventTemplate?.attendeeQuestions;
                    track question.id
                  ) {
                    <app-event-rsvp-question
                      [form]="form"
                      [question]="question"
                    ></app-event-rsvp-question>
                  }
                }
              }
            </div>
          } @else {
            <div class="flex-inputs-cont">
              <mat-form-field appearance="outline" class="flex-input">
                <mat-label>{{ "APP.EVENT_RSVP.NAME" | translate }}</mat-label>
                <input
                  type="text"
                  matInput
                  [ngModel]="loggedUser.name"
                  [ngModelOptions]="{ standalone: true }"
                  readonly
                  placeholder=""
                />
              </mat-form-field>

              @if (event.eventTemplate?.attendeeQuestions) {
                @for (
                  question of event.eventTemplate?.attendeeQuestions;
                  track question.id
                ) {
                  <app-event-rsvp-question
                    [form]="form"
                    [question]="question"
                  ></app-event-rsvp-question>
                }
              }
            </div>
          }

          <div formArrayName="plusOnes">
            <div
              *ngFor="let plusOneForm of plusOnes.controls; let i = index"
              [formGroupName]="i"
              class="flex-inputs-cont plus-one-cont"
            >
              @if (
                event.eventTemplate?.rsvpSettings?.hasPlusOnesAdditionalData()
              ) {
                @if (event.eventTemplate?.rsvpSettings?.showPlusOnesFirstName) {
                  <mat-form-field appearance="outline" class="flex-input">
                    <mat-label>{{ "APP.FIRST_NAME" | translate }}</mat-label>
                    <input
                      type="text"
                      matInput
                      formControlName="firstName"
                      placeholder=""
                    />
                  </mat-form-field>
                }
                @if (event.eventTemplate?.rsvpSettings?.showPlusOnesLastName) {
                  <mat-form-field appearance="outline" class="flex-input">
                    <mat-label>{{ "APP.LAST_NAME" | translate }}</mat-label>
                    <input
                      type="text"
                      matInput
                      formControlName="lastName"
                      placeholder=""
                    />
                  </mat-form-field>
                }
                @if (event.eventTemplate?.rsvpSettings?.showPlusOnesEmail) {
                  <mat-form-field
                    appearance="outline"
                    class="flex-input email-input"
                  >
                    <mat-label>{{ "APP.EMAIL" | translate }}</mat-label>
                    <input type="text" matInput formControlName="email" />
                  </mat-form-field>
                }
                @if (event.eventTemplate?.rsvpSettings?.showPlusOnesPhone) {
                  <mat-form-field
                    class="flex-input phone-form"
                    appearance="outline"
                  >
                    <app-tel-input formControlName="phone"></app-tel-input>
                    @if (
                      getFormGroup(plusOneForm).controls["phone"].hasError(
                        "custom"
                      )
                    ) {
                      <mat-error>
                        {{
                          getFormGroup(plusOneForm).controls["phone"].getError(
                            "custom"
                          )
                        }}
                      </mat-error>
                    } @else if (
                      getFormGroup(plusOneForm).controls["phone"].hasError(
                        "required"
                      )
                    ) {
                      <mat-error>
                        {{ "APP.AUTH.ERRORS.PHONE_REQUIRED" | translate }}
                      </mat-error>
                    } @else if (
                      getFormGroup(plusOneForm).controls["phone"].hasError(
                        "invalidNumber"
                      )
                    ) {
                      <mat-error>
                        {{ "APP.AUTH.ERRORS.PHONE_INVALID" | translate }}
                      </mat-error>
                    }
                  </mat-form-field>
                }

                @if (event.eventTemplate?.attendeeQuestions) {
                  @for (
                    question of event.eventTemplate?.attendeeQuestions;
                    track question.id
                  ) {
                    <app-event-rsvp-question
                      [form]="getFormGroup(plusOneForm)"
                      [question]="question"
                    ></app-event-rsvp-question>
                  }
                }

                <i
                  class="fal fa-times remove-plus-one"
                  (click)="removePlusOne(i)"
                ></i>
              }
            </div>
          </div>

          <div class="text-btn add-plus-one-btn" (click)="addPlusOne()">
            {{ "APP.EVENT_RSVP.ADD_PLUS_ONE" | translate }}
          </div>
        </form>
      }

      <div class="btn btn-weddingpage rsvp-btn" (click)="rsvp()">
        {{ "APP.EVENT_RSVP.SEND_RSVP" | translate }}
      </div>
    }
  </div>
}
