import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnInit,
  Output,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { EventRSVPConfirmationPageSectionType } from '@models/events/event-rsvp-confirmation-page-section-type.enum';
import { textColors } from '@modules/shared/components/rich-text-editor/utils/text-colors';
import { QuillEditorComponent, QuillModules, QuillService } from 'ngx-quill';
import { FormsModule } from '@angular/forms';
import { Event } from '@models/events/event.model';
import Embed from 'quill/blots/embed';
import Delta from 'quill-delta';
import { BlockEmbed } from 'quill/blots/block';
import { CommonModule, isPlatformServer } from '@angular/common';
import { debounceTime, first, Subject } from 'rxjs';

export class SoftLineBreakBlot extends Embed {
  static override blotName = 'softbreak';
  static override tagName = 'br';
  static override className = 'softbreak';
}

export class DividerBlot extends BlockEmbed {
  static override blotName = 'divider';
  static override tagName = 'hr';
}

function brMatcher(node: Node, delta: Delta) {
  const newDelta = new Delta();
  newDelta.insert({ softbreak: true });
  return newDelta;
}

function removeStylesMatcher(node: Node, delta: Delta) {
  delta.forEach((e) => {
    if (e && e.attributes) {
      e.attributes['color'] = '';
      e.attributes['background'] = '';
    }
  });
  return delta;
}

@Component({
  selector: 'app-rich-text-editor',
  standalone: true,
  imports: [CommonModule, FormsModule, QuillEditorComponent],
  templateUrl: './rich-text-editor.component.html',
  styleUrl: './rich-text-editor.component.scss',
})
export class RichTextEditorComponent implements OnInit, AfterViewInit {
  @Input() adminView = false;
  @Input() valueToChange: any;
  showToolbar: boolean = false;
  @Input() event?: Event;
  @Input() section?: any;
  @Input() isRSVPSection: boolean = false;
  @Input() sectionProperty?: string;
  @Input() showImage?: boolean = false;
  @Input() bodyColor?: string;
  @Input() borderColor?: string;
  @Input() rsvpColors?: boolean = true;
  quillInstance!: any;
  @Input() theme: string = 'bubble';
  @Input() maximumCharacters?: number;
  @Output() updateValue = new EventEmitter();
  @Output() textCountChange = new EventEmitter<number>();
  @Output() updateSameParagraph = new EventEmitter();
  @ViewChild('quillContainer') quillContainer!: ElementRef;
  @ViewChild('quillEditor', { static: false }) quillEditor?: any;
  valueInEditor: string = '';
  editorConfig: QuillModules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ align: [] }],
      ['clean'],
      ['link'],
      [
        {
          color: textColors,
        },
      ],
    ],
    keyboard: {
      bindings: {
        Enter: {
          key: 'Enter',
          shiftKey: true,
          handler: this.shiftEnterHandler,
        },
      },
    },
    clipboard: {
      matchVisual: false,
    },
  };

  isServer = false;
  private contentChanged = new Subject<void>();

  constructor(
    private quillService: QuillService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    this.isServer = isPlatformServer(platformId);
  }

  ngOnInit() {
    this.contentChanged.pipe(debounceTime(1000)).subscribe(() => {
      this.saveValue();
    });
    if (this.valueToChange) {
      this.valueInEditor = this.valueToChange;
    }
    if (this.section && this.section.bodyColor) {
      this.bodyColor = this.section.bodyColor;
    }
    if (this.adminView) {
      this.editorConfig.toolbar = [
        ['bold', 'italic', 'underline'],
        [{ header: 1 }, { header: 2 }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ align: [] }],
        ['clean'],
        ['link'],
      ];
    }

    if (this.showImage) {
      this.editorConfig.toolbar = [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ align: [] }],
        ['clean'],
        ['link', 'image'],
        [{ color: [] }],
      ];
    }
  }

  ngAfterViewInit() {
    if (this.quillEditor && !this.isServer) {
      this.quillEditor.onEditorCreated.subscribe((editor: any) => {
        this.quillInstance = editor;
        this.registerCustomModules();
      });
    }
  }
  onEditorBlur() {
    this.saveValue();
  }

  onKeyDown(event: any) {
    if (this.maximumCharacters && this.quillInstance) {
      const text = this.quillInstance.getText().trim();
      if (
        text.length >= this.maximumCharacters &&
        event.code !== 'Backspace' &&
        event.code !== 'Delete' &&
        event.target.localName !== 'input'
      ) {
        event.preventDefault();
      }
    }
  }

  onContentChanged(event: any): void {
    if (this.maximumCharacters) {
      const text = event?.editor?.getText().replace(/\r?\n/g, '') || '';

      if (text.length > this.maximumCharacters) {
        const delta = event.editor.getContents();
        const newlineCount = (event?.editor?.getText().match(/\r?\n/g) || [])
          .length;

        const truncatedDelta = delta.slice(
          0,
          this.maximumCharacters + (newlineCount - 1),
        );
        event.editor.setContents(truncatedDelta);
        event.editor.setSelection(this.maximumCharacters + (newlineCount - 1));
        this.textCountChange.emit(this.maximumCharacters);
        return;
      }

      this.updateValue.emit(this.valueInEditor);
      this.textCountChange.emit(text.length);
      this.contentChanged.next();
    } else {
      const text = event?.editor?.getText() || '';
      this.updateValue.emit(this.valueInEditor);
      this.textCountChange.emit(text.length);
      this.contentChanged.next();
    }
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: any) {
    if (!this.quillContainer) {
      return;
    }

    const clickedInside = this.isDescendant(
      this.quillContainer.nativeElement,
      event.target as Node,
    );
    if (!clickedInside) {
      if (this.showToolbar) {
        this.saveValue();
        this.showToolbar = false;
      }
    }
  }

  registerCustomModules() {
    this.quillService
      .getQuill()
      .pipe(first())
      .subscribe((quill) => {
        const Link = quill.import('formats/link') as any;
        if (Link) {
          Link.sanitize = (url: string) => {
            return url;
          };
        }
        quill.register(SoftLineBreakBlot);
        quill.register(DividerBlot);
      });
    const clipboardModule = this.quillInstance.getModule('clipboard');

    if (clipboardModule) {
      this.quillInstance.clipboard.addMatcher('br', brMatcher);
      this.quillInstance.clipboard.addMatcher(
        Node.ELEMENT_NODE,
        removeStylesMatcher,
      );
    } else {
      console.error('Quill clipboard module not available.');
    }
  }

  shiftEnterHandler(this: any, range: any) {
    const currentLeaf = this.quill.getLeaf(range.index)[0];
    const nextLeaf = this.quill.getLeaf(range.index + 1)[0];
    this.quill.insertEmbed(range.index, 'softbreak', true, 'user');
    if (nextLeaf === null || currentLeaf.parent !== nextLeaf.parent) {
      this.quill.insertEmbed(range.index, 'softbreak', true, 'user');
    }
    this.quill.setSelection(range.index + 1, 'silent');
  }

  private isDescendant(parent: Node, child: Node): boolean {
    let node: Node | null = child;
    while (node !== null) {
      if (node === parent) {
        return true;
      }
      node = node.parentNode;
    }
    return false;
  }

  saveValue() {
    let htmlContent = '';
    if (this.valueInEditor) {
      htmlContent = this.valueInEditor
        .replaceAll('<p></p>', '<br>')
        .replaceAll('<p><br class="softbreak"></p>', '');
    }

    if (this.section && !this.isRSVPSection) {
      this.section = {
        ...this.section,
        description: htmlContent,
      };
      this.updateValue.emit(this.section);
    } else if (this.section && this.isRSVPSection && this.sectionProperty) {
      this.section = {
        ...this.section,
        [this.sectionProperty]: htmlContent,
      };
      this.updateValue.emit(this.section);
    } else {
      this.updateValue.emit({
        value: htmlContent,
      });
    }
  }

  protected readonly EventRSVPConfirmationPageSectionType =
    EventRSVPConfirmationPageSectionType;
}
