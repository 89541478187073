import { isPlatformServer, UpperCasePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  PLATFORM_ID,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EventFaq } from '@models/events/event-faq.model';
import { EventSectionType } from '@models/events/event-section-type.enum';
import { EventSection } from '@models/events/event-section.model';
import { EventTimetable } from '@models/events/event-timetable.model';
import { EventType } from '@models/events/event-type.enum';
import { EventWeddingParty } from '@models/events/event-wedding-party.model';
import { Event } from '@models/events/event.model';
import { User } from '@models/users/user.model';
import { EventRsvpComponent } from '@modules/events/components/event-rsvp/event-rsvp.component';
import { EventSectionEventListComponent } from '@modules/events/components/event-section-event-list/event-section-event-list.component';
import { EventSectionLocationDateComponent } from '@modules/events/components/event-section-location-date/event-section-location-date.component';
import { EventSectionSponsorsComponent } from '@modules/events/components/event-section-sponsors/event-section-sponsors.component';
import { EventSectionTestimonialsComponent } from '@modules/events/components/event-section-testimonials/event-section-testimonials.component';
import { TranslateModule } from '@ngx-translate/core';
import { EventStore } from '@services/stores/event.store';
import { SafeHtmlPipe } from '../../../../pipes/safe-html.pipe';
import { EventSectionFaqComponent } from '../event-section-faq/event-section-faq.component';
import { EventSectionMapComponent } from '../event-section-map/event-section-map.component';
import { EventSectionRsvpComponent } from '../event-section-rsvp/event-section-rsvp.component';
import { EventSectionTimetableComponent } from '../event-section-timetable/event-section-timetable.component';
import { EventSectionWeddingPartyComponent } from '../event-section-wedding-party/event-section-wedding-party.component';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-event-section',
  standalone: true,
  imports: [
    EventSectionTimetableComponent,
    EventSectionMapComponent,
    EventSectionWeddingPartyComponent,
    EventSectionRsvpComponent,
    EventSectionFaqComponent,
    EventSectionSponsorsComponent,
    EventSectionTestimonialsComponent,
    EventSectionEventListComponent,
    TranslateModule,
    UpperCasePipe,
    EventSectionLocationDateComponent,
    EventRsvpComponent,
    SafeHtmlPipe,
  ],
  templateUrl: './event-section.component.html',
  styleUrl: './event-section.component.scss',
})
export class EventSectionComponent implements OnInit {
  @Input() section?: EventSection;
  @Input() isSubSection = false;
  @Input() loggedUser?: User;
  @Output() findEvent = new EventEmitter();
  @Output() learnMore = new EventEmitter();
  locationsSafeHtml: SafeHtml | undefined;

  event?: Event;

  isServer = false;
  isDarkThemeDesign = false;

  onFindEvent() {
    this.findEvent.emit();
  }
  onLearnMore() {
    this.learnMore.emit();
  }

  getAttrId(sectionType: EventSectionType) {
    let type;
    if (sectionType === EventSectionType.RSVP) {
      type = 'rsvp-section';
    } else if (
      sectionType === EventSectionType.TIMELINE &&
      this.event?.type === EventType.LANDINGPAGE
    ) {
      type = 'program-tour';
    } else {
      type = null;
    }
    return type;
  }

  constructor(
    private eventStore: EventStore,
    @Inject(PLATFORM_ID) platformId: Object,
    private sanitizer: DomSanitizer,
  ) {
    this.isServer = isPlatformServer(platformId);

    this.eventStore.event.pipe(takeUntilDestroyed()).subscribe((event) => {
      if (event) {
        this.event = event;
        this.isDarkThemeDesign =
          event.type === EventType.SPORTPAGE ||
          event.type === EventType.LANDINGPAGE;
      }
    });
  }

  ngOnInit(): void {
    this.locationsSafeHtml = this.getLocationSafeHtml();
  }

  getLocationSafeHtml(): SafeHtml {
    if (this.isUaSection) {
      const address = this.event
        ?.getSportPageLocationDisplay(true)
        ?.toUpperCase();
      const htmlString = `<object
            data="https://static.eventpage.ai/event-templates/event-templates-1594/bullet.svg"
            type="image/svg+xml"
          ></object> ${address}`;
      return this.sanitizer.bypassSecurityTrustHtml(htmlString);
    } else {
      const address = this.event?.getSportPageLocationDisplay()?.toUpperCase();
      const htmlString = `<i class="fas fa-map-marker-smile yellow-icon"></i> ${address}`;
      return this.sanitizer.bypassSecurityTrustHtml(htmlString);
    }
  }

  goToUaWebsite() {
    window.open('https://www.underarmour.de/de-de/', '_blank');
  }

  get isAccentSection(): boolean {
    return (
      !!this.section &&
      [
        EventSectionType.TIMELINE,
        EventSectionType.FAQ,
        EventSectionType.FOOTER,
      ].includes(this.section.type)
    );
  }

  get eventTimetable(): EventTimetable | undefined {
    return this.event?.eventTemplate?.eventTimetable;
  }

  get eventWeddingParty(): EventWeddingParty | undefined {
    return this.event?.eventTemplate?.eventWeddingParty;
  }

  get eventFaqs(): EventFaq[] | undefined {
    return this.event?.eventTemplate?.eventFaqs;
  }

  get isAttendeeView(): boolean {
    return (
      !!this.loggedUser &&
      !!this.event?.getUserAttendeeRSVPOption(this.loggedUser.id) &&
      this.event?.isNonPendingAttendee(this.loggedUser.id)
    );
  }

  get isUaSection(): boolean {
    return !!this.event?.uri.includes('underarmour-infinite-elite-testival');
  }

  protected readonly EventSectionType = EventSectionType;
  protected readonly EventType = EventType;
  protected readonly EventSection = EventSection;
}
