import { ClassData } from '@models/class-data';

export class CommunityLegal {
  id: number;

  companyName: string;

  address: string;
  postalCode: string;
  locality: string;
  country?: string;

  termsAndConditionsLink: string;
  dataPrivacyLink: string;
  contactLink: string;

  constructor(json: ClassData<CommunityLegal>) {
    this.id = json.id;

    this.companyName = json.companyName;

    this.address = json.address;
    this.postalCode = json.postalCode;
    this.locality = json.locality;
    this.country = json.country;

    this.termsAndConditionsLink = json.termsAndConditionsLink;
    this.dataPrivacyLink = json.dataPrivacyLink;
    this.contactLink = json.contactLink;
  }
}
