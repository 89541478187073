@if (event) {
  @if (isUaEvent) {
    <div class="ua-event-wrapper" (click)="goToEvent()">
      <div
        class="event-image"
        [ngStyle]="{
          'background-image':
            'url(' +
            (event.mainPictureUrl && event.mainPictureUrl !== ''
              ? event.mainPictureUrl
              : visual?.keyVisual2Url) +
            ')',
          'background-color': visual?.backgroundColor
        }"
      >
        <div class="img-overlay"></div>
        <img
          class="sponsor-overlay"
          src="https://static.eventpage.ai/event-templates/event-templates-1594/sportscheck-logo.svg"
          alt="sportScheck Logo"
        />
      </div>
      <div class="event-data">
        <div class="event-data-info">
          <div class="date">
            <object
              data="https://static.eventpage.ai/event-templates/event-templates-1594/plus.svg"
              type="image/svg+xml"
            ></object>
            {{ this.formatDate(event.startDate) }}
          </div>
          <div class="date">
            <object
              data="https://static.eventpage.ai/event-templates/event-templates-1594/plus.svg"
              type="image/svg+xml"
            ></object>
            {{ this.formatTime(event.startDate) }}
          </div>
        </div>
        <img
          src="https://static.eventpage.ai/event-templates/event-templates-1594/button.svg"
          width="70px"
          height="70px"
        />
      </div>
    </div>
  } @else {
    <div
      class="event-wrapper"
      (click)="goToEvent()"
      [class.finished]="isInThePast"
    >
      <div
        class="event-image"
        [ngStyle]="{
          'background-image':
            'url(' +
            (event.mainPictureUrl && event.mainPictureUrl !== ''
              ? event.mainPictureUrl
              : visual?.keyVisual2Url) +
            ')',
          'background-color': visual?.backgroundColor
        }"
      >
        <div class="img-overlay"></div>
      </div>
      <div class="event-data">
        <div class="event-data-info">
          <div class="title truncate">{{ event.name | uppercase }}</div>
          <div class="date">{{ this.formatDate(event.startDate) }}</div>
          <div class="time">
            {{ this.formatTime(event.startDate) }}
            {{ "APP.TIME_AGO.SINGLE_HOUR" | translate | uppercase }}
          </div>
        </div>
        <button class="btn btn-primary btn-navigate">
          <i class="fas fa-chevron-right"></i>
        </button>
      </div>
    </div>
  }
}
