export enum QuestionType {
  TEXT = 'TEXT',
  DROPDOWN = 'DROPDOWN',
  CHECKBOX = 'CHECKBOX',
  NUMBER = 'NUMBER',
  TEXTAREA = 'TEXTAREA',
}

export namespace QuestionType {
  export function getAllQuestionTypes(): QuestionType[] {
    return [
      QuestionType.TEXT,
      QuestionType.DROPDOWN,
      QuestionType.CHECKBOX,
      QuestionType.NUMBER,
      QuestionType.TEXTAREA,
    ];
  }

  export function getDataFieldsDisplayName(type?: QuestionType): string {
    switch (type) {
      case QuestionType.TEXT:
        return 'APP.QUESTION_TYPE.SHORT';
      case QuestionType.DROPDOWN:
        return 'APP.QUESTION_TYPE.MULTIPLE_CHOICE';
      case QuestionType.CHECKBOX:
        return 'APP.QUESTION_TYPE.YES_OR_NO';
      case QuestionType.NUMBER:
        return 'APP.QUESTION_TYPE.NUMBER';
      case QuestionType.TEXTAREA:
        return 'APP.QUESTION_TYPE.TEXTAREA';
      default:
        return '';
    }
  }
}
