import { ClassData } from '@models/class-data';
import { EventAttendeeStatus } from '@models/event-attendance/event-attendee-status.enum';

export class EventMessageRecipient {
  id: number;
  eventMessageId: number;
  recipient: EventAttendeeStatus;

  constructor(json: ClassData<EventMessageRecipient>) {
    this.id = +json.id;
    this.eventMessageId = json.eventMessageId;
    this.recipient = json.recipient;
  }
}
