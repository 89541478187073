import {
  CdkDrag,
  CdkDragHandle,
  CdkDragDrop,
  CdkDropList,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { NgForOf } from '@angular/common';
import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIconButton, MatMiniFabButton } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { CampaignStatus } from '@models/campaigns/campaign-status.enum';
import { ConfigurableSectionItem } from '@models/events/dto/configurable-section-item.model';
import { EventUpdateRequest } from '@models/events/dto/event-update.request';
import { EventSponsor } from '@models/events/event-sponsor.model';
import { EventType } from '@models/events/event-type.enum';
import { Event } from '@models/events/event.model';
import { getAssetFromURL } from '@models/shared/asset.model';
import { YesNoDialog } from '@modules/customer/dialogs/yes-no/yes-no.dialog';
import { EventSectionSponsorItemComponent } from '@modules/events/components/event-section-sponsor-item/event-section-sponsor-item.component';
import { EventSectionTestimonialsItemComponent } from '@modules/events/components/event-section-testimonials-item/event-section-testimonials-item.component';
import { UpdateActions } from '@modules/events/components/event-section-testimonials/event-section-testimonials.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { EventService } from '@services/events/event.service';
import { NotificationService } from '@services/shared/notification.service';
import { EventStore } from '@services/stores/event.store';
import { SwiperOptions } from 'swiper/types';
import { SwiperDirective } from '../../../../directives/swiper.directive';
import { CamelCasePipe } from '../../../../pipes/camel-case.pipe';

@Component({
  selector: 'app-event-section-sponsors',
  standalone: true,
  imports: [
    EventSectionSponsorItemComponent,
    SwiperDirective,
    CdkDrag,
    CdkDropList,
    EventSectionTestimonialsItemComponent,
    NgForOf,
    MatMiniFabButton,
    MatIcon,
    MatMenuTrigger,
    MatIconButton,
    MatMenu,
    MatMenuItem,
    TranslateModule,
    CamelCasePipe,
  ],
  providers: [NotificationService],
  templateUrl: './event-section-sponsors.component.html',
  styleUrl: './event-section-sponsors.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class EventSectionSponsorsComponent implements OnInit {
  @Input() isEdit?: boolean = false;
  @Output() onEdit = new EventEmitter<ConfigurableSectionItem>();
  @Output() configureSection = new EventEmitter<void>();
  event?: Event;
  draggedSponsor?: EventSponsor;
  private _sponsors: EventSponsor[] = [];
  get sponsors(): EventSponsor[] | undefined {
    return this._sponsors;
  }
  @Input()
  set sponsors(value: EventSponsor[] | undefined) {
    if (value) {
      this._sponsors = value.sort((a, b) => a.order - b.order);
    }
  }

  swiperConfig: SwiperOptions = {
    slidesPerView: 2,
    speed: 2000,
    loop: true,
    autoplay: {
      delay: 1000,
    },
    spaceBetween: 1,
    injectStyles: [
      `
        :host .swiper { overflow: visible;}
      `,
    ],
    breakpoints: {
      580: {
        slidesPerView: 3,
      },
    },
  };
  constructor(
    private eventStore: EventStore,
    private dialog: MatDialog,
    private eventService: EventService,
    private translateService: TranslateService,
    private notificationService: NotificationService,
  ) {
    this.eventStore.event.pipe(takeUntilDestroyed()).subscribe((event) => {
      this.event = event;
    });
  }
  ngOnInit(): void {
    if (this.event?.type === EventType.ADVANCED_ONE_COLUMN) {
      this.swiperConfig = {
        slidesPerView: 1,
        speed: 2000,
        loop: true,
        autoplay: {
          delay: 1000,
        },
        spaceBetween: 1,
        injectStyles: [
          `
        :host .swiper { overflow: visible; }
      `,
        ],
        breakpoints: {
          400: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 4,
          },
          1200: {
            slidesPerView: 5,
          },
        },
      };
    }
  }

  trackByFn(index: number, item: any) {
    return item.id;
  }
  drop(event: CdkDragDrop<any[]>, draggedSponsor?: EventSponsor) {
    if (this.sponsors) {
      moveItemInArray(this.sponsors, event.previousIndex, event.currentIndex);
    }

    if (draggedSponsor) {
      this.eventService
        .updateEvent(this.event?.id, {
          sponsor: {
            id: this.draggedSponsor?.id,
            linkUrl: this.draggedSponsor?.linkUrl,
            imageAsset: this.draggedSponsor?.imageUrl
              ? getAssetFromURL(this.draggedSponsor?.imageUrl)
              : undefined,
            name: this.draggedSponsor?.name,
            order: event.currentIndex,
          },
        })
        .subscribe({
          next: () => {},
        });
    }
  }

  onUpdate(action: UpdateActions, sponsor: EventSponsor) {
    if (action === UpdateActions.EDIT) {
      this.onEdit.emit({
        sponsor: sponsor,
      });
    } else if (action === UpdateActions.COPY) {
      const newItem = {
        isCopy: true,
        id: 0,
        linkUrl: sponsor.linkUrl,
        imageAsset: sponsor.imageUrl
          ? getAssetFromURL(sponsor.imageUrl)
          : undefined,
        order: sponsor.order,
      };
      const eventRequest = {
        sponsor: newItem,
      };
      this.update(eventRequest);
    } else if (action === UpdateActions.DELETE) {
      this.removeSponsor(sponsor);
    }
  }

  removeSponsor(sponsor: EventSponsor) {
    const dialogRef = this.dialog.open(YesNoDialog, {
      maxWidth: '602px',
      maxHeight: '100vh',
      width: '100%',
      height: 'auto',
      data: {
        title: 'APP.ADVANCED_ONE_COLUMN_EVENT.DELETE_ITEM_TITLE',
        message: 'APP.ADVANCED_ONE_COLUMN_EVENT.DELETE_ITEM_CONFIRMATION',
        yesLabel: 'APP.YES',
        font: this.event?.eventTemplate?.font,
        isDeleteDialog: true,
      },
      panelClass: ['normal-dialog'],
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res && this.event) {
        this.eventService
          .updateEvent(this.event?.id, {
            sponsorDeleteId: sponsor.id,
          })
          .subscribe({
            next: () => {
              if (this.event?.uri) {
                this.eventStore.refreshEvent(this.event?.uri);
              }
            },
            error: () => {
              this.notificationService.error(
                this.translateService.instant(
                  'APP.ADVANCED_ONE_COLUMN_EVENT.DELETE_ERROR',
                ),
              );
            },
          });
      }
    });
  }
  update(req?: EventUpdateRequest) {
    if (this.event) {
      if (req) {
        req.localization = this.translateService.currentLang;
      }

      this.eventService.updateEvent(this.event.id, req).subscribe((event) => {
        this.event = event;
        this.eventStore.setEvent(event);
      });
    }
  }

  onConfigureSection() {
    this.configureSection.emit();
  }

  protected readonly UpdateActions = UpdateActions;
  protected readonly CampaignStatus = CampaignStatus;
}
