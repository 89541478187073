@if (event && visual && form) {
  @if (event.isInThePast()) {
    <div
      class="rsvp-dialog past-rsvp-dialog event-dialog-content"
      mat-dialog-content
    >
      <div class="dialog-body">
        <div class="dialog-title primary-font">
          {{ "APP.EVENT_RSVP.PAST_EVENT_RSVP_TITLE" | translate }}
        </div>

        <div class="message primary-font">
          {{ "APP.EVENT_RSVP.PAST_EVENT_RSVP_DESCRIPTION" | translate }}
        </div>

        <div mat-dialog-actions class="dialog-actions">
          <div
            class="btn btn-invitation-transparent"
            [style.background]="
              visual.backgroundColor ? visual.backgroundColor : ''
            "
            (click)="close()"
          >
            {{ "APP.OK" | translate }}
          </div>
        </div>
      </div>
    </div>
  } @else {
    <div
      class="rsvp-dialog event-dialog-content"
      mat-dialog-content
      [formGroup]="form"
    >
      <div (click)="close()" class="close-button">
        <i class="fal fa-times-circle"></i>
      </div>
      <div class="dialog-body">
        <div class="dialog-title primary-font">
          {{ "APP.EVENT_RSVP.INVITATION_RSVP_TITLE" | translate }}
        </div>

        <div class="dialog-form">
          <mat-form-field class="w-100">
            <mat-label>{{ "APP.FIRST_NAME" | translate }}*</mat-label>
            <input type="text" matInput formControlName="firstName" />
          </mat-form-field>
          <mat-form-field class="w-100">
            <mat-label>{{ "APP.LAST_NAME" | translate }}*</mat-label>
            <input type="text" matInput formControlName="lastName" />
          </mat-form-field>
          <mat-form-field class="w-100">
            <mat-label>{{ "APP.EMAIL" | translate }}*</mat-label>
            <input type="text" matInput formControlName="email" />
          </mat-form-field>

          @if (event.eventTemplate?.attendeeQuestions) {
            @for (question of event.eventTemplate?.attendeeQuestions; track question.id) {
              <app-event-rsvp-question
                [form]="form"
                [question]="question"
                matFormFieldClass="w-100"
                matFormFieldAppearance="fill"
              ></app-event-rsvp-question>
            }
          }
        </div>
        <div mat-dialog-actions class="dialog-actions">
          <!-- <div
          class="btn btn-invitation-transparent"
          [style.background]="
                    visual.backgroundColor ? visual.backgroundColor : ''
                  "
          (click)="rsvp(RSVPOptionType.YES)"
        >
          I’M JOINING
        </div>
        <div
          class="btn btn-invitation-transparent"
          [style.background]="
                    visual.backgroundColor ? visual.backgroundColor : ''
                  "
          (click)="rsvp(RSVPOptionType.NO)"
        >
          CAN’T JOIN
        </div> -->

          @for (rsvpOption of event.rsvpOptions; track rsvpOption.type) {
            <div
              class="btn btn-invitation-transparent"
              [style.background]="
                visual.backgroundColor ? visual.backgroundColor : ''
              "
              (click)="rsvp(rsvpOption.type)"
            >
              {{ rsvpOption.name | translate }}
            </div>
          }
        </div>
      </div>
    </div>
  }
}
