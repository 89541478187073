import { ClassData } from '@models/class-data';
import { CommunityAdditionalDataField } from '@models/communities/community-additional-data-field.model';
import {
  CommunityPage,
  CommunityPageType,
} from '@models/communities/community-landing-page.model';
import { CommunityMember } from '@models/communities/community-member.model';
import { CommunityStatus } from '@models/communities/community-status.enum';
import { EmailSender } from '@models/users/email-sender.model';
import { User } from '@models/users/user.model';
import { CommunityLegal } from './community-legal.model';
import { environment as env } from '@environments/environment';
import { CommunityMemberStatus } from './community-member-status.enum';
import { CommunityEvent } from './community-event.model';

export class Community {
  id: number;
  createdAt?: Date;
  uri: string;
  domain: string;
  ownerId: number;
  owner?: User;
  name: string;
  logoUrl: string;
  logoBigUrl: string;
  status?: CommunityStatus;
  members?: CommunityMember[];
  additionalDataFields?: CommunityAdditionalDataField[];
  events?: CommunityEvent[];
  pages?: CommunityPage[];

  emailSenderId?: number;
  emailSender?: EmailSender;

  legalId?: number;
  legal?: CommunityLegal;

  faviconUrl: string;

  language?: string;
  themeMode?: string;

  pageViews?: number;

  constructor(json: ClassData<Community>) {
    this.id = json.id;
    this.createdAt = json.createdAt;
    this.uri = json.uri;
    this.domain = json.domain;
    this.owner = json.owner;
    this.name = json.name;
    this.logoUrl = json.logoUrl;
    this.logoBigUrl = json.logoBigUrl;
    this.status = json.status;
    this.ownerId = json.ownerId;
    if (json.members) {
      this.members = json.members.map((member) => new CommunityMember(member));
    }

    if (json.additionalDataFields) {
      this.additionalDataFields = json.additionalDataFields.map(
        (field) => new CommunityAdditionalDataField(field),
      );
    }

    if (json.events) {
      this.events = json.events;
    }
    if (json.pages) {
      this.pages = json.pages.map((page) => new CommunityPage(page));
    }

    this.emailSenderId = json.emailSenderId;
    if (json.emailSender) {
      this.emailSender = new EmailSender(json.emailSender);
    }

    this.legalId = json.legalId;
    if (json.legal) {
      this.legal = new CommunityLegal(json.legal);
    }

    this.faviconUrl = json.faviconUrl;

    this.language = json.language;
    this.themeMode = json.themeMode;

    this.pageViews = json.pageViews;
  }

  getBackgroundColor(): string | undefined {
    const landingPage = this.getPage(CommunityPageType.LANDING);

    if (!landingPage) {
      return undefined;
    }

    return landingPage.backgroundColor;
  }

  isBackgroundImage(): boolean {
    const landingPage = this.getPage(CommunityPageType.LANDING);

    if (!landingPage) {
      return false;
    }

    return landingPage.isBackgroundImage();
  }

  isBackgroundVideo(): boolean {
    const landingPage = this.getPage(CommunityPageType.LANDING);

    if (!landingPage) {
      return false;
    }

    return landingPage.isBackgroundVideo();
  }

  getEncodedBackground(allowVideo = false): string {
    const landingPage = this.getPage(CommunityPageType.LANDING);

    if (!landingPage) {
      return '';
    }

    return landingPage.getEncodedBackground();
  }

  getPage(type: CommunityPageType): CommunityPage | undefined {
    if (!this.pages) {
      return undefined;
    }

    return this.pages.find((page) => page.type === type);
  }

  isPublished(): boolean {
    return this.status === CommunityStatus.PUBLIC;
  }

  isOwner(userId: number): boolean {
    return this.ownerId === userId;
  }

  isMember(userId: number): boolean {
    const member = this.members?.find((x) => x.userId === userId);
    return !!member;
  }

  getLink(): string {
    return `${env.website}/communities/${this.uri}`;
  }

  getPageViews(): number {
    if (!this.pageViews) {
      return 0;
    }

    return this.pageViews;
  }

  getNumMembers(): number {
    if (!this.members) {
      return 0;
    }

    return this.members.filter(
      (member) => member.status === CommunityMemberStatus.APPROVED,
    ).length;
  }

  getNumApplications(): number {
    if (!this.members) {
      return 0;
    }

    return this.members.filter(
      (member) => member.status === CommunityMemberStatus.PENDING,
    ).length;
  }

  hasPresetThemeMode(): boolean {
    return !!this.themeMode && this.themeMode !== '';
  }

  /*   public getAdditionalDataFieldsSorted():
    | CommunityAdditionalDataField[]
    | undefined {
    return this.additionalDataFields?.sort((a, b) => a.order - b.order);
  } */
}
