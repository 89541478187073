@if (event && visual && form) {
  <div
    class="rsvp-dialog event-dialog-content"
    mat-dialog-content
    [formGroup]="form"
  >
    <div (click)="close()" class="close-button">
      <i class="fal fa-times-circle"></i>
    </div>
    <div class="dialog-body">
      <div class="dialog-title">
        {{ "APP.EVENT_RSVP.ADD_GUEST" | translate }}
      </div>

      <div class="dialog-form">
        @if (event.eventTemplate?.rsvpSettings?.requireGroupRsvp && !groupLeadAttendeeId) {
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ "APP.EVENT_RSVP.TEAM_NAME" | translate }}*</mat-label>
            <input type="text" matInput formControlName="groupName" />
          </mat-form-field>
        }

        <mat-form-field class="w-100" appearance="outline">
          <mat-label>{{ "APP.FIRST_NAME" | translate }}*</mat-label>
          <input type="text" matInput formControlName="firstName" />
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>{{ "APP.LAST_NAME" | translate }}*</mat-label>
          <input type="text" matInput formControlName="lastName" />
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
          <mat-label
            >{{ "APP.EMAIL" | translate }}
            @if (!groupLeadAttendeeId) {
              *
            }
          </mat-label>
          <input type="text" matInput formControlName="email" />
        </mat-form-field>
        @if (groupLeadAttendeeId) {
          <mat-form-field appearance="outline" class="w-100 tel-input-field">
            <app-tel-input formControlName="phone"></app-tel-input>
            @if (form.controls["phone"].hasError("invalidNumber")) {
              <mat-error>
                {{ "APP.AUTH.ERRORS.PHONE_INVALID" | translate }}
              </mat-error>
            }
          </mat-form-field>
        }

        @if (event.eventTemplate?.attendeeQuestions) {
          @for (
            question of event.eventTemplate?.getAttendeeQuestionsSorted();
            track question.id
          ) {
            <app-event-rsvp-question
              [form]="form"
              [question]="question"
              matFormFieldClass="w-100"
            ></app-event-rsvp-question>
          }
        }

        @if (!groupLeadAttendeeId) {
          <mat-form-field
            class="w-100"
            appearance="outline"
            [class.display-none]="!event.plusOnesLimit"
          >
            <mat-select formControlName="numAttendees">
              <mat-option [value]="0">
                {{ "APP.EVENT_RSVP.NO_PLUS_ONES" | translate }}
              </mat-option>
              <mat-option
                *ngFor="let numAttendees of numAttendeesOptions"
                [value]="numAttendees"
                [class.has-value]="form.controls['numAttendees'].value !== ''"
              >
                {{ "APP.EVENT_RSVP.PLUS" | translate }} {{ numAttendees }}
                {{
                  (numAttendees === 1
                    ? "APP.EVENT_RSVP.ATTENDEE"
                    : "APP.EVENT_RSVP.ATTENDEES"
                  ) | translate
                }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-checkbox
            formControlName="sendConfirmation"
            class="guest-checkbox"
            >{{
              "APP.EVENT_RSVP.ADD_GUEST_SEND_CONFIRMATION" | translate
            }}</mat-checkbox
          >
        }
      </div>
      <div mat-dialog-actions class="dialog-actions">
        <div class="btn btn-primary-party-page" (click)="addGuest()">
          {{ "APP.EVENT_RSVP.ADD_GUEST" | translate }}
        </div>
      </div>
    </div>
  </div>
}
