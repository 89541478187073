@if (testimonials) {
  <div>

  </div>
  <div
    class="swiper-wrapper-global"
    [class.advanced-one-column]="
      event && event.type === EventType.ADVANCED_ONE_COLUMN
    "
  >
    @if (isEdit) {
      <div
        cdkDropList
        cdkDropListOrientation="horizontal"
        (cdkDropListDropped)="drop($event, draggedTestimonial)"
        class="testimonial-drop-list horizontal-scroll-container"
      >
        <button
          class="btn btn-primary add-button"
          (click)="onConfigureSection()"
        >
          <i class="far fa-plus"></i>
        </button>

        <div
          *ngFor="let testimonial of testimonials; trackBy: trackByFn"
          cdkDrag
          class="testimonial-item"
          (cdkDragStarted)="draggedTestimonial = testimonial"
        >
          <app-event-section-testimonials-item
            [testimonial]="testimonial"
            [event]="event"
          ></app-event-section-testimonials-item>
          <div cdkDragHandle class="grab-action">
            <i class="fas fa-grip-vertical"></i>
          </div>
          <div class="update-actions" appTextSize [size]='"small"'>
            <div
              class="action"
              (click)="onUpdate(UpdateActions.EDIT, testimonial)"
            >
              <i class="fal fa-edit"></i>
              <span>
                {{ "APP.EDIT" | translate | camelCase }}
              </span>
            </div>
            <div
              class="action"
              (click)="onUpdate(UpdateActions.COPY, testimonial)"
            >
              <i class="fal fa-copy"></i>
              <span>
                {{ "APP.COPY" | translate | camelCase }}
              </span>
            </div>
            <div
              class="action"
              (click)="onUpdate(UpdateActions.DELETE, testimonial)"
            >
              <i class="far fa-trash"></i>
              <span>
                {{ "APP.DELETE" | translate | camelCase }}
              </span>
            </div>
          </div>
        </div>
      </div>
    } @else {
      <swiper-container
        appSwiper
        [config]="swiperConfigTestimonials"
        init="false"
        class="testimonial-swiper"
      >
        @for (testimonial of testimonials; track testimonial.id) {
          <swiper-slide>
            <app-event-section-testimonials-item
              [testimonial]="testimonial"
              [event]="event"
            />
          </swiper-slide>
        }
      </swiper-container>
    }
  </div>

  @if(!isEdit){
    <div class='indicator-wrapper'>
      @for(testimonial of testimonials; let i = $index; track testimonial.id){
        <div class='progress-indicator' [class.active]="i === activeIndex"></div>
      }
    </div>
  }
}
