<div
  class="event-section-map"
  [class.advanced-one-column]="
    event?.type === EventType.ADVANCED_ONE_COLUMN && !disableActions
  "
  [class.sport-page-map]="
    event?.type === EventType.SPORTPAGE || event?.type === EventType.LANDINGPAGE
  "
>
  <div class="google-map-container">
    @if (event?.type === EventType.LANDINGPAGE) {
      <google-map
        mapId="wp-map"
        [zoom]="mapZoom"
        [center]="mapCenter"
        [options]="mapOptions"
      >
        @for (event of events; track event.id) {
          <map-advanced-marker
            [position]="getPosition(event.id)"
            [options]="getMarkerOptions()"
          >
          </map-advanced-marker>
        }
      </google-map>
    } @else {
      <google-map
        mapId="wp-map"
        [zoom]="disableActions ? 15 : mapZoom"
        [center]="mapCenter"
        [options]="disableActions ? noActionsMapOptions : mapOptions"
      >
        <map-advanced-marker
          [position]="markerPosition"
          [options]="
            event?.type === EventType.SPORTPAGE
              ? getMarkerOptions()
              : markerOptions
          "
        >
        </map-advanced-marker>
      </google-map>
    }
    @if (disableActions) {
      <div class="clickable-overlay" (click)="openGoogleMaps()"></div>
    }
    <div class="red-overlay"></div>
  </div>

  @if (event?.type === EventType.WEDDINGPAGE && !disableActions) {
    <div class="btn btn-weddingpage" (click)="openGoogleMaps()">
      Google Maps
    </div>
  }

  @if (event?.type === EventType.SPORTPAGE && !disableActions) {
    <div
      class="btn btn-sportpage"
      [class.btn-ua]="isUaSection"
      (click)="openGoogleMaps()"
    >
      {{ "APP.SPORT_PAGE.SHOW_GOOGLE_MAPS" | translate }}
    </div>
  }

  @if (event?.type === EventType.ADVANCED_ONE_COLUMN && !disableActions) {
    <div class="btn btn-advanced-one-column" (click)="openGoogleMaps()">
      {{ "APP.SPORT_PAGE.SHOW_GOOGLE_MAPS" | translate }}
    </div>
  }
</div>
