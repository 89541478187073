import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { AffiliateProductOverlayPageComponent } from '@modules/admin/affiliates/dialogs/affiliate-product-overlay-page/affiliate-product-overlay-page.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TemplateOverlayHeaderComponent } from '../../components/template-overlay-header/template-overlay-header.component';
import { BackService } from '@services/shared/back.service';
import { finalize, Subject, takeUntil } from 'rxjs';
import { TemplateBasicInfosStepComponent } from '../../components/template-basic-infos-step/template-basic-infos-step.component';
import { TemplateVisualStepComponent } from '../../components/template-visual-step/template-visual-step.component';
import { TemplateTextStepComponent } from '../../components/template-text-step/template-text-step.component';
import { TemplateWidgetsAndKitsStepComponent } from '../../components/template-widgets-and-kits-step/template-widgets-and-kits-step.component';
import { TemplatePartnerAndAffiliatesStepComponent } from '../../components/template-partner-and-affiliates-step/template-partner-and-affiliates-step.component';
import { TemplateInvitationCardStepComponent } from '../../components/template-invitation-card-step/template-invitation-card-step.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormsModules } from '@utils/shared-modules';
import { TemplateSeoStepComponent } from '../../components/template-seo-step/template-seo-step.component';
import { EventTemplateService } from '@services/events/event-template.service';
import { EventTemplateCreationRequest } from '@models/design-templates/dto/event-template-creation.request';
import { CustomDialogData } from '@models/overlay-dialogs/custom-dialog-data.model';
import { CustomDialogComponent } from '@modules/shared/dialogs/custom-dialog/custom-dialog.component';
import { NotificationService } from '@services/shared/notification.service';
import { Kit } from '@models/pricing-plans/kit.enum';
import { EventTemplateAffiliateProduct } from '@models/design-templates/event-template-affiliate-product.model';
import { EventAffiliateProductType } from '@models/events/event-affiliate-product-type.enum';
import { AffiliateProduct } from '@models/affiliate-products/affiliate-product.model';
import { Address } from '@models/shared/address.model';
import { ClassData } from '@models/class-data';
import { DateUtils } from '@utils/date-utils';
import moment from 'moment-timezone';
import { TemplatePreviewOverlayPageComponent } from '../template-preview-overlay-page/template-preview-overlay-page.component';
import {
  EventTemplate,
  mapCreationRequestToEventTemplate,
} from '@models/design-templates/event-template.model';
import { getAssetFromURL } from '@models/shared/asset.model';
import { TimezoneOption } from '@models/timezone/timezone-option.model';
import { atLeastOneRequired } from 'app/validators/at-least-one-required.validator';
import { EventTemplateAccess } from '@models/design-templates/event-template-access.enum';
import { EventTemplateCreationLangRequest } from '@models/design-templates/dto/event-template-creation-lang.request';
import { EventTemplateTranslation } from '@models/design-templates/event-template-translation.model';
import { EventTemplateInvitationCardTranslation } from '@models/design-templates/event-template-invitation-card-translation.model';
import { EventTemplateSeoTranslation } from '@models/design-templates/event-template-seo-translation.model';

@Component({
  selector: 'app-design-template-overlay-page',
  standalone: true,
  imports: [
    CommonModule,
    FormsModules,
    TranslateModule,
    TemplateOverlayHeaderComponent,
    TemplateBasicInfosStepComponent,
    TemplateVisualStepComponent,
    TemplateTextStepComponent,
    TemplateWidgetsAndKitsStepComponent,
    TemplatePartnerAndAffiliatesStepComponent,
    TemplateInvitationCardStepComponent,
    TemplateSeoStepComponent,
  ],
  providers: [EventTemplateService, NotificationService],
  templateUrl: './design-template-overlay-page.component.html',
  styleUrl: './design-template-overlay-page.component.scss',
})
export class DesignTemplateOverlayPageComponent implements OnInit {
  currentStep = 1;
  numberOfSteps = 7;

  form?: FormGroup;
  eventTemplate?: EventTemplate;

  loading = false;
  isEdit = false;
  isDraft = false;
  isFromPageTable = false;

  currentLang = 'de';

  requestDE?: EventTemplateCreationRequest;
  requestEN?: EventTemplateCreationRequest;

  specificEventTemplateTranslation?: EventTemplateTranslation;
  specificEventTemplateInvitationCardTranslation?: EventTemplateInvitationCardTranslation;
  specificEventTemplateSeoTranslation?: EventTemplateSeoTranslation;

  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();

  constructor(
    private backService: BackService,
    private eventTemplateService: EventTemplateService,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    public dialogRef: MatDialogRef<AffiliateProductOverlayPageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translateService: TranslateService,
  ) {
    if (this.data) {
      this.isEdit = this.data.isEdit;
      this.isFromPageTable = this.data.isFromPageTable;
      this.eventTemplate = this.data.eventTemplate;
      if (this.eventTemplate) {
        this.currentLang = this.eventTemplate?.languageCode;
      }

      this.changeTranslations(this.eventTemplate);
    }

    this.isDraft =
      this.eventTemplate === undefined ||
      this.eventTemplate?.access == EventTemplateAccess.DRAFT;

    this.setFormValues();

    this.basicInfosForm.controls['status'].valueChanges.subscribe((val) => {
      if (this.eventTemplate) {
        this.isDraft = val === EventTemplateAccess.DRAFT;

        if (this.isDraft && this.form) {
          this.form.markAsPristine();
          this.form.markAsUntouched();
        }
      }
    });

    // this.translateService.onLangChange.subscribe(() => {
    //   this.currentLang = this.translateService.currentLang;
    //   this.changeTranslations(this.eventTemplate);
    // });

    // if (!this.isFromPageTable) {
    //   this.currentLang = this.translateService.currentLang;
    // }
  }

  changeTranslations(eventTemplate?: EventTemplate) {
    if (eventTemplate && eventTemplate.translations) {
      this.specificEventTemplateTranslation = eventTemplate.translations.filter(
        (t) => t.languageCode === this.currentLang,
      )[0];

      if (
        eventTemplate.invitationCard &&
        eventTemplate.invitationCard.translations
      ) {
        this.specificEventTemplateInvitationCardTranslation =
          eventTemplate.invitationCard.translations?.filter(
            (t) => t.languageCode === this.currentLang,
          )[0];
      }

      if (eventTemplate.seo && eventTemplate.seo.translations) {
        this.specificEventTemplateSeoTranslation =
          eventTemplate.seo?.translations?.filter(
            (t) => t.languageCode === this.currentLang,
          )[0];
      }
    }
  }

  ngOnInit(): void {
    this.backService.backButtonClick$
      .pipe(takeUntil(this._onDestroy))
      .subscribe((clicked) => {
        if (clicked && this.currentStep > 1) {
          this.currentStep--;

          if (this.isDraft && this.form) {
            this.form.markAsPristine();
            this.form.markAsUntouched();
          }
        }
      });
  }

  setRequestObject() {
    const request = this.currentLang === 'de' ? this.requestDE : this.requestEN;
    this.setTranslatedFormValues(request);
  }

  selectLanguage(event: string) {
    const currentRequest = this.getTemplateCreationData();
    if (this.currentLang === 'de') {
      this.requestDE = currentRequest;
    } else if (this.currentLang === 'en') {
      this.requestEN = currentRequest;
    }

    this.currentLang = event;

    this.changeTranslations(this.eventTemplate);
    if (this.eventTemplate) {
      if (this.currentLang === 'en') {
        this.setTranslatedFormValues(this.requestEN, this.eventTemplate);
      } else if (this.currentLang === 'de') {
        this.setTranslatedFormValues(this.requestDE, this.eventTemplate);
      }
    } else {
      this.setRequestObject();
    }
  }

  setTranslatedFormValues(
    request?: EventTemplateCreationRequest,
    eventTemplate?: EventTemplate,
  ) {
    this.basicInfosForm.controls['name'].setValue(
      request?.name || this.specificEventTemplateTranslation?.name,
    );
    this.basicInfosForm.controls['eventName'].setValue(
      request?.eventName || this.specificEventTemplateTranslation?.eventName,
    );
    this.basicInfosForm.controls['eventCta'].setValue(
      request?.eventCta || this.specificEventTemplateTranslation?.eventCta,
    );
    this.basicInfosForm.controls['description'].setValue(
      request?.description ||
        this.specificEventTemplateTranslation?.description,
    );

    this.seoForm.controls['uri'].setValue(
      request?.uri || this.specificEventTemplateTranslation?.uri,
    );
    this.seoForm.controls['seoPictureTitle'].setValue(
      request?.seo?.seoPictureTitle ||
        this.specificEventTemplateSeoTranslation?.seoPictureTitle,
    );
    this.seoForm.controls['seoPictureAlt'].setValue(
      request?.seo?.seoPictureAlt ||
        this.specificEventTemplateSeoTranslation?.seoPictureAlt,
    );
    this.seoForm.controls['seoTitle'].setValue(
      request?.seo?.title || this.specificEventTemplateSeoTranslation?.title,
    );
    this.seoForm.controls['seoDescription'].setValue(
      request?.seo?.description ||
        this.specificEventTemplateSeoTranslation?.description,
    );
    this.seoForm.controls['seoPageTitle'].setValue(
      request?.seo?.pageTitle ||
        this.specificEventTemplateSeoTranslation?.pageTitle,
    );
    this.seoForm.controls['seoPageDetailSummary'].setValue(
      request?.seo?.pageDetailSummary ||
        this.specificEventTemplateSeoTranslation?.pageDetailSummary,
    );
    this.seoForm.controls['seoPageDetail'].setValue(
      request?.seo?.pageDetail ||
        this.specificEventTemplateSeoTranslation?.pageDetail,
    );

    if (this.form) {
      this.form.controls['standardInvitationText'].setValue(
        request?.standardInvitationText ||
          this.specificEventTemplateTranslation?.standardInvitationText,
      );
      this.form.controls['invitationCardTitle'].setValue(
        request?.invitationCard?.title ||
          this.specificEventTemplateInvitationCardTranslation?.title,
      );
      this.form.controls['invitationCardText'].setValue(
        request?.invitationCard?.invitationText ||
          this.specificEventTemplateInvitationCardTranslation?.invitationText,
      );
    }

    this.form?.markAsUntouched();
  }

  setFormValues() {
    let thumbnailAsset = undefined;
    if (
      this.eventTemplate?.visual?.thumbnailUrl &&
      this.eventTemplate?.visual?.thumbnailUrl !== ''
    ) {
      thumbnailAsset = getAssetFromURL(
        this.eventTemplate?.visual?.thumbnailUrl,
      );
    }

    let logoAsset = undefined;
    if (
      this.eventTemplate?.visual?.logoUrl &&
      this.eventTemplate?.visual?.logoUrl !== ''
    ) {
      logoAsset = getAssetFromURL(this.eventTemplate?.visual?.logoUrl);
    }

    // let keyVisual1Asset = undefined;
    // if (
    //   this.eventTemplate?.visual?.keyVisual1Url &&
    //   this.eventTemplate?.visual?.keyVisual1Url !== ''
    // ) {
    //   keyVisual1Asset = getAssetFromURL(
    //     this.eventTemplate?.visual?.keyVisual1Url,
    //   );
    // }

    let keyVisual2Asset = undefined;
    if (
      this.eventTemplate?.visual?.keyVisual2Url &&
      this.eventTemplate?.visual?.keyVisual2Url !== ''
    ) {
      keyVisual2Asset = getAssetFromURL(
        this.eventTemplate?.visual?.keyVisual2Url,
      );
    }

    let patternAsset = undefined;
    if (
      this.eventTemplate?.visual?.patternUrl &&
      this.eventTemplate?.visual?.patternUrl !== ''
    ) {
      patternAsset = getAssetFromURL(this.eventTemplate?.visual?.patternUrl);
    }

    // let backgroundAsset = undefined;
    // if (
    //   this.eventTemplate?.visual?.backgroundUrl &&
    //   this.eventTemplate?.visual?.backgroundUrl !== ''
    // ) {
    //   backgroundAsset = getAssetFromURL(
    //     this.eventTemplate?.visual?.backgroundUrl,
    //   );
    // }

    let marketplaceVisualAssets = undefined;
    if (
      this.eventTemplate?.visual?.marketplaceVisuals &&
      this.eventTemplate?.visual?.marketplaceVisuals.length > 0
    ) {
      marketplaceVisualAssets =
        this.eventTemplate?.visual?.marketplaceVisuals?.map((mv) =>
          getAssetFromURL(mv.url, mv.title, mv.alt),
        );
    }

    let seoSharingPictureAsset = undefined;
    if (
      this.eventTemplate?.seo?.sharingPictureUrl &&
      this.eventTemplate?.seo?.sharingPictureUrl != ''
    ) {
      seoSharingPictureAsset = getAssetFromURL(
        this.eventTemplate?.seo?.sharingPictureUrl,
      );
    }

    let presetTimeZoneOffset = null;
    if (this.eventTemplate?.timeZone) {
      presetTimeZoneOffset = new TimezoneOption({
        name: this.eventTemplate.timeZone,
        offset: moment.tz(this.eventTemplate.timeZone).utcOffset(),
      });
    }

    let startTime = undefined;
    let startDate = undefined;
    if (this.eventTemplate?.startDate) {
      let startDateTimeMoment = moment.tz(
        this.eventTemplate.startDate,
        presetTimeZoneOffset ? presetTimeZoneOffset.name : moment.tz.guess(),
      );

      startDate = new Date(
        startDateTimeMoment.year(),
        startDateTimeMoment.month(),
        startDateTimeMoment.date(),
      );

      startTime = startDateTimeMoment.format('HH:mm');
    }

    let endTime = undefined;
    let endDate = undefined;
    if (this.eventTemplate?.endDate) {
      let endDateTimeMoment = moment.tz(
        this.eventTemplate.endDate,
        presetTimeZoneOffset ? presetTimeZoneOffset.name : moment.tz.guess(),
      );

      endDate = new Date(
        endDateTimeMoment.year(),
        endDateTimeMoment.month(),
        endDateTimeMoment.date(),
      );

      endTime = endDateTimeMoment.format('HH:mm');
    }

    let promoTimeZoneOffset = null;
    if (this.eventTemplate?.promotionTimeZone) {
      promoTimeZoneOffset = new TimezoneOption({
        name: this.eventTemplate.promotionTimeZone,
        offset: moment.tz(this.eventTemplate.promotionTimeZone).utcOffset(),
      });
    }

    let promoStartTime = undefined;
    let promoStartDate = undefined;
    if (this.eventTemplate?.promotionStartDate) {
      let promoStartDateTimeMoment = moment.tz(
        this.eventTemplate.promotionStartDate,
        promoTimeZoneOffset ? promoTimeZoneOffset.name : moment.tz.guess(),
      );

      promoStartDate = new Date(
        promoStartDateTimeMoment.year(),
        promoStartDateTimeMoment.month(),
        promoStartDateTimeMoment.date(),
      );

      promoStartTime = promoStartDateTimeMoment.format('HH:mm');
    }

    let promoEndTime = undefined;
    let promoEndDate = undefined;
    if (this.eventTemplate?.promotionEndDate) {
      let promoEndDateTimeMoment = moment.tz(
        this.eventTemplate.promotionEndDate,
        promoTimeZoneOffset ? promoTimeZoneOffset.name : moment.tz.guess(),
      );

      promoEndDate = new Date(
        promoEndDateTimeMoment.year(),
        promoEndDateTimeMoment.month(),
        promoEndDateTimeMoment.date(),
      );

      promoEndTime = promoEndDateTimeMoment.format('HH:mm');
    }

    const translation = this.eventTemplate?.getTranslation();

    this.form = new FormGroup({
      // step 1
      basicInfosForm: new FormGroup({
        name: new FormControl(
          this.specificEventTemplateTranslation?.name || '',
          [Validators.required],
        ),
        status: new FormControl(this.eventTemplate?.access || '', [
          Validators.required,
        ]),
        relevance: new FormControl(this.eventTemplate?.relevance || '', [
          Validators.required,
        ]),
        description: new FormControl(
          this.specificEventTemplateTranslation?.description || '',
          [Validators.required],
        ),
        eventName: new FormControl(
          this.specificEventTemplateTranslation?.eventName || '',
          [Validators.required],
        ),
        eventCta: new FormControl(
          this.specificEventTemplateTranslation?.eventCta || '',
          [Validators.required],
        ),
        locationName: new FormControl(
          this.eventTemplate?.eventAddress?.name || '',
        ),
        locationAddress: new FormGroup({
          street: new FormControl(
            this.eventTemplate?.eventAddress?.street || '',
          ),
          streetNumber: new FormControl(
            this.eventTemplate?.eventAddress?.streetNumber || '',
          ),
          postalCode: new FormControl(
            this.eventTemplate?.eventAddress?.postalCode || '',
          ),
          locality: new FormControl(
            this.eventTemplate?.eventAddress?.locality || '',
          ),
          country: new FormControl(
            this.eventTemplate?.eventAddress?.country || '',
          ),
          federalState: new FormControl(
            this.eventTemplate?.eventAddress?.federalState || '',
          ),
          lat: new FormControl(this.eventTemplate?.eventAddress?.lat || 0.0),
          lng: new FormControl(this.eventTemplate?.eventAddress?.lng || 0.0),
          placeId: new FormControl(
            this.eventTemplate?.eventAddress?.placeId || '',
          ),
        }),

        presetStartDate: new FormControl(startDate || null),
        presetEndDate: new FormControl(endDate || null),
        presetStartTime: new FormControl(startTime || ''),
        presetEndTime: new FormControl(endTime || ''),
        presetTimeZone: new FormControl(
          presetTimeZoneOffset ? presetTimeZoneOffset : '',
        ),

        promotionPeriodStartDate: new FormControl(promoStartDate || null),
        promotionPeriodEndDate: new FormControl(promoEndDate || null),
        promotionPeriodStartTime: new FormControl(promoStartTime || ''),
        promotionPeriodEndTime: new FormControl(promoEndTime || ''),
        promotionPeriodTimeZone: new FormControl(
          promoTimeZoneOffset ? promoTimeZoneOffset : '',
        ),

        keywords: new FormControl(
          this.eventTemplate?.keywords?.map((kw) => kw.id) || [],
          [Validators.required],
        ),
        categories: new FormControl(
          this.eventTemplate?.eventCategories?.map((c) => c.id) || [],
          [Validators.required],
        ),
        subCategories: new FormControl(
          this.eventTemplate?.eventSubcategories?.map((sc) => sc.id) || [],
          [Validators.required],
        ),
        targetGroups: new FormControl(this.eventTemplate?.targetGroups || [], [
          Validators.required,
        ]),
        languageCode: new FormControl(
          this.eventTemplate?.languageCode || 'de',
          [Validators.required],
        ),
        translationTemplate: new FormControl(translation?.id || null),
        translationTemplateLanguageCode: new FormControl(
          translation?.languageCode || '',
        ),
      }),

      // step 2
      visualForm: new FormGroup(
        {
          thumbnailAsset: new FormControl(thumbnailAsset || undefined, [
            Validators.required,
          ]),
          logoAsset: new FormControl(logoAsset || undefined),
          keyVisual1: new FormControl(
            this.eventTemplate?.visual?.keyVisual1 || null,
            [Validators.required],
          ),
          keyVisual2Asset: new FormControl(keyVisual2Asset || undefined),
          patternAsset: new FormControl(patternAsset || undefined),
          background: new FormControl(
            this.eventTemplate?.visual?.background || null,
          ),
          effect: new FormControl(this.eventTemplate?.visual?.effect || null),
          backgroundColor: new FormControl(
            this.eventTemplate?.visual?.backgroundColor || '',
          ),
          footerColor: new FormControl(
            this.eventTemplate?.visual?.footerColor || '',
          ),
        },
        {
          validators: atLeastOneRequired('background', 'backgroundColor'),
        },
      ),

      // step 3
      standardInvitationText: new FormControl(
        this.specificEventTemplateTranslation?.standardInvitationText || '',
      ),
      primaryLogoFont: new FormControl(
        this.eventTemplate?.font?.primaryLogoFont || 'Gabarito',
        [Validators.required],
      ),
      primaryLogoFontColor: new FormControl(
        this.eventTemplate?.font?.primaryLogoFontColor || '#473bff',
      ),
      primaryFontWeight: new FormControl(
        this.eventTemplate?.font?.primaryFontWeight || '',
        [Validators.required],
      ),
      bodyFont: new FormControl(
        this.eventTemplate?.font?.bodyFont || 'Gabarito',
        [Validators.required],
      ),
      bodyFontColor: new FormControl(
        this.eventTemplate?.font?.bodyFontColor || '#ffffff',
      ),
      bodyFontWeight: new FormControl(
        this.eventTemplate?.font?.bodyFontWeight || '',
        [Validators.required],
      ),
      // signalFont: new FormControl(
      //   this.eventTemplate?.font?.signalFont || 'Gabarito',
      //   [Validators.required],
      // ),
      // signalFontColor: new FormControl(
      //   this.eventTemplate?.font?.signalFontColor || '#473bff',
      // ),
      // signalFontWeight: new FormControl(
      //   this.eventTemplate?.font?.signalFontWeight || '',
      //   [Validators.required],
      // ),

      // step 4
      presetWidgets: new FormControl(this.eventTemplate?.widgets || []),
      // kits: new FormControl([]),
      promotionKit: new FormControl(
        (this.eventTemplate?.kits?.length && this.eventTemplate.kits) || false,
      ),

      // step 5
      partner: new FormControl(this.eventTemplate?.partner || ''),
      afterCreationAffiliateProducts: new FormControl(
        this.eventTemplate?.affiliateProducts
          ?.filter((ap) => ap.type === EventAffiliateProductType.AFTER_CREATION)
          ?.map((ap) => ap.affiliateProduct) || [],
      ),
      afterAttendGiftAffiliateProducts: new FormControl(
        this.eventTemplate?.affiliateProducts
          ?.filter(
            (ap) => ap.type === EventAffiliateProductType.AFTER_ATTEND_GIFT,
          )
          ?.map((ap) => ap.affiliateProduct) || [],
      ),
      afterAttendAffiliateProducts: new FormControl(
        this.eventTemplate?.affiliateProducts
          ?.filter((ap) => ap.type === EventAffiliateProductType.AFTER_ATTEND)
          ?.map((ap) => ap.affiliateProduct) || [],
      ),
      afterAttendShoppingListAffiliateProducts: new FormControl(
        this.eventTemplate?.affiliateProducts
          ?.filter(
            (ap) =>
              ap.type === EventAffiliateProductType.AFTER_ATTEND_SHOPPING_LIST,
          )
          ?.map((ap) => ap.affiliateProduct) || [],
      ),

      // step 6
      invitationCardTitle: new FormControl(
        this.specificEventTemplateInvitationCardTranslation?.title || '',
      ),
      invitationCardText: new FormControl(
        this.specificEventTemplateInvitationCardTranslation?.invitationText ||
          '',
      ),
      invitationCardContactFullName: new FormControl(
        this.eventTemplate?.invitationCard?.contactName || '',
      ),
      invitationCardContactEmail: new FormControl(
        this.eventTemplate?.invitationCard?.contactEmail || '',
      ),
      invitationCardContactPhone: new FormControl(
        this.eventTemplate?.invitationCard?.contactPhone || '',
      ),

      // step 7
      seoForm: new FormGroup({
        uri: new FormControl(this.specificEventTemplateTranslation?.uri || '', [
          Validators.required,
        ]),
        shownOnSite: new FormControl(this.eventTemplate?.shownOnSite || false, [
          Validators.required,
        ]),
        seoSharingPictureAsset: new FormControl(
          seoSharingPictureAsset || undefined,
          // [Validators.required],
        ),
        seoPictureFileName: new FormControl(
          seoSharingPictureAsset?.name || '',
          [
            // Validators.required,
            Validators.maxLength(65),
          ],
        ),
        seoPictureTitle: new FormControl(
          this.specificEventTemplateSeoTranslation?.seoPictureTitle || '',
          [
            // Validators.required,
            Validators.maxLength(65),
          ],
        ),
        seoPictureAlt: new FormControl(
          this.specificEventTemplateSeoTranslation?.seoPictureAlt || '',
          [
            // Validators.required,
            Validators.maxLength(100),
            // Validators.minLength(70),
          ],
        ),
        seoTitle: new FormControl(
          this.specificEventTemplateSeoTranslation?.title || '',
          // [Validators.required],
        ),
        seoDescription: new FormControl(
          this.specificEventTemplateSeoTranslation?.description || '',
          // [Validators.required],
        ),
        seoPageTitle: new FormControl(
          this.specificEventTemplateSeoTranslation?.pageTitle || '',
        ),
        seoPageDetailSummary: new FormControl(
          this.specificEventTemplateSeoTranslation?.pageDetailSummary || '',
        ),
        seoPageDetail: new FormControl(
          this.specificEventTemplateSeoTranslation?.pageDetail || '',
        ),
        category: new FormControl(
          this.eventTemplate?.seo?.eventCategoryId || null,
          // [Validators.required],
        ),
        keywords: new FormControl(
          this.eventTemplate?.seo?.keywords?.map((kw) => kw.id) || [],
          // [Validators.required],
        ),
        marketplaceVisuals: new FormControl(marketplaceVisualAssets || []),
      }),
    });

    this.basicInfosForm?.controls['languageCode'].valueChanges.subscribe(
      (val) => {
        this.currentLang = val;
      },
    );

    this.seoForm?.valueChanges.subscribe((value) => {
      const isShowOnSiteValid = !!(
        value.seoSharingPictureAsset !== undefined &&
        value.seoPictureFileName !== '' &&
        value.seoPictureTitle !== '' &&
        value.seoPictureAlt !== '' &&
        value.seoTitle !== '' &&
        value.seoDescription !== '' &&
        value.seoPageTitle !== '' &&
        value.seoPageDetailSummary !== '' &&
        value.seoPageDetail !== '' &&
        value.category &&
        value.keywords &&
        value.marketplaceVisuals
      );

      if (!isShowOnSiteValid) {
        this.seoForm.controls['shownOnSite'].disable({
          onlySelf: true,
          emitEvent: false,
        });
        this.seoForm.controls['shownOnSite'].patchValue(false, {
          onlySelf: true,
          emitEvent: false,
        });
      } else {
        this.seoForm.controls['shownOnSite'].enable({
          onlySelf: true,
          emitEvent: false,
        });
      }
    });
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  nextStep() {
    if (!this.form) {
      return;
    }

    if (this.isDraft) {
      this.form.markAsPristine();
      this.form.markAsUntouched();
    }

    if (this.currentStep < 7) {
      this.currentStep++;
      return;
    }

    this.publishDesignTemplateShortcut();
  }

  publishDesignTemplateShortcut() {
    if (!this.form) {
      return;
    }
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      this.notificationService.error(
        this.translateService.instant(
          'APP.DESIGN_TEMPLATES.CREATE.ERRORS.FORM_INVALID',
        ),
      );
      return;
    }

    if (this.currentLang === 'de') {
      this.requestDE = this.getTemplateCreationData();
    } else if (this.currentLang === 'en') {
      this.requestEN = this.getTemplateCreationData();
    }
    // const request = this.getTemplateCreationData();

    // let loggedUser = localStorage.getItem('user');
    // let languageCode = '';
    // if (loggedUser) {
    //   let parsedLoggedUser = JSON.parse(loggedUser);
    //   languageCode = parsedLoggedUser.language;
    // }

    const request: EventTemplateCreationLangRequest = {
      requestDe: this.requestDE,
      requestEn: this.requestEN,
      languageCode: this.currentLang,
    };

    if (
      request &&
      this.requestDE &&
      this.requestEN &&
      ((this.requestDE || this.requestEN).access === undefined ||
        (this.requestDE || this.requestEN).access === EventTemplateAccess.DRAFT)
    ) {
      request.access = EventTemplateAccess.PUBLIC;
    }
    request.isDraft = false;

    this.loading = true;

    if (this.isEdit) {
      this.eventTemplateService
        .update(this.eventTemplate!.id, request)
        .pipe(
          finalize(() => {
            this.loading = false;
          }),
        )
        .subscribe({
          next: (eventTemplate) => {
            const confirmation =
              'APP.AFFILIATE_PRODUCTS_PAGE_LIST.CUSTOM_DIALOG.PRODUCT_CONFIRMATION_MESSAGE';
            const okButtonMessage = 'APP.OK';
            const title = 'APP.LOOKS_GOOD';

            const data: CustomDialogData = {
              confirmation: confirmation,
              title: title,
              cancelButtonMessage: okButtonMessage,
              id: eventTemplate.id,
              pictureUrl: eventTemplate.visual?.thumbnailUrl,
            };

            this.dialog.open(CustomDialogComponent, {
              maxWidth: '455px',
              width: '100%',
              height: 'auto',
              maxHeight: '100vh',
              data: data,
            });

            this.dialogRef.close({
              eventTemplate: eventTemplate,
              refresh: true,
            });
          },
          error: (err) => {
            if (err) {
              if (err.message) {
                this.notificationService.error(err.message);
              } else if (err.error) {
                this.notificationService.error(err.error);
              }
            }
          },
        });
    } else {
      this.eventTemplateService
        .create(request)
        .pipe(
          finalize(() => {
            this.loading = false;
          }),
        )
        .subscribe({
          next: (eventTemplate) => {
            const confirmation =
              'APP.AFFILIATE_PRODUCTS_PAGE_LIST.CUSTOM_DIALOG.PRODUCT_CONFIRMATION_MESSAGE';
            const okButtonMessage = 'APP.OK';
            const title = 'APP.LOOKS_GOOD';

            const data: CustomDialogData = {
              confirmation: confirmation,
              title: title,
              cancelButtonMessage: okButtonMessage,
              id: eventTemplate.id,
              pictureUrl: eventTemplate.visual?.thumbnailUrl,
            };

            this.dialog.open(CustomDialogComponent, {
              maxWidth: '455px',
              width: '100%',
              height: 'auto',
              maxHeight: '100vh',
              data: data,
            });

            this.dialogRef.close(true);
          },
          error: (err) => {
            if (err && err.message) {
              this.notificationService.error(err.message);
            }
          },
        });
    }
  }

  saveAsDraftDesignTemplateShortcut() {
    if (this.currentLang === 'de') {
      this.requestDE = this.getTemplateCreationData();
    } else if (this.currentLang === 'en') {
      this.requestEN = this.getTemplateCreationData();
    }
    // const request = this.getTemplateCreationData();
    // let loggedUser = localStorage.getItem('user');
    // let languageCode = '';
    // if (loggedUser) {
    //   let parsedLoggedUser = JSON.parse(loggedUser);
    //   languageCode = parsedLoggedUser.language;
    // }

    const request: EventTemplateCreationLangRequest = {
      requestDe: this.requestDE,
      requestEn: this.requestEN,
      access: EventTemplateAccess.DRAFT,
      isDraft: true,
      languageCode: this.currentLang,
    };

    this.loading = true;

    if (this.isEdit) {
      this.eventTemplateService
        .update(this.eventTemplate!.id, request)
        .pipe(
          finalize(() => {
            this.loading = false;
          }),
        )
        .subscribe({
          next: (eventTemplate) => {
            const confirmation =
              'APP.AFFILIATE_PRODUCTS_PAGE_LIST.CUSTOM_DIALOG.PRODUCT_CONFIRMATION_MESSAGE';
            const okButtonMessage = 'APP.OK';
            const title = 'APP.LOOKS_GOOD';

            const data: CustomDialogData = {
              confirmation: confirmation,
              title: title,
              cancelButtonMessage: okButtonMessage,
              id: eventTemplate.id,
              pictureUrl: eventTemplate.visual?.thumbnailUrl,
            };

            this.dialog.open(CustomDialogComponent, {
              maxWidth: '455px',
              width: '100%',
              height: 'auto',
              maxHeight: '100vh',
              data: data,
            });

            this.dialogRef.close({
              eventTemplate: eventTemplate,
              refresh: true,
            });
          },
          error: (err) => {
            if (err) {
              if (err.message) {
                this.notificationService.error(err.message);
              } else if (err.error) {
                this.notificationService.error(err.error);
              }
            }
          },
        });
    } else {
      this.eventTemplateService
        .create(request)
        .pipe(
          finalize(() => {
            this.loading = false;
          }),
        )
        .subscribe({
          next: (eventTemplate) => {
            const confirmation =
              'APP.AFFILIATE_PRODUCTS_PAGE_LIST.CUSTOM_DIALOG.PRODUCT_CONFIRMATION_MESSAGE';
            const okButtonMessage = 'APP.OK';
            const title = 'APP.LOOKS_GOOD';

            const data: CustomDialogData = {
              confirmation: confirmation,
              title: title,
              cancelButtonMessage: okButtonMessage,
              id: eventTemplate.id,
              pictureUrl: eventTemplate.visual?.thumbnailUrl,
            };

            this.dialog.open(CustomDialogComponent, {
              maxWidth: '455px',
              width: '100%',
              height: 'auto',
              maxHeight: '100vh',
              data: data,
            });

            this.dialogRef.close(true);
          },
          error: (err) => {
            if (err && err.message) {
              this.notificationService.error(err.message);
            }
          },
        });
    }
  }

  getTemplateCreationData(): EventTemplateCreationRequest {
    const value = this.form!.value;

    const kits = [];

    if (value.promotionKit) {
      kits.push(Kit.PROMOTION_KIT);
    }

    let partnerId = null;
    if (value.partner && typeof value.partner === 'object') {
      partnerId = value.partner.id;
    }

    const affiliateProducts: EventTemplateAffiliateProduct[] = [];
    if (value.afterCreationAffiliateProducts?.length > 0) {
      affiliateProducts.push(
        ...this.mapAffiliateProductToTemplateDTO(
          value.afterCreationAffiliateProducts,
          EventAffiliateProductType.AFTER_CREATION,
        ),
      );
    }
    if (value.afterAttendGiftAffiliateProducts?.length > 0) {
      affiliateProducts.push(
        ...this.mapAffiliateProductToTemplateDTO(
          value.afterAttendGiftAffiliateProducts,
          EventAffiliateProductType.AFTER_ATTEND_GIFT,
        ),
      );
    }
    if (value.afterAttendAffiliateProducts?.length > 0) {
      affiliateProducts.push(
        ...this.mapAffiliateProductToTemplateDTO(
          value.afterAttendAffiliateProducts,
          EventAffiliateProductType.AFTER_ATTEND,
        ),
      );
    }
    if (value.afterAttendShoppingListAffiliateProducts?.length > 0) {
      affiliateProducts.push(
        ...this.mapAffiliateProductToTemplateDTO(
          value.afterAttendShoppingListAffiliateProducts,
          EventAffiliateProductType.AFTER_ATTEND_SHOPPING_LIST,
        ),
      );
    }

    let eventAddress: ClassData<Address> | undefined = {
      name: value.basicInfosForm.locationName,
      street: value.basicInfosForm.locationAddress.street,
      streetNumber: value.basicInfosForm.locationAddress.streetNumber,
      postalCode: value.basicInfosForm.locationAddress.postalCode,
      locality: value.basicInfosForm.locationAddress.locality,
      country: value.basicInfosForm.locationAddress.country,
      federalState: value.basicInfosForm.locationAddress.federalState,
      lat: value.basicInfosForm.locationAddress.lat,
      lng: value.basicInfosForm.locationAddress.lng,
      placeId: value.basicInfosForm.locationAddress.placeId,
    };

    if (
      (!value.basicInfosForm.locationName ||
        value.basicInfosForm.locationName === '') &&
      (!value.basicInfosForm.locationAddress ||
        value.basicInfosForm.locationAddress.street === '')
    ) {
      eventAddress = undefined;
    }

    const presetTimeZoneObj = value.basicInfosForm.presetTimeZone;
    let presetTimeZone = '';
    if (presetTimeZoneObj) {
      presetTimeZone = presetTimeZoneObj.name;
    }

    const presetDateRange = DateUtils.getDateRangeAtTimezone(
      value.basicInfosForm.presetStartDate,
      value.basicInfosForm.presetEndDate,
      value.basicInfosForm.presetStartTime,
      value.basicInfosForm.presetEndTime,
      presetTimeZone,
    );

    const promotionPeriodTimeZoneObj =
      value.basicInfosForm.promotionPeriodTimeZone;
    let promotionPeriodTimeZone = '';
    if (promotionPeriodTimeZoneObj) {
      promotionPeriodTimeZone = promotionPeriodTimeZoneObj.name;
    }

    const promotionPeriodDateRange = DateUtils.getDateRangeAtTimezone(
      value.basicInfosForm.promotionPeriodStartDate,
      value.basicInfosForm.promotionPeriodEndDate,
      value.basicInfosForm.promotionPeriodStartTime,
      value.basicInfosForm.promotionPeriodEndTime,
      promotionPeriodTimeZone,
    );

    const sharingPictureAsset = value.seoForm.seoSharingPictureAsset;
    if (sharingPictureAsset && value.seoForm.seoPictureFileName) {
      sharingPictureAsset.name = value.seoForm.seoPictureFileName;
    }

    return {
      uri: value.seoForm.uri,
      name: value.basicInfosForm.name,
      description: value.basicInfosForm.description,
      eventName: value.basicInfosForm.eventName,
      eventCta: value.basicInfosForm.eventCta,
      access: value.basicInfosForm.status,
      relevance: value.basicInfosForm.relevance,

      shownOnSite: this.seoForm.getRawValue().shownOnSite,

      categoryIds: value.basicInfosForm.categories,
      subcategoryIds: value.basicInfosForm.subCategories,

      startDate: presetDateRange.startDate,
      endDate: presetDateRange.endDate,
      timeZone:
        presetTimeZone === '' && presetDateRange.startDate
          ? moment.tz.guess()
          : presetTimeZone,

      promotionStartDate: promotionPeriodDateRange.startDate,
      promotionEndDate: promotionPeriodDateRange.endDate,
      promotionTimeZone:
        promotionPeriodTimeZone === '' && promotionPeriodDateRange.startDate
          ? moment.tz.guess()
          : promotionPeriodTimeZone,

      targetGroupIds: value.basicInfosForm.targetGroups,

      visual: {
        thumbnailAsset: value.visualForm.thumbnailAsset,
        logoAsset: value.visualForm.logoAsset,
        keyVisual1Id:
          value.visualForm.keyVisual1 &&
          typeof value.visualForm.keyVisual1 === 'object'
            ? value.visualForm.keyVisual1.id
            : undefined,
        keyVisual1: value.visualForm.keyVisual1,
        keyVisual2Asset: value.visualForm.keyVisual2Asset,
        patternAsset: value.visualForm.patternAsset,
        backgroundId:
          value.visualForm.background &&
          typeof value.visualForm.background === 'object'
            ? value.visualForm.background.id
            : undefined,
        background: value.visualForm.background,
        backgroundColor: value.visualForm.backgroundColor,
        footerColor: value.visualForm.footerColor,
        effectId:
          value.visualForm.effect && typeof value.visualForm.effect === 'object'
            ? value.visualForm.effect.id
            : undefined,
        effect: value.visualForm.effect,

        marketplaceVisuals: value.seoForm.marketplaceVisuals,
      },

      font: {
        primaryLogoFont: value.primaryLogoFont,
        primaryLogoFontColor: value.primaryLogoFontColor,
        primaryFontWeight:
          value.primaryFontWeight !== '' ? value.primaryFontWeight : undefined,
        bodyFont: value.bodyFont,
        bodyFontColor: value.bodyFontColor,
        bodyFontWeight:
          value.bodyFontWeight !== '' ? value.bodyFontWeight : undefined,
        // signalFont: value.signalFont,
        // signalFontColor: value.signalFontColor,
        // signalFontWeight: value.signalFontWeight,
      },

      widgets: value.presetWidgets,
      kits: kits,

      partnerId: partnerId,

      affiliateProducts: affiliateProducts,

      eventAddress: eventAddress,

      standardInvitationText: value.standardInvitationText,

      invitationCard: {
        title: value.invitationCardTitle,
        invitationText: value.invitationCardText,

        contactName: value.invitationCardContactFullName,
        contactEmail: value.invitationCardContactEmail,
        contactPhone: value.invitationCardContactPhone,
      },

      seo: {
        sharingPictureAsset: sharingPictureAsset,
        seoPictureTitle: value.seoForm.seoPictureTitle,
        seoPictureAlt: value.seoForm.seoPictureAlt,
        title: value.seoForm.seoTitle,
        description: value.seoForm.seoDescription,
        pageTitle: value.seoForm.seoPageTitle,
        pageDetailSummary: value.seoForm.seoPageDetailSummary,
        pageDetail: value.seoForm.seoPageDetail,
        eventCategoryId: value.seoForm.category,
        keywords: value.seoForm.keywords,
      },

      keywords: value.basicInfosForm.keywords,

      localization: this.currentLang,

      languageCode: this.basicInfosForm.getRawValue().languageCode,

      translationTemplateId: value.basicInfosForm.translationTemplate,
    };
  }

  previewTemplate() {
    const eventTemplate = mapCreationRequestToEventTemplate(
      this.getTemplateCreationData(),
    );

    const dialogRef = this.dialog.open(TemplatePreviewOverlayPageComponent, {
      width: '100%',
      height: '100%',
      maxWidth: '100%',
      panelClass: 'overlay-page-preview-dialog',
      data: {
        eventTemplate: eventTemplate,
        isAdminPreview: true,
      },
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
      }
    });
  }

  get basicInfosForm(): FormGroup {
    return this.form?.get('basicInfosForm') as FormGroup;
  }

  get visualForm(): FormGroup {
    return this.form?.get('visualForm') as FormGroup;
  }

  get seoForm(): FormGroup {
    return this.form?.get('seoForm') as FormGroup;
  }

  private mapAffiliateProductToTemplateDTO(
    affiliateProducts: AffiliateProduct[],
    type: EventAffiliateProductType,
  ): EventTemplateAffiliateProduct[] {
    const templateAffiliateProducts: EventTemplateAffiliateProduct[] = [];

    affiliateProducts.forEach((affiliateProduct: AffiliateProduct) => {
      templateAffiliateProducts.push({
        affiliateProductId: affiliateProduct.id,
        type: type,
      });
    });

    return templateAffiliateProducts;
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  protected readonly event = event;
  protected readonly EventTemplateAccess = EventTemplateAccess;
}
