import { ClassData } from '@models/class-data';
import { EventMessageChannelType } from '@models/events/event-message-channel-type.enum';

export class EventMessageChannel {
  id: number;
  eventMessageId: number;
  channel: EventMessageChannelType;

  constructor(json: ClassData<EventMessageChannel>) {
    this.id = +json.id;
    this.eventMessageId = json.eventMessageId;
    this.channel = json.channel;
  }
}
