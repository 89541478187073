import { Component, Input } from '@angular/core';
import { EventTimetableItem } from '@models/events/event-timetable-item.model';
import { EventType } from '@models/events/event-type.enum';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-event-section-timetable-item',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './event-section-timetable-item.component.html',
  styleUrl: './event-section-timetable-item.component.scss',
})
export class EventSectionTimetableItemComponent {
  @Input() item?: EventTimetableItem;
  @Input() eventType: EventType = EventType.INVITATION;
  @Input() isUaEvent = false;
  protected readonly EventType = EventType;
}
