<div class="generic-get-tickets-wrapper">
  @if (!showSuccessPage) {
      <div class="item payment">
        <ng-container *ngTemplateOutlet="payment"></ng-container>
      </div>
      <div class="item">
        <div class="ticket-selection-content smaller-ticket-view">
          <!--Event info-->
          <ng-container *ngTemplateOutlet="eventInfo"></ng-container>

          <!--Get tickets accordion-->
          <div class="ticket-wrapper">
            <ng-container
              *ngTemplateOutlet="ticketsSelection"
            ></ng-container>
          </div>

          <!--Apply voucher and Total price section-->
          <ng-container *ngTemplateOutlet="orderOverview"></ng-container>
        </div>
      </div>
  } @else {
    <div class='success-page'>
    <div class='title-success'>{{ "APP.EVENT_PAYMENT.THANK_YOU_FOR_YOUR_ORDER" | translate }}!</div>
    <app-ticket-payment-success
      [isGeneric]='true'
      [ticketOrder]="ticketOrder"
      [mainImage]="mainImage"
      [event]="event"
      [disableClose]="true"
      (onClose)="close()"
    />
    </div>
  }
</div>

<ng-template #eventInfo>
  @if(event){
    <div class="event-info-wrapper">
      <div class="event-image-wrapper">
        <div class="overlay">
          <img [src]="mainImage" class="key-visual-img" alt="Key Visual" />
        </div>
        <img [src]="mainImage" class="key-visual-img logo-image" alt="Key Visual" />
      </div>
      <div class="event-info">
        <div class="name">
          {{ event.name }}
        </div>
        <div class="date-address">
          <div class="date">
            {{ event.getEventInfoDateLine1(currentLang) }}
            @if (event.endDate && !event.endsOnSameDate()) {
              {{ "APP.DATE_TO" | translate }}
            }
            {{ event.getEventInfoDateLine2(currentLang) }}
          </div>
          <div class="address">
            {{ event.getAddressDisplayNamePrimary() }}
            {{ event.getAddressDisplayNameSecondary() }}
          </div>
        </div>
      </div>
    </div>
  }

</ng-template>

<ng-template #orderOverview>
  <div class="selection-footer">
    @if (form) {
      <form [formGroup]="form" class="voucher-form">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label class="placeholder">{{
              "APP.VOUCHERS.VOUCHER_CODE" | translate
            }}</mat-label>
          <input formControlName="voucherCode" type="text" matInput />
          @if (voucherApplied) {
            <div matSuffix class="suffix-actions">
              <div class="success-code">
                <i class="fas fa-check-circle"></i>
              </div>
              <div
                class="remove-button"
                [matTooltip]="'APP.REMOVE' | translate"
                (click)="removeCode()"
              >
                <i class="far fa-trash"></i>
              </div>
            </div>
          } @else {
            <div matSuffix class="suffix-actions">
              @if (unrecognizedCode) {
                <div class="danger-code">
                  <i class="fas fa-minus-circle"></i>
                </div>
              } @else {
                @if(form.controls['voucherCode'].value && form.controls['voucherCode'].value.trim() !== ''){
                  <div
                    matSuffix
                    class="apply-button"
                    [matTooltip]="'APP.APPLY' | translate"
                    (click)="applyCode()"
                  >
                    {{ "APP.APPLY" | translate }}
                  </div>
                }

              }
            </div>
          }
          @if (unrecognizedCode) {
            <mat-hint class="code-hint danger text">{{
                "APP.VOUCHERS.ERRORS.UNRECOGNIZED_CODE" | translate
              }}</mat-hint>
          }
          @if (voucherApplied) {
            <mat-hint class="code-hint text">
              {{ voucherApplied.codeName }}
              {{ "APP.VOUCHERS.APPLIED" | translate }}
            </mat-hint>
          }
          @if (!voucherApplied && hasPublicCode) {
            <mat-hint
              class="code-hint text apply-public"
              (click)="getPublicCode()"
            >
              {{ "APP.VOUCHERS.APPLY_PUBLIC_CODE" | translate }}
            </mat-hint>
          }
        </mat-form-field>
      </form>
    }
      @if (createOrderTicketsMap()) {
        @for (ticket of createOrderTicketsMap() | keyvalue; track $index) {
          <div class="order-item-wrapper">
            <div class="item">
              <div>
                {{ ticket.value[0].quantity }} x
                {{ ticket.key.name }}
              </div>
              <!-- <div class="date">{{ attendeeTicket.ticket.eventTicket?.day | date: 'EEE. dd. MMMM' }}</div>-->
            </div>

            <div class="price">
              @if (checkIfDiscountApplied(ticket.key)) {
                {{ calculateDiscount(ticket.key, ticket.value[0].quantity) }}
              } @else {
                {{
                  NumberUtils.roundToUpTo2Decimals(
                    (ticket.value[0].quantity * ticket.key.price) / 100.0
                  )
                }}
                &#8364;
              }
            </div>
          </div>
        }
        @if (!noSessionTickets) {
          <div class="divider"></div>
        }
      }

    <div class="total">
      {{ "APP.VOUCHERS.TOTAL" | translate }}:
      <span class="price"
        >{{ NumberUtils.roundToUpTo2Decimals(fullPrice / 100.0) }} &#8364;</span
      >
    </div>
  </div>
</ng-template>

<ng-template #ticketsSelection>
  @for (ticket of filteredTickets; track ticket.id) {
    <app-event-ticket-selection-view
      [isSoldOut]="checkIfSoldOut(ticket)"
      [ticket]="ticket"
      [orderTickets]="orderTickets"
      [ticketsWithAppliedDiscount]="ticketsWithAppliedDiscount"
      [appliedDiscount]="checkIfDiscountApplied(ticket)"
      [appliedVoucher]="voucherApplied"
      [ticketQuantity]="ticket.id ? getTicketQuantity(ticket.id) : 0"
      [currentTicketQuantity]="currentTicketQuantity"
      (addTicket)="addTicket($event)"
      (removeTicket)="removeTicket($event)"
    />
  }
</ng-template>

<ng-template #payment>
  <app-event-payment
    [isPreview]="isPreview"
    [event]="event"
    [ticketOrder]="ticketOrder"
    [mainImage]="mainImage"
    [voucher]="voucherApplied"
    [isGeneric]="true"
    [canPay]="!checkIfNoTickets()"
    [updatedSessionRequest]="updatedSessionRequest"
    (nextStep)="onPaymentSuccess($event)"
    (paymentFailed)="onPaymentFailed($event)"
  />
</ng-template>
