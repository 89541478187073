import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable } from 'rxjs';
import { EventAttendee } from '@models/event-attendance/event-attendee.model';
import { Helpers } from '@utils/helpers';

export class EventAttendeeFeDataSource implements DataSource<EventAttendee> {
  private eventAttendeesSubject = new BehaviorSubject<EventAttendee[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private numRecordsSubject = new BehaviorSubject<number>(0);

  numRecords$ = this.numRecordsSubject.asObservable();
  loading$ = this.loadingSubject.asObservable();

  data!: EventAttendee[];

  constructor() {}
  connect(_: CollectionViewer): Observable<EventAttendee[]> {
    return this.eventAttendeesSubject.asObservable();
  }

  disconnect(_: CollectionViewer): void {
    this.eventAttendeesSubject.complete();
    this.numRecordsSubject.complete();
    this.loadingSubject.complete();
  }

  filterEventAttendees(
    eventAttendees: EventAttendee[],
    filters: Map<string, any>,
    pageNumber = 1,
    pageSize = 10,
  ) {
    this.loadingSubject.next(true);

    this.eventAttendeesSubject.next(
      Helpers.paginateArray(eventAttendees, pageNumber, pageSize),
    );
    this.numRecordsSubject.next(eventAttendees.length);

    this.data = eventAttendees;

    this.loadingSubject.next(false);
  }
}
