export interface EventAttendeesExportRequest {
  type: EventAttendeesExportType;
  userLanguage?: string;
  eventAttendeesForExport: EventAttendeeForExport[];
}

export interface EventAttendeeForExport {
  name: string;
  status: string;
  teamName: string;
  email: string;
  phone: string;
  rsvpDate: string;
  plusOnes: string;
  questionColumns?: EventAttendeeExportQuestionColumn[];
}

export interface EventAttendeeExportQuestionColumn {
  label: string;
  value: string;
}

export enum EventAttendeesExportType {
  NORMAL = 'NORMAL',
  ALL_GUESTS = 'ALL_GUESTS',
  QUESTIONNAIRES = 'QUESTIONNAIRES',
}
