import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModules } from '@utils/shared-modules';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { EventWidget } from '@models/events/event-widget.enum';

@Component({
  selector: 'app-widgets-select',
  standalone: true,
  imports: [
    CommonModule,
    FormsModules,
    TranslateModule,
    MatChipsModule,
    MatCheckboxModule,
  ],
  templateUrl: './widgets-select.component.html',
  styleUrl: './widgets-select.component.scss',
})
export class WidgetsSelectComponent implements OnInit, OnDestroy {
  private _items: EventWidget[] = [];
  private valueChangesSet = false;

  @Input() form!: FormGroup;
  @Input() selectedFormControlName: string = '';
  @Input() set items(items: EventWidget[]) {
    if (items.length > 0) {
      this._items = items;
      if (!this.valueChangesSet) {
        this.filteredItems = this.inputControl.valueChanges.pipe(
          startWith<string | null>(''),
          map((value) => (typeof value === 'string' ? value : this.lastFilter)),
          map((filter) => this.filter(filter)),
        );
      }
    }
  }

  get items(): EventWidget[] {
    return this._items;
  }

  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();

  constructor(private translateService: TranslateService) {}

  inputControl = new FormControl<string>('');

  selectedItems: EventWidget[] = new Array<EventWidget>();

  filteredItems: Observable<EventWidget[]> = new Observable();
  lastFilter: string = '';

  ngOnInit(): void {
    this.selectedItems = this.form.controls[this.selectedFormControlName].value;

    this.form.controls[this.selectedFormControlName].valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe((val) => (this.selectedItems = val));
  }

  filter(filter: string): EventWidget[] {
    this.lastFilter = filter;
    if (filter) {
      return this.items.filter((option) => {
        const label = this.translateService.instant(
          EventWidget.getDisplayName(option),
        );
        return label.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
      });
    } else {
      return this.items.slice();
    }
  }

  optionClicked(event: Event, item: EventWidget) {
    event.stopPropagation();
    this.toggleSelection(item);
  }

  toggleSelection(item: EventWidget) {
    if (!this.isSelected(item)) {
      this.selectedItems.push(item);
    } else {
      this.remove(item, false);
    }

    this.inputControl.setValue('');
    this.form.controls[this.selectedFormControlName].setValue(
      this.selectedItems,
    );
  }

  remove(item: EventWidget, updateFormValue = true): void {
    const index = this.selectedItems.findIndex(
      (selectedItem) => selectedItem === item,
    );

    if (index >= 0) {
      this.selectedItems.splice(index, 1);

      if (updateFormValue) {
        this.form.controls[this.selectedFormControlName].setValue(
          this.selectedItems,
        );
      }
    }
  }

  isSelected(item: EventWidget): boolean {
    return !!this.selectedItems.find((selectedItem) => selectedItem === item);
  }

  displayFn(_: string): string {
    return '';
  }

  getDisplayName(item: EventWidget) {
    return EventWidget.getDisplayName(item);
  }

  get selectedFormControl(): AbstractControl {
    return this.form.controls[this.selectedFormControlName];
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
