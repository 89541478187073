<div class="page-container auth-page-center" [class.community-view]='community'>
  @if(community){
    <img class="community-logo" [src]="community.logoUrl" alt="Community Logo" />
  }
  @if (showSuccess) {
  <h1>{{ "APP.AUTH.PASSWORD_SET" | translate }}</h1>
  <div class="subheader subheader-sm">
    {{ "APP.AUTH.PASSWORD_SET_SUBHEADER" | translate }}
  </div>

  <div class="form-380">
    <button
      type="button"
      class="btn btn-primary-party-page"
      (click)="goToLogin()"
    >
      {{ "APP.AUTH.LOGIN" | translate }}
    </button>
  </div>
  } @else {
  <h1>{{ "APP.AUTH.RESET_PASSWORD_TITLE" | translate }}</h1>
  <div class="subheader subheader-sm">
    {{ "APP.AUTH.RESET_PASSWORD_SUBHEADER" | translate }}
  </div>

  @if (form) {
  <div class="form-380">
    <form class="form" [formGroup]="form" (keyup.enter)="resetPassword()">
      <mat-form-field
        class="w-100"
        [class.has-value]="form.controls['password'].value !== ''"
        appearance="outline"
      >
        <mat-label>{{ "APP.AUTH.PASSWORD" | translate }}</mat-label>
        <input
          type="password"
          matInput
          formControlName="password"
          placeholder=""
        />
        @if (form.controls['password'].hasError('custom')) {
        <mat-error>
          {{ form.controls["password"].getError("custom") }}
        </mat-error>
        } @else if (form.controls['password'].hasError('strong') &&
        !form.controls['password'].hasError('required')) {
        <mat-error>
          {{ "APP.AUTH.ERRORS.PASSWORD_INVALID" | translate }}
        </mat-error>
        } @else if(form.controls['password'].hasError('required')) {
        <mat-error
          >{{ "APP.AUTH.ERRORS.PASSWORD_REQUIRED" | translate }}</mat-error
        >
        }
      </mat-form-field>
      <mat-form-field
        class="w-100"
        [class.has-value]="form.controls['confirmPassword'].value !== ''"
        appearance="outline"
      >
        <mat-label>{{ "APP.AUTH.CONFIRM_PASSWORD" | translate }}</mat-label>
        <input
          type="password"
          matInput
          formControlName="confirmPassword"
          placeholder=""
        />
        @if (form.controls['confirmPassword'].hasError('custom')) {
        <mat-error>
          {{ form.controls["confirmPassword"].getError("custom") }}
        </mat-error>
        } @else if(form.controls['confirmPassword'].hasError('required')) {
        <mat-error
          >{{ "APP.AUTH.ERRORS.PASSWORD_REQUIRED" | translate }}</mat-error
        >
        } @else if(form.controls['confirmPassword'].hasError('notEqualTo')) {
        <mat-error
          >{{ "APP.AUTH.ERRORS.CONFIRM_PASSWORD_ERROR" | translate }}</mat-error
        >
        }
      </mat-form-field>
      <div class="action-btn-container">
        <button
          type="button"
          [class.btn-community]='community'
          class="btn btn-primary-party-page"
          (click)="resetPassword()"
          [disabled]="inProgress"
          [class.spinner]="inProgress"
        >
          {{ "APP.AUTH.RESET_PASSWORD_TITLE" | translate }}
        </button>
      </div>
    </form>
  </div>
  } }
</div>
