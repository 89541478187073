export class AppRoutes {
  static Root = class {
    static signUp = 'sign-up';
    static login = 'login';
    static forgotPassword = 'forgot-password';
    static resetPassword = 'reset-password';
    static success = 'success';
    static ssoSuccess = 'sso-success'
  };
  static LandingPage = class {
    static landingPage = 'landing/:uri';
  };
  static Events = class {
    static events = 'events';
    static eventByUri = 'events/:uri';
    static eventSellTickets = 'events/:uri/sell-tickets';
    static create = 'create';
    static createEvent = 'create-event';
    static createCampainEvent = 'create-event/:campaignUri';
    static campaignEventVote = 'c/:campaignUri/voting/:eventUri';
    static mizunoTermsOfUse = 'mizuno-terms-of-use';
    static underarmourTermsOfUse = 'underarmour-terms-of-use';
    static eventPayment = 'event/payment';
  };
  static DesignTemplates = class {
    static designTemplates = 'design-templates';
    static designTemplatePreview = 'design-templates/:uri';
  };
  static Customer = class {
    static dashboard = 'dashboard';
    static welcome = 'welcome';
    static Events = class {
      static events = 'events';
    };
    static guests = 'guests';
    static templates = 'templates';
    static settings = 'settings';
    static profile = 'profile';
    static eventLink = 'eventlink.bio';
    static eventLinkUrl = 'eventlink.bio/:url';
    static domainsAndSender = 'domains-and-senders';
    static communityProfile = 'community/profile';
    static communityEvents = 'community/events';
    static community = 'community';
    static communityUrl = 'community/:url';
    static communities = 'communities/:url';
    static communitiesUrlAction = 'communities/:url/:action';
    static communitiesOverview = 'communities';
    static communityManagement = 'communities/:url/manage';
  };
  static Admin = class {
    static root = 'admin';
    static revenue = 'revenue';
    static locations = 'locations';

    static Users = class {
      static root = 'users';
    };

    static Events = class {
      static root = 'events';
    };

    static Affiliates = class {
      static affiliates = 'affiliates';
      static affiliateDetails = 'affiliates/:id';
      static affiliateStatistics = 'affiliate-statistics';
    };

    static DesignTemplates = class {
      static designTemplates = 'design-templates';
      static designTemplateDetails = 'design-templates/:id';
      static designTemplateStatistics = 'design-template-statistics';
    };

    static Campaigns = class {
      static root = 'campaigns';
      static campaignDetails = 'campaigns/:uri';
    };

    static TemplateAssets = class {
      static templateAssets = 'template-assets';
    };

    static dataSet = 'dataset';
    static prompts = 'prompts';
    static profile = 'profile';
  };
}
