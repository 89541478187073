import { Injectable } from '@angular/core';
import { CommunityMember } from '@models/communities/community-member.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { Community } from '@models/communities/community.model';
import { CommunityService } from '@services/communities/community.service';

@Injectable()
export class CommunityStore {
  private _community: BehaviorSubject<Community | undefined> =
    new BehaviorSubject<Community | undefined>(undefined);

  private _communityMember: BehaviorSubject<CommunityMember | undefined> =
    new BehaviorSubject<CommunityMember | undefined>(undefined);

  private _errorMessage: BehaviorSubject<string | undefined> =
    new BehaviorSubject<string | undefined>(undefined);

  public readonly community: Observable<Community | undefined> =
    this._community.asObservable();

  public readonly communityMember: Observable<CommunityMember | undefined> =
    this._communityMember.asObservable();

  public readonly errorMessage: Observable<string | undefined> =
    this._errorMessage.asObservable();

  constructor(private communityService: CommunityService) {}

  getCommunityByUri(uri: string, lsParams?: Map<string, any>) {
    this.communityService.getCommunityByUri(uri).subscribe({
      next: (community) => {
        this._community.next(community);
      },
      error: (error) => {
        if (error?.error) {
          this._errorMessage.next(error.error);
        }
      },
    });
  }

  getCommunityByDomain(domain: string, lsParams?: Map<string, any>) {
    this.communityService.getCommunityByDomain(domain).subscribe({
      next: (community) => {
        this._community.next(community);
      },
      error: (error) => {
        if (error?.error) {
          this._errorMessage.next(error.error);
        }
      },
    });
  }

  setCommunity(community: Community) {
    this._community.next(community);
  }

  setCommunityMember(member?: CommunityMember) {
    this._communityMember.next(member);
  }

  refreshCommunity(communityUri: string) {
    let lsParams = new Map<string, any>();

    const userLang = localStorage.getItem('userLang');
    if (userLang) {
      lsParams.set('userLang', userLang);
    }

    this.getCommunityByUri(communityUri, lsParams);
  }
}
