import {
  Component,
  Inject,
  Input,
  OnChanges,
  OnInit,
  PLATFORM_ID,
  SimpleChanges,
} from '@angular/core';
import { CommonModule, isPlatformServer } from '@angular/common';
import { EventAttendeeStatus } from '@models/event-attendance/event-attendee-status.enum';
import { TranslateModule } from '@ngx-translate/core';
import { AuthService } from '@services/auth/auth.service';
import { FooterComponent } from 'app/components/footer/footer.component';
import { EventTemplateVisual } from '@models/design-templates/event-template-visual.model';
import { EventTemplateFont } from '@models/design-templates/event-template-font.model';
import { Event } from '@models/events/event.model';
import { FontService } from '@services/shared/font.service';
import { ScreenWidthService } from '@services/shared/screen-width.service';
import { User } from '@models/users/user.model';
import { AddToCalendarDialog } from '@modules/shared/dialogs/add-to-calendar/add-to-calendar.dialog';
import { MatDialog } from '@angular/material/dialog';
import { EventService } from '@services/events/event.service';
import { EventStore } from '@services/stores/event.store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Helpers } from '@utils/helpers';
import { EventWidget } from '@models/events/event-widget.enum';
import { CampaignEventRankingsResponse } from '@models/campaigns/dto/event-campaign-rating.response';
import { EventSection } from '@models/events/event-section.model';
import { EventSectionComponent } from '../event-section/event-section.component';
import { ScrollToModule, ScrollToService } from '@hungthuanmk/ngx-scroll-to';

@Component({
  selector: 'app-weddingpage-event',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ScrollToModule,
    FooterComponent,
    EventSectionComponent,
  ],
  providers: [EventService, FontService, ScreenWidthService, ScrollToService],
  templateUrl: './weddingpage-event.component.html',
  styleUrl: './weddingpage-event.component.scss',
})
export class WeddingpageEventComponent implements OnInit, OnChanges {
  isServer = false;

  private _event?: Event;
  @Input() isVotingView?: boolean = false;

  @Input() set event(event: Event) {
    this._event = event;

    if (this.font) {
      const primaryLogoFont = this.font.primaryLogoFont;
      const bodyFont = this.font.bodyFont;
      const fontFamilies: string[] = [];
      if (primaryLogoFont) {
        fontFamilies.push(primaryLogoFont);
      }
      if (bodyFont) {
        fontFamilies.push(bodyFont);
      }
      fontFamilies.push('Bodoni Moda');

      this.fontService.updateFonts(fontFamilies);

      Helpers.setEventFonts(
        this.font.primaryLogoFont,
        this.font.bodyFont,
        this.font.bodyFontWeight,
      );
    }
  }

  get event(): Event | undefined {
    return this._event;
  }

  @Input() isHostView = false;

  @Input() loggedUser?: User;

  isAfterCreation = false;
  eventActionsSticky = false;
  isDesktop = true;
  isNotAttendingUser?: boolean;
  availableCapacity?: number;
  showMaxCapacity?: boolean;
  isCapacity?: boolean;
  isCampaign?: boolean = false;
  campaignData?: CampaignEventRankingsResponse;
  userOnWaitList: boolean = false;

  constructor(
    private eventService: EventService,
    private eventStore: EventStore,
    private fontService: FontService,
    private screenWidthService: ScreenWidthService,
    private dialog: MatDialog,
    private authService: AuthService,
    private scrollToService: ScrollToService,
    @Inject(PLATFORM_ID) platformId: Object,
  ) {
    this.isServer = isPlatformServer(platformId);

    this.eventStore.event.pipe(takeUntilDestroyed()).subscribe((event) => {
      if (event) {
        this.event = event;
      }
    });
    this.screenWidthService
      .isDesktop()
      .pipe(takeUntilDestroyed())
      .subscribe({
        next: (isDesktop: boolean) => {
          this.isDesktop = isDesktop;
        },
      });
    this.screenWidthService
      .isOver992()
      .pipe(takeUntilDestroyed())
      .subscribe({
        next: (isOver992: boolean) => {
          this.eventActionsSticky = !isOver992;
        },
      });

    this.authService.userSubject
      .pipe(takeUntilDestroyed())
      .subscribe((user) => {
        if (user.id > 0) {
          this.loggedUser = user;
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.font && this.isVotingView) {
      Helpers.setEventTheme(
        this.font.primaryLogoFontColor,
        this.font.bodyFontColor,
        this.visual?.backgroundColor,
      );
    }
  }

  ngOnInit(): void {
    const foundAttendee = this.event?.attendees?.find(
      (x) => x.userId === this.loggedUser?.id,
    );
    if (
      foundAttendee &&
      foundAttendee.status === EventAttendeeStatus.ON_WAITLIST
    ) {
      this.userOnWaitList = true;
    }

    this.loggedUser && this.checkIfUserAttending();
    this.calculateMaxCapacity();
    if (this.font && this.isVotingView) {
      Helpers.setEventTheme(
        this.font.primaryLogoFontColor,
        this.font.bodyFontColor,
        this.visual?.backgroundColor,
      );
    }
  }

  calculateMaxCapacity() {
    if (!this.event?.maxCapacity || this.event.maxCapacity === 0) {
      this.showMaxCapacity = false;
      this.isCapacity = true; // There is no limit
    } else {
      const guestsGoing = this.event.getNumYesAttendees() || 0;
      const availablePlaces = this.event.maxCapacity - guestsGoing;

      if (availablePlaces > 0) {
        this.availableCapacity = availablePlaces;
        this.showMaxCapacity = true;
      } else {
        this.showMaxCapacity = false;
      }

      this.isCapacity = this.showMaxCapacity || guestsGoing === 0;
    }
  }
  checkIfUserAttending() {
    const loggedInUserId = this.loggedUser?.id;
    const eventAttendees = this.event?.attendees?.map((x) => x.user?.id);
    const eventHosts = this.event?.hosts?.map((x) => x.user?.id);

    const isNotHost = this.event?.hostUserId !== loggedInUserId;
    const isNotCoHost = !eventAttendees?.includes(loggedInUserId);
    const isNotAttendee = !eventHosts?.includes(loggedInUserId);

    this.isNotAttendingUser = isNotHost && isNotCoHost && isNotAttendee;
  }

  onRefreshEvent() {
    if (this.event) {
      const params = new Map<string, string>();
      const userLang = localStorage.getItem('userLang');
      if (userLang) {
        params.set('userLang', userLang);
      }
      this.eventService.getByUri(this.event.uri, params).subscribe({
        next: (event) => {
          this.event = event;
        },
      });
    }
  }

  shareEvent() {
    if (this.event) {
      window.navigator.share({
        text: this.event.description,
        title: this.event.name,
        url: this.event.getLink(),
      });
    }
  }

  addToCalendar() {
    this.dialog.open(AddToCalendarDialog, {
      maxWidth: '602px',
      maxHeight: '100vh',
      width: '100%',
      height: 'auto',
      data: this.event,
      panelClass: ['normal-dialog', 'event-dialog', 'overlay-theme'],
    });
  }

  goToRSVP() {
    this.scrollToService.scrollTo({
      target: 'rsvp-section',
    });
  }

  get sections(): EventSection[] | undefined {
    return this.event?.eventTemplate?.getEventSectionsOrdered();
  }

  get visual(): EventTemplateVisual | undefined {
    return this.event?.eventTemplate?.visual;
  }

  get font(): EventTemplateFont | undefined {
    return this.event?.eventTemplate?.font;
  }

  get isAttendeeView(): boolean {
    return (
      !!this.loggedUser &&
      !!this.event?.getUserAttendeeRSVPOption(this.loggedUser.id) &&
      this.event.isNonPendingAttendee(this.loggedUser.id)
    );
  }

  get widgets(): EventWidget[] | undefined {
    return this.event?.eventTemplate?.widgets;
  }

  get isUserHost(): boolean {
    return (
      !!this.loggedUser &&
      !!this.loggedUser.id &&
      !!this.event &&
      (this.event.isUserHost(this.loggedUser.id) ||
        this.event.isNotLoggedUserHost())
    );
  }

  protected readonly EventWidget = EventWidget;
}
