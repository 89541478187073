import { Injectable } from '@angular/core';
import { environment as env } from '@environments/environment';
import { PageViewRequest } from '@models/trackings/dto/page-view.request';
import { ApiService } from '@services/api.service';
import { Observable } from 'rxjs';

@Injectable()
export class TrackingService {
  constructor(private api: ApiService) {}

  createPageView(request: PageViewRequest): Observable<any> {
    return this.api.post(`${env.api}/tracking/page-views`, request);
  }
}
