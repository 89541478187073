import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Community } from '@models/communities/community.model';
import { PasswordResetRequest } from '@models/users/dto/password-reset.request';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommunityStore } from '@services/stores/community.store';
import { FormsModules } from '@utils/shared-modules';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from '@services/auth/auth.service';
import { finalize } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { jwtDecode } from 'jwt-decode';
import { sameAsFormControl } from 'app/validators/same-as-form-control.validator';
import { PasswordValidator } from 'app/validators/password.validator';
import { AppRoutes } from 'app/routes';
import { CommunityPageType } from '@models/communities/community-landing-page.model';
import { Helpers } from '@utils/helpers';
import { FontService } from '@services/shared/font.service';

@Component({
  selector: 'app-reset-password',
  standalone: true,
  imports: [CommonModule, TranslateModule, FormsModules],
  templateUrl: './reset-password.page.html',
  styleUrl: './reset-password.page.scss',
})
export class ResetPasswordPage implements OnInit {
  private token: string = '';
  email: string = '';
  form?: FormGroup;
  inProgress = false;
  showSuccess = false;
  community?: Community;
  isDesktop?: boolean;
  constructor(
    private authService: AuthService,
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private communityStore: CommunityStore,
    private fontService: FontService,
  ) {
    this.communityStore.community
      .pipe(takeUntilDestroyed())
      .subscribe((community) => {
        this.community = community;
        this.setCommunityPage();
      });
    this.activatedRoute.url.subscribe((segments) => {
      if (segments && segments.length > 0 && segments[0].path === 'success') {
        this.showSuccess = true;
      } else {
        this.form = new FormGroup({
          password: new FormControl('', [
            Validators.required,
            PasswordValidator.strong,
          ]),
          confirmPassword: new FormControl('', [
            Validators.required,
            sameAsFormControl('password'),
          ]),
        });
      }
    });
  }
  ngOnInit(): void {
    this.token = this.activatedRoute.snapshot.queryParams['token'];
    const decodedToken = jwtDecode<any>(this.token);
    this.email = decodedToken.email;
  }

  resetPassword(): void {
    if (!this.form) {
      return;
    }
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    this.inProgress = true;
    let request: PasswordResetRequest = {
      password: this.form.controls['password'].value,
      token: this.token,
    };

    if (this.community) {
      request = {
        ...request,
        communityUri: this.community.uri,
      };
    }
    this.authService
      .resetPassword(request)
      .pipe(finalize(() => (this.inProgress = false)))
      .subscribe({
        next: () => {
          this.router.navigate([AppRoutes.Root.success], {
            relativeTo: this.activatedRoute,
            queryParamsHandling: 'merge',
          });
        },
        error: () => {
          this.form &&
            this.form.controls['email'].setErrors({
              custom: this.translateService.instant(
                'APP.AUTH.ERRORS.RESET_PASSWORD_ERROR',
              ),
            });
        },
      });
  }

  goToLogin(): void {
    this.router.navigate(['/', AppRoutes.Root.login]);
  }

  private setCommunityPage() {
    const landingPage = this.community?.getPage(CommunityPageType.LANDING);
    if (landingPage) {
      Helpers.setCommunityTheme(
        landingPage.backgroundColor,
        landingPage.font?.primaryLogoFontColor,
        landingPage.font?.bodyFontColor,
      );

      const font = landingPage.font;

      if (font) {
        const primaryLogoFont = font.primaryLogoFont;
        const bodyFont = font.bodyFont;
        const fontFamilies: string[] = [];
        if (primaryLogoFont) {
          fontFamilies.push(primaryLogoFont);
        }
        if (bodyFont) {
          fontFamilies.push(bodyFont);
        }

        this.fontService.updateFonts(fontFamilies);

        Helpers.setEventFonts(
          font.primaryLogoFont,
          font.bodyFont,
          font.bodyFontWeight,
        );
      }
    }
  }
}
