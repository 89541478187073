@if (sponsors) {
  <div class="sponsors-swiper">
    @if (isEdit) {
      <div
        cdkDropList
        cdkDropListOrientation="horizontal"
        (cdkDropListDropped)="drop($event, draggedSponsor)"
        class="sponsor-drop-list horizontal-scroll-container"
      >
        <button
          class="btn btn-primary add-button"
          (click)="onConfigureSection()"
        >
          <i class="far fa-plus"></i>
        </button>

        <div
          *ngFor="let sponsor of sponsors; trackBy: trackByFn"
          class="testimonial-item"
          cdkDrag
          (cdkDragStarted)="draggedSponsor = sponsor"
        >
          <app-event-section-sponsor-item [sponsor]="sponsor" />
          <div cdkDragHandle class="grab-action">
            <i class="fas fa-grip-vertical"></i>
          </div>
          <div class="update-actions-menu">
            <div class="menu-trigger" [matMenuTriggerFor]="menu">
              <i class="far fa-ellipsis-v"></i>
            </div>
            <mat-menu #menu="matMenu" class="table-actions-container">
              <button
                mat-menu-item
                (click)="onUpdate(UpdateActions.EDIT, sponsor)"
              >
                <span class="menu-item-content">
                  <i class="far fa-pen"></i>
                  <span class="text">{{
                    "APP.EDIT" | translate | camelCase
                  }}</span>
                </span>
              </button>

              <button
                mat-menu-item
                (click)="onUpdate(UpdateActions.COPY, sponsor)"
              >
                <span class="menu-item-content">
                  <i class="far fa-copy"></i>
                  <span class="text">{{
                    "APP.COPY" | translate | camelCase
                  }}</span>
                </span>
              </button>

              <button
                mat-menu-item
                (click)="onUpdate(UpdateActions.DELETE, sponsor)"
              >
                <span class="menu-item-content">
                  <i class="far fa-trash"></i>
                  <span class="text">{{
                    "APP.DELETE" | translate | camelCase
                  }}</span>
                </span>
              </button>
            </mat-menu>
          </div>
        </div>
      </div>
    } @else {
      <swiper-container appSwiper [config]="swiperConfig" init="false">
        @for (sponsor of sponsors; track sponsor.id) {
          <swiper-slide>
            <app-event-section-sponsor-item [sponsor]="sponsor" />
          </swiper-slide>
        }
      </swiper-container>
    }
  </div>
}
