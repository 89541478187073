<nav
  class="navbar"
  [class.shadow]="lastTopPosition > 0"
  [class.hidden]="isDesktop || (!isDesktop && !navbarDisplayed)"
  [class.lower-z-index]="isLowerZIndexPage"
>
  <div class="container header-container">
    <div class="header-left">
      <div
        class="hamburger"
        [class.close]="sidenavOpen"
        (click)="toggleSidenav()"
      >
        <span class="line"></span>
        <span class="line"></span>
        <span class="line"></span>
      </div>

      @if (selectedCommunity) {
        <a class="navbar-brand" routerLink="/community">
          <img
            class="community-logo"
            [src]="selectedCommunity.logoUrl"
            alt="Community Logo"
          />
        </a>
      } @else {
        <a class="navbar-brand" routerLink="/">
          <img
            class="media-object header-logo"
            [src]="env.iconAsset"
            alt="eventpage.ai Logo"
          />
        </a>
      }
    </div>
    <div class="header-right">
      <div class="user-actions">
        @if (!selectedCommunity) {
          <i
            class="far fa-ticket-alt"
            (click)="onOpenReferralInviteDialog()"
          ></i>
        }
        @if (selectedCommunity && !isOwnerView) {
          <app-internal-sidenav-user-icon
            [menuData]="getCommunityMemberData()"
            [themeModeAvailable]="!selectedCommunity.hasPresetThemeMode()"
          ></app-internal-sidenav-user-icon>
        } @else {
          <app-internal-sidenav-user-icon></app-internal-sidenav-user-icon>
        }
      </div>
    </div>
  </div>
  <div class="navbar-nav" [class.hidden]="!sidenavOpen">
    @for (routeItem of routes; track routeItem.name) {
      @if (!routeItem.children?.length) {
        <a
          [routerLink]="
            selectedCommunity
              ? '/community/' + routeItem.route
              : routeItem.route
          "
          (click)="onRouteClick()"
          class="menu-item"
          [class.active]="isRouteActive(routeItem.route)"
        >
          @if (routeItem.iconClasses) {
            <i [class]="routeItem.iconClasses"></i>
          }

          <span class="menu-item-label"
            >{{ routeItem.name }}
            @if (routeItem.subtitle) {
              <span class="menu-item-label-small">{{
                routeItem.subtitle
              }}</span>
            }
          </span>
        </a>
      } @else {
        <a
          (click)="onParentRouteClick(routeItem)"
          class="menu-item parent-menu-item"
          [class.parent-opened]="routeItem.opened"
        >
          @if (routeItem.iconClasses) {
            <i [class]="routeItem.iconClasses"></i>
          }

          <span class="menu-item-label"
            >{{ routeItem.name }}
            @if (routeItem.subtitle) {
              <span class="menu-item-label-small">{{
                routeItem.subtitle
              }}</span>
            }
          </span>

          <i
            class="fal fa-angle-{{ routeItem.opened ? 'up' : 'down' }} toggle"
          ></i>
        </a>

        @if (routeItem.opened) {
          @for (
            childRouteItem of routeItem.children;
            track childRouteItem.name
          ) {
            <a
              [routerLink]="childRouteItem.route"
              (click)="onRouteClick()"
              class="menu-item child-menu-item"
              [class.active]="childRouteItem.route === activeRouteUrl"
            >
              <span class="menu-item-label"
                >{{ childRouteItem.name }}
                @if (childRouteItem.subtitle) {
                  <span class="menu-item-label-small">{{
                    childRouteItem.subtitle
                  }}</span>
                }
              </span>
            </a>
          }
        }
      }
    }
  </div>
</nav>
