import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AuthGoogleService } from '@services/auth/auth-google.service';

@Component({
  selector: 'app-sso-buttons',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './sso-buttons.component.html',
  styleUrl: './sso-buttons.component.scss',
})
export class SsoButtonsComponent {
  @Input() isSignUp = false;

  ssoButtons = [
    {
      provider: 'google',
      icon: './assets/social/google.svg',
      label: 'Google',
    },
  ];

  constructor(private authGoogleService: AuthGoogleService) {}

  loginWith(provider: string) {
    const redirectUri = window.location.pathname;
    if (provider === 'google') {
      this.authGoogleService.login(redirectUri);
    }
  }
}
