import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { CommunityMember } from '@models/communities/community-member.model';
import { Community } from '@models/communities/community.model';
import { EventAttendee } from '@models/event-attendance/event-attendee.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TelInputComponent } from '@modules/shared/components/tel-input/tel-input.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { UserAvatarComponent } from '@modules/shared/components/user-avatar/user-avatar.component';
import { FormsModules } from '@utils/shared-modules';
import { SaveAttendeeRequest } from '@models/events/dto/save-attendee.request';
import { EventService } from '@services/events/event.service';
import { Event } from '@models/events/event.model';
import { EventStore } from '@services/stores/event.store';
import { NotificationService } from '@services/shared/notification.service';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import moment from 'moment/moment';

@Component({
  selector: 'app-general-details',
  standalone: true,
  imports: [
    CommonModule,
    FormsModules,
    MatTooltipModule,
    TranslateModule,
    TelInputComponent,
    UserAvatarComponent,
  ],
  providers: [EventService, NotificationService, EventStore],
  templateUrl: './general-details.component.html',
  styleUrl: './general-details.component.scss',
})
export class GeneralDetailsComponent {
  private _attendee?: EventAttendee;
  private _communityMember?: CommunityMember;

  @Input() event?: Event;

  @Input()
  set attendee(attendee: EventAttendee | undefined) {
    this._attendee = attendee;

    this.form = undefined;
    this.cd.detectChanges();

    if (!this.attendee) {
      return;
    }

    if (this.attendee.userId) {
      this.form = this.fb.group({
        name: [
          {
            value: this.attendee.getName() || '',
            disabled: true,
          },
        ],
        email: [
          {
            value: this.attendee.getEmail('') || '',
            disabled: true,
          },
        ],
        phone: [
          {
            value: this.attendee.getPhone('') || '',
            disabled: true,
          },
        ],
      });
    } else {
      this.form = this.fb.group({
        firstName: [this.attendee.firstName || ''],
        lastName: [this.attendee.lastName || ''],
        email: [this.attendee?.getEmail('') || ''],
        phone: [this.attendee?.getPhone('') || ''],
      });
    }
  }

  get communityMember(): CommunityMember | undefined {
    return this._communityMember;
  }

  @Input()
  set communityMember(communityMember: CommunityMember | undefined) {
    this._communityMember = communityMember;

    this.form = undefined;
    this.cd.detectChanges();

    if (!this.communityMember) {
      return;
    }

    if (this.communityMember.user) {
      this.form = this.fb.group({
        name: [
          {
            value: this.communityMember.user.name || '',
            disabled: true,
          },
        ],
        email: [
          {
            value: this.communityMember.user.email || '',
            disabled: true,
          },
        ],
        phone: [
          {
            value: this.communityMember.user.phone || '',
            disabled: true,
          },
        ],
        birthday: [
          {
            value:
              moment(this.communityMember.user.birthday).format('DD.MM.YYYY') ||
              '',
            disabled: true,
          },
        ],
        gender: [
          {
            value: this.communityMember.user.gender || '',
            disabled: true,
          },
        ],
      });
    }
  }

  get attendee(): EventAttendee | undefined {
    return this._attendee;
  }

  form?: FormGroup;

  constructor(
    private eventService: EventService,
    private eventStore: EventStore,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private notificationService: NotificationService,
    private translateService: TranslateService,
  ) {}

  updateState(controlName: string, value: any) {
    if (!this.form || !this.attendee || !this.event) return;

    const formValue = this.form.value;

    const request: SaveAttendeeRequest = {
      [controlName]: formValue[controlName],
    };

    request.attendeeId = this.attendee.id;

    this.eventService.saveAttendee(this.event.id, request).subscribe({
      next: (attendee) => {
        this.attendee = attendee;
        this.eventStore.refreshEvent(this.event!.uri);
      },
      error: (err) => {
        if (
          err?.error ===
          'bad request - an attendee with the new email/phone already exists'
        ) {
          this.notificationService.error(
            this.translateService.instant(
              'APP.EVENT_RSVP.ERRORS.SAVE_ATTENDEE_EXISTS',
            ),
          );
        } else {
          this.notificationService.error(
            this.translateService.instant('APP.ERRORS.COULD_NOT_SAVE'),
          );
        }
      },
    });
  }
}
