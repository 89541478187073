import { ClassData } from '@models/class-data';
import { EventTemplateFont } from '@models/design-templates/event-template-font.model';
import { EventTemplateSeo } from '@models/design-templates/event-template-seo.model';
import { EventTemplateAsset } from '@models/events/event-template-asset.model';
import { Helpers } from '@utils/helpers';

export enum CommunityPageType {
  LANDING = 'LANDING',
  EVENTS = 'EVENTS',
}

export class CommunityPage {
  id: number;
  title?: string;
  description?: string;
  backgroundId?: number;
  background?: EventTemplateAsset;
  backgroundColor?: string;
  customBackgroundUrl?: string;
  customBackgroundMobileUrl?: string;
  titleColor?: string;
  bodyColor?: string;
  effectId?: number;
  effect?: EventTemplateAsset;
  seo?: EventTemplateSeo;
  seoId?: number;
  font?: EventTemplateFont;
  type: CommunityPageType;
  constructor(json: ClassData<CommunityPage>) {
    this.id = json.id;
    this.title = json.title;
    this.description = json.description;
    if (json.backgroundId) {
      this.backgroundId = +json.backgroundId;
    }
    if (json.background) {
      this.background = new EventTemplateAsset(json.background);
    }

    if (this.seoId) {
      this.seoId = json.seoId;
    }

    if (json.seo) {
      this.seo = new EventTemplateSeo(json.seo);
    }
    this.backgroundColor = json.backgroundColor;
    this.customBackgroundUrl = json.customBackgroundUrl;
    this.customBackgroundMobileUrl = json.customBackgroundMobileUrl;
    this.titleColor = json.titleColor;
    this.bodyColor = json.bodyColor;
    if (json.effectId !== undefined) {
      this.effectId = +json.effectId;
    }
    if (json.effect) {
      this.effect = new EventTemplateAsset(json.effect);
    }
    if (json.font) {
      this.font = new EventTemplateFont(json.font);
    }

    this.type = json.type;
  }

  isBackgroundImage(): boolean {
    return (
      (!!this.background &&
        Helpers.isImageMimeType(this.background.mimeType)) ||
      (!!this.customBackgroundUrl &&
        !this.customBackgroundUrl.includes('.mp4') &&
        !this.customBackgroundUrl.includes('.webm'))
    );
  }

  isBackgroundVideo(): boolean {
    return (
      (!!this.background &&
        Helpers.isVideoMimeType(this.background.mimeType)) ||
      (!!this.customBackgroundUrl &&
        (!!this.customBackgroundUrl.includes('.mp4') ||
          !!this.customBackgroundUrl.includes('.webm')))
    );
  }

  getEncodedBackground(allowVideo = false): string {
    let backgroundImage = '';
    if (!!this.customBackgroundUrl && this.customBackgroundUrl !== '') {
      if (allowVideo || !this.isBackgroundVideo()) {
        backgroundImage = this.customBackgroundUrl;
      }
    } else if (this.background?.url && this.background?.url !== '') {
      backgroundImage = this.background.url;
    }

    if (
      backgroundImage.includes('unsplash') ||
      backgroundImage.includes('giphy')
    ) {
      return encodeURI(backgroundImage);
    }

    return Helpers.encodeUrl(backgroundImage);
  }
}
