@if (testimonial) {
  <div
    class="testimonial-wrapper"
    [class.advanced-one-column-testimonial]="
      event && event.type === EventType.ADVANCED_ONE_COLUMN
    "
  >
    <div class="avatar">
      <img [src]="testimonial.userAvatarUrl" class="image" />
    </div>
    <div class="user-data">
      <div class="user-name">
        {{ testimonial.userName }}
      </div>
      @if (testimonial.userCity && testimonial.userJobTitle && event && event.type !== EventType.ADVANCED_ONE_COLUMN) {
        <div class="user-data-description">
          {{ testimonial.userJobTitle }}, {{ testimonial.userCity }}
        </div>
      }

      @if (
        event &&
        event.type === EventType.ADVANCED_ONE_COLUMN &&
        testimonial.userJobTitle
      ) {
        <div class="user-data-description">
          {{ testimonial.userJobTitle }}
        </div>
      }

      @if (testimonial.description) {
        <div class="description">
          &#x201F; {{ testimonial.description }} &#x201F;
        </div>
      }
    </div>
  </div>
}
