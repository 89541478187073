export class StringUtils {
  static removeSpecialCharacters(input: string, localization?: string): string {
    input = input.toLowerCase().replace(/ /g, '-');

    input = input
      .replace(/ä/g, 'ae')
      .replace(/ö/g, 'oe')
      .replace(/ü/g, 'ue')
      .replace(/ß/g, 'ss');

    if (localization) {
      if (localization === 'en') {
        input = input.replace(/&/g, 'and');
      } else if (localization === 'de') {
        input = input.replace(/&/g, 'und');
      }
    }

    input = input.replace(/[^a-zA-Z0-9-]+/g, '');

    return input;
  }
}
