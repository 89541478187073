import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Asset, createDataUrl } from '@models/shared/asset.model';
import { MatDialog } from '@angular/material/dialog';
import { ViewerDialog } from '@modules/shared/dialogs/viewer/viewer.dialog';
import { Helpers } from '@utils/helpers';
import { FormsModules } from '@utils/shared-modules';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-image-preview',
  standalone: true,
  imports: [CommonModule, FormsModules, MatTooltipModule, TranslateModule],
  templateUrl: './image-preview.component.html',
  styleUrl: './image-preview.component.scss',
})
export class ImagePreviewComponent {
  createDataUrl = createDataUrl;

  @Input() selectedFile?: Asset;
  @Input() dataEditable = false;
  @Input() simpleMode = false;

  @Output() editFile: EventEmitter<void> = new EventEmitter();
  @Output() deleteFile: EventEmitter<void> = new EventEmitter();
  @Output() updateFile: EventEmitter<Asset> = new EventEmitter();

  constructor(private dialog: MatDialog) {}

  onViewImage(e: Event) {
    this.dialog.open(ViewerDialog, {
      maxWidth: '455px',
      width: 'auto',
      height: 'auto',
      maxHeight: '100vh',
      data: this.selectedFile,
      panelClass: ['viewer-dialog', 'transparent-viewer-dialog'],
    });
    e.preventDefault();
  }

  onEditImage(e: Event) {
    e.preventDefault();
    this.editFile.emit();
  }

  onDeleteFile(e: Event) {
    e.preventDefault();
    this.deleteFile.emit();
  }

  onAssetNameChanged(name: string) {
    if (this.selectedFile) {
      this.selectedFile.name = name;

      this.updateFile.emit(this.selectedFile);
    }
  }

  onAssetTitleChanged(title: string) {
    if (this.selectedFile) {
      this.selectedFile.title = title;

      this.updateFile.emit(this.selectedFile);
    }
  }

  onAssetAltChanged(alt: string) {
    if (this.selectedFile) {
      this.selectedFile.alt = alt;

      this.updateFile.emit(this.selectedFile);
    }
  }

  get isVideo(): boolean {
    if (this.selectedFile?.mimeType) {
      return Helpers.isVideoMimeType(this.selectedFile.mimeType);
    }

    return false;
  }
}
