import { CommunityMember } from '@models/communities/community-member.model';
import { Community } from '@models/communities/community.model';
import { UserRole } from './user-role.enum';
import moment from 'moment';
import { ClassData } from '@models/class-data';
import { UserUseCase } from './user-use-case.enum';
import { UserAddress } from './user-address.model';
import { ShippingData } from './shipping-data.model';
import { Account } from './account.model';
import { environment as env } from '@environments/environment';
import { UserPricingPlan } from '@models/pricing-plans/user-pricing-plan.model';
import { PricingPlanType } from '@models/pricing-plans/pricing-plan-type.enum';
import { BillingAddress } from '@models/payments/billing-address.model';
import { CommunityMemberStatus } from '@models/communities/community-member-status.enum';

export class User {
  id: number;
  uuid?: string;

  createdAt?: Date;

  email: string;
  emailVerified: boolean;

  role: UserRole;

  name: string;

  phone: string;
  phoneVerified: boolean;

  avatarUrl: string;
  gender: string;
  birthday?: Date;

  description: string;
  profession?: string;

  address?: UserAddress;

  shippingDataId?: number;
  shippingData?: ShippingData;

  useCase?: UserUseCase;

  accountId?: number;
  account?: Account;

  hasPublishedEvent?: boolean;

  language: string;
  platform: string;

  isB2B: boolean;

  referralCode?: string;

  referredByUser?: User;

  pricingPlan?: UserPricingPlan;

  stripeCustomerId?: string;

  billingAddressId?: number;
  billingAddress?: BillingAddress;

  // used for admin user list
  numHostingEvents?: number;
  numCoHostingEvents?: number;
  numAttendingEvents?: number;

  // account info
  hasPassword?: boolean;
  hasGoogleUser?: boolean;
  communitiesOwned?: Community[];
  communitiesMemberOf?: CommunityMember[];

  country?: string;

  constructor(json: ClassData<User>) {
    this.id = +json.id;

    this.uuid = json.uuid;

    if (json.createdAt) {
      this.createdAt = new Date(json.createdAt);
    }

    this.email = json.email;
    this.emailVerified = !!json.emailVerified;

    this.role = json.role;

    this.name = json.name;

    this.phone = json.phone;
    this.phoneVerified = json.phoneVerified;
    this.country = json.country;

    this.avatarUrl = json.avatarUrl;
    this.gender = json.gender;
    if (json.birthday) {
      this.birthday = new Date(json.birthday);
    }

    this.description = json.description;
    this.profession = json.profession;

    if (json.shippingDataId) {
      this.shippingDataId = +json.shippingDataId;
    }
    if (json.shippingData) {
      this.shippingData = new ShippingData(json.shippingData);
    }

    this.useCase = json.useCase;

    if (json.accountId) {
      this.accountId = json.accountId;
    }
    if (json.account) {
      this.account = new Account(json.account);
    }
    this.hasPublishedEvent = json.hasPublishedEvent;

    this.language = json.language;
    this.platform = json.platform;

    this.isB2B = json.isB2B;

    this.referralCode = json.referralCode;

    if (json.referredByUser) {
      this.referredByUser = json.referredByUser;
    }

    if (json.pricingPlan) {
      this.pricingPlan = new UserPricingPlan(json.pricingPlan);
    }

    this.stripeCustomerId = json.stripeCustomerId;

    if (json.billingAddressId) {
      this.billingAddressId = +json.billingAddressId;
    }
    if (json.billingAddress) {
      this.billingAddress = new BillingAddress(json.billingAddress);
    }

    if (json.numHostingEvents !== undefined) {
      this.numHostingEvents = +json.numHostingEvents;
    }
    if (json.numCoHostingEvents !== undefined) {
      this.numCoHostingEvents = +json.numCoHostingEvents;
    }
    if (json.numAttendingEvents !== undefined) {
      this.numAttendingEvents = +json.numAttendingEvents;
    }

    this.hasPassword = json.hasPassword;
    this.hasGoogleUser = json.hasGoogleUser;

    if (json.communitiesOwned) {
      this.communitiesOwned = json.communitiesOwned.map(
        (community) => new Community(community),
      );
    }

    if (json.communitiesMemberOf) {
      this.communitiesMemberOf = json.communitiesMemberOf.map(
        (communityMember) => new CommunityMember(communityMember),
      );
    }
  }

  getDisplayName(): string {
    return this.name ? this.name : this.email;
  }

  getDisplayNameShort(): string {
    return this.name ? this.name.trim().split(/\s+/)[0] : '';
  }

  getInitials(): string {
    if (!this.name || this.name === '') {
      return '';
    }

    const nameParts = this.name.trim().split(/\s+/);

    if (nameParts.length > 1) {
      return nameParts[0][0] + nameParts[1][0];
    }

    return nameParts[0][0];
  }

  isAdmin(): boolean {
    return this.role === UserRole.ADMIN;
  }

  isCustomer() {
    return this.role === UserRole.CUSTOMER;
  }

  age(): string {
    return `${moment().diff(moment(this.birthday), 'years')} Years`;
  }

  getFirstName(): string {
    if (!this.name || this.name === '') {
      return '';
    }

    const nameParts = this.name.trim().split(/\s+/);

    return nameParts[0];
  }

  getLastName(): string {
    if (!this.name || this.name === '') {
      return '';
    }

    const nameParts = this.name.trim().split(/\s+/);

    if (nameParts.length > 1) {
      return nameParts[1];
    }

    return '';
  }

  getUserEmailAndPhoneInfo(): string {
    let contactInfo = '';
    if (this.email && this.email !== '') {
      contactInfo += this.email;
      if (this.phone && this.phone !== '') {
        contactInfo += ` / ${this.phone}`;
      }
    } else if (this.phone && this.phone !== '') {
      contactInfo += this.phone;
    } else {
      contactInfo = '/';
    }

    return contactInfo;
  }

  getLastLogin(): Date | undefined {
    return this.account?.lastLogin;
  }

  getReferralLink(): string {
    return `${env.website}/sign-up?ref=${this.referralCode}`;
  }

  getReferral(): string {
    return this.referredByUser
      ? `${this.referredByUser.name} (${this.referredByUser.referralCode})`
      : '-';
  }

  isPro(): boolean {
    if (this.isB2B) {
      return true;
    }

    if (!this.pricingPlan || !this.pricingPlan.pricingPlan) {
      return false;
    }

    return (
      this.pricingPlan.isActive() &&
      this.pricingPlan.pricingPlan.type === PricingPlanType.PRO
    );
  }

  isCommunityMember(communityUri: string) {
    if (this.communitiesMemberOf && this.communitiesMemberOf?.length > 0) {
      const communityMember = this.communitiesMemberOf.find(
        (item) => item.community?.uri === communityUri,
      );
      return (
        communityMember &&
        communityMember.status === CommunityMemberStatus.APPROVED
      );
    }

    return false;
  }
}
