import { CommonModule, isPlatformServer } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { EventAttendeeFeDataSource } from '@ds/event-attendee-fe.ds';
import { EventAttendee } from '@models/event-attendance/event-attendee.model';
import {
  EventAttendeeForExport,
  EventAttendeesExportRequest,
  EventAttendeesExportType,
} from '@models/events/dto/event-attendees-export.request';
import { SaveAttendeeRequest } from '@models/events/dto/save-attendee.request';
import { Event } from '@models/events/event.model';
import { User } from '@models/users/user.model';
import { AddGuestDialog } from '@modules/events/dialogs/add-guest/add-guest.dialog';
import { SearchBoxComponent } from '@modules/shared/components/search-box/search-box.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { EventService } from '@services/events/event.service';
import { NotificationService } from '@services/shared/notification.service';
import { SidebarService } from '@services/sidebar.service';
import { EventStore } from '@services/stores/event.store';
import { FormsModules } from '@utils/shared-modules';
import { TextEditableDirective } from 'app/directives/text-editable.directive';
import moment from 'moment';
import { finalize } from 'rxjs';

interface AttendeeListFilters {
  searchTerm: string;
}

@Component({
  selector: 'app-guests-details',
  standalone: true,
  imports: [
    CommonModule,
    FormsModules,
    TranslateModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatTableModule,
    SearchBoxComponent,
    TextEditableDirective,
  ],
  providers: [EventService, NotificationService],
  templateUrl: './guests-details.component.html',
  styleUrl: './guests-details.component.scss',
})
export class GuestsDetailsComponent implements AfterViewInit {
  private _event?: Event;
  private _attendee?: EventAttendee;

  @Input() set attendee(attendee: EventAttendee | undefined) {
    this._attendee = attendee;

    this.refresh();
  }

  get attendee(): EventAttendee | undefined {
    return this._attendee;
  }

  @Input() set event(event: Event | undefined) {
    this._event = event;

    if (this.refreshAttendeesOnEventRefresh) {
      this.refreshAttendeesOnEventRefresh = false;
      this.refresh();
    }
  }

  get event(): Event | undefined {
    return this._event;
  }

  @Input() loggedUser?: User;

  isViewByQuestion = false;

  @ViewChild(MatSort, { static: false }) sort!: MatSort;

  dataSource: EventAttendeeFeDataSource;

  displayedColumns = ['firstName', 'lastName', 'email', 'phone', 'actions'];

  filters: AttendeeListFilters = {
    searchTerm: '',
  };

  exportInProgress = false;
  refreshAttendeesOnEventRefresh = false;

  isServer = false;

  constructor(
    private eventService: EventService,
    private eventStore: EventStore,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private notificationService: NotificationService,
    protected sidebarService: SidebarService,
    private cd: ChangeDetectorRef,
    @Inject(PLATFORM_ID) platformId: Object,
  ) {
    this.isServer = isPlatformServer(platformId);
    this.dataSource = new EventAttendeeFeDataSource();
  }

  ngAfterViewInit() {
    if (!this.isServer) {
      this.sort.sortChange.subscribe(() => {
        this.refresh();
      });

      this.refresh();

      this.cd.detectChanges();
    }
  }

  refresh() {
    let filteredAttendees: EventAttendee[] = [];

    if (this.attendee) {
      filteredAttendees = [this.attendee];

      if (this.attendee.groupMembers) {
        filteredAttendees.push(...this.attendee.groupMembers);
      }
    }

    if (this.filters.searchTerm) {
      const searchTerm = this.filters.searchTerm.toLowerCase();
      filteredAttendees = filteredAttendees.filter(
        (attendee) =>
          attendee.getName().toLowerCase().includes(searchTerm) ||
          (attendee.getEmail() &&
            attendee.getEmail().toLowerCase().includes(searchTerm)) ||
          (attendee.getPhone() &&
            attendee.getPhone().toLowerCase().includes(searchTerm)),
      );
    }

    if (!!this.sort?.direction) {
      if (this.sort.active === 'firstName') {
        if (this.sort.direction === 'asc') {
          filteredAttendees = filteredAttendees.sort((a, b) =>
            a.getFirstName().localeCompare(b.getFirstName()),
          );
        } else {
          filteredAttendees = filteredAttendees.sort((a, b) =>
            b.getFirstName().localeCompare(a.getFirstName()),
          );
        }
      } else if (this.sort.active === 'lastName') {
        if (this.sort.direction === 'asc') {
          filteredAttendees = filteredAttendees.sort((a, b) =>
            a.getLastName().localeCompare(b.getLastName()),
          );
        } else {
          filteredAttendees = filteredAttendees.sort((a, b) =>
            b.getLastName().localeCompare(a.getLastName()),
          );
        }
      } else if (this.sort.active === 'email') {
        if (this.sort.direction === 'asc') {
          filteredAttendees = filteredAttendees.sort((a, b) =>
            a.getEmail().localeCompare(b.getEmail()),
          );
        } else {
          filteredAttendees = filteredAttendees.sort((a, b) =>
            b.getEmail().localeCompare(a.getEmail()),
          );
        }
      } else if (this.sort.active === 'phone') {
        if (this.sort.direction === 'asc') {
          filteredAttendees = filteredAttendees.sort((a, b) =>
            a.getPhone().localeCompare(b.getPhone()),
          );
        } else {
          filteredAttendees = filteredAttendees.sort((a, b) =>
            b.getPhone().localeCompare(a.getPhone()),
          );
        }
      }
    }

    this.dataSource.filterEventAttendees(filteredAttendees, new Map());
  }

  onSearchTermChange(searchTerm: string) {
    this.filters.searchTerm = searchTerm;
    this.refresh();
  }

  addPlusOne() {
    if (!this.event || !this.attendee) {
      return;
    }

    const dialogRef = this.dialog.open(AddGuestDialog, {
      maxWidth: '602px',
      maxHeight: '100vh',
      width: '100%',
      height: 'auto',
      data: {
        event: this.event,
        groupLeadAttendeeId: this.attendee.id,
      },
      panelClass: ['normal-dialog'],
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res && res.refresh && this.event) {
        this.refreshAttendeesOnEventRefresh = true;
        this.eventStore.refreshEvent(this.event.uri);
      }
    });
  }

  deleteAttendee(attendee: EventAttendee): void {
    if (!this.event) {
      return;
    }

    this.eventService.deleteAttendee(attendee.eventId, attendee.id).subscribe({
      next: () => {
        if (this.event) {
          this.refreshAttendeesOnEventRefresh = true;
          this.eventStore.refreshEvent(this.event.uri);

          if (!attendee.groupLeadAttendeeId) {
            this.toggleSidebar();
          }
        }
      },
      error: () => {
        this.notificationService.error(
          this.translateService.instant('APP.ERRORS.COULD_NOT_DELETE'),
        );
      },
    });
  }

  exportGuestList() {
    if (!this.event || this.exportInProgress) {
      return;
    }

    this.exportInProgress = true;

    const attendeesForExport = this.dataSource.data.map((attendee) => {
      const exportAttendee: EventAttendeeForExport = {
        name: attendee.getName(),
        status: '',
        teamName: '',
        email: attendee.getEmail() || '-',
        phone: attendee.getPhone() || '-',
        rsvpDate: attendee.rsvpDate
          ? moment(attendee.rsvpDate).format('DD.MM.yyyy, HH:mm')
          : '-',
        plusOnes: '',
      };

      let questionColumns = [];
      if (
        this.event?.eventTemplate?.attendeeQuestions &&
        this.event.eventTemplate?.attendeeQuestions.length > 0
      ) {
        for (const question of this.event.eventTemplate?.attendeeQuestions) {
          if (question.showInGuestList || question.isRsvpDataField) {
            questionColumns.push({
              label: question.label,
              value: attendee.getQuestionAnswerDisplay(question) || '-',
            });
          }
        }
      }

      exportAttendee.questionColumns = questionColumns;

      return exportAttendee;
    });

    const req: EventAttendeesExportRequest = {
      type: EventAttendeesExportType.ALL_GUESTS,
      eventAttendeesForExport: attendeesForExport,
    };

    if (this.loggedUser && this.loggedUser.language !== '') {
      req.userLanguage = this.loggedUser.language;
    }

    const fileName = `${this.attendee?.getName()}_${this.translateService.instant(
      'APP.EVENT_RSVP.GUEST_LIST_FILE_NAME',
    )}.csv`;

    this.eventService
      .exportEventAttendeesCsv(this.event.id, req, fileName)
      .pipe(finalize(() => (this.exportInProgress = false)))
      .subscribe({
        next: () => {},
        error: () => {},
      });
  }

  onFirstNameChange(attendee: EventAttendee, val: any) {
    if (attendee && attendee.firstName !== val) {
      attendee.firstName = val;
    }
  }

  updateAttendee(
    attendee: EventAttendee,
    propertyName: keyof EventAttendee,
    val: any,
  ) {
    if (this.event && attendee && attendee[propertyName] !== val) {
      this.setProperty(attendee, propertyName, val);

      const request: SaveAttendeeRequest = {
        [propertyName]: attendee[propertyName],
      };

      request.attendeeId = attendee.id;

      this.eventService.saveAttendee(this.event.id, request).subscribe({
        next: () => {
          this.eventStore.refreshEvent(this.event!.uri);
        },
        error: (err) => {
          if (
            err?.error ===
            'bad request - an attendee with the new email/phone already exists'
          ) {
            this.notificationService.error(
              this.translateService.instant(
                'APP.EVENT_RSVP.ERRORS.SAVE_ATTENDEE_EXISTS',
              ),
            );
          } else {
            this.notificationService.error(
              this.translateService.instant('APP.ERRORS.COULD_NOT_SAVE'),
            );
          }
        },
      });
    }
  }

  setProperty<T, K extends keyof T>(obj: T, key: K, value: T[K]): void {
    obj[key] = value;
  }

  toggleSidebar(): void {
    this.sidebarService.toggleDetailsSidebar();
    this.cd.detectChanges();
  }
}
