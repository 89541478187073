import { CommonModule, isPlatformServer } from '@angular/common';
import { Component, Inject, Input, PLATFORM_ID } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { EventTemplateFont } from '@models/design-templates/event-template-font.model';
import { EventType } from '@models/events/event-type.enum';
import { Event } from '@models/events/event.model';
import { EventSectionMapComponent } from '@modules/events/components/event-section-map/event-section-map.component';
import { DateCardComponent } from '@modules/shared/components/date-card/date-card.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ScreenWidthService } from '@services/shared/screen-width.service';
import { DateUtils } from '@utils/date-utils';
import { SafeHtmlPipe } from 'app/pipes/safe-html.pipe';
import { EventHostsComponent } from '../event-hosts/event-hosts.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-event-info',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    SafeHtmlPipe,
    EventHostsComponent,
    EventSectionMapComponent,
    MatTooltip,
    DateCardComponent,
  ],
  templateUrl: './event-info.component.html',
  styleUrl: './event-info.component.scss',
})
export class EventInfoComponent {
  @Input() event?: Event;
  @Input() isEventThemeColor?: boolean = true;
  @Input() bodyColor?: string;
  @Input() showDescription: boolean = true;
  @Input() showHosts: boolean = true;
  @Input() ticketsView: boolean = false;
  @Input() isVotingView? = false;
  @Input() blurredFields? = false;
  @Input() showMap? = false;
  isServer = false;
  currentLang?: string;
  isDesktop: boolean = true;

  constructor(
    private translateService: TranslateService,
    private screenWidthService: ScreenWidthService,
    @Inject(PLATFORM_ID) platformId: Object,
  ) {
    this.translateService.onLangChange
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        this.currentLang = this.translateService.currentLang;
      });
    this.currentLang = this.translateService.currentLang;

    this.screenWidthService.isDesktop().subscribe({
      next: (isDesktop: boolean) => {
        this.isDesktop = isDesktop;
      },
    });

    this.isServer = isPlatformServer(platformId);
  }

  get font(): EventTemplateFont | undefined {
    return this.event?.eventTemplate?.font;
  }

  protected readonly EventType = EventType;
  protected readonly DateUtils = DateUtils;
}
