import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Campaign } from '@models/campaigns/campaign.model';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-campaign-date-invalid',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './campaign-date-invalid.dialog.html',
  styleUrl: './campaign-date-invalid.dialog.scss',
})
export class CampaignDateInvalidDialog {
  isTooEarly = false;
  date: Date;

  campaign?: Campaign;
  isBitsCampaign = false;
  isWgPartyChallenge = false;

  isShippingOff = false;
  isWgFimoChallenge = false;

  constructor(
    public dialogRef: MatDialogRef<CampaignDateInvalidDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.isTooEarly = data.isTooEarly;
    this.date = data.date;
    this.campaign = data.campaign;
    this.isBitsCampaign = data.isBitsCampaign;
    this.isWgPartyChallenge = data.isWgPartyChallenge;

    this.isShippingOff = data.isShippingOff;
    this.isWgFimoChallenge = data.isWgFimoChallenge;
  }

  close(confirm?: boolean) {
    this.dialogRef.close(confirm);
  }
}
