<div class="order-overview" [class.mobile-view]='!isDesktop && !disableMobileView' [class.full-screen]='isOrderOpen && !disableMobileView' [class.generic-overview]='isGeneric'>
  @if(isDesktop || isOrderOpen || disableMobileView){
    @if(showImage){
      <img [src]="mainImage" class="logo-image" alt="" />
    }

    @if(showTitle){
      <div class="title-wrapper">
        <div class="title">
          {{ "APP.EVENT_PAYMENT.ORDER_OVERVIEW" | translate | uppercase }}
        </div>
      </div>
    }

    @if(!isDesktop && isOrderOpen){
      <div (click)="toggleIsOrderOpen()" class="close-button">
        <i class="fal fa-times-circle"></i>
      </div>
    }

    @if (ticketOrder) {
      @for (
          attendeeTicket of ticketQuantityMap;
        track attendeeTicket.ticket.eventTicket
        ) {
        <div class="order-item-wrapper">
          <div class="item">
            <div>
              {{ attendeeTicket.quantity }} x
              {{ attendeeTicket.ticket.eventTicket?.name }}
            </div>
            <div class="date">{{ attendeeTicket.ticket.eventTicket?.day | date: 'EEE. dd. MMMM' }}</div>
          </div>

          <div class="price">
            {{
              NumberUtils.roundToUpTo2Decimals(
                (attendeeTicket.quantity * attendeeTicket.ticket.price) / 100.0
              )
            }}
            &#8364;
          </div>
        </div>
      }
      <div class="divider"></div>
      }
    }

  @if(ticketOrder && (isDesktop || disableMobileView)){
    <div class="order-item-wrapper bigger">
      <div class="item">
        @if(isGeneric){
          {{ "APP.EVENT_PAYMENT.TOTAL" | translate }}
        }@else{
          {{ "APP.EVENT_PAYMENT.IN_TOTAL" | translate | uppercase }}
        }
      </div>
      <div class="price">
        {{ ticketOrder.getDisplayTotalPrice() }}
      </div>
    </div>
    @if(showOrder){
      <button
        class="btn btn-primary order-btn"
        (click)="onPay()"
        [disabled]="!isValid || paying()"
        [class.spinner]="paying()"
      >
        {{ "APP.EVENT_PAYMENT.ORDER" | translate }}
      </button>
      @if(isDesktop){
        <div class="terms-of-use">
          {{ "APP.EVENT_PAYMENT.AGREE_LILLET_TERMS_OF_USE" | translate }}
        </div>
      }
    }
  }

  @if(ticketOrder && !isDesktop && !disableMobileView){
    <div class='total-mobile-wrapper'>
      <div class="order-item-wrapper bigger">
          <div class='chevron-btn' (click)='toggleIsOrderOpen()'>
            @if(isOrderOpen){
              <i class="fal fa-chevron-down"></i>
            }@else{
              <i class="fal fa-chevron-up"></i>
            }
          </div>
        <div class="item">
          {{ "APP.EVENT_PAYMENT.IN_TOTAL" | translate | uppercase }}
        </div>
        <div class="price">
          {{ ticketOrder.getDisplayTotalPrice() }}
        </div>
      </div>
      @if(showOrder){
        <button
          class="btn btn-primary order-btn"
          [class.squared-style]='squaredStyle'
          (click)="onPay()"
          [class.spinner]="paying()"
        >
          {{ "APP.EVENT_PAYMENT.ORDER" | translate }}
        </button>
        @if(isDesktop && event && event.name && event.type === EventType.ADVANCED_ONE_COLUMN){
          <div class="terms-of-use">
            {{
              ("APP.EVENT_PAYMENT.AGREE_LILLET_TERMS_OF_USE"
                | translate: { eventName: event.name })
            }}
          </div>
        }
      }
    </div>
  }
</div>
