<ng-container>
  <h1>{{ "APP.AUTH.REFERRAL_CODE_TITLE" | translate }}</h1>
  <div class="subheader subheader-sm">
    {{
      "APP.AUTH.REFERRAL_CODE_SUBHEADER" | translate: { platform: env.platform }
    }}
  </div>

  <div class="form-380">
    <form [formGroup]="form" class="form">
      <mat-form-field class="referral-code-input" appearance="outline">
        <div class="input-container">
          @if (referralCodeValid !== undefined) {
            <div matPrefix class="prefix-placeholder"></div>
          }

          <input
            type="text"
            matInput
            formControlName="referralCode"
            placeholder="&#9679; &#9679; &#9679; &#9679; &#9679; &#9679;"
            class="input-text editable"
            [maxLength]="6"
          />
          @if (referralCodeValid) {
            <div matSuffix class="icon-success">
              <i class="fal fa-check-circle"></i>
            </div>
          }

          @if (referralCodeValid === false && this.referralCode !== undefined) {
            <div matSuffix class="icon-error">
              <i class="fal fa-times-circle"></i>
            </div>
          }
        </div>
      </mat-form-field>
    </form>
    <button
      class="btn btn-primary-party-page"
      [class.btn-community]='isCommunity'
      [disabled]="!referralCodeValid"
      (click)="onCodeCompleted()"
    >
      {{ "APP.NEXT" | translate }}
    </button>

    <div class="auth-fallback-container">
      <span class="text-link" (click)="onNoCode()">
        {{ "APP.AUTH.NO_REFERRAL_CODE" | translate }}
      </span>
    </div>
  </div>
</ng-container>
