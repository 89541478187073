@if (section && event) {
  <div
    class="event-section"
    [class.main-section]="section.type === EventSectionType.MAIN"
    [class.edit]="isEdit"
    [class.center-section]="
      section.backgroundSize === EventSectionBackgroundSize.SMALL
    "
    [class.testimonials-section]="
      section.type === EventSectionType.TESTIMONIALS
    "
    [class.normal-medium-section]="
      section.type === EventSectionType.NORMAL &&
      section.backgroundSize === EventSectionBackgroundSize.MEDIUM
    "
    [class.medium-section]="
      section.backgroundSize === EventSectionBackgroundSize.MEDIUM
    "
    [class.sponsors-section]="section.type === EventSectionType.SPONSORS"
    [class.hide-image]="
      !isDesktop &&
      section.type === EventSectionType.NORMAL &&
      section.imagePosition === ImagePosition.LEFT
    "
    [class.faq-section]="section.type === EventSectionType.FAQ"
    [class.full-colored-background]="
      section.type === EventSectionType.NORMAL &&
      (section.imagePosition === ImagePosition.RIGHT ||
        section.imagePosition === ImagePosition.LEFT)
    "
    [class.left-subsection]="
      section.type === EventSectionType.NORMAL &&
      section.imagePosition === ImagePosition.TOP &&
      isSubSection
    "
    [class.right-subsection]="
      section.type === EventSectionType.NORMAL &&
      section.imagePosition === ImagePosition.BOTTOM &&
      isSubSection
    "
    [class.normal-subsection]="
      section.type === EventSectionType.NORMAL &&
      isSubSection &&
      section.imagePosition
    "
  >
    @if (
      section.title &&
      section.type !== EventSectionType.NORMAL &&
      section.type !== EventSectionType.TIMELINE
    ) {
      @if (isEdit) {
        <app-rich-text-editor
          class="title"
          [section]="section"
          [isRSVPSection]="true"
          [bodyColor]="'var(--event-theme-primary-color)'"
          [sectionProperty]="'title'"
          [valueToChange]="section.title"
          (updateValue)="saveText($event, 'title')"
        />
      } @else {
        <div class="title" [innerHTML]="section.title | safeHtml"></div>
      }
    }

    @if (section.type === EventSectionType.MAIN) {
      <div
        class="main-section-wrapper media-wrapper"
        [class.edit-view]="isEdit"
        (click)="isEdit && openImagePicker(false)"
      >
        @if (isEdit) {
          <div class="edit-action-bar">
            <i class="fa fa-upload"></i>
          </div>
        }
        @if (event.isMainPictureVideo()) {
          <div class="key-visual-video-cont">
            <video
              #keyVisualVideo
              class="key-visual-video key-visual-1"
              [muted]="'muted'"
              autoplay
              playsinline
              loop
              disablePictureInPicture
            >
              <source [src]="event.mainPictureUrl" type="video/mp4" />
            </video>
          </div>
        } @else {
          <img
            [src]="event.getEncodedMainImage()"
            class="key-visual-img logo-image key-visual-1"
            alt="Key Visual"
          />
        }
      </div>
    }

    @if (section.type === EventSectionType.DATE_LOCATION) {
      @if (isEdit) {
        <app-event-section-location-date
          [event]="event"
          [section]="section"
          class="date-location"
          [dates]="dates"
          [isDateEdit]="true"
          [isAddressEdit]="true"
          (updateDate)="openDateTimePickerDialog()"
          (updateAddress)="openPickerDialog()"
        />
      } @else {
        <app-event-section-location-date
          [event]="event"
          [section]="section"
          class="date-location"
          [dates]="dates"
        />
      }

      <div class="ticket-section">
        @if (order) {
          @if (order.refund) {
            <button
              class="btn btn-action"
              (click)="openRequestedRefundOverviewDialog()"
            >
              {{ "APP.REQUEST_REFUND.YOU_REQUESTED_REFUND" | translate }}
            </button>
          }
          <button class="btn btn-action" (click)="openGetBoughtTicketsDialog()">
            {{ "APP.REQUEST_REFUND.SEE_YOUR_TICKETS" | translate }}
          </button>
        } @else {
          <button class="btn btn-action" (click)="openGetTicketsDialog()">
            {{ "APP.EVENT.GET_YOUR_TICKET_NOW" | translate }}
          </button>
        }
      </div>
    }

    @if (section.type === EventSectionType.NORMAL) {
      @if (section.imagePosition === ImagePosition.RIGHT) {
        <div class="main-description center-section no-bg">
          @if (isEdit) {
            <app-rich-text-editor
              class="description"
              [section]="section"
              [isRSVPSection]="true"
              [bodyColor]="'var(--event-theme-primary-color)'"
              [sectionProperty]="'description'"
              [valueToChange]="section.description"
              (updateValue)="saveText($event, 'description')"
            />
          } @else {
            <div
              [innerHTML]="section.description | safeHtml"
              class="description primary-color"
            ></div>
          }

          <div
            class="media-wrapper"
            [class.edit-view]="isEdit"
            (click)="isEdit && openImagePicker(true)"
          >
            @if (isEdit) {
              <div class="edit-action-bar">
                <i class="fa fa-upload"></i>
              </div>
            }
            @if (section.isSectionImageVideo()) {
              <video
                #keyVisualOverlayVideo
                class="main-asset key-visual-1"
                [muted]="'muted'"
                autoplay
                playsinline
                loop
                disablePictureInPicture
              >
                <source [src]="section.imageUrl" type="video/mp4" />
              </video>
            } @else {
              <img class="main-asset key-visual-1" [src]="section.imageUrl" />
            }
          </div>
        </div>
      } @else if (section.imagePosition === ImagePosition.LEFT) {
        <div class="normal-section" [class.edit-section]="isEdit">
          <div
            class="image media-wrapper"
            [class.edit-view]="isEdit"
            (click)="isEdit && openImagePicker(true)"
          >
            @if (isEdit) {
              <div class="edit-action-bar">
                <i class="fa fa-upload"></i>
              </div>
            }
            <img class="key-visual-1" [src]="section.imageUrl" />
          </div>

          <div class="info" [class.is-edit]="isEdit">
            @if (isEdit) {
              <app-rich-text-editor
                class="title w-100"
                [section]="section"
                [isRSVPSection]="true"
                [bodyColor]="'var(--event-theme-primary-color)'"
                [sectionProperty]="'title'"
                [valueToChange]="section.title"
                (updateValue)="saveText($event, 'title')"
              />
              <app-rich-text-editor
                class="description w-100"
                [section]="section"
                [isRSVPSection]="true"
                [bodyColor]="'var(--event-theme-primary-color)'"
                [sectionProperty]="'description'"
                [valueToChange]="section.description"
                (updateValue)="saveText($event, 'description')"
              />
            } @else {
              <div class="title" [innerHTML]="section.title | safeHtml"></div>
              <div
                class="description"
                [innerHTML]="section.description | safeHtml"
              ></div>
            }
          </div>
          <div class="image-bottle">
            <img src="assets/lillet-bottle.svg" />
          </div>
          <div class="image-fruit-leaves">
            <img src="assets/lillet-fruit-leaves.svg" />
          </div>
        </div>
      } @else if (section.imagePosition === ImagePosition.TOP) {
        <div
          class="slider-subsection media-wrapper"
          [class.edit-view]="isEdit"
          (click)="isEdit && openImagePicker(true)"
        >
          @if (isEdit) {
            <div class="edit-action-bar">
              <i class="fa fa-upload"></i>
            </div>
          }
          <img
            [src]="section.imageUrl"
            class="portrait-image key-visual-1"
            alt=""
          />
          <img src="assets/kim_hnizdo_logo.png" class="logo-image" alt="" />
        </div>
      } @else if (section.imagePosition === ImagePosition.BOTTOM) {
        <div class="slider-subsection">
          @if (isEdit) {
            <app-rich-text-editor
              class="description w-100"
              [section]="section"
              [isRSVPSection]="true"
              [bodyColor]="'var(--event-theme-primary-color)'"
              [sectionProperty]="'description'"
              [valueToChange]="section.description"
              (updateValue)="saveText($event, 'description')"
            />
          } @else {
            <div [innerHTML]="section.description | safeHtml"></div>
          }
          <div
            class="media-wrapper absolute-picker-wrapper"
            [class.edit-view]="isEdit"
            (click)="isEdit && openImagePicker(true)"
          >
            @if (isEdit) {
              <div class="edit-action-bar">
                <i class="fa fa-upload"></i>
              </div>
            }
            <img
              [src]="section.imageUrl"
              class="sample-image key-visual-1"
              alt=""
            />
          </div>
        </div>
      } @else if (section.imagePosition === ImagePosition.CENTER) {
        <div class="image-section">
          <div
            class="media-wrapper"
            [class.edit-view]="isEdit"
            (click)="isEdit && openImagePicker(true)"
          >
            @if (isEdit) {
              <div class="edit-action-bar">
                <i class="fa fa-upload"></i>
              </div>
            }
            <img [src]="section.imageUrl" class="image key-visual-1" alt="" />
          </div>
        </div>
      } @else {
        <div></div>
      }
    }

    @if (section.type === EventSectionType.TIMELINE) {
      <div class="timeline-section">
        @if (checkIfDateInRange() && isEdit) {
          <button
            class="btn btn-primary add-day-button add-button"
            (click)="addProgramDate()"
            title="Add Day"
          >
            <i class="far fa-plus"></i>
          </button>
        }
        <div class="toggle-buttons">
          @for (date of programDates; track $index) {
            <button
              class="btn btn-action"
              [class.selected]="selectedDay === date"
              (click)="onSelectDay(date)"
            >
              {{ "APP.DAY" | translate | uppercase }} {{ $index + 1 }}
            </button>
          }
        </div>
        <div class="program">
          @if (isEdit) {
            <app-rich-text-editor
              class="title"
              [section]="section"
              [isRSVPSection]="true"
              [bodyColor]="'var(--event-theme-primary-color)'"
              [sectionProperty]="'title'"
              [valueToChange]="section.title"
              (updateValue)="saveText($event, 'title')"
            />
          } @else {
            <div
              [innerHTML]="section.title | uppercase | safeHtml"
              class="title"
            ></div>
          }
          <div class="date">
            {{ formatDateMonth(selectedDay) | uppercase }}
          </div>
          <app-event-section-timetable
            [eventTimetable]="displayedEventTimetable"
            [eventType]="event.type"
            [isEdit]="isEdit"
            [selectedDay]="selectedDay"
            (onEdit)="onConfigureSection($event)"
          ></app-event-section-timetable>
          @if (isEdit) {
            <div class="actions-section">
              <button
                class="btn btn-primary add-button"
                (click)="
                  onConfigureSection({
                    day: selectedDay
                  })
                "
              >
                <i class="far fa-plus"></i>
              </button>
            </div>
          }
        </div>
      </div>
      <div class="image-orange">
        <img src="assets/lillet-orange.svg" />
      </div>
      <div class="stripes-layout">
        @for (char of "Event"; track char) {
          <div class="stripe"></div>
        }
      </div>
    }

    @if (section.type === EventSectionType.TESTIMONIALS) {
      <app-event-section-testimonials
        class="testimonials-section"
        [class.edit-view]="isEdit"
        [event]="event"
        [isEdit]="isEdit"
        [testimonials]="event.eventTemplate?.eventTestimonials"
        (onEdit)="onConfigureSection($event)"
        (configureSection)="onConfigureSection()"
      />
    }

    @if (
      section.type === EventSectionType.SPONSORS &&
      event.eventTemplate?.eventSponsors
    ) {
      <app-event-section-sponsors
        class="sponsor-section"
        [class.edit-view]="isEdit"
        [isEdit]="isEdit"
        [class.host-view]="event.isUserHost(loggedUser?.id)"
        [sponsors]="event.eventTemplate?.eventSponsors"
        (onEdit)="onConfigureSection($event)"
        (configureSection)="onConfigureSection()"
      />
    }

    @if (section.type === EventSectionType.LOCATION && !isServer) {
      <div class="map-wrapper">
        <app-event-section-map class="w-100"></app-event-section-map>
        <img src="assets/lemons.svg" class="lemons-image" alt="" />
      </div>
    }

    @if (section.type === EventSectionType.FAQ && eventFaqs) {
      <app-event-section-faq
        class="w-100"
        [faqs]="eventFaqs"
        [event]="event"
        [isEdit]="isEdit"
        (onEdit)="onConfigureSection($event)"
      ></app-event-section-faq>
      @if (isEdit) {
        <div class="actions-section">
          <button
            class="btn btn-primary add-button"
            (click)="onConfigureSection()"
          >
            <i class="far fa-plus"></i>
          </button>
        </div>
      }
    }

    @if (section.type === EventSectionType.FOOTER) {
      <div class="footer">
        <div class="footer-info">
          @if (isDesktop) {
            <div class="footer-powered-by-cont before">
              <span>{{ "APP.POWERED_BY" | translate }}</span>
              <a class="footer-icon-a" routerLink="/">
                <app-event-page-logo class="w-100" />
              </a>
            </div>
            <div
              [innerHTML]="section.description | safeHtml"
              class="description"
            ></div>
          }
        </div>

        <div class="logo">
          <img [src]="section.imageUrl" />
        </div>
        <ul class="widget-list">
          <li class="widget-item">
            <a href="https://eventpage.ai/imprint" target="_blank">{{
              "APP.USER_GENERATED_CONTENT_POLICY" | translate
            }}</a>
          </li>

          <li class="widget-item">
            <a
              href="https://eventpage.ai/datenschutzerklaerung/"
              target="_blank"
              >{{ "APP.GENERAL_TERMS_AND_CONDITIONS_ABBR" | translate }}</a
            >
          </li>

          <li class="widget-item">
            <a href="https://eventpage.ai/terms-of-use/" target="_blank">{{
              "APP.DATA_PRIVACY" | translate
            }}</a>
          </li>

          <li class="widget-item">
            <a href="mailto:support@eventpage.ai" class="text-link">{{
              "APP.IMPRINT" | translate
            }}</a>
          </li>
        </ul>
        @if (!isDesktop) {
          <div class="footer-info">
            <div class="footer-powered-by-cont after">
              <span>{{ "APP.POWERED_BY" | translate }}</span>
              <a class="footer-icon-a" routerLink="/">
                <app-event-page-logo class="w-100" />
              </a>
            </div>
          </div>
        }
      </div>
    }

    <div
      [class.slider-section]="
        section.type === EventSectionType.NORMAL && !section.imagePosition
      "
    >
      @if (section.subSections && section.subSections.length > 0) {
        @for (
          subSection of section.getSubSectionsOrdered();
          track subSection.id
        ) {
          <app-advanced-one-column-event-section
            [section]="subSection"
            [isSubSection]="true"
            [isEdit]="isEdit"
          ></app-advanced-one-column-event-section>
        }
      }
    </div>
  </div>
}
