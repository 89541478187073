import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'dateAmPm',
  standalone: true,
})
export class DateAmPmPipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(value: any): any {
    if (!(value instanceof Date)) {
      value = new Date(value);
    }

    let formattedDate = '';

    const transformed = this.datePipe.transform(value, 'h.mm a');
    if (transformed) {
      formattedDate = transformed;
    }

    return formattedDate ? formattedDate.toLowerCase() : value;
  }
}
