import { Component, Input, HostListener, Optional } from '@angular/core';
import { CommonModule } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Event } from '@models/events/event.model';
import { User } from '@models/users/user.model';
import { AuthService } from '@services/auth/auth.service';
import { ScreenWidthService } from '@services/shared/screen-width.service';
import { SidenavService } from '@services/shared/sidenav.service';
import { SidenavDropdownData } from '@models/sidenav/sidenav-dropdown-data.model';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';
import { EventStore } from '@services/stores/event.store';
import { ThemeService } from '@services/theme.service';
import { AppRoutes } from 'app/routes';
import { Helpers } from '@utils/helpers';
import { AuthGoogleService } from '@services/auth/auth-google.service';

@Component({
  selector: 'app-internal-sidenav-user-icon',
  standalone: true,
  imports: [CommonModule, TranslateModule, RouterModule, MatMenuModule],
  providers: [ScreenWidthService, SidenavService],
  templateUrl: './internal-sidenav-user-icon.component.html',
  styleUrl: './internal-sidenav-user-icon.component.scss',
})
export class InternalSidenavUserIconComponent {
  loggedUser: User | undefined;
  event?: Event;

  @Input() menuData: SidenavDropdownData = {
    headerText: '',
    initials: '',
    items: [
      {
        url: `/${AppRoutes.Customer.profile}`,
        label: 'APP.MY_PROFILE',
        icon: 'fa-user-circle',
      },
      {
        url: `/${AppRoutes.Customer.settings}`,
        label: 'APP.SETTINGS',
        icon: 'fa-cog',
      },
      {
        url: `/${AppRoutes.Customer.domainsAndSender}`,
        label: 'APP.DOMAINS_AND_SENDER.TITLE',
        icon: 'fa-paper-plane',
        separator: true,
      },
      {
        event: 'OPEN_SUPPORT',
        label: 'APP.SUPPORT_AND_FAQ',
        icon: 'fa-question-circle',
      },
      {
        event: 'OPEN_TERMS',
        label: 'APP.TERMS_OF_USE',
        icon: 'fa-laptop',
        separator: true,
      },
      {
        event: 'LOGOUT',
        label: 'APP.LOGOUT',
        icon: 'fa-sign-out-alt',
      },
    ],
  };

  isDesktop?: boolean;
  navbarDisplayed = true;
  lastTopPosition = 0;
  private topBuffer = 20;

  userTheme = 'light';

  @Input() noMargin = false;

  @Input() themeModeAvailable = true;

  constructor(
    private authService: AuthService,
    private authGoogleService: AuthGoogleService,
    private sidenavService: SidenavService,
    private screenWidthService: ScreenWidthService,
    private translate: TranslateService,
    private themeService: ThemeService,
    @Optional() private eventStore: EventStore,
  ) {
    if (this.eventStore) {
      this.eventStore.event.pipe(takeUntilDestroyed()).subscribe((event) => {
        this.event = event;
      });
    }

    this.trackUserChanges();
    this.trackIsDesktopChange();

    this.getUserTheme();
  }

  changeUserTheme(e: any, userTheme: string) {
    e.stopPropagation();

    localStorage.setItem('userTheme', userTheme);
    this.themeService.setTheme(userTheme);
    this.userTheme = userTheme;
    Helpers.setTheme();
    if (this.event) {
      const primaryColor = this.event.eventTemplate?.font?.primaryLogoFontColor;
      if (primaryColor) {
        Helpers.setPopColor(primaryColor);
      }
    }
  }

  private getUserTheme() {
    const userTheme = localStorage.getItem('userTheme');

    if (userTheme) {
      this.userTheme = userTheme;
    } else {
      this.userTheme = 'light';
    }
  }

  onMenuEvent(eventID: string) {
    switch (eventID) {
      case 'LOGOUT':
        this.onLogout();
        break;
      case 'OPEN_SUPPORT':
        window.open(
          'https://eventpageaigmbh.freshdesk.com/support/home',
          '_blank',
        );
        break;
      case 'OPEN_TERMS':
        if (this.translate.currentLang === 'de') {
          window.open('https://eventpage.ai/de/agbs/', '_blank');
        } else {
          window.open('https://eventpage.ai/terms-of-use/', '_blank');
        }
        break;
      default:
    }
  }

  onLogout() {
    this.authGoogleService.logout();
    this.authService.logout();
  }

  private trackUserChanges() {
    this.authService.userSubject
      .pipe(takeUntilDestroyed())
      .subscribe((user) => {
        this.loggedUser = user;
        this.setMenuData();
        this.setUserNameInMoreMenu();

        if (!this.loggedUser.isPro()) {
          this.menuData.items = this.menuData.items.filter(
            (item) => item.url !== `/${AppRoutes.Customer.domainsAndSender}`,
          );
        }
      });
  }

  toggleSidenav() {
    this.sidenavService.toggle().then();
  }

  private setMenuData() {
    this.menuData = {
      headerText: '',
      initials: '',
      items: [
        {
          url: `/${AppRoutes.Customer.profile}`,
          label: 'APP.MY_PROFILE',
          icon: 'fa-user-circle',
        },
        {
          url: `/${AppRoutes.Customer.settings}`,
          label: 'APP.SETTINGS',
          icon: 'fa-cog',
          separator: !this.loggedUser?.isPro(),
        },
        {
          url: `/${AppRoutes.Customer.domainsAndSender}`,
          label: 'APP.DOMAINS_AND_SENDER.TITLE',
          icon: 'fa-paper-plane',
          separator: true,
        },
        {
          event: 'OPEN_SUPPORT',
          label: 'APP.SUPPORT_AND_FAQ',
          icon: 'fa-question-circle',
        },
        {
          event: 'OPEN_TERMS',
          label: 'APP.TERMS_OF_USE',
          icon: 'fa-laptop',
          separator: true,
        },
        {
          event: 'LOGOUT',
          label: 'APP.LOGOUT',
          icon: 'fa-sign-out-alt',
        },
      ],
    };
  }

  private setUserNameInMoreMenu() {
    if (!this.loggedUser) {
      return;
    }

    if (this.isDesktop) {
      this.menuData.headerText = this.loggedUser.getDisplayName();
    } else {
      this.menuData.headerText = this.loggedUser.getDisplayNameShort();
    }

    this.menuData.initials = this.loggedUser.getInitials();
  }

  private trackIsDesktopChange() {
    this.screenWidthService
      .isDesktop()
      .pipe(takeUntilDestroyed())
      .subscribe((isDesktop) => {
        this.isDesktop = isDesktop;
        this.setUserNameInMoreMenu();
      });
  }

  @HostListener('window:scroll')
  onWindowScroll() {
    if (!this.isDesktop) {
      const isScrollUp = this.isScrollUp();

      this.navbarDisplayed = isScrollUp;
    }

    this.lastTopPosition = window.scrollY;
  }

  private isScrollUp() {
    return (
      this.lastTopPosition <= this.topBuffer ||
      this.lastTopPosition > window.scrollY
    );
  }

  get isCustomer(): boolean {
    return !!this.loggedUser && this.loggedUser.isCustomer();
  }
}
