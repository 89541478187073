import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDrawer, MatDrawerContainer } from '@angular/material/sidenav';
import { ConfigurableSectionItem } from '@models/events/dto/configurable-section-item.model';
import { EventUpdateRequest } from '@models/events/dto/event-update.request';
import { EventSectionType } from '@models/events/event-section-type.enum';
import { EventType } from '@models/events/event-type.enum';
import { Event } from '@models/events/event.model';
import { Asset, getAssetFromURL } from '@models/shared/asset.model';
import { GeneralDetailsComponent } from '@modules/events/components/general-details/general-details.component';
import { GuestsDetailsComponent } from '@modules/events/components/guests-details/guests-details.component';
import { HistoryDetailsComponent } from '@modules/events/components/history-details/history-details.component';
import { FileUploaderComponent } from '@modules/shared/components/file-uploader/file-uploader.component';
import { TimePickerComponent } from '@modules/shared/components/time-picker/time-picker.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { EventService } from '@services/events/event.service';
import { SidebarService } from '@services/sidebar.service';
import { EventStore } from '@services/stores/event.store';
import { FormsModules } from '@utils/shared-modules';
import { EventSponsorRequest } from '@models/events/dto/event-sponsor.request';

@Component({
  selector: 'app-advanced-one-column-configure-section',
  standalone: true,
  imports: [
    AsyncPipe,
    GeneralDetailsComponent,
    GuestsDetailsComponent,
    HistoryDetailsComponent,
    MatDrawer,
    MatDrawerContainer,
    TimePickerComponent,
    TranslateModule,
    FormsModules,
    FileUploaderComponent,
  ],
  templateUrl: './advanced-one-column-configure-section.component.html',
  styleUrl: './advanced-one-column-configure-section.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AdvancedOneColumnConfigureSectionComponent
  implements OnInit, OnChanges
{
  @Input() configurableObject?: ConfigurableSectionItem;
  @Output() close = new EventEmitter();
  form?: FormGroup;
  event?: Event;
  constructor(
    protected sidebarService: SidebarService,
    private cd: ChangeDetectorRef,
    private fb: FormBuilder,
    private translateService: TranslateService,
    private eventService: EventService,
    private eventStore: EventStore,
  ) {
    this.eventStore.event.pipe(takeUntilDestroyed()).subscribe((event) => {
      this.event = event;
    });
  }

  ngOnInit(): void {
    this.updateSection();
  }

  onClose() {
    this.close.emit();
  }
  toggleSidebar(): void {
    this.sidebarService.toggleEventSectionDetailsSidebar();
    this.cd.detectChanges();
  }

  getTitle() {
    if (this.configurableObject) {
      if (
        this.configurableObject.timeTableItem ||
        this.configurableObject.testimonial ||
        this.configurableObject.faq ||
        this.configurableObject.sponsor
      ) {
        return this.translateService.instant(
          'APP.ADVANCED_ONE_COLUMN_EVENT.EDIT_ITEM',
        );
      } else {
        return this.translateService.instant(
          'APP.ADVANCED_ONE_COLUMN_EVENT.ADD_ITEM',
        );
      }
    }
  }
  updateSection() {
    if (this.configurableObject) {
      if (this.configurableObject.section) {
        switch (this.configurableObject.section.type) {
          case EventSectionType.TIMELINE: {
            this.form = this.fb.group({
              title: [this.configurableObject.timeTableItem?.title || ''],
              description: [
                this.configurableObject.timeTableItem?.description || '',
              ],
              timeFrom: [
                this.configurableObject.timeTableItem?.timeFrom || null,
              ],
              timeTo: [this.configurableObject.timeTableItem?.timeTo || null],
            });
            break;
          }
          case EventSectionType.TESTIMONIALS: {
            this.form = this.fb.group({
              userAvatarAsset: [
                this.configurableObject.testimonial?.userAvatarUrl
                  ? getAssetFromURL(
                      this.configurableObject.testimonial?.userAvatarUrl,
                    )
                  : null,
              ],
              userName: [this.configurableObject.testimonial?.userName || ''],
              userJobTitle: [
                this.configurableObject.testimonial?.userJobTitle || null,
              ],
            });
            break;
          }
          case EventSectionType.FAQ: {
            this.form = this.fb.group({
              question: [this.configurableObject.faq?.question || ''],
              answer: [this.configurableObject.faq?.answer || ''],
            });
            break;
          }
          case EventSectionType.SPONSORS: {
            this.form = this.fb.group({
              link: [this.configurableObject.sponsor?.linkUrl || ''],
              imageAsset: [
                this.configurableObject.sponsor?.imageUrl
                  ? getAssetFromURL(this.configurableObject.sponsor?.imageUrl)
                  : null,
              ],
            });
            break;
          }
        }
      }
    }
  }

  onImageChanged(selectedFile?: Asset) {
    switch (this.configurableObject?.section?.type) {
      case EventSectionType.TESTIMONIALS: {
        this.form?.controls['userAvatarAsset'].patchValue(selectedFile);
        break;
      }
      case EventSectionType.SPONSORS: {
        this.form?.controls['imageAsset'].patchValue(selectedFile);
        break;
      }
    }
  }

  protected readonly EventType = EventType;
  protected readonly EventSectionType = EventSectionType;

  ngOnChanges(changes: SimpleChanges): void {
    this.updateSection();
  }

  onSave() {
    if (this.form && this.form.valid && this.configurableObject) {
      const formValues = this.form.value;
      let newItem: any = {};
      let eventRequest: EventUpdateRequest = {};

      switch (this.configurableObject.section?.type) {
        case EventSectionType.TIMELINE: {
          const day = this.configurableObject?.day;
          let fullDate = undefined;

          if (day) {
            fullDate = new Date(day);
            fullDate.setHours(14, 0, 0, 0);
          }

          newItem = {
            id: this.configurableObject.timeTableItem
              ? this.configurableObject.timeTableItem.id
              : 0,
            eventTimetableId: this.configurableObject.timeTableItem
              ? this.configurableObject.timeTableItem.eventTimetableId
              : 0,
            title: formValues.title,
            description: formValues.description,
            timeFrom: formValues.timeFrom,
            timeTo: formValues.timeTo,
            day: fullDate,
          };
          eventRequest = {
            timetableItem: newItem,
          };
          break;
        }
        case EventSectionType.TESTIMONIALS: {
          newItem = {
            id: this.configurableObject.testimonial
              ? this.configurableObject.testimonial.id
              : 0,
            userAvatarAsset: formValues.userAvatarAsset,
            userName: formValues.userName,
            userJobTitle: formValues.userJobTitle,
            order: this.configurableObject.testimonial
              ? this.configurableObject.testimonial.order
              : undefined,
          };
          eventRequest = {
            testimonial: newItem,
          };
          break;
        }
        case EventSectionType.FAQ: {
          newItem = {
            id: this.configurableObject.faq
              ? this.configurableObject.faq.id
              : 0,
            question: formValues.question,
            answer: formValues.answer,
            order: this.configurableObject.faq
              ? this.configurableObject.faq.order
              : undefined,
          };
          eventRequest = {
            faq: newItem,
          };
          break;
        }
        case EventSectionType.SPONSORS: {
          const newItem: EventSponsorRequest = {
            id: this.configurableObject.sponsor
              ? this.configurableObject.sponsor.id
              : 0,
            linkUrl: formValues.link,
            imageAsset: formValues.imageAsset,
            order: this.configurableObject.sponsor
              ? this.configurableObject.sponsor.order
              : undefined,
          };
          eventRequest = {
            sponsor: newItem,
          };
          break;
        }
      }
      this.update(eventRequest);
    } else {
      console.log('Form is invalid or not initialized');
    }
  }

  update(req?: EventUpdateRequest) {
    if (this.event) {
      if (req) {
        req.localization = this.translateService.currentLang;
      }
      this.eventService.updateEvent(this.event.id, req).subscribe((event) => {
        this.event = event;
        this.eventStore.setEvent(event);
        this.eventStore.refreshEvent(event.uri);
        this.onClose();
      });
    }
  }
}
