import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EventAttendeeStatus } from '@models/event-attendance/event-attendee-status.enum';
import { RSVPOptionType } from '@models/event-attendance/rsvp-option-type.enum';
import { EventTicket } from '@models/events/event-ticket.model';
import { Event } from '@models/events/event.model';
import { TicketConfigurationType } from '@models/tickets/ticket-configuration-type.enum';
import { YesNoDialog } from '@modules/customer/dialogs/yes-no/yes-no.dialog';
import { PaginatorComponent } from '@modules/shared/components/paginator/paginator.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@services/shared/notification.service';
import { SidebarService } from '@services/sidebar.service';
import { EventStore } from '@services/stores/event.store';
import { TicketsStore } from '@services/stores/tickets.store';
import { TicketService } from '@services/tickets/ticket.service';
import { FormsModules } from '@utils/shared-modules';

export enum TicketStatus {
  ALL = 'ALL',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

@Component({
  selector: 'app-tickets-management',
  standalone: true,
  imports: [
    TranslateModule,
    CommonModule,
    FormsModules,
    TranslateModule,
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
  ],
  templateUrl: './tickets-management.component.html',
  styleUrl: './tickets-management.component.scss',
  providers: [TicketService],
})
export class TicketsManagementComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  @ViewChild(PaginatorComponent) paginator!: PaginatorComponent;
  @Output() setConfigurationTicket =
    new EventEmitter<TicketConfigurationType>();
  event?: Event;

  displayedColumnsDefault: string[] = [
    'name',
    'sold',
    'price',
    'status',
    'startSale',
    'endSale',
    'day',
    'actions',
  ];

  displayedColumns = this.displayedColumnsDefault;
  allTickets: EventTicket[] = [];
  filteredTickets: EventTicket[] = [];
  ticketStatus: TicketStatus = TicketStatus.ALL;

  constructor(
    private ticketService: TicketService,
    private eventStore: EventStore,
    private sidebarService: SidebarService,
    private ticketsStore: TicketsStore,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private notificationService: NotificationService,
  ) {
    this.eventStore.event.pipe(takeUntilDestroyed()).subscribe((event) => {
      if (event) {
        this.event = event;
      }
    });
    this.ticketsStore.eventTickets
      .pipe(takeUntilDestroyed())
      .subscribe((eventTickets) => {
        if (eventTickets) {
          this.allTickets = eventTickets;
          this.applyFilters();
        }
      });
  }

  ngOnInit(): void {
    if (this.event) {
      this.ticketService.getTickets(this.event.id).subscribe({
        next: (eventTickets) => {
          this.allTickets = eventTickets;
          this.filteredTickets = eventTickets;
          this.ticketsStore.setEventTickets(eventTickets);
        },
      });
    }
  }

  changeFilterStatus(filterStatus: TicketStatus) {
    this.ticketStatus = filterStatus;
    this.applyFilters();
  }

  applyFilters() {
    switch (this.ticketStatus) {
      case TicketStatus.ALL:
        this.filteredTickets = this.allTickets;
        break;
      case TicketStatus.INACTIVE:
        this.filteredTickets = this.allTickets.filter(
          (x) => this.getTicketStatus(x) === TicketStatus.INACTIVE,
        );
        break;
      case TicketStatus.ACTIVE:
        this.filteredTickets = this.allTickets.filter(
          (x) => this.getTicketStatus(x) === TicketStatus.ACTIVE,
        );
        break;
    }
  }

  getTicketStatus(ticket: EventTicket): string {
    const now = new Date();

    if (
      (ticket.maxCapacity &&
        ticket.numSoldTickets &&
        ticket.numSoldTickets >= ticket.maxCapacity) ||
      (ticket.saleStartDate && now < ticket.saleStartDate) ||
      (ticket.saleEndDate && now > ticket.saleEndDate)
    ) {
      return TicketStatus.INACTIVE;
    }

    return TicketStatus.ACTIVE;
  }

  onCreateTicket() {
    this.setConfigurationTicket.emit(TicketConfigurationType.CREATE);
    if (this.sidebarService.sidebarStateSubject.value) {
      this.sidebarService.toggleSidebar();
    }

    if (!this.sidebarService.sidebarDetailsStateSubject.value) {
      this.sidebarService.toggleDetailsSidebar();
    }
  }

  editTicket(ticket: EventTicket) {
    this.ticketsStore.setEventTicket(ticket);
    this.setConfigurationTicket.emit(TicketConfigurationType.EDIT);
    if (this.sidebarService.sidebarStateSubject.value) {
      this.sidebarService.toggleSidebar();
    }

    if (!this.sidebarService.sidebarDetailsStateSubject.value) {
      this.sidebarService.toggleDetailsSidebar();
    }
  }

  changeEventCapacity() {
    this.setConfigurationTicket.emit(TicketConfigurationType.SET_CAPACITY);
    if (this.sidebarService.sidebarStateSubject.value) {
      this.sidebarService.toggleSidebar();
    }

    if (!this.sidebarService.sidebarDetailsStateSubject.value) {
      this.sidebarService.toggleDetailsSidebar();
    }
  }

  copyTicket(ticket: EventTicket) {
    this.ticketService.copy(ticket).subscribe({
      next: () => {
        this.notificationService.success(
          this.translateService.instant(
            'APP.TICKETS_EDIT.SUCCESS.TICKET_COPIED_SUCCESSFULLY',
          ),
        );
        if (this.event) this.ticketsStore.refreshEventTickets(this.event.id);
      },
      error: () => {
        this.notificationService.error(
          this.translateService.instant(
            'APP.TICKETS_EDIT.ERRORS.TICKET_CANNOT_BE_COPIED',
          ),
        );
      },
    });
  }

  deleteTicket(ticket: EventTicket) {
    const dialogRef = this.dialog.open(YesNoDialog, {
      maxWidth: '602px',
      maxHeight: '100vh',
      width: '100%',
      height: 'auto',
      data: {
        title: this.translateService.instant('APP.TICKETS_EDIT.DELETE_TICKET'),
        message: this.translateService.instant(
          'APP.TICKETS_EDIT.DELETE_TICKET_CONFIRMATION',
        ),
        isDeleteDialog: true,
      },
      panelClass: ['normal-dialog'],
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result === true && ticket && ticket.id) {
        this.ticketService.delete(ticket.id).subscribe({
          next: () => {
            this.notificationService.success(
              this.translateService.instant(
                'APP.TICKETS_EDIT.SUCCESS.TICKET_DELETED_SUCCESSFULLY',
              ),
            );
            if (this.event)
              this.ticketsStore.refreshEventTickets(this.event.id);
          },
          error: () => {
            this.notificationService.error(
              this.translateService.instant(
                'APP.TICKETS_EDIT.ERRORS.TICKET_CANNOT_BE_DELETED',
              ),
            );
          },
        });
      }
    });
  }

  get ticketsMaxCapacity(): number {
    return this.allTickets.reduce(
      (sum, ticket) => sum + (ticket.maxCapacity || 0),
      0,
    );
  }

  protected readonly RSVPOptionType = RSVPOptionType;
  protected readonly EventAttendeeStatus = EventAttendeeStatus;
  protected readonly TicketStatus = TicketStatus;
}
