import { ClassData } from '@models/class-data';
import { QuestionType } from './event-attendee-question-type.enum';
import { EventAttendeeQuestionOption } from './event-attendee-question-option.model';

export class EventAttendeeQuestion {
  id: number;

  eventTemplateId: number;

  type: QuestionType;

  label: string;

  order: number;
  isRequired: boolean;

  showInGuestList: boolean;
  isRsvpDataField: boolean;

  options?: EventAttendeeQuestionOption[];

  constructor(json: ClassData<EventAttendeeQuestion>) {
    this.id = +json.id;

    this.eventTemplateId = +json.eventTemplateId;

    this.type = json.type;

    this.label = json.label;

    this.order = +json.order;
    this.isRequired = json.isRequired;

    this.showInGuestList = json.showInGuestList;
    this.isRsvpDataField = json.isRsvpDataField;

    if (json.options) {
      this.options = this.mapOptions(json.options);
    }
  }

  getQuestionColumnId(): string {
    return `question#${this.id}`;
  }

  private mapOptions(
    options: EventAttendeeQuestionOption[],
  ): EventAttendeeQuestionOption[] {
    return options.map((it) => new EventAttendeeQuestionOption(it));
  }
}
