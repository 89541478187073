import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EventHost } from '@models/events/event-host.model';
import { Event } from '@models/events/event.model';
import { EventTemplateFont } from '@models/design-templates/event-template-font.model';
import { User } from '@models/users/user.model';
import { RestrictedAccessComponent } from '@modules/events/dialogs/attendee-rsvp/restricted-access/restricted-access.component';
import { AuthService } from '@services/auth/auth.service';
import { RSVPOptionType } from '@models/event-attendance/rsvp-option-type.enum';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { EventAttendee } from '@models/event-attendance/event-attendee.model';
import { SearchBoxComponent } from '@modules/shared/components/search-box/search-box.component';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { EventService } from '@services/events/event.service';
import { UpdateEventAttendeeRsvpOptionRequest } from '@models/events/dto/update-event-attendee-rsvp-option.request';
import { AttendeeRowComponent } from '@modules/events/dialogs/attendee-rsvp/attendee-row/attendee-row.component';
import { BackButtonComponent } from '@modules/shared/components/back-button/back-button.component';
import { EventAttendeeStatus } from '@models/event-attendance/event-attendee-status.enum';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-attendee-list',
  standalone: true,
  imports: [
    CommonModule,
    MatTabsModule,
    TranslateModule,
    SearchBoxComponent,
    AttendeeRowComponent,
    RestrictedAccessComponent,
    BackButtonComponent,
    MatButtonModule,
    MatMenuModule,
  ],
  animations: [
    trigger('statusMenuAnimation', [
      state('visible', style({ opacity: 1, height: '*' })),
      state('hidden', style({ opacity: 0, height: '0' })),
      transition('visible <=> hidden', animate('150ms ease-in-out')),
    ]),
  ],
  providers: [EventService],
  templateUrl: './attendee-list.dialog.html',
  styleUrl: './attendee-list.dialog.scss',
})
export class AttendeeListDialog {
  event?: Event;
  rsvpTypes = RSVPOptionType.getAllTypes();
  selectedRSVPType?: RSVPOptionType;

  allAttendees?: EventAttendee[];
  yesAttendees?: EventAttendee[];
  noAttendees?: EventAttendee[];
  maybeAttendees?: EventAttendee[];
  pendingAttendees?: EventAttendee[];
  waitlistAttendees?: EventAttendee[];
  filteredAttendees?: EventAttendee[];
  filteredAttendeesNames: string[] = [];
  eventCohosts: (User | undefined)[] = [];

  loggedUser?: User;

  selectedTabIndex = 0;

  rsvp_all = RSVPOptionType.ALL;
  rsvp_pending_approval = RSVPOptionType.PENDING_APPROVAL;

  searchTerm: string = '';
  statusMenuOpen: boolean = false;
  selectedStatus?: RSVPOptionType;
  restrictedView: boolean = false;

  selectedAttendee?: EventAttendee;
  displayMessagesDialog: boolean = false;
  sortType: string = 'name';

  constructor(
    public dialogRef: MatDialogRef<AttendeeListDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthService,
    private eventService: EventService,
    private translateService: TranslateService,
  ) {
    if (data) {
      this.event = data.event;
      this.selectedRSVPType = data.rsvpType;

      this.authService.userSubject
        .pipe(takeUntilDestroyed())
        .subscribe((user) => {
          if (user.id > 0) {
            this.loggedUser = user;
          }

          if (this.event) {
            this.rsvpTypes =
              this.event.rsvpOptions?.map((x) => x.type) ?? this.rsvpTypes;
            if (this.event.requireAttendeeApproval) {
              this.rsvpTypes.push(RSVPOptionType.PENDING_APPROVAL);
            }

            this.yesAttendees =
              this.event.getAttendees(RSVPOptionType.YES) || [];
            this.noAttendees = this.event.getAttendees(RSVPOptionType.NO) || [];
            this.maybeAttendees =
              this.event.getAttendees(RSVPOptionType.MAYBE) || [];
            this.pendingAttendees =
              this.event.getAttendeesByStatus(
                EventAttendeeStatus.PENDING_APPROVAL,
              ) || [];
            this.waitlistAttendees =
              this.event.getAttendeesByStatus(
                EventAttendeeStatus.ON_WAITLIST,
              ) || [];

            if (this.event.requireAttendeeApproval) {
              this.allAttendees = [
                ...this.yesAttendees,
                ...this.noAttendees,
                ...this.maybeAttendees,
              ];
            } else {
              this.allAttendees = [
                ...this.yesAttendees,
                ...this.noAttendees,
                ...this.maybeAttendees,
                ...this.waitlistAttendees,
              ];
            }

            this.onSortByName();
            this.setSelectedTabIndex();
            this.filterAttendees();
            this.eventCohosts =
              this.event.getActiveCoHosts()?.map((x) => x.user) || [];
            //  this.checkIfRsvp();
          }
        });
    }
  }

  close() {
    this.dialogRef.close({
      yesAttendees: this.yesAttendees,
      noAttendees: this.noAttendees,
      maybeAttendees: this.maybeAttendees,
      waitlistAttendees: this.waitlistAttendees,
      allAttendees: this.allAttendees,
      refresh: true,
    });
  }

  checkIfRsvp() {
    let isRsvp = false;
    if (this.allAttendees && this.loggedUser) {
      for (let i = 0; i < this.allAttendees.length; i++) {
        if (this.allAttendees[i].user?.id === this.loggedUser.id) {
          isRsvp = true;
        }
      }
      if (!isRsvp) {
        this.restrictedView = true;
      }
    }
  }

  sortFilters(filteredItems?: EventAttendee[]) {
    if (filteredItems) {
      if (this.sortType === 'name') {
        this.onSortByName();
      }
      if (this.sortType === 'status') {
        filteredItems = this.sortByStatus(filteredItems);
      }

      if (this.sortType === 'date-asc') {
        this.onSortByDate(true);
      }

      if (this.sortType === 'date-desc') {
        this.onSortByDate(false);
      }
    }
    return filteredItems;
  }
  getAttendees(type: RSVPOptionType): EventAttendee[] | undefined {
    switch (type) {
      case RSVPOptionType.YES: {
        if (this.searchTerm.length) {
          this.filteredAttendees = this.yesAttendees?.filter(
            (attendee) =>
              attendee
                .getName()
                .toLowerCase()
                .indexOf(this.searchTerm.toLowerCase()) >= 0,
          );
        } else {
          this.filteredAttendees = this.yesAttendees;
        }

        return this.sortFilters(this.filteredAttendees);
      }
      case RSVPOptionType.NO: {
        if (this.searchTerm.length) {
          this.filteredAttendees = this.noAttendees?.filter(
            (attendee) =>
              attendee
                .getName()
                .toLowerCase()
                .indexOf(this.searchTerm.toLowerCase()) >= 0,
          );
        } else {
          this.filteredAttendees = this.noAttendees;
        }

        return this.sortFilters(this.filteredAttendees);
      }
      case RSVPOptionType.MAYBE: {
        if (this.searchTerm.length) {
          this.filteredAttendees = this.maybeAttendees?.filter(
            (attendee) =>
              attendee
                .getName()
                .toLowerCase()
                .indexOf(this.searchTerm.toLowerCase()) >= 0,
          );
        } else {
          this.filteredAttendees = this.maybeAttendees;
        }

        return this.sortFilters(this.filteredAttendees);
      }
      case RSVPOptionType.ALL: {
        if (this.searchTerm.length) {
          this.filteredAttendees = this.allAttendees?.filter(
            (attendee) =>
              attendee
                .getName()
                .toLowerCase()
                .indexOf(this.searchTerm.toLowerCase()) >= 0,
          );
        } else {
          this.filteredAttendees = this.allAttendees;
        }

        return this.sortFilters(this.filteredAttendees);
      }
      case RSVPOptionType.PENDING_APPROVAL: {
        if (this.searchTerm.length) {
          this.filteredAttendees = this.pendingAttendees?.filter(
            (attendee) =>
              attendee
                .getName()
                .toLowerCase()
                .indexOf(this.searchTerm.toLowerCase()) >= 0,
          );
        } else {
          this.filteredAttendees = this.pendingAttendees;
        }

        return this.sortFilters(this.filteredAttendees);
      }
      default: {
        if (this.searchTerm.length) {
          this.filteredAttendees = this.allAttendees?.filter(
            (attendee) =>
              attendee
                .getName()
                .toLowerCase()
                .indexOf(this.searchTerm.toLowerCase()) >= 0,
          );
        } else {
          this.filteredAttendees = this.allAttendees;
        }

        return this.sortFilters(this.filteredAttendees);
      }
    }
  }

  getAttendeesByStatus(
    status: EventAttendeeStatus,
  ): EventAttendee[] | undefined {
    switch (status) {
      case EventAttendeeStatus.ON_WAITLIST: {
        if (this.searchTerm.length) {
          this.filteredAttendees = this.waitlistAttendees?.filter(
            (attendee) =>
              attendee
                .getName()
                .toLowerCase()
                .indexOf(this.searchTerm.toLowerCase()) >= 0,
          );
        } else {
          this.filteredAttendees = this.waitlistAttendees;
        }

        return this.filteredAttendees;
      }
    }

    return undefined;
  }

  getAttendeesLength(type: RSVPOptionType): number {
    const attendees = this.getAttendees(type);

    return attendees ? attendees.length : 0;
  }

  getAttendeesLengthByStatus(status: EventAttendeeStatus): number {
    const attendees = this.getAttendeesByStatus(status);

    return attendees ? attendees.length : 0;
  }

  setSelectedTabIndex() {
    if (this.event) {
      const hasYesAttendees = this.yesAttendees?.length;
      const hasNoAttendees = this.noAttendees?.length;
      const hasMaybeAttendees = this.maybeAttendees?.length;
      const hasWaitlistAttendees = this.waitlistAttendees?.length;

      if (this.selectedRSVPType === RSVPOptionType.YES) {
        this.selectedTabIndex = hasYesAttendees ? 1 : 0;
        return;
      } else if (this.selectedRSVPType === RSVPOptionType.NO) {
        this.selectedTabIndex = hasNoAttendees ? 2 : 0;
        return;
      } else if (this.selectedRSVPType === RSVPOptionType.MAYBE) {
        this.selectedTabIndex = hasMaybeAttendees ? 3 : 0;
        return;
      } else {
        this.selectedTabIndex = 0;
        return;
      }
    }
  }

  get font(): EventTemplateFont | undefined {
    return this.event?.eventTemplate?.font;
  }

  onSearchTermChange(searchTerm: string) {
    this.searchTerm = searchTerm;
    this.filterAttendees();
  }

  filterAttendees() {
    this.filteredAttendeesNames = (this.allAttendees ?? [])
      ?.filter(
        (attendee) =>
          attendee
            .getName()
            .toLowerCase()
            .indexOf(this.searchTerm.toLowerCase()) >= 0,
      )
      .map((attendee) => attendee.getName());
  }

  selectStatus(attendee: EventAttendee, status: RSVPOptionType): void {
    if (attendee.rsvpType !== status) {
      const dto: UpdateEventAttendeeRsvpOptionRequest = {
        attendeeId: attendee.id,
        rsvpOption: status,
      };
      this.eventService
        .updateAttendeeRSVPOption(this.event?.id, dto)
        .subscribe((result) => {
          this.yesAttendees =
            result?.filter(
              (attendee) =>
                attendee.rsvpType === RSVPOptionType.YES &&
                attendee.status === EventAttendeeStatus.ATTENDING,
            ) || [];
          this.noAttendees =
            result?.filter(
              (attendee) =>
                attendee.rsvpType === RSVPOptionType.NO &&
                attendee.status == EventAttendeeStatus.NOT_ATTENDING,
            ) || [];
          this.maybeAttendees =
            result?.filter(
              (attendee) =>
                attendee.rsvpType === RSVPOptionType.MAYBE &&
                attendee.status == EventAttendeeStatus.MAYBE_ATTENDING,
            ) || [];
          this.waitlistAttendees =
            result?.filter(
              (attendee) => attendee.status === EventAttendeeStatus.ON_WAITLIST,
            ) || [];
          this.pendingAttendees =
            result?.filter(
              (attendee) =>
                attendee.status === EventAttendeeStatus.PENDING_APPROVAL,
            ) || [];

          this.allAttendees = [
            ...this.yesAttendees,
            ...this.noAttendees,
            ...this.maybeAttendees,
          ];

          this.event ? (this.event.attendees = this.allAttendees) : undefined;
        });
    }
  }

  onPendingChange(attendee: EventAttendee, rsvpOptionType: RSVPOptionType) {
    const dto: UpdateEventAttendeeRsvpOptionRequest = {
      attendeeId: attendee.id,
      rsvpOption: rsvpOptionType,
    };

    if (rsvpOptionType === RSVPOptionType.YES) {
      dto.approve = true;
    } else if (rsvpOptionType === RSVPOptionType.NO) {
      dto.approve = false;
    }

    this.eventService
      .updateAttendeeRSVPOption(this.event?.id, dto)
      .subscribe((result) => {
        this.yesAttendees =
          result?.filter(
            (attendee) =>
              attendee.rsvpType === RSVPOptionType.YES &&
              attendee.status === EventAttendeeStatus.ATTENDING,
          ) || [];
        this.noAttendees =
          result?.filter(
            (attendee) =>
              attendee.rsvpType === RSVPOptionType.NO &&
              attendee.status == EventAttendeeStatus.NOT_ATTENDING,
          ) || [];
        this.maybeAttendees =
          result?.filter(
            (attendee) =>
              attendee.rsvpType === RSVPOptionType.MAYBE &&
              attendee.status == EventAttendeeStatus.MAYBE_ATTENDING,
          ) || [];
        this.waitlistAttendees =
          result?.filter(
            (attendee) => attendee.status === EventAttendeeStatus.ON_WAITLIST,
          ) || [];
        this.pendingAttendees =
          result?.filter(
            (attendee) =>
              attendee.status === EventAttendeeStatus.PENDING_APPROVAL,
          ) || [];

        if (this.event?.requireAttendeeApproval) {
          this.allAttendees = [
            ...this.yesAttendees,
            ...this.noAttendees,
            ...this.maybeAttendees,
          ];
        } else {
          this.allAttendees = [
            ...this.yesAttendees,
            ...this.noAttendees,
            ...this.maybeAttendees,
            ...this.waitlistAttendees,
          ];
        }
        this.onSortByName();
        this.allAttendees = this.sortFilters(this.allAttendees);

        this.event ? (this.event.attendees = this.allAttendees) : undefined;
      });
  }

  onSortByName() {
    this.sortType = 'name';
    const attendeeLists = [
      this.allAttendees,
      this.yesAttendees,
      this.noAttendees,
      this.maybeAttendees,
      this.waitlistAttendees,
      this.pendingAttendees,
    ];

    attendeeLists.forEach((attendeeList) => {
      if (attendeeList) {
        attendeeList = this.sortByName(attendeeList);
      }
    });
  }

  sortByName(items: EventAttendee[]) {
    return items.sort((a, b) => a.getName().localeCompare(b.getName()));
  }

  onSortByDate(asc: boolean) {
    const type = asc ? 'asc' : 'desc';
    this.sortType = `date-${type}`;
    const attendeeLists = [
      this.allAttendees,
      this.yesAttendees,
      this.noAttendees,
      this.maybeAttendees,
      this.waitlistAttendees,
      this.pendingAttendees,
    ];

    attendeeLists.forEach((attendeeList) => {
      if (attendeeList) {
        attendeeList = this.sortByDate(attendeeList, asc);
      }
    });
  }

  sortByDate(items: EventAttendee[], asc: boolean) {
    return items.sort((a, b) => {
      if (a.rsvpDate && b.rsvpDate) {
        if (asc) {
          return a.rsvpDate > b.rsvpDate ? 1 : -1;
        } else {
          return b.rsvpDate > a.rsvpDate ? 1 : -1;
        }
      }
      if (asc) {
        return a.id - b.id;
      } else {
        return b.id - a.id;
      }
    });
  }

  getAttendeeDisplayRSVPOptionName(attendee: EventAttendee): string {
    if (attendee.status === EventAttendeeStatus.ON_WAITLIST) {
      return 'APP.RSVP_OPTION.WAITLIST';
    }

    const rsvpOption = this.event?.rsvpOptions?.find(
      (rsvpOption) => rsvpOption.type === attendee.rsvpType,
    );
    if (rsvpOption) {
      return rsvpOption.name;
    }

    return RSVPOptionType.getDisplayName(attendee.rsvpType);
  }

  sortByStatus(items: EventAttendee[]) {
    return items.sort((a, b) => {
      const aRSVP = this.translateService.instant(
        this.getAttendeeDisplayRSVPOptionName(a),
      );
      const bRSVP = this.translateService.instant(
        this.getAttendeeDisplayRSVPOptionName(b),
      );
      return aRSVP.localeCompare(bRSVP);
    });
  }
  onSortByStatus() {
    this.sortType = 'status';
    if (this.allAttendees) {
      if (!this.filteredAttendees || this.filteredAttendees?.length === 0) {
        this.filteredAttendees = this.allAttendees;
      }
      this.filteredAttendees = this.sortByStatus(this.filteredAttendees);
    }
  }

  removeFilter(items: EventAttendee[]) {
    return items.sort((a, b) => {
      return a.id - b.id;
    });
  }
  handleRsvpChange(rsvp: boolean) {}
  getDisplayStatusName(status: RSVPOptionType): string {
    return RSVPOptionType.getDisplayName(status);
  }
  displayMsgForNotAttendingUser(attendee: EventAttendee) {
    this.selectedAttendee = attendee;
    this.displayMessagesDialog = true;
  }
  displayGuestListDialog() {
    this.displayMessagesDialog = false;
  }

  protected readonly EventAttendeeStatus = EventAttendeeStatus;
  protected readonly EventHost = EventHost;
}
