@if (eventTimetable) {
  <div
    class="timetable border-top"
    [class.border-top-none]="
      (eventType !== EventType.SPORTPAGE &&
        eventType !== EventType.LANDINGPAGE) ||
      isUaEvent
    "
  >
    @for (item of filteredItems; track item.id) {
      @if(isEdit){
        <div class='update-actions-menu'>
          <app-event-section-timetable-item
            [item]="item"
            [eventType]="eventType"
            [isUaEvent]="isUaEvent"
          >

          </app-event-section-timetable-item>
          <div
            class='menu-trigger'
            [matMenuTriggerFor]="menu">
            <i class="far fa-ellipsis-v"></i>
          </div>
          <mat-menu #menu="matMenu" class="table-actions-container">
            <button mat-menu-item (click)='onUpdate(UpdateActions.EDIT, item)'>
                <span class="menu-item-content">
                  <i class="far fa-pen"></i>
                  <span class="text">{{ 'APP.EDIT' | translate | camelCase }}</span>
                </span>
            </button>

            <button mat-menu-item (click)='onUpdate(UpdateActions.DELETE, item)'>
                <span class="menu-item-content">
                  <i class="far fa-trash"></i>
                  <span class="text"
                  >{{ 'APP.DELETE' |
                    translate | camelCase }}</span
                  >
                </span>
            </button>
          </mat-menu>
        </div>
      } @else {
        <app-event-section-timetable-item
          [item]="item"
          [eventType]="eventType"
          [isUaEvent]="isUaEvent"
        >
        </app-event-section-timetable-item>
      }

    }
  </div>
}
