@if (event && event.rsvpOptions) {
  @if (event.hasOneButtonSignUp() && yesRSVPOption) {
    @if (!editMode) {
      @if (currentUserRSVP === RSVPOptionType.YES) {
        @if (isOnWaitlist) {
          <button class="btn transparent-button disabled-icon-button">
            <i class="far fa-clock icon"></i>
            {{ "APP.WAIT_LIST.JOINED_WAIT_LIST" | translate }}
          </button>
        } @else {
          @if (isUaEvent) {
            <div class="rsvp-btn-section">
              <button
                class="btn transparent-button"
                [class.attendee-view]="isAttendeeView"
                [ngStyle]="{
                  'font-family': font?.bodyFont ? font?.bodyFont : ''
                }"
                (click)="onRSVP(RSVPOptionType.NO)"
              >
                @if (!isAttendeeView) {
                  {{ yesRSVPOption.name | translate }}
                } @else {
                  <i class="fal fa-check"></i>
                  {{ "APP.EVENTS.HAVE_REGISTERED" | translate }}
                }
              </button>
              <button
                class="btn transparent-button btn-secondary"
                [ngStyle]="{
                  'font-family': font?.bodyFont ? font?.bodyFont : ''
                }"
                (click)="onRSVP(RSVPOptionType.NO)"
              >
                {{ "APP.EVENT_RSVP.UNSUBSCRIBE" | translate }}
              </button>
            </div>
          } @else {
            <button
              class="btn transparent-button"
              [class.attendee-view]="isAttendeeView"
              [ngStyle]="{
                'font-family': font?.bodyFont ? font?.bodyFont : ''
              }"
              (click)="onRSVP(RSVPOptionType.NO)"
            >
              @if (!isAttendeeView) {
                {{ yesRSVPOption.name | translate }}
              } @else {
                <i class="fal fa-check"></i>
                {{ "APP.EVENTS.HAVE_REGISTERED" | translate }}
              }
            </button>
          }
        }
      } @else {
        <button
          class="btn transparent-button"
          [class.attendee-view]="isAttendeeView"
          [ngStyle]="{
            'font-family': font?.bodyFont ? font?.bodyFont : ''
          }"
          (click)="onRSVP(RSVPOptionType.YES)"
        >
          {{ yesRSVPOption.name | translate }}
        </button>
      }
    } @else {
      <div
        text-editable
        class="btn transparent-button"
        [ngStyle]="{
          'font-family': font?.bodyFont ? font?.bodyFont : ''
        }"
        maxlength="20"
        [ngModel]="yesRSVPOption.name | translate"
        (ngModelChange)="onRSVPOptionNameChanged($event, yesRSVPOption)"
      >
        {{ yesRSVPOption.name | translate }}
      </div>
    }
  } @else if (event.hasTwoButtonSignUp() && yesRSVPOption && noRSVPOption) {
    @if (!editMode) {
      @if (event.maxCapacityReached() && event.waitlistEnabled) {
        <div
          class="btn transparent-button"
          [class.attendee-view]="isAttendeeView"
          [ngStyle]="{
            'font-family': font?.bodyFont ? font?.bodyFont : ''
          }"
          [class.btn-invitation-transparent]="
            event.type === EventType.INVITATION
          "
          (click)="onRSVP(RSVPOptionType.YES)"
        >
          {{
            (yesRSVPOption ? yesRSVPOption.name : "APP.AUTH.JOIN_NOW")
              | translate
          }}
        </div>
      } @else {
        <div class="buttons-wrapper">
          @if (!event.isAttendee(loggedUserId)) {
            @for (
              rsvpOption of event.getMainRsvpOptions();
              track rsvpOption.type
            ) {
              <div
                class="btn transparent-button"
                [class.attendee-view]="isAttendeeView"
                [ngStyle]="{
                  'font-family': font?.bodyFont ? font?.bodyFont : ''
                }"
                [class.btn-invitation-transparent]="
                  event.type === EventType.INVITATION
                "
                (click)="onRSVP(rsvpOption.type)"
              >
                {{ rsvpOption.name | translate }}
              </div>
            }
          } @else {
            @if (
              event.isAttendee(loggedUserId) &&
              event.getAttendee(loggedUserId)?.rsvpType === RSVPOptionType.NO
            ) {
              <button
                class="btn transparent-button"
                [class.attendee-view]="isAttendeeView"
                [ngStyle]="{
                  'font-family': font?.bodyFont ? font?.bodyFont : ''
                }"
                (click)="onRSVP(RSVPOptionType.NO)"
              >
                {{ noRSVPOption.name | translate }}
              </button>
            } @else {
              <button
                class="btn transparent-button"
                [class.attendee-view]="isAttendeeView"
                [ngStyle]="{
                  'font-family': font?.bodyFont ? font?.bodyFont : ''
                }"
                (click)="onRSVP(RSVPOptionType.YES)"
              >
                <div class="going-button">
                  <span>{{ yesRSVPOption.name | translate }}</span>
                  <i class="far fa-check"></i>
                </div>
              </button>
            }
          }
        </div>
      }
    } @else {
      <div class="buttons-wrapper">
        <div
          class="btn transparent-button"
          text-editable
          [class.attendee-view]="isAttendeeView"
          maxlength="20"
          [ngModel]="yesRSVPOption.name | translate"
          (ngModelChange)="onRSVPOptionNameChanged($event, yesRSVPOption)"
          [ngStyle]="{
            'font-family': font?.bodyFont ? font?.bodyFont : ''
          }"
          [class.btn-invitation-transparent]="
            event.type === EventType.INVITATION
          "
          (click)="onRSVP(RSVPOptionType.YES)"
        >
          {{ yesRSVPOption.name | translate }}
        </div>
        <div
          class="btn transparent-button"
          text-editable
          [class.attendee-view]="isAttendeeView"
          maxlength="20"
          [ngModel]="noRSVPOption.name | translate"
          (ngModelChange)="onRSVPOptionNameChanged($event, noRSVPOption)"
          [ngStyle]="{
            'font-family': font?.bodyFont ? font?.bodyFont : ''
          }"
          [class.btn-invitation-transparent]="
            event.type === EventType.INVITATION
          "
          (click)="onRSVP(RSVPOptionType.NO)"
        >
          {{ noRSVPOption.name | translate }}
        </div>
      </div>
      <!--      @if(yesRSVPOption?.name && noRSVPOption?.name){
        <div class='buttons-wrapper'>
          <div
            text-editable
            class="btn transparent-button"
            [ngStyle]="{
          'font-family': font?.bodyFont ? font?.bodyFont : ''
        }"
            maxlength="20"
            [ngModel]="yesRSVPOption.name | translate"
            (ngModelChange)="onRSVPOptionNameChanged($event, yesRSVPOption)"
          >
            {{ yesRSVPOption.name | translate }}
          </div>
          <div
            text-editable
            class="btn transparent-button"
            [ngStyle]="{
          'font-family': font?.bodyFont ? font?.bodyFont : ''
        }"
            maxlength="20"
            [ngModel]="noRSVPOption.name | translate"
            (ngModelChange)="onRSVPOptionNameChanged($event, noRSVPOption)"
          >
            {{ noRSVPOption.name | translate }}
          </div>
        </div>
      }-->
    }
  } @else {
    <div class="rsvp-options">
      @for (rsvpOption of event.rsvpOptions; track rsvpOption.type) {
        @if (
          !userAttendeeRSVPOption ||
          userAttendeeRSVPOption.type === rsvpOption.type
        ) {
          <div class="rsvp-option-container">
            @if (
              getAttendees(rsvpOption.type) &&
              (showGuestCount || isLoggedUserHost) &&
              showGuestCountForHost
            ) {
              <div
                class="attendees"
                (click)="viewAttendeeList(rsvpOption.type)"
                [ngxTippy]="!event.showGuestList ? tooltipTemplate : null"
                [tippyProps]="{
                  trigger: 'click'
                }"
              >
                @for (
                  attendee of getAttendees(rsvpOption.type, true);
                  track attendee.id
                ) {
                  <app-user-avatar
                    [user]="attendee.user"
                    [size]="28"
                    [placeholderFontSize]="12"
                    [fallbackInitials]="attendee.getFallbackInitials()"
                  ></app-user-avatar>
                }
                @if (getAttendeesLength(rsvpOption.type) > 3) {
                  <div
                    class="more-attendees"
                    [style.width.px]="28"
                    [style.height.px]="28"
                    [style.backgroundColor]="font?.primaryLogoFontColor"
                    [style.color]="
                      getTextColorForBg(font?.primaryLogoFontColor)
                    "
                  >
                    +{{ getAttendeesLength(rsvpOption.type) - 3 }}
                  </div>
                }
              </div>

              <ng-template #tooltipTemplate>
                <div>
                  {{ "APP.EVENT_RSVP.GUEST_LIST_DISABLED" | translate }}
                </div>
              </ng-template>
            }
            <div
              class="rsvp-option"
              [class.editable]="editMode"
              (click)="onRSVP(rsvpOption.type)"
            >
              <div
                class="rsvp-emoji"
                (click)="openEmojiPicker(rsvpOption.type)"
              >
                {{ rsvpOption.emoji }}
              </div>
              @if (!editMode) {
                <div
                  class="rsvp-name"
                  appTextSize
                  [size]="'small'"
                  [ngStyle]="{
                    'font-family': font?.bodyFont ? font?.bodyFont : ''
                  }"
                >
                  {{ rsvpOption.name | translate }}
                </div>
              } @else {
                <div
                  text-editable
                  class="rsvp-name"
                  [ngStyle]="{
                    'font-family': font?.bodyFont ? font?.bodyFont : ''
                  }"
                  maxlength="10"
                  [ngModel]="rsvpOption.name | translate"
                  (ngModelChange)="onRSVPOptionNameChanged($event, rsvpOption)"
                >
                  {{ rsvpOption.name | translate }}
                </div>
              }
            </div>
          </div>
        }
      }
      @if (showEmojiPicker) {
        <emoji-mart
          id="emoji-palette"
          class="emoji-picker"
          title="{{ 'APP.EVENT_EDIT.PICK_YOUR_EMOJI' | translate }}"
          [showPreview]="false"
          [perLine]="8"
          (emojiClick)="changeEmoji($event)"
        ></emoji-mart>
      }
    </div>
  }
}
