<div class="tab-content">
  <div class="filter-container">
    <app-search-box
      [placeholder]="'APP.SEARCH'"
      (valueChanged)="onSearchTermChange($event)"
    ></app-search-box>

    <div class="header-actions-cont">
      <button
        (click)="exportGuestList()"
        class="btn btn-primary-outline export-btn"
        [class.spinner]="exportInProgress"
        [disabled]="exportInProgress"
      >
        {{ "APP.EXPORT" | translate }}
      </button>

      <div (click)="addPlusOne()" class="btn btn-primary export-btn">
        {{ "APP.EVENT_RSVP.ADD_PLUS_ONE_SHORT" | translate }}
      </div>
    </div>
  </div>

  <div class="table-scrollable" [class.loading]="dataSource.loading$ | async">
    <mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8">
      <!-- First Name Column -->
      <ng-container matColumnDef="firstName">
        <mat-header-cell mat-sort-header *matHeaderCellDef>{{
          "APP.FIRST_NAME" | translate
        }}</mat-header-cell>
        <mat-cell *matCellDef="let attendee">
          @if (attendee.userId) {
            <div class="ellipsis-field">
              {{ attendee.getFirstName() }}
            </div>
          } @else {
            <div
              text-editable
              class="table-text-editable"
              [attr.placeholder]="'APP.FIRST_NAME' | translate"
              [ngModel]="attendee.firstName"
              (ngModelChange)="updateAttendee(attendee, 'firstName', $event)"
            >
              {{ attendee.firstName }}
            </div>
          }
        </mat-cell>
      </ng-container>

      <!-- Last Name Column -->
      <ng-container matColumnDef="lastName">
        <mat-header-cell mat-sort-header *matHeaderCellDef>{{
          "APP.LAST_NAME" | translate
        }}</mat-header-cell>
        <mat-cell *matCellDef="let attendee">
          @if (attendee.userId) {
            <div class="ellipsis-field">
              {{ attendee.getLastName() }}
            </div>
          } @else {
            <div
              text-editable
              class="table-text-editable"
              [attr.placeholder]="'APP.LAST_NAME' | translate"
              [ngModel]="attendee.lastName"
              (ngModelChange)="updateAttendee(attendee, 'lastName', $event)"
            >
              {{ attendee.lastName }}
            </div>
          }
        </mat-cell>
      </ng-container>

      <!-- Email Column -->
      <ng-container matColumnDef="email">
        <mat-header-cell mat-sort-header *matHeaderCellDef>{{
          "APP.EMAIL" | translate
        }}</mat-header-cell>
        <mat-cell *matCellDef="let attendee">
          @if (attendee.userId) {
            {{ attendee.getEmail() || "-" }}
          } @else {
            <div
              text-editable
              class="table-text-editable"
              [attr.placeholder]="'APP.EMAIL' | translate"
              [ngModel]="attendee.email"
              (ngModelChange)="updateAttendee(attendee, 'email', $event)"
            >
              {{ attendee.email }}
            </div>
          }
        </mat-cell>
      </ng-container>

      <!-- Phone Column -->
      <ng-container matColumnDef="phone">
        <mat-header-cell mat-sort-header *matHeaderCellDef>{{
          "APP.USERS.PHONE" | translate
        }}</mat-header-cell>
        <mat-cell *matCellDef="let attendee">
          @if (attendee.userId) {
            {{ attendee.getPhone() || "-" }}
          } @else {
            <div
              text-editable
              class="table-text-editable"
              [attr.placeholder]="'APP.USERS.PHONE' | translate"
              [ngModel]="attendee.phone"
              (ngModelChange)="updateAttendee(attendee, 'phone', $event)"
            >
              {{ attendee.phone }}
            </div>
          }
        </mat-cell>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="actions" stickyEnd>
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let attendee">
          <button
            mat-icon-button
            [matMenuTriggerFor]="menu"
            class="more-vert-rotate-90"
            (click)="$event.stopPropagation()"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu" class="table-actions-container">
            <button mat-menu-item (click)="deleteAttendee(attendee)">
              <span class="menu-item-content">
                <i class="far fa-trash"></i>
                <span class="text">{{
                  "APP.AFFILIATE_PRODUCTS_PAGE_LIST.ACTIONS.DELETE" | translate
                }}</span>
              </span>
            </button>
          </mat-menu>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row
        [hidden]="dataSource.loading$ | async"
        *matRowDef="let row; columns: displayedColumns"
      ></mat-row>

      <div
        class="spinner-container d-flex justify-content-center"
        *ngIf="dataSource.loading$ | async"
      >
        <mat-spinner></mat-spinner>
      </div>
    </mat-table>
  </div>
</div>
