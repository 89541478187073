import { isPlatformServer } from '@angular/common';
import {
  Directive,
  ElementRef,
  Renderer2,
  OnInit,
  OnDestroy,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { Subscription, debounceTime, fromEvent } from 'rxjs';

@Directive({
  selector: '[appStickyFixed]',
  standalone: true,
})
export class StickyFixedDirective implements OnInit, OnDestroy {
  private isFixed: boolean = false;
  private scrollContainer: HTMLElement | null = null;
  private scrollSubscription: Subscription | null = null;

  isServer = false;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    @Inject(PLATFORM_ID) platformId: Object,
  ) {
    this.isServer = isPlatformServer(platformId);
  }

  ngOnInit() {
    if (this.isServer) {
      return;
    }

    this.scrollContainer = document.querySelector('.mat-sidenav-content');
    if (this.scrollContainer) {
      this.scrollSubscription = fromEvent(this.scrollContainer, 'scroll')
        .pipe(debounceTime(15))
        .subscribe(() => this.handleScroll());

      setTimeout(() => {
        this.handleScroll();
      });
    }
  }

  ngOnDestroy() {
    if (this.scrollSubscription) {
      this.scrollSubscription.unsubscribe();
    }
  }

  private setFixed(fixed: boolean) {
    if (fixed) {
      this.renderer.addClass(this.el.nativeElement, 'fixed');
      this.isFixed = true;
    } else {
      this.renderer.removeClass(this.el.nativeElement, 'fixed');
      this.isFixed = false;
    }
  }

  private handleScroll() {
    const parentRect =
      this.el.nativeElement.parentElement.getBoundingClientRect();

    let scrollTop = 0;
    if (this.scrollContainer) {
      scrollTop = this.scrollContainer.scrollTop;
    }

    if (parentRect.top <= scrollTop && this.isFixed) {
      this.setFixed(false);
    } else if (parentRect.top > scrollTop && !this.isFixed) {
      this.setFixed(true);
    }
  }
}
