@if (form) {
  <div [formGroup]="form" class="date-time-range-picker">
    <div class="daterange-info">
      <div
        class="date-info"
        [class.selected]="isStartDateSelection"
        (click)="onStartDateSelection()"
      >
        @if (selectedDateRange && selectedDateRange.start) {
          <div class="date">
            {{ selectedDateRange.start | date: "EEE MMM dd" }}
          </div>
          <div class="time">
            {{
              form.controls["startTime"].value
                ? form.controls["startTime"].value
                : ("APP.START_DATE" | translate)
            }}
          </div>
        } @else {
          <div class="date">{{ "APP.REQUIRED" | translate }}</div>
          <div class="time">{{ "APP.START_DATE" | translate }}</div>
        }
      </div>
      <div class="date-to">
        <i class="fal fa-chevron-right"></i>
      </div>
      <div
        class="date-info"
        [class.selected]="!isStartDateSelection"
        (click)="onEndDateSelection($event)"
      >
        @if (selectedDateRange && selectedDateRange.end) {
          <div class="date">
            {{ selectedDateRange.end | date: "EEE MMM dd" }}
          </div>
          <div class="time">
            <i
              class="far fa-minus remove"
              (click)="removeEndTime($event)"
              matTooltip="{{ 'APP.REMOVE_END_DATE' | translate }}"
              matTooltipPosition="above"
            ></i>
            {{
              form.controls["endTime"].value
                ? form.controls["endTime"].value
                : ("APP.END_DATE" | translate)
            }}
          </div>
        } @else {
          <div class="date empty">{{ "APP.OPTIONAL" | translate }}</div>
          <div class="time">
            <i
              class="far fa-plus"
              (click)="addEndDate($event)"
              matTooltip="{{ 'APP.ADD_END_DATE' | translate }}"
              matTooltipPosition="above"
            ></i>
            {{ "APP.END_DATE" | translate }}
          </div>
        }
      </div>
    </div>
    <mat-calendar
      class="event-theme-calendar"
      [dateClass]="dateClass()"
      [selected]="selectedDateRange"
      [startAt]="selectedDateRange?.start || startAt"
      [minDate]="todayDate"
      (selectedChange)="_onSelectedChange($event)"
    ></mat-calendar>

    <div class="sticky-section">
      <app-time-picker
        [class.display-none]="!isStartDateSelection"
        formControlName="startTime"
        [interval]="15"
        [format24Hours]="true"
        [size]="SelectionSize.SMALL"
        label="APP.START_TIME"
      ></app-time-picker>
      <app-time-picker
        [class.display-none]="isStartDateSelection"
        formControlName="endTime"
        [interval]="15"
        [format24Hours]="true"
        [size]="SelectionSize.SMALL"
        label="APP.END_TIME"
      ></app-time-picker>

      <app-timezone-select
        [form]="form"
        selectedFormControlName="timeZone"
        class="timezone"
      >
      </app-timezone-select>
    </div>
  </div>
}
