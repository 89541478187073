import { Component, Input } from '@angular/core';
import { EventAttendee } from '@models/event-attendance/event-attendee.model';

@Component({
  selector: 'app-history-details',
  standalone: true,
  imports: [],
  templateUrl: './history-details.component.html',
  styleUrl: './history-details.component.scss',
})
export class HistoryDetailsComponent {
  @Input() attendee?: EventAttendee;
}
