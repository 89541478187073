import { Component, Input } from '@angular/core';
import { EventTestimonial } from '@models/events/event-testimonial.model';
import { EventType } from '@models/events/event-type.enum';
import { Event } from '@models/events/event.model';

@Component({
  selector: 'app-event-section-testimonials-item',
  standalone: true,
  imports: [],
  templateUrl: './event-section-testimonials-item.component.html',
  styleUrl: './event-section-testimonials-item.component.scss',
})
export class EventSectionTestimonialsItemComponent {
  @Input() testimonial?: EventTestimonial;
  @Input() event?: Event;

  protected readonly EventType = EventType;
}
