import { Injectable } from '@angular/core';
import { Briefing } from '@models/briefings/briefing.model';
import { EventBriefingRequest } from '@models/briefings/dto/event-briefing.request';
import { EventBriefing } from '@models/briefings/event-briefing.model';
import { EventTemplateWidgetStatus } from '@models/design-templates/event-template-widget-status.enum';
import { AddEventMessageRequest } from '@models/events/dto/add-event-message.request';
import { CampaignEventVoteRequest } from '@models/events/dto/campaign-event-vote.request';
import { CreateEventTicketOrderRefundRequest } from '@models/events/dto/create-event-ticket-order-refund.request';
import { CreateOrUpdateEventEmailRequest } from '@models/events/dto/create-or-update-event-email.request';
import { EventTicketCheckInRequest } from '@models/events/dto/event-ticket-check-in.request';
import { ToggleSellTicketsRequest } from '@models/events/dto/toggle-sell-ticktes.request';
import { UpdateEventRSVPConfirmationPageSectionRequest } from '@models/events/dto/update-event-rsvp-confirmation-page-section.request';
import { UpdateEventTicketOrderRefundRequest } from '@models/events/dto/update-event-ticket-order-refund.request';
import { EventEmailType } from '@models/events/event-email-type.enum';
import { EventEmail } from '@models/events/event-email.model';
import { EventMessage } from '@models/events/event-message.model';
import { EventRSVPConfirmationPageSection } from '@models/events/event-rsvp-confirmation-page-section.model';
import { EventRsvpConfirmationPage } from '@models/events/event-rsvp-confirmation-page.model';
import { EventRSVPConfirmationPageType } from '@models/events/event-rsvp-confirmation-page.type.enum';
import { EventWidget } from '@models/events/event-widget.enum';
import { Observable, Subject, Subscription } from 'rxjs';
import { TicketingDashboardData } from '@models/tickets/dto/ticketing-dashboard-data.response';
import { environment as env } from '@environments/environment';
import { ApiService } from '@services/api.service';
import { catchError, concatMap, map, tap } from 'rxjs/operators';
import { EventCreationRequest } from '@models/events/dto/event-creation.request';
import { Event } from '@models/events/event.model';
import { EventSuggestedData } from '@models/events/dto/event-suggested-data.response';
import { EventRSVPRequest } from '@models/events/dto/event-rsvp.request';
import { EventHost } from '@models/events/event-host.model';
import { EmailRequest } from '@models/shared/email.request';
import { EventAttendee } from '@models/event-attendance/event-attendee.model';
import { UpdateEventAttendeeRsvpOptionRequest } from '@models/events/dto/update-event-attendee-rsvp-option.request';
import { EventUpdateRequest } from '@models/events/dto/event-update.request';
import { EventAIMoodBoardRequest } from '@models/events/dto/event-ai-mood-board.request';
import { EventAIMoodBoardResponse } from '@models/events/dto/event-ai-mood-board.response';
import { User } from '@models/users/user.model';
import { PageListingResponse } from '@models/api/page-listing-response.model';
import { EventInviteRequest } from '@models/events/dto/event-invite.request';
import { EventCohostAcceptOrRejectInvitationRequest } from '@models/events/dto/event-cohost-accept-or-reject-invitation.request';
import { VerifyAccessCodeRequest } from '@models/events/dto/verify-access-code.request';
import { CampaignEventRankingsResponse } from '@models/campaigns/dto/event-campaign-rating.response';
import { BlobWithFileName } from '@models/api/blob-with-filename.model';
import { saveAs } from 'file-saver';
import { EventAttendeesExportRequest } from '@models/events/dto/event-attendees-export.request';
import { SaveAttendeeRequest } from '@models/events/dto/save-attendee.request';
import { VerifyNoAccountRSVPRequest } from '@models/events/dto/verify-no-account-rsvp.request';
import { EventDeleteRequest } from '@models/events/dto/event-delete.request';
import { SetEventCampaignStatusRequest } from '@models/campaigns/dto/set-event-campaign-status.request';
import { SetEventCampaignRequest } from '@models/campaigns/dto/set-event-campaign.request';
import { EventAttendeeTicketOrder } from '@models/events/event-attendee-ticket-order.model';
import { CreateEventTicketOrderSessionRequest } from '@models/events/dto/create-event-ticket-order-session.request';
import { ConfirmEventTicketOrderRequest } from '@models/events/dto/confirm-event-ticket-order.request';
import { VerifyEventTicketRequest } from '@models/events/dto/verify-event-ticket.request';
import { VerifyEventTicketResponse } from '@models/events/dto/verify-event-ticket.response';

@Injectable()
export class EventService {
  private updateQueue = new Subject<{
    eventId?: number;
    req?: EventUpdateRequest;
    resolver: (event: Event) => void;
    rejecter: (err: any) => void;
  }>();
  private updateQueueSubscription: Subscription;

  constructor(private api: ApiService) {
    this.updateQueueSubscription = this.processUpdateQueue();
  }

  create(object: EventCreationRequest): Observable<Event> {
    return this.api
      .post(`${env.api}/events/create`, object)
      .pipe(map((it) => new Event(it)));
  }

  get(id: number): Observable<Event> {
    return this.api
      .get(`${env.api}/events/${id}`)
      .pipe(map((it) => new Event(it)));
  }

  getByUri(uri: string, lsParams?: Map<string, string>): Observable<Event> {
    return this.api
      .get(`${env.api}/events/uri/${uri}`, lsParams)
      .pipe(map((it) => new Event(it)));
  }

  setLinkBioVisibility(id: number, hidden: boolean) {
    return this.api.post(`${env.api}/events/${id}/set-link-bio-visibility`, {
      hidden: hidden,
    });
  }

  toggleSellTickets(eventId: number, request: ToggleSellTicketsRequest) {
    return this.api.post(
      `${env.api}/events/${eventId}/toggle-sell-tickets`,
      request,
    );
  }

  setAppStatus(
    eventId: number,
    app: EventWidget,
    status: EventTemplateWidgetStatus,
  ) {
    return this.api.post(`${env.api}/events/${eventId}/set-app-status`, {
      app: app,
      status: status,
    });
  }

  setLinkBioHighlight(id: number, highlighted: boolean) {
    return this.api.post(`${env.api}/events/${id}/set-link-bio-highlight`, {
      highlighted: highlighted,
    });
  }

  getCampaignEventsPageNumberOfEvent(lsParams?: Map<string, string>) {
    return this.api.get(
      `${env.api}/events/campaign-events/page-number`,
      lsParams,
    );
  }
  getEvents(filters: Map<string, any>): Observable<Event[]> {
    return this.api.get(`${env.api}/events`, filters).pipe(map(this.mapEvents));
  }

  getEventMessages(
    eventId: number,
    filters?: Map<string, any>,
  ): Observable<EventMessage[]> {
    return this.api
      .get(`${env.api}/events/${eventId}/messages`, filters)
      .pipe(map(this.mapEventMessages));
  }

  addEventMessage(eventId: number, request: AddEventMessageRequest) {
    return this.api.post(`${env.api}/events/${eventId}/messages`, request);
  }
  private mapEvents(res: Event[]) {
    return res?.map((it) => new Event(it));
  }

  private mapEventMessages(res: EventMessage[]) {
    return res?.map((it) => new EventMessage(it));
  }
  getEventsPaged(
    filters: Map<string, string>,
    pageNumber: number = 1,
    pageSize: number = 10,
  ): Observable<PageListingResponse<Event>> {
    return this.api
      .getPaged(`${env.api}/events/paged`, pageNumber, pageSize, filters)
      .pipe(map((it) => this.mapPaginatedEvents(it)));
  }

  getCampaignEventsPaged(
    filters: Map<string, string>,
    pageNumber: number = 1,
    pageSize: number = 10,
  ): Observable<PageListingResponse<Event>> {
    return this.api
      .getPaged(
        `${env.api}/events/campaign-events`,
        pageNumber,
        pageSize,
        filters,
      )
      .pipe(map((it) => this.mapPaginatedEvents(it)));
  }

  voteForEventCampaign(req?: CampaignEventVoteRequest): Observable<any> {
    return this.api.post(`${env.api}/events/campaign-events/vote`, req);
  }

  getCampaignRankings(
    req?: CampaignEventVoteRequest,
  ): Observable<CampaignEventRankingsResponse> {
    return this.api.post(`${env.api}/events/campaign-events/ranking`, req);
  }
  getWinnerCampaignRankings(
    req?: CampaignEventVoteRequest,
  ): Observable<CampaignEventRankingsResponse[]> {
    return this.api.post(
      `${env.api}/events/campaign-events/winning-rankings`,
      req,
    );
  }

  private mapPaginatedEvents(res: PageListingResponse<Event>) {
    return {
      total: res.total,
      records: res.records ? res.records.map((it) => new Event(it)) : [],
    };
  }

  getSuggestedData(
    prompt: string,
    campaignUri?: string,
  ): Observable<EventSuggestedData> {
    const params = new Map<string, string>();
    params.set('prompt', prompt);
    if (campaignUri) {
      params.set('campaignUri', campaignUri);
    }

    const userLang = localStorage.getItem('userLang');
    if (userLang) {
      params.set('userLanguage', userLang);
    }

    return this.api.get(`${env.api}/events/suggested-data`, params);
  }

  saveRSVP(eventId?: number, req?: EventRSVPRequest): Observable<User> {
    return this.api
      .post(`${env.api}/events/${eventId}/rsvp`, req)
      .pipe(map((it) => new User(it)));
  }

  verifyNoAccountRSVP(
    eventId: number,
    req: VerifyNoAccountRSVPRequest,
  ): Observable<void> {
    return this.api.post(
      `${env.api}/events/${eventId}/verify-no-account-rsvp`,
      req,
    );
  }

  exportEventAttendeesCsv(
    eventId: number,
    req: EventAttendeesExportRequest,
    fileName?: string,
  ): Observable<any> {
    return this.api
      .postBlobWithFileName(
        `${env.api}/events/${eventId}/export-attendees-csv`,
        req,
      )
      .pipe(
        tap((blobWithFileName: BlobWithFileName) => {
          saveAs(
            blobWithFileName.blob,
            fileName && fileName !== '' ? fileName : blobWithFileName.fileName,
          );
        }),
      );
  }

  addHost(eventId: number, req: EmailRequest): Observable<EventHost> {
    return this.api
      .post(`${env.api}/events/${eventId}/hosts`, req)
      .pipe(map((it) => new EventHost(it)));
  }

  removeHost(eventId: number, hostId: number): Observable<void> {
    return this.api.delete(`${env.api}/events/${eventId}/hosts/${hostId}`);
  }

  acceptOrRejectCoHostInvite(
    uri: string,
    req: EventCohostAcceptOrRejectInvitationRequest,
  ): Observable<void> {
    return this.api.post(
      `${env.api}/events/${uri}/co-host/accept-or-reject`,
      req,
    );
  }

  getTicketOrders(
    eventId?: number,
    forLoggedUser?: boolean,
  ): Observable<EventAttendeeTicketOrder[]> {
    let path = `${env.api}/events/${eventId}/ticket-orders`;
    if (forLoggedUser) {
      path += '?forLoggedUser=true';
    }
    const value = this.api.get(path).pipe(map(this.mapTicketOrders));
    return value;
  }

  private mapTicketOrders(res: EventAttendeeTicketOrder[]) {
    return res?.map((it) => new EventAttendeeTicketOrder(it));
  }

  getTicketingDashboardData(
    eventId?: number,
  ): Observable<TicketingDashboardData> {
    return this.api.get(
      `${env.api}/events/${eventId}/ticketing-dashboard-data`,
    );
  }

  updateAttendeeRSVPOption(
    eventId?: number,
    req?: UpdateEventAttendeeRsvpOptionRequest,
  ): Observable<EventAttendee[]> {
    return this.api
      .put(`${env.api}/events/update-attendee-rsvp/${eventId}`, req)
      .pipe(map(this.mapEventsAttendees));
  }

  saveAttendee(
    eventId: number,
    req: SaveAttendeeRequest,
  ): Observable<EventAttendee> {
    return this.api
      .post(`${env.api}/events/${eventId}/attendee`, req)
      .pipe(map((it) => new EventAttendee(it)));
  }

  deleteAttendee(eventId: number, attendeeId: number): Observable<void> {
    return this.api.delete(`${env.api}/events/${eventId}/rsvp/${attendeeId}`);
  }

  createRefund(orderId: number, request: CreateEventTicketOrderRefundRequest) {
    return this.api.post(
      `${env.api}/events/ticket-orders/${orderId}/create-refund`,
      request,
    );
  }

  updateTicketOrderRefund(
    orderRefundId: number,
    request: UpdateEventTicketOrderRefundRequest,
  ) {
    return this.api.post(
      `${env.api}/events/update-ticket-order-refund/${orderRefundId}`,
      request,
    );
  }

  getTicketOrderRefunds(eventId: number) {
    return this.api.get(`${env.api}/events/${eventId}/ticket-order-refunds`);
  }

  private mapEventsAttendees(res: EventAttendee[]) {
    return res?.map((it) => new EventAttendee(it));
  }

  updateEvent(eventId?: number, req?: EventUpdateRequest): Observable<Event> {
    const userLang = localStorage.getItem('userLang');
    if (req && userLang) {
      req.languageCode = userLang;
    }

    return new Observable<Event>((subscriber) => {
      this.updateQueue.next({
        eventId,
        req,
        resolver: (event) => {
          subscriber.next(event);
          subscriber.complete();
        },
        rejecter: (err) => {
          subscriber.error(err);
        },
      });
    });
  }

  private updateEventApiCall(
    eventId?: number,
    req?: EventUpdateRequest,
  ): Observable<Event> {
    return this.api
      .put(`${env.api}/events/update/${eventId}`, req)
      .pipe(map((it) => new Event(it)));
  }

  createAIMoodBoard(
    req: EventAIMoodBoardRequest,
  ): Observable<EventAIMoodBoardResponse> {
    const userLang = localStorage.getItem('userLang');
    if (userLang) {
      req.userLanguage = userLang;
    }
    return this.api.post(`${env.api}/events/ai-mood-board`, req);
  }

  createUserAtPublish(id?: string, req?: EventUpdateRequest): Observable<User> {
    return this.api
      .put(`${env.api}/events/${id}/create-new-user-at-publish`, req)
      .pipe(map((it) => new User(it)));
  }

  setEventCampaignStatus(
    eventId: number,
    req: SetEventCampaignStatusRequest,
  ): Observable<void> {
    return this.api.post(
      `${env.api}/events/${eventId}/set-campaign-status`,
      req,
    );
  }

  setEventCampaign(
    eventId: number,
    req: SetEventCampaignRequest,
  ): Observable<void> {
    return this.api.post(`${env.api}/events/${eventId}/set-campaign`, req);
  }

  delete(id: number, req?: EventDeleteRequest): Observable<void> {
    return this.api.post(`${env.api}/events/delete/${id}`, req);
  }

  duplicate(id: number): Observable<string> {
    return this.api.get(`${env.api}/events/${id}/duplicate`);
  }

  inviteGuests(id: number, req: EventInviteRequest): Observable<void> {
    return this.api.post(`${env.api}/events/${id}/invite-guests`, req);
  }

  downloadEventKeyVisual(keyVisualUrl: string) {
    if (keyVisualUrl.includes('unsplash') || keyVisualUrl.includes('giphy')) {
      return saveAs(keyVisualUrl);
    }

    const params = new Map<string, string>().set(
      'url',
      encodeURIComponent(keyVisualUrl),
    );

    return this.api
      .getBlobWithFileName<BlobWithFileName>(
        `${env.api}/download-asset`,
        params,
      )
      .subscribe({
        next: (blob) => {
          saveAs(blob.blob, blob.fileName);
        },
        error: () => {
          saveAs(keyVisualUrl);
        },
      });
  }

  getEventAttendeeByUuid(
    id: number,
    attendeeUuid: string,
  ): Observable<EventAttendee> {
    return this.api
      .get(`${env.api}/events/${id}/attendee/${attendeeUuid}`)
      .pipe(map((it) => new EventAttendee(it)));
  }

  verifyAccessCode(req: VerifyAccessCodeRequest): Observable<void> {
    return this.api.post(`${env.api}/events/verify-access-code`, req);
  }

  getRSVPConfirmationPageByType(
    id: number,
    pageType: EventRSVPConfirmationPageType,
  ): Observable<EventRsvpConfirmationPage> {
    return this.api.get(
      `${env.api}/events/${id}/rsvp-confirmation-page?pageType=${pageType}`,
    );
  }

  updateEventRSVPConfirmationPageSection(
    id: number,
    sectionId: number,
    request: UpdateEventRSVPConfirmationPageSectionRequest,
  ): Observable<EventRSVPConfirmationPageSection> {
    return this.api.put(
      `${env.api}/events/${id}/rsvp-confirmation-page-section/${sectionId}`,
      request,
    );
  }

  getEventEmail(id: number, emailType: EventEmailType): Observable<EventEmail> {
    return this.api.get(
      `${env.api}/events/${id}/custom-email?emailType=${emailType}`,
    );
  }

  updateEventEmail(
    id: number,
    request: CreateOrUpdateEventEmailRequest,
  ): Observable<EventEmail> {
    return this.api.put(`${env.api}/events/${id}/custom-email`, request);
  }

  // tickets
  createEventTicketOrderSession(
    eventId: number,
    req: CreateEventTicketOrderSessionRequest,
  ): Observable<EventAttendeeTicketOrder> {
    return this.api
      .post(`${env.api}/events/${eventId}/create-ticket-order-session`, req)
      .pipe(map((it) => new EventAttendeeTicketOrder(it)));
  }

  getEventTicketOrder(
    eventId: number,
    uuid: string,
  ): Observable<EventAttendeeTicketOrder> {
    return this.api
      .get(`${env.api}/events/${eventId}/ticket-order/${uuid}`)
      .pipe(map((it) => new EventAttendeeTicketOrder(it)));
  }

  confirmEventTicketOrder(
    req: ConfirmEventTicketOrderRequest,
  ): Observable<EventAttendeeTicketOrder> {
    return this.api
      .post(`${env.api}/events/confirm-ticket-order`, req)
      .pipe(map((it) => new EventAttendeeTicketOrder(it)));
  }

  leaveEventTicketOrderSession(uuid: string): Observable<void> {
    return this.api.post(
      `${env.api}/events/leave-ticket-order-session/${uuid}`,
      {},
    );
  }

  downloadTicketOrderPdf(uuid: string, fileName?: string, ticketUuid?: string) {
    return this.api
      .postBlobWithFileName(`${env.api}/events/download-ticket-order-pdf`, {
        uuid: uuid,
        ticketUuid: ticketUuid,
      })
      .pipe(
        tap((blobWithFileName: BlobWithFileName) => {
          saveAs(
            blobWithFileName.blob,
            fileName && fileName !== '' ? fileName : blobWithFileName.fileName,
          );
        }),
      );
  }

  downloadTicketOrderInvoicePdf(uuid: string, fileName?: string) {
    return this.api
      .postBlobWithFileName(
        `${env.api}/events/download-ticket-order-invoice-pdf`,
        {
          uuid: uuid,
        },
      )
      .pipe(
        tap((blobWithFileName: BlobWithFileName) => {
          saveAs(
            blobWithFileName.blob,
            fileName && fileName !== '' ? fileName : blobWithFileName.fileName,
          );
        }),
      );
  }

  getTicketOrderPdf(uuid: string, fileName?: string, ticketUuid?: string) {
    return this.api.postBlobWithFileName(
      `${env.api}/events/download-ticket-order-pdf`,
      {
        uuid: uuid,
        ticketUuid: ticketUuid,
      },
    );
  }

  cancelTicketOrderRefund(refundId: number) {
    return this.api.post(
      `${env.api}/events/cancel-ticket-order-refund/${refundId}`,
      {},
    );
  }

  downloadTicketOrderRefundPdf(orderRefundId: number, fileName?: string) {
    return this.api
      .postBlobWithFileName(
        `${env.api}/events/download-ticket-order-refund-pdf`,
        {
          id: orderRefundId,
        },
      )
      .pipe(
        tap((blobWithFileName: BlobWithFileName) => {
          saveAs(
            blobWithFileName.blob,
            fileName && fileName !== '' ? fileName : blobWithFileName.fileName,
          );
        }),
      );
  }

  verifyEventTicket(
    eventId: number,
    req: VerifyEventTicketRequest,
  ): Observable<VerifyEventTicketResponse> {
    return this.api.post(`${env.api}/events/${eventId}/verify-ticket`, req);
  }

  undoEventTicketCheckIn(
    eventId: number,
    req: EventTicketCheckInRequest,
  ): Observable<VerifyEventTicketResponse> {
    return this.api.post(`${env.api}/events/${eventId}/ticket-check-in`, req);
  }

  getEventTicketWalletPass(ticketUuid: string) {
    return this.api.get<string>(
      `${env.api}/events/order-ticket-wallet-pass/${ticketUuid}`,
    );
  }

  getEventTicketOrderWalletPassBundle(orderUuid: string) {
    return this.api.get<string>(
      `${env.api}/events/ticket-order-wallet-pass-bundle/${orderUuid}`,
    );
  }

  private processUpdateQueue(): Subscription {
    return this.updateQueue
      .pipe(
        concatMap(({ eventId, req, resolver, rejecter }) =>
          this.updateEventApiCall(eventId, req).pipe(
            map((event) => {
              resolver(event);
              return event;
            }),
            catchError((err) => {
              rejecter(err);
              throw err;
            }),
          ),
        ),
      )
      .subscribe();
  }

  getAllBriefings(eventId: number): Observable<Briefing[]> {
    return this.api.get(`${env.api}/events/briefings?eventId=${eventId}`);
  }

  createBriefing(
    eventId: number,
    request: EventBriefingRequest,
  ): Observable<EventBriefing> {
    return this.api.post(`${env.api}/events/${eventId}/briefings`, request);
  }

  updateBriefing(eventId: number, id: number, request: EventBriefingRequest) {
    return this.api.put(
      `${env.api}/events/${eventId}/briefings/${id}`,
      request,
    );
  }

  getAllEventBriefings(eventId: number): Observable<EventBriefing[]> {
    return this.api.get(`${env.api}/events/briefings/${eventId}`);
  }

  getEventBriefing(eventId: number, id: number): Observable<EventBriefing> {
    return this.api.get(`${env.api}/events/${eventId}/briefings/${id}`);
  }

  ngOnDestroy(): void {
    if (this.updateQueueSubscription) {
      this.updateQueueSubscription.unsubscribe();
    }
  }
}
