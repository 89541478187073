<div
  class="container"
  [style.height.px]="size"
  [style.width.px]="size"
  [class.selected]="selected"
  [class.create-event-view]="createEventView"
  [class.shadow]="displayShadow"
  [class.clickable]="selectMode"
  (click)="selectMode && chooseTemplate()"
>
  <span class="badge-pro" *ngIf="template.eventType && template.eventType !== EventType.PARTYPAGE">
    <i class="far fa-star"></i>
    {{ "APP.PRO" | translate }}
  </span>
  <img [src]="template.thumbnailUrl" alt="Template Thumbnail" class="image" />
  <div class="middle" *ngIf="!selectMode">
    <button
      *ngIf="allowActions"
      type="button"
      class="btn btn-primary-outline"
      [class.event-btn-outline]='eventColors'
      (click)="previewTemplate()"
    >
      {{ "APP.PREVIEW" | translate }}
    </button>
    <button
      *ngIf="allowActions && !previewOnly"
      type="button"
      class="btn btn-primary-party-page"
      [class.event-btn]='eventColors'
      (click)="chooseTemplate()"
      [class.spinner]="eventLoading"
      [disabled]="eventLoading"
    >
      {{ "APP.CREATE_EVENT.CHOOSE_THIS_TEMPLATE" | translate }}
    </button>
  </div>
</div>
