import { ClassData } from '@models/class-data';

export class CommunityAdditionalDataFieldOption {
  id?: number;
  communityAdditionalDataFieldId?: number;
  label: string;
  order: number;

  constructor(json: ClassData<CommunityAdditionalDataFieldOption>) {
    this.id = json.id;
    this.communityAdditionalDataFieldId = json.communityAdditionalDataFieldId;
    this.label = json.label;
    this.order = +json.order;
  }
}
