import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private themeSubject = new BehaviorSubject<string | null>(
    localStorage.getItem('userTheme'),
  );
  theme$ = this.themeSubject.asObservable();

  setTheme(theme: string): void {
    localStorage.setItem('userTheme', theme);
    this.themeSubject.next(theme);
  }

  getTheme(): string | null {
    return localStorage.getItem('userTheme');
  }
}
