@if (globalSettings) {
  <div class="page-container auth-page-center" [class.community-view]='isCommunity'>
    <app-sign-up-step0
      [class.display-none]="step !== 0"
      [phone]="phone"
      [email]="email"
      [hasNoAccount]="hasNoAccount"
      [selectedOption]="selectedOption"
      (selectOptionEvent)="selectOption($event)"
      (emailEntered)="onEmailEntered($event)"
      (phoneEntered)="onPhoneEntered($event)"
      [isCommunity]='isCommunity'
      [community]=community
      (toLogin)='onLogin()'
      [isRegisterWithoutEmail]="
        !!(globalSettings && globalSettings.isRegisterWithoutEmail())
      "
      [platform]="platform"
      class="auth-step-page-center"
    >
    </app-sign-up-step0>
    <app-sign-up-step1
      [class.display-none]="step !== 1"
      [selectedOption]="selectedOption"
      [email]="email"
      [phone]="phone"
      [isCommunity]='isCommunity'
      [signUpIsInProgress]="signUpIsInProgress"
      (next)="signUpFromForm($event)"
      class="auth-step-page-center"
    >
    </app-sign-up-step1>

    @if (selectedOption === "email") {
      <app-verify-email-step
        [class.display-none]="step !== 2"
        [userId]="userId"
        [email]="email"
        (verifyEmailSuccess)="onVerifyEmailSuccess()"
        class="auth-step-page-center"
      >
      </app-verify-email-step>
    } @else if (selectedOption === "phone") {
      <app-verify-phone-step
        [class.display-none]="step !== 2"
        [userId]="userId"
        [phone]="phone"
        [isWrongCode]="isWrongCode"
        (changePhone)="changePhone()"
        (verifyPhoneSuccess)="onVerifyPhoneSuccess($event)"
        class="auth-step-page-center"
      >
      </app-verify-phone-step>
    }

    <app-sign-up-referral-step
      [isCommunity]='isCommunity'
      [class.display-none]="step !== 3"
      [referralCode]="referralCode"
      [signUpIsInProgress]="signUpIsInProgress"
      [isWrongCode]="isWrongCode"
      (next)="signUpWReferral($event)"
      class="auth-step-page-center"
    >
    </app-sign-up-referral-step>

    <!--  <app-set-phone-step-->
    <!--    [class.display-none]="step !== 3"-->
    <!--    [userId]="userId"-->
    <!--    (enterPhoneRes)="onEnterPhoneRes($event)"-->
    <!--    class="auth-step-page-center"-->
    <!--  >-->
    <!--  </app-set-phone-step>-->

    @if (step === 2 && signUpIsInProgress) {
      <div class="full-page-loader-cont">
        <div class="loader"></div>
      </div>
    }
  </div>
}
