import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MatTooltip } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import { CreateEventTicketOrderSessionTicket } from '@models/events/dto/create-event-ticket-order-session.request';
import { EventTicket } from '@models/events/event-ticket.model';
import { EventType } from '@models/events/event-type.enum';
import { Event } from '@models/events/event.model';
import { TicketVoucherCodeResponse } from '@models/tickets/dto/ticket-voucher-code.response';
import { Voucher } from '@models/tickets/voucher.model';
import { EventTicketSelectionViewComponent } from '@modules/events/components/event-ticket-selection-view/event-ticket-selection-view.component';
import { EventPageLogoComponent } from '@modules/shared/components/event-page-logo/event-page-logo.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ScreenWidthService } from '@services/shared/screen-width.service';
import { TicketService } from '@services/tickets/ticket.service';
import { NumberUtils } from '@utils/number-utils';
import { FormsModules } from '@utils/shared-modules';
import moment from 'moment';
import { FooterComponent } from '../../../../components/footer/footer.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-event-tickets-selection',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatInput,
    EventPageLogoComponent,
    RouterLink,
    EventTicketSelectionViewComponent,
    FooterComponent,
    FormsModules,
    MatTooltip,
    MatExpansionModule,
  ],
  providers: [ScreenWidthService],
  templateUrl: './event-tickets-selection.component.html',
  styleUrl: './event-tickets-selection.component.scss',
})
export class EventTicketsSelectionComponent implements OnInit, OnDestroy {
  private _tickets?: EventTicket[];
  @Input() set tickets(tickets: EventTicket[] | undefined) {
    this._tickets = tickets;
    this.setFilteredTickets();
  }
  get tickets(): EventTicket[] | undefined {
    return this._tickets;
  }

  @Input() event?: Event;
  @Input() mainImage?: string;
  selectedDay?: Date;
  allDays?: boolean = true;
  fullPrice: number = 0;
  isDesktop?: boolean;
  form?: FormGroup;
  orderTickets: CreateEventTicketOrderSessionTicket[] = [];
  filteredTickets?: EventTicket[] = [];
  voucherApplied?: TicketVoucherCodeResponse;
  ticketsWithAppliedDiscount: number[] = [];
  unrecognizedCode?: string;
  currentTicketQuantity: number = 0;

  @Input() checkoutCreationLoading = signal(false);

  @Output() nextStep = new EventEmitter<
    CreateEventTicketOrderSessionTicket[]
  >();
  @Output() applyVoucher = new EventEmitter<TicketVoucherCodeResponse>();

  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();

  constructor(
    private translateService: TranslateService,
    private screenWidthService: ScreenWidthService,
    private fb: FormBuilder,
    private ticketService: TicketService,
  ) {
    this.form = this.fb.group({
      voucherCode: [null],
    });
    this.trackIsDesktopChange();
  }

  ngOnInit() {
    this.getPublicCode();
  }

  getPublicCode() {
    if (this.event) {
      this.ticketService.getPublicVoucherCode(this.event?.id).subscribe({
        next: (result) => {
          this.voucherApplied = result;
          this.applyVoucher.emit(result);
          this.form?.controls['voucherCode'].setValue(result.codeName);
          if (this.form) {
            this.form
              .get('voucherCode')
              ?.valueChanges.pipe(takeUntil(this._onDestroy))
              .subscribe((newValue) => {
                this.unrecognizedCode = undefined;
              });
          }
          this.form?.get('voucherCode')?.disable();
          if (result.tickets && result.tickets.length > 0) {
            this.ticketsWithAppliedDiscount = result.tickets
              .filter((x) => x.eventTicketId !== undefined)
              .map((x) => x.eventTicketId as number);
          }
        },
      });
    }
  }

  removeCode() {
    this.voucherApplied = undefined;
    this.applyVoucher.emit(undefined);
    this.currentTicketQuantity = 0;
    this.form?.get('voucherCode')?.enable();
    this.form?.controls['voucherCode'].setValue(undefined);
    this.calculateFullPrice();
  }

  checkIfDiscountApplied(ticket: EventTicket) {
    if (this.voucherApplied) {
      if (this.voucherApplied?.tickets?.length === 0) {
        if (!ticket.price || ticket.price === 0) {
          return false;
        }
        return true;
      } else {
        return !!(
          ticket?.id !== undefined &&
          this.ticketsWithAppliedDiscount?.includes(ticket.id) &&
          ticket.price !== 0
        );
      }
    } else {
      return false;
    }
  }

  applyCode() {
    if (this.form && this.form.valid && this.event) {
      const voucherCode = this.form.controls['voucherCode'].value;
      this.ticketService.getVoucherCode(voucherCode, this.event?.id).subscribe({
        next: (result) => {
          this.currentTicketQuantity = 0;
          this.voucherApplied = result;
          this.form?.get('voucherCode')?.disable();
          if (result) {
            this.applyVoucher.emit(result);
            this.form?.controls['voucherCode'].setValue(result.codeName);
            this.ticketsWithAppliedDiscount = [];

            if (result.tickets && result.tickets.length > 0) {
              this.ticketsWithAppliedDiscount = result.tickets
                .filter((x) => x.eventTicketId !== undefined)
                .map((x) => x.eventTicketId as number);
            }

            if (this.filteredTickets && result.limit) {
              for (let ticket of this.filteredTickets) {
                const existingTicketIdx = this.orderTickets.findIndex(
                  (orderTicket) => orderTicket.eventTicketId === ticket.id,
                );
                if (existingTicketIdx !== -1) {
                  let existingTicket = this.orderTickets[existingTicketIdx];
                  if (this.checkIfDiscountApplied(ticket)) {
                    const remainingLimit =
                      result.limit - this.currentTicketQuantity;
                    if (remainingLimit > 0) {
                      if (
                        remainingLimit < ticket.minAmountPerOrder ||
                        remainingLimit === ticket.minAmountPerOrder
                      ) {
                        this.orderTickets[existingTicketIdx].quantity = 0;
                      } else if (existingTicket.quantity > remainingLimit) {
                        this.currentTicketQuantity = result.limit;
                        this.orderTickets[existingTicketIdx].quantity =
                          remainingLimit;
                      } else {
                        this.currentTicketQuantity += existingTicket.quantity;
                      }
                    } else {
                      this.orderTickets[existingTicketIdx].quantity = 0;
                    }
                  }
                }
              }
            }
            this.calculateFullPrice();

            // TODO: set tickets qty if qty is higher than voucher limit
          }
        },
        error: () => {
          this.unrecognizedCode = voucherCode;
        },
      });
    } else {
      this.form?.markAsTouched();
    }
  }

  setFilteredTickets() {
    const currentDate = new Date();
    this.filteredTickets = this.tickets
      ?.filter(
        (ticket) => !ticket.saleEndDate || ticket.saleEndDate >= currentDate,
      )
      .slice()
      .sort((a, b) => {
        const isASoldOut = this.checkIfSoldOut(a);
        const isBSoldOut = this.checkIfSoldOut(b);

        if (isASoldOut && !isBSoldOut) {
          return 1;
        } else if (!isASoldOut && isBSoldOut) {
          return -1;
        } else {
          return 0;
        }
      });
  }

  filterDates(date: Date) {
    const currentDate = new Date();
    this.filteredTickets = this.tickets
      ?.filter(
        (ticket) => !ticket.saleEndDate || ticket.saleEndDate >= currentDate,
      )
      ?.filter((x) => !x.day || x.day.toDateString() === date.toDateString())
      .slice()
      .sort((a, b) => {
        const isASoldOut = this.checkIfSoldOut(a);
        const isBSoldOut = this.checkIfSoldOut(b);

        if (isASoldOut && !isBSoldOut) {
          return 1;
        } else if (!isASoldOut && isBSoldOut) {
          return -1;
        } else {
          return 0;
        }
      });
  }

  getDatesInRange(startDate?: Date, endDate?: Date): Date[] {
    const start = moment(startDate).startOf('day');
    const end = moment(endDate).startOf('day');

    const dates: Date[] = [];

    while (start.isSameOrBefore(end)) {
      dates.push(start.toDate());
      start.add(1, 'days');
    }
    return dates;
  }

  checkIfSoldOut(ticket: EventTicket) {
    return (
      !this.isBeforeStartSale(ticket) && ticket.getNumAvailableTickets() < 1
    );
  }

  isBeforeStartSale(ticket: EventTicket): boolean {
    if (ticket) {
      const today = new Date();

      if (!ticket.saleStartDate) {
        return false;
      }

      return ticket.saleStartDate ? today < ticket.saleStartDate : false;
    } else return false;
  }
  getEventDatesWithTickets(): Date[] {
    if (this.tickets && this.event) {
      const allDates: Set<string> = new Set();

      const hasAllDaysTicket = this.tickets.some((ticket) => !ticket.day);

      const startDate = this.event.startDate;
      const endDate = this.event.endDate;
      if (hasAllDaysTicket && startDate) {
        const updatedStartDate = new Date(startDate);
        updatedStartDate.setHours(14, 0, 0, 0);
        const currentDate = new Date(updatedStartDate);
        if (endDate) {
          while (currentDate <= endDate) {
            allDates.add(currentDate.toISOString().split('T')[0]);
            currentDate.setDate(currentDate.getDate() + 1);
          }
        } else {
          allDates.add(currentDate.toISOString().split('T')[0]);
        }
      } else {
        this.tickets.forEach((ticket) => {
          if (ticket.day) {
            allDates.add(ticket.day.toISOString().split('T')[0]);
          }
        });
      }
      return Array.from(allDates).map((dateStr) => new Date(dateStr));
    } else return [];
  }

  formatDate(date?: Date | null): string {
    if (!date) {
      return '';
    }
    const currentLang = this.translateService.currentLang;
    const locale = currentLang === 'de' ? 'de' : 'en';
    moment.locale(locale);
    return moment(date).format('dd. DD. MMMM');
  }

  selectDate(date: Date) {
    this.allDays = false;
    this.selectedDay = date;
    this.filterDates(date);
  }

  selectAllDays() {
    this.selectedDay = undefined;
    this.allDays = true;
    this.setFilteredTickets();
  }

  addTicket(ticket: EventTicket) {
    if (!ticket.id) {
      return;
    }

    const existingTicketIdx = this.orderTickets.findIndex(
      (orderTicket) => orderTicket.eventTicketId === ticket.id,
    );

    const currentOrder = this.orderTickets.find(
      (order) => order.eventTicketId === ticket.id,
    );

    if (
      this.voucherApplied &&
      (this.ticketsWithAppliedDiscount?.includes(ticket.id) ||
        this.ticketsWithAppliedDiscount?.length === 0)
    ) {
      if (
        (!currentOrder?.quantity || currentOrder?.quantity === 0) &&
        ticket.minAmountPerOrder
      ) {
        if (
          this.voucherApplied.limit &&
          (this.voucherApplied.limit >
            this.currentTicketQuantity + ticket.minAmountPerOrder ||
            this.voucherApplied.limit ===
              this.currentTicketQuantity + ticket.minAmountPerOrder)
        ) {
          this.currentTicketQuantity += ticket.minAmountPerOrder;
        } else {
          this.currentTicketQuantity++;
        }
      } else {
        this.currentTicketQuantity++;
      }
    }
    if (existingTicketIdx !== -1) {
      if (
        this.orderTickets[existingTicketIdx].quantity === 0 &&
        ticket.minAmountPerOrder
      ) {
        this.orderTickets[existingTicketIdx].quantity +=
          ticket.minAmountPerOrder;
      } else {
        this.orderTickets[existingTicketIdx].quantity += 1;
      }
    } else {
      this.orderTickets.push({
        eventTicketId: ticket.id,
        quantity: ticket.minAmountPerOrder ? ticket.minAmountPerOrder : 1,
        price: ticket.price,
      });
    }

    this.calculateFullPrice();
  }

  removeTicket(ticket: EventTicket) {
    if (!ticket.id) {
      return;
    }

    const existingTicketIdx = this.orderTickets.findIndex(
      (orderTicket) => orderTicket.eventTicketId === ticket.id,
    );

    const currentOrder = this.orderTickets.find(
      (order) => order.eventTicketId === ticket.id,
    );

    if (existingTicketIdx !== -1) {
      if (
        currentOrder &&
        currentOrder.quantity &&
        ticket.minAmountPerOrder &&
        currentOrder.quantity === ticket.minAmountPerOrder
      ) {
        this.orderTickets[existingTicketIdx].quantity = 0;
        this.currentTicketQuantity =
          this.currentTicketQuantity - ticket.minAmountPerOrder;
      } else {
        if (this.orderTickets[existingTicketIdx].quantity > 1) {
          this.orderTickets[existingTicketIdx].quantity -= 1;
        } else {
          this.orderTickets.splice(existingTicketIdx, 1);
        }
        if (
          this.ticketsWithAppliedDiscount?.includes(ticket.id) ||
          (this.ticketsWithAppliedDiscount?.length === 0 && this.voucherApplied)
        ) {
          this.currentTicketQuantity--;
        }
      }
      this.calculateFullPrice();
    }
  }

  /*   getTicketQuantity(ticketId: number) {
    const existingTicket = this.orderTickets.find(
      (orderTicket) => orderTicket.eventTicketId === ticketId,
    );

    const ticket = this.filteredTickets?.find((x) => x.id === ticketId);

    if (existingTicket && ticket) {
      if (
        this.checkIfDiscountApplied(ticket) &&
        this.voucherApplied &&
        this.voucherApplied.limit
      ) {
        const remainingLimit =
          this.voucherApplied.limit - this.currentTicketQuantity;
        if (remainingLimit > 0) {
          if (existingTicket.quantity > remainingLimit) {
            this.currentTicketQuantity = this.voucherApplied.limit;
            return remainingLimit;
          } else {
            this.currentTicketQuantity += existingTicket.quantity;
            return existingTicket.quantity;
          }
        } else {
          return 0;
        }
      }
      return existingTicket.quantity;
    } else {
      return 0;
    }
  } */

  getTicketQuantity(ticketId: number) {
    const existingTicket = this.orderTickets.find(
      (orderTicket) => orderTicket.eventTicketId === ticketId,
    );

    const ticket = this.filteredTickets?.find((x) => x.id === ticketId);

    if (existingTicket) {
      return existingTicket.quantity;
    } else {
      return 0;
    }
  }

  calculateFullPrice() {
    // let remainingLimit = this.voucherApplied?.limit ?? 0;

    this.fullPrice = this.orderTickets
      .map((orderTicket) => {
        let applicableQuantity = orderTicket.quantity;

        let price = orderTicket.price ?? 0;
        const ticket = this.filteredTickets?.find(
          (ticket) => ticket.id === orderTicket.eventTicketId,
        );
        if (ticket && ticket.price === 0) {
          return 0;
        }

        if (
          this.voucherApplied &&
          (this.ticketsWithAppliedDiscount?.length === 0 ||
            this.ticketsWithAppliedDiscount?.includes(
              orderTicket.eventTicketId,
            ))
        ) {
          // applicableQuantity = Math.min(orderTicket.quantity, remainingLimit);
          //remainingLimit -= applicableQuantity;
          if (this.voucherApplied.discountFixedPrice) {
            price = price - this.voucherApplied.discountFixedPrice;
          }
          if (this.voucherApplied.discountPercentage) {
            price =
              price * ((100 - this.voucherApplied.discountPercentage) / 100);
          }
        }

        return (price ?? 0) * applicableQuantity;
      })
      .reduce((a, b) => a + b, 0);
  }

  goPay() {
    if (this.orderTickets.length === 0) {
      return;
    }

    this.nextStep.emit(this.orderTickets);
  }
  private trackIsDesktopChange() {
    this.screenWidthService
      .isDesktop()
      .pipe(takeUntilDestroyed())
      .subscribe((isDesktop) => {
        this.isDesktop = isDesktop;
      });
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  protected readonly moment = moment;
  protected readonly NumberUtils = NumberUtils;
  protected readonly Voucher = Voucher;
  protected readonly EventType = EventType;
}
