<div class="campaign-dialog">
  <div class="campaign-header">
    <div (click)="close()" class="close-button">
      <i class="fal fa-times-circle"></i>
    </div>
  </div>

  @if (isShippingOff) {
    <div class="campaign-title">
      <h1>
        {{ "APP.CAMPAIGNS.INVALID_DATE.WG.SHIPPING_OFF.TITLE" | translate }}
      </h1>
    </div>
    <div class="campaign-content">
      <div class="description d-1">
        {{ "APP.CAMPAIGNS.INVALID_DATE.WG.SHIPPING_OFF.MESSAGE_1" | translate }}
      </div>
      <div class="description">
        {{ "APP.CAMPAIGNS.INVALID_DATE.WG.SHIPPING_OFF.DATE" | translate }}
      </div>
      <div class="campaign-time description">
        {{
          "APP.CAMPAIGNS.INVALID_DATE.WG.SHIPPING_OFF.DATE_DISPLAY" | translate
        }}
      </div>
      <div
        class="description d-2"
        [innerText]="
          'APP.CAMPAIGNS.INVALID_DATE.WG.SHIPPING_OFF.MESSAGE_2' | translate
        "
      ></div>
      <div class="ok-button">
        <button class="btn btn-primary-party-page" (click)="close(true)">
          {{ "APP.CAMPAIGNS.INVALID_DATE.OK" | translate }}
        </button>
      </div>
    </div>
  } @else if (this.isWgFimoChallenge) {
    <div class="campaign-title">
      <h1>{{ "APP.CAMPAIGNS.INVALID_DATE.WG_FIMO.TITLE" | translate }}</h1>
    </div>
    <div class="campaign-content">
      <div class="description d-1">
        {{ "APP.CAMPAIGNS.INVALID_DATE.WG_FIMO.MESSAGE_1" | translate }}
      </div>
      <div class="description">
        <span class="campaign-time-span"
          >13.01.2025
          {{
            "APP.CAMPAIGNS.INVALID_DATE.WG_FIMO.DATE_1" | translate
          }}
          28.02.2025</span
        >
        {{ "APP.CAMPAIGNS.INVALID_DATE.WG_FIMO.DATE_2" | translate }}
      </div>
      <div class="description d-2">
        {{ "APP.CAMPAIGNS.INVALID_DATE.WG_FIMO.MESSAGE_2" | translate }}
      </div>
      <div class="ok-button">
        <button class="btn btn-primary-party-page" (click)="close(true)">
          {{ "APP.CAMPAIGNS.INVALID_DATE.OK" | translate }}
        </button>
      </div>
    </div>
  } @else {
    @if (isBitsCampaign) {
      @if (isTooEarly) {
        <div class="campaign-title">
          <h1>
            {{ "APP.CAMPAIGNS.INVALID_DATE.BITS.EARLY.TITLE" | translate }}
          </h1>
        </div>
        <div class="campaign-content">
          <div class="description d-1">
            {{ "APP.CAMPAIGNS.INVALID_DATE.BITS.EARLY.MESSAGE_1" | translate }}
          </div>
          <div class="description">
            {{ "APP.CAMPAIGNS.INVALID_DATE.BITS.EARLY.DATE" | translate }}
          </div>
          <div class="campaign-time description">
            {{ date | date: "dd.MM.yyy" }}
          </div>
          <div class="description d-2">
            {{ "APP.CAMPAIGNS.INVALID_DATE.BITS.EARLY.MESSAGE_2" | translate }}
          </div>
          <div class="ok-button">
            <button class="btn btn-primary-party-page" (click)="close(true)">
              {{ "APP.CAMPAIGNS.INVALID_DATE.OK" | translate }}
            </button>
          </div>
        </div>
      } @else {
        <div class="campaign-title">
          <h1>
            {{ "APP.CAMPAIGNS.INVALID_DATE.BITS.LATE.TITLE" | translate }}
          </h1>
        </div>
        <div class="campaign-content">
          <div class="description d-1">
            {{ "APP.CAMPAIGNS.INVALID_DATE.BITS.LATE.MESSAGE_1" | translate }}
          </div>
          <div class="description">
            {{ "APP.CAMPAIGNS.INVALID_DATE.BITS.LATE.DATE" | translate }}
          </div>
          <div class="campaign-time description">
            {{ date | date: "dd.MM.yyy" }}
          </div>
          <div class="description d-2">
            {{ "APP.CAMPAIGNS.INVALID_DATE.BITS.LATE.MESSAGE_2" | translate }}
          </div>
          <div class="ok-button">
            <button class="btn btn-primary-party-page" (click)="close(true)">
              {{ "APP.CAMPAIGNS.INVALID_DATE.OK" | translate }}
            </button>
          </div>
        </div>
      }
    } @else if (isWgPartyChallenge) {
      @if (isTooEarly) {
        <div class="campaign-title">
          <h1>{{ "APP.CAMPAIGNS.INVALID_DATE.WG.EARLY.TITLE" | translate }}</h1>
        </div>
        <div class="campaign-content">
          <div class="description d-1">
            {{ "APP.CAMPAIGNS.INVALID_DATE.WG.EARLY.MESSAGE_1" | translate }}
          </div>
          <div class="description">
            {{ "APP.CAMPAIGNS.INVALID_DATE.EARLY.DATE" | translate }}
          </div>
          <div class="campaign-time description">
            {{ date | date: "dd.MM.yyy" }}
          </div>
          <div class="description d-2">
            {{ "APP.CAMPAIGNS.INVALID_DATE.EARLY.MESSAGE_2" | translate }}
          </div>
          <div class="ok-button">
            <button class="btn btn-primary-party-page" (click)="close(true)">
              {{ "APP.CAMPAIGNS.INVALID_DATE.OK" | translate }}
            </button>
          </div>
        </div>
      } @else {
        <div class="campaign-title">
          <h1>{{ "APP.CAMPAIGNS.INVALID_DATE.LATE.TITLE" | translate }}</h1>
        </div>
        <div class="campaign-content">
          <div class="description d-1">
            {{ "APP.CAMPAIGNS.INVALID_DATE.LATE.MESSAGE_1" | translate }}
          </div>
          <div class="description">
            {{ "APP.CAMPAIGNS.INVALID_DATE.LATE.DATE" | translate }}
          </div>
          <div class="campaign-time description">
            {{ date | date: "dd.MM.yyy" }}
          </div>
          <div class="description d-2">
            {{ "APP.CAMPAIGNS.INVALID_DATE.LATE.MESSAGE_2" | translate }}
          </div>
          <div class="ok-button">
            <button class="btn btn-primary-party-page" (click)="close(true)">
              {{ "APP.CAMPAIGNS.INVALID_DATE.OK" | translate }}
            </button>
          </div>
        </div>
      }
    } @else if (campaign) {
      @if (isTooEarly) {
        <div class="campaign-title">
          <h1>{{ "APP.CAMPAIGNS.INVALID_DATE.EARLY.TITLE" | translate }}</h1>
        </div>
        <div class="campaign-content">
          <div class="description d-1">
            {{
              "APP.CAMPAIGNS.INVALID_DATE.EARLY.MESSAGE_1_DYNAMIC"
                | translate
                  : {
                      campaignName: campaign.name,
                      daysInFuture: campaign.daysInFuture
                    }
            }}
          </div>
          <div class="description">
            {{ "APP.CAMPAIGNS.INVALID_DATE.EARLY.DATE" | translate }}
          </div>
          <div class="campaign-time description">
            {{ date | date: "dd.MM.yyy" }}
          </div>
          <div class="description d-2">
            {{ "APP.CAMPAIGNS.INVALID_DATE.EARLY.MESSAGE_2" | translate }}
          </div>
          <div class="ok-button">
            <button class="btn btn-primary-party-page" (click)="close(true)">
              {{ "APP.CAMPAIGNS.INVALID_DATE.OK" | translate }}
            </button>
          </div>
        </div>
      } @else {
        <div class="campaign-title">
          <h1>{{ "APP.CAMPAIGNS.INVALID_DATE.LATE.TITLE" | translate }}</h1>
        </div>
        <div class="campaign-content">
          <div class="description d-1">
            {{
              "APP.CAMPAIGNS.INVALID_DATE.LATE.MESSAGE_1_DYNAMIC"
                | translate: { campaignName: campaign.name }
            }}
          </div>
          <div class="description">
            {{ "APP.CAMPAIGNS.INVALID_DATE.LATE.DATE" | translate }}
          </div>
          <div class="campaign-time description">
            {{ date | date: "dd.MM.yyy" }}
          </div>
          <div class="description d-2">
            {{ "APP.CAMPAIGNS.INVALID_DATE.LATE.MESSAGE_2" | translate }}
          </div>
          <div class="ok-button">
            <button class="btn btn-primary-party-page" (click)="close(true)">
              {{ "APP.CAMPAIGNS.INVALID_DATE.OK" | translate }}
            </button>
          </div>
        </div>
      }
    }
  }
</div>
