import { CommonModule, UpperCasePipe } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { EventSection } from '@models/events/event-section.model';
import { Event } from '@models/events/event.model';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { EventTemplateVisual } from '@models/design-templates/event-template-visual.model';
import { EventType } from '@models/events/event-type.enum';

@Component({
  selector: 'app-event-section-location-date',
  standalone: true,
  imports: [CommonModule, TranslateModule, UpperCasePipe],
  templateUrl: './event-section-location-date.component.html',
  styleUrl: './event-section-location-date.component.scss',
})
export class EventSectionLocationDateComponent
  implements OnInit, AfterViewInit, OnChanges
{
  @Input() event?: Event;
  @Input() section?: EventSection;
  @Input() dates: Date[] = [];
  @Input() isDateEdit?: boolean = false;
  @Input() isAddressEdit?: boolean = false;
  @Output() updateDate = new EventEmitter();
  @Output() updateAddress = new EventEmitter();

  locationSafeHtml: SafeHtml | undefined;
  formatRange?: string;

  constructor(
    private sanitizer: DomSanitizer,
    private translateService: TranslateService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    setTimeout(() => {
      this.formatRange = this.formatDateRange(this.dates);
    }, 0);
  }

  ngOnInit(): void {
    this.locationSafeHtml = this.getLocationSafeHtml();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.formatRange = this.formatDateRange(this.dates);
    }, 0);
  }

  editDate() {
    this.updateDate.emit();
  }

  editAddress() {
    this.updateAddress.emit();
  }
  getTimeTableDates() {
    if (this.dates.length > 1) {
      return this.formatDateRange(this.dates);
    } else {
      return this.formatDateMonth(this.dates[0]);
    }
  }
  getLocationSafeHtml(): SafeHtml {
    if (this.isUaSection) {
      const address = this.event
        ?.getSportPageLocationDisplay(true)
        ?.toUpperCase();
      const htmlString = `<object
            data="https://static.eventpage.ai/event-templates/event-templates-1594/bullet.svg"
            type="image/svg+xml"
          ></object> ${address}`;
      return this.sanitizer.bypassSecurityTrustHtml(htmlString);
    } else {
      const address = this.event?.getSportPageLocationDisplay()?.toUpperCase();
      const htmlString = `<i class="fas fa-map-marker-smile yellow-icon"></i> ${address}`;
      return this.sanitizer.bypassSecurityTrustHtml(htmlString);
    }
  }

  formatDate(date?: Date) {
    if (!date) {
      return null;
    }
    const createdAtMoment = moment(date);
    return createdAtMoment.format('DD.MM.YYYY');
  }

  formatDateRange(dates: Date[]): string {
    if (!dates || dates.length === 0) {
      return '';
    }
    const sortedDates = dates.sort(
      (a, b) => new Date(a).getTime() - new Date(b).getTime(),
    );
    const firstDate = moment(sortedDates[0]);
    const lastDate = moment(sortedDates[sortedDates.length - 1]);

    const currentLang = this.translateService.currentLang;
    const locale = currentLang === 'de' ? 'de' : 'en';
    moment.locale(locale);

    if (firstDate.isSame(lastDate, 'day')) {
      return firstDate.format('DD MMMM YYYY');
    }

    if (lastDate.diff(firstDate, 'days') === 1) {
      if (firstDate.year() === lastDate.year()) {
        return `${firstDate.format('DD')}/${lastDate.format('DD MMMM YYYY')}`;
      } else {
        return `${firstDate.format('DD MMMM YYYY')} - ${lastDate.format(
          'DD MMMM YYYY',
        )}`;
      }
    }

    if (
      firstDate.year() === lastDate.year() &&
      firstDate.month() === lastDate.month()
    ) {
      return `${firstDate.format('DD')} - ${lastDate.format('DD MMMM YYYY')}`;
    }

    if (firstDate.year() === lastDate.year()) {
      return `${firstDate.format('DD MMMM')} - ${lastDate.format(
        'DD MMMM YYYY',
      )}`;
    }

    return `${firstDate.format('DD MMMM YYYY')} - ${lastDate.format(
      'DD MMMM YYYY',
    )}`;
  }

  formatDateMonth(date?: Date) {
    if (!date) {
      return null;
    }
    const currentLang = this.translateService.currentLang;

    const locale = currentLang === 'de' ? 'de' : 'en';
    moment.locale(locale);

    const createdAtMoment = moment(date);
    return createdAtMoment.format('D MMMM YYYY'); // This will use localized month names
  }

  formatTime(date?: Date) {
    if (!date) {
      return null;
    }
    const createdAtMoment = moment(date);
    return createdAtMoment.format('HH:mm');
  }

  get isUaSection(): boolean {
    return !!this.event?.uri.includes('underarmour-infinite-elite-testival');
  }

  get visual(): EventTemplateVisual | undefined {
    return this.event?.eventTemplate?.visual;
  }

  protected readonly EventType = EventType;
}
