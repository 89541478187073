import { ClassData } from '@models/class-data';

export class Address {
  id?: number;

  name?: string;

  street: string;
  streetNumber: string;
  postalCode: string;
  locality: string;
  country?: string;
  federalState?: string;

  lat: number;
  lng: number;

  placeId?: string;

  constructor(json: ClassData<Address>) {
    if (json.id !== undefined) {
      this.id = +json.id;
    }
    this.name = json.name;

    this.street = json.street;
    this.streetNumber = json.streetNumber;
    this.postalCode = json.postalCode;
    this.locality = json.locality;
    this.country = json.country;
    this.federalState = json.federalState;

    this.lat = +json.lat;
    this.lng = +json.lng;

    this.placeId = json.placeId;
  }

  isFilled(): boolean {
    return (
      !!this.street &&
      !!this.streetNumber &&
      !!this.postalCode &&
      !!this.locality
    );
  }

  toString(addHTMLLineBreakBeforePostalCode?: boolean): string {
    if (addHTMLLineBreakBeforePostalCode) {
      return `${this.street} ${this.streetNumber},<br/> ${this.postalCode} ${this.locality}`;
    }

    return `${this.street} ${this.streetNumber}, ${this.postalCode} ${this.locality}`;
  }

  toStringWithName(): string {
    if (this.name && this.name !== '') {
      return `${this.name}, ${this.street} ${this.streetNumber}, ${this.locality}`;
    }
    return `${this.street} ${this.streetNumber}, ${this.locality}`;
  }

  toStringWithNameWHtmlLineBreak(): string {
    if (this.name && this.name !== '') {
      return `${this.name}<br/>${this.street} ${this.streetNumber}<br/>${this.postalCode} ${this.locality}`;
    }
    return `${this.street} ${this.streetNumber}<br/>${this.postalCode} ${this.locality}`;
  }

  toDisplayForEventPrimary(): string {
    if (this.name && this.name !== '') {
      return this.name;
    }
    return `${this.street} ${this.streetNumber}`;
  }

  toDisplayForEventSecondary(): string {
    if (this.name && this.name !== '') {
      return `${this.street} ${this.streetNumber}, ${this.locality}`;
    }
    return this.locality;
  }

  toDisplayForEventCommunityCard(): string {
    if (this.name && this.name !== '') {
      return this.name;
    }
    return this.locality;
  }

  latLng(): string {
    return `${this.lat}, ${this.lng}`;
  }
}
