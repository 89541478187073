export enum EventAttendeeStatus {
  INVITED = 'INVITED',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  ON_WAITLIST = 'ON_WAITLIST',

  ATTENDING = 'ATTENDING',
  NOT_ATTENDING = 'NOT_ATTENDING',
  MAYBE_ATTENDING = 'MAYBE_ATTENDING',
}

export namespace EventAttendeeStatus {
  export function getAll() {
    return [
      EventAttendeeStatus.ATTENDING,
      EventAttendeeStatus.NOT_ATTENDING,
      EventAttendeeStatus.MAYBE_ATTENDING,
      EventAttendeeStatus.INVITED,
      EventAttendeeStatus.ON_WAITLIST,
      EventAttendeeStatus.PENDING_APPROVAL,
    ];
  }
  export function getDisplayName(status?: EventAttendeeStatus): string {
    switch (status) {
      case EventAttendeeStatus.ATTENDING:
        return 'APP.MESSAGES.RECIPIENT_GUESTS.YES_GUESTS';
      case EventAttendeeStatus.NOT_ATTENDING:
        return 'APP.MESSAGES.RECIPIENT_GUESTS.NO_GUESTS';
      case EventAttendeeStatus.MAYBE_ATTENDING:
        return 'APP.MESSAGES.RECIPIENT_GUESTS.MAYBE_GUESTS';
      case EventAttendeeStatus.INVITED:
        return 'APP.MESSAGES.RECIPIENT_GUESTS.INVITED_GUESTS';
      case EventAttendeeStatus.ON_WAITLIST:
        return 'APP.MESSAGES.RECIPIENT_GUESTS.WAIT_LIST_GUESTS';
      case EventAttendeeStatus.PENDING_APPROVAL:
        return 'APP.MESSAGES.RECIPIENT_GUESTS.PENDING_GUESTS';
      default:
        return '';
    }
  }
}
