<div
  class="user-icon-cont"
  [class.customer-view]="isCustomer"
  [class.no-margin]="noMargin"
  (click)="$event.stopPropagation()"
  [matMenuTriggerFor]="navUserMenu"
  [matMenuTriggerData]="{ data: menuData }"
>
  <div class="open-menu-btn">
    @if (loggedUser && loggedUser.avatarUrl !== "") {
      <img class="avatar" src="{{ loggedUser.avatarUrl }}" />
    } @else {
      <div class="avatar-placeholder">{{ menuData.initials }}</div>
    }

    @if (!isCustomer && !noMargin) {
      <div class="user-text">
        {{ menuData.headerText }}
      </div>
    }
  </div>
</div>

<mat-menu
  #navUserMenu="matMenu"
  [class]="
    'table-mat-menu-panel mb-1' + (!themeModeAvailable ? ' no-theme-mode' : '')
  "
>
  <ng-template matMenuContent let-data="data">
    @if (themeModeAvailable) {
      <div class="toggle-container">
        <div
          class="toggle-button"
          [class.active]="userTheme === 'dark'"
          (click)="changeUserTheme($event, 'dark')"
        >
          {{ "APP.THEME.DARK" | translate }}
        </div>
        <div
          class="toggle-button"
          [class.active]="userTheme === 'light'"
          (click)="changeUserTheme($event, 'light')"
        >
          {{ "APP.THEME.LIGHT" | translate }}
        </div>
        <div
          class="toggle-button"
          [class.active]="userTheme === 'system'"
          (click)="changeUserTheme($event, 'system')"
        >
          {{ "APP.THEME.SYSTEM" | translate }}
        </div>
      </div>
    }

    @for (item of data.items; track item.label) {
      <span [class]="item.fontColor">
        @if (item.url) {
          <a [routerLink]="item.url" mat-menu-item class="menu-btn">
            @if (item.icon) {
              <i class="fal {{ item.icon }}"></i>
            }
            {{ item.label | translate }}</a
          >
        }
        @if (item.event) {
          <a
            (click)="onMenuEvent(item.event)"
            href="javascript:void(0)"
            mat-menu-item
            class="menu-btn"
          >
            @if (item.icon) {
              <i class="fal {{ item.icon }}"></i>
            }
            {{ item.label | translate }}</a
          >
        }
        @if (!item.url && !item.event) {
          <a mat-menu-item class="menu-btn">
            @if (item.icon) {
              <i class="fal {{ item.icon }}"></i>
            }
            {{ item.label | translate }}</a
          >
        }
        @if (item.separator) {
          <hr class="menu-separator" />
        }
      </span>
    }
  </ng-template>
</mat-menu>
