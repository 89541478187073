import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class NotificationService {
  constructor(
    private toastr: ToastrService,
    private translateService: TranslateService,
  ) {}

  success(message: string) {
    this.toastr.success(message, undefined, this.toastrConfig);
  }

  error(message: string, title?: string) {
    this.toastr.error(message, title, this.toastrConfig);
  }

  warning(message: string) {
    this.toastr.warning(message, undefined, this.toastrConfig);
  }

  successWithClass(message: string, customClass: string) {
    this.toastr.success(message, undefined, {
      ...this.toastrConfig,
      toastClass: `${customClass} ngx-toastr`,
    });
  }

  saveSuccess() {
    this.toastr.success(
      this.translateService.instant('APP.TOASTS.CHANGES_SAVED'),
      undefined,
      this.toastrSaveSuccessConfig,
    );
  }

  saveError() {
    this.toastr.error(
      this.translateService.instant('APP.TOASTS.SAVE_ERROR'),
      undefined,
      this.toastrSaveSuccessConfig,
    );
  }

  private get toastrConfig() {
    return {
      closeButton: true,
      timeOut: 5000,
    };
  }

  private get toastrSaveSuccessConfig() {
    return {
      closeButton: true,
      timeOut: 1250,
    };
  }
}
