@if (isEventEditPicker) {
  <div
    class="event-address event-info-with-icon flex-input address-picker-field event-edit-textarea"
    (click)="openPickerDialog()"
  >
    <div class='icon'>
      <i class="fal fa-map-marker-alt"></i>
    </div>
    @if (address) {
      <div>
        <div class="event-info-primary">
          {{ address.toDisplayForEventPrimary() }}
        </div>
        <div class="event-info-sub">
          {{ address.toDisplayForEventSecondary() }}
        </div>
      </div>
      <i
        matSuffix
        class="fas fa-times clear-suffix-icon"
        (click)="clearAddress($event)"
      ></i>
    } @else {
      <span class="event-info-primary">
        {{ "APP.EVENT_EDIT.ADDRESS_PLACEHOLDER" | translate }}
      </span>
    }
  </div>
} @else {
  <mat-form-field
    appearance="outline"
    class="flex-input address-picker-field"
    (click)="openPickerDialog()"
  >
    <mat-label>{{ "APP.LOCATION_ADDRESS" | translate }}</mat-label>
    <input
      type="text"
      matInput
      [formControl]="addressControl"
      placeholder=""
      readonly
    />
    @if (address) {
      <i
        matSuffix
        class="fas fa-times clear-suffix-icon"
        (click)="clearAddress($event)"
      ></i>
    }
  </mat-form-field>
}
